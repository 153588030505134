import React from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import UserEdit from './edit';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';

const User = () => {
    const setDefaultFilterValue = () => {
        if (NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'])) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'customer.id', condition: 'is_null', value: '' },
            ];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    return (
        <NyDataTable
            headerTitle={geti18nText('user.table.header')}
            url={CONSTANTS_REQ.USER.LIST}
            addNewButtonText={geti18nText('user.table.add')}
            scroll={{ x: 800 }}
            exportCSV={true}
            showRecordModal={NySession.hasAnyRole(['ROLE_AUTH_ADMIN'])}
            modalComponent={UserEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            hideButtons={!NySession.hasAnyRole(['ROLE_AUTH_ADMIN'])}
            readonly={!NySession.hasAnyRole(['ROLE_AUTH_ADMIN'])}
            columns={[
                {
                    title: geti18nText('user.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('user.table.column.firstName'),
                    dataIndex: 'first_name',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('user.table.column.lastName'),
                    dataIndex: 'last_name',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('user.table.column.username'),
                    dataIndex: 'username',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('user.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default User;
