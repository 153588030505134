import React, { useState, useEffect } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
//import UserEdit from './UserEdit';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { GetEnumNameForValue, GetEnum } from '../../utils/Enums';
import AlarmEdit from './AlarmEdit';
import { useDispatch } from 'react-redux';
import { selectedItemSet } from '../../slices/selectedItemSlice';
import {
    ENUMS,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySession,
    NyUtils,
} from '@nybble/nyreact';

const AlarmTable = () => {
    const { item } = useSelector((state: RootState) => state.selectedItem);
    const dispatch = useDispatch();
    const [dataForm, setDataForm] = useState(null);

    const setDefaultFilterValue = () => {
        return [{ field: 'userId', condition: 'equals', value: item }];
    };

    const alarmTypes = () => {
        const aTypes = GetEnum('ALARM_STATUS');
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.ALARM_STATUS.' + key);
                types.push(ret);
            }
        }
        return types;
    };

    useEffect(() => {
        dispatch(selectedItemSet({ item: item, refreshItem: 0 }));
    }, [dataForm]);

    return (
        <NyDataTable
            headerTitle={geti18nText('user.alarm.table.header')}
            url={CONSTANTS_REQ.USER.USER_ALARM_LIST}
            addNewButtonText={geti18nText('user.alarm.table.add')}
            showRecordModal={true}
            exportCSV={true}
            scroll={{ x: 800 }}
            modalComponent={AlarmEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            hideButtons={!NySession.hasAnyRole(['ROLE_AUTH_ADMIN'])}
            columns={[
                {
                    title: geti18nText('user.alarm.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('user.alarm.table.column.alarmStatus'),
                    dataIndex: 'alarmStatus',
                    sorter: (a: any, b: any) => {},
                    render: (text: string, record: { [index: string]: any }) => {
                        return (
                            <div>
                                <b style={{ color: 'red' }}>
                                    {geti18nText(
                                        'app.enum.ALARM_STATUS.' +
                                            GetEnumNameForValue('ALARM_STATUS', record.alarmStatus)
                                    )}
                                </b>
                            </div>
                        );
                    },
                    ...getColumnSearchOption(alarmTypes()),
                },
                {
                    title: geti18nText('user.alarm.table.column.tsFrom'),
                    dataIndex: 'tsFrom',
                    sorter: (a: any, b: any) => {},
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.tsFrom) {
                            const ts: Date = new Date(record.tsFrom);
                            return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                        }
                    },
                },
                {
                    title: geti18nText('user.alarm.table.column.tsTo'),
                    dataIndex: 'tsTo',
                    sorter: (a: any, b: any) => {},
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.tsTo) {
                            const ts: Date = new Date(record.tsTo);
                            return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                        }
                    },
                },
                {
                    title: geti18nText('user.alarm.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default AlarmTable;
