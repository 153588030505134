import Dashboard from '../../views/dashboard';
import Country from '../../views/country';
import Device from '../../views/device';
import DeviceType from '../../views/device-type';
import CardDetails from '../../views/card-details';
import CustomersIndex from '../../views/customers';
import User from '../../views/user';
import DeviceGroup from '../../views/device-groups';
import WarehouseIndex from '../../views/warehouse';
import TasksIndex from '../../views/tasks';
import DeviceModelIndex from '../../views/device-model';
import DMAIndex from '../../views/dma';
import ApplicationIndex from '../../views/application';
import ConsumersIndex from '../../views/consumers';
import GatewaysIndex from '../../views/gateways';
import ImportExport from '../../views/importExport';
import Lights from '../../views/lights';
import LightsGroup from '../../views/lights-group';
import LightDetails from '../../views/light-details';
import EnergyMeters from '../../views/energy';
import EnergyMeterGroup from '../../views/energy-grup';
import ApplicationSettingsIndex from '../../views/application-settings/index';
import WeatherMeters from '../../views/weather-station';
import WeatherMeterGroup from '../../views/weather-station/groups';
import WaterMeterDashboard from '../../views/dashboard/WaterMeterDashboard';
import DashboardMain from '../../views/dashboard/dashboardMain';
import LightsDashboard from '../../views/dashboard/LightsDashboard';
import EnergyDashboard from '../../views/dashboard/EnergyDashboard';
import WeatherDashboard from '../../views/dashboard/WeatherDashboard';
import AlarmSettingsIndex from '../../views/alarm-settings';
import WeatherDeviceAlarmTabsIndex from '../../views/alarm-settings/tabs';
import WeatherStateCategoryIndex from '../../views/weather-state-category';
import WeatherMainDashboard from '../../views/dashboard/WeatherMainDashboard';
import CategoryIndex from '../../views/category';
import ParameterCatalogIndex from '../../views/parameter-catalog';
import DashboardMapMain from '../../views/dashboard/dashboardMap';

export const mainRoutes = [
    {
        path: '/',
        exact: true,
        component: DashboardMain,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER', 'ROLE_CUSTOMER_SPECTATOR'],
    },
    {
        path: '/dashboard',
        exact: true,
        component: DashboardMapMain,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER', 'ROLE_CUSTOMER_SPECTATOR'],
    },
    {
        path: '/countries',
        exact: true,
        component: Country,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'],
    },
    {
        path: '/devices',
        exact: true,
        component: Device,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/deviceTypes',
        exact: true,
        component: DeviceType,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'],
    },
    {
        path: '/card-details',
        exact: true,
        component: CardDetails,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER', 'ROLE_CUSTOMER_SPECTATOR'],
    },
    {
        path: '/light-details',
        exact: true,
        component: LightDetails,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER', 'ROLE_CUSTOMER_SPECTATOR'],
    },
    {
        path: '/customers',
        exact: true,
        component: CustomersIndex,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'],
    },
    {
        path: '/users',
        exact: true,
        component: User,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/device-groups',
        exact: true,
        component: DeviceGroup,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/warehouse',
        exact: true,
        component: WarehouseIndex,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/tasks',
        exact: true,
        component: TasksIndex,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/device-models',
        exact: true,
        component: DeviceModelIndex,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/dma',
        exact: true,
        component: DMAIndex,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/applications',
        exact: true,
        component: ApplicationIndex,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/consumers',
        exact: true,
        component: ConsumersIndex,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/gateways',
        exact: true,
        component: GatewaysIndex,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/import-export',
        exact: true,
        component: ImportExport,
        role: ['ROLE_CUSTOMER'],
    },
    {
        path: '/lights',
        exact: true,
        component: Lights,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/lightsGroup',
        exact: true,
        component: LightsGroup,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/energyMeter',
        exact: true,
        component: EnergyMeters,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/energyMetersGroup',
        exact: true,
        component: EnergyMeterGroup,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/applicationSettings',
        exact: true,
        component: ApplicationSettingsIndex,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'],
    },
    {
        path: '/weatherMeter',
        exact: true,
        component: WeatherMeters,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    // {
    //     path: '/weatherMeterCategory',
    //     exact: true,
    //     component: WeatherStateCategoryIndex,
    //     role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    // },
    {
        path: '/weatherMetersGroup',
        exact: true,
        component: WeatherMeterGroup,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/waterMeterDashboard',
        exact: true,
        component: WaterMeterDashboard,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER', 'ROLE_CUSTOMER_SPECTATOR'],
    },

    {
        path: '/lightsDashboard',
        exact: true,
        component: LightsDashboard,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER', 'ROLE_CUSTOMER_SPECTATOR'],
    },
    {
        path: '/energyDashboard',
        exact: true,
        component: EnergyDashboard,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER', 'ROLE_CUSTOMER_SPECTATOR'],
    },
    {
        path: '/weatherDashboard',
        exact: true,
        component: WeatherMainDashboard,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER', 'ROLE_CUSTOMER_SPECTATOR'],
    },
    {
        path: '/alarmSettings',
        exact: true,
        component: WeatherDeviceAlarmTabsIndex,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/category',
        exact: true,
        component: CategoryIndex,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
    {
        path: '/parameterCatalog',
        exact: true,
        component: ParameterCatalogIndex,
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
    },
];
