import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISelectedCustomerState {
    customer: any;
    categoryType: any;
}

const initialState: ISelectedCustomerState = {
    customer: undefined,
    categoryType: undefined
};

const selectedCustomer = createSlice({
    name: 'selectedCustomer',
    initialState,
    reducers: {
        selectedCustomerInit(state) {
            state.customer = {};
            state.categoryType = [];
        },
        selectedCustomerSet(state, action: PayloadAction<ISelectedCustomerState>) {
            const { customer,categoryType } = action.payload;
            state.customer = customer;
            state.categoryType = categoryType;
        },
    },
});

export const { selectedCustomerInit, selectedCustomerSet } = selectedCustomer.actions;

export default selectedCustomer.reducer;
