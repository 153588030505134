import React, { useState } from 'react';
import { Form, Row, Col, Input } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { GetEnum } from '../../utils/Enums';
import QRCode from 'qrcode.react';
import { geti18nText, NyDataEdit, NySearchField, NySession } from '@nybble/nyreact';

const ApplicationEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('application.edit.new'));
    const [loading, setLoading] = useState(false);
    const [showQRCode, setShowQRCode] = useState(false);
    const [qrCodeValue, setQrCodeValue] = useState('');
    const [refIdValue, setRefIdValue] = useState(uuidv4());
    const [isCreate, setIsCreate] = useState(false);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setEditHeader(dataForm.name);
        let qr = { guid: dataForm.refId, name: dataForm.name };
        setQrCodeValue(JSON.stringify(qr));
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    function onSaveAndGetID(id: number) {
        if (id > 0) {
            let qr = { guid: refIdValue, name: form.getFieldValue('name') };
            setQrCodeValue(JSON.stringify(qr));
            setShowQRCode(true);
        }
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('application.edit.new'));
        setShowQRCode(false);
        setRefIdValue(uuidv4());
        setQrCodeValue('');
    };

    return (
        <NyDataEdit
            {...props}
            layout="horizontal"
            formProps={{ labelCol: { span: 5 }, wrapperCol: { span: 14 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.APPLICATION.EDIT}
            setValues={setValues}
            width={1000}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            shouldCloseModal={!isCreate || (isCreate && showQRCode)}
            onSaveAndGetID={onSaveAndGetID}
            onModalClose={onModalClose}
            hideActivationButtons={isCreate}
            normalize={(values: any) => {
                if (values.customer) {
                    values.customer = {
                        id: values.customer.constructor === Number ? values.customer : values.customer.id,
                    };
                }
                values.refId = isCreate ? refIdValue : values.refId;
                values.active = isCreate ? false : values.active;
                values.appType = GetEnum('APPLICATION_TYPE')['ANDROID'];
                return values;
            }}
        >
            {(!isCreate || showQRCode) && (
                <Row>
                    <Col xs={{ span: 3, offset: 3 }} md={{ span: 8, offset: 8 }}>
                        <QRCode size={256} value={qrCodeValue} />
                        <br />
                        <br />
                    </Col>
                </Row>
            )}
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    {NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) && (
                        <Form.Item label={geti18nText('device.edit.customer')} name="customer">
                            <NySearchField
                                url={CONSTANTS_REQ.CUSTOMER.SEARCH}
                                map={{ id: 'id', label: 'name' }}
                                searchBy="name"
                            />
                        </Form.Item>
                    )}
                    {!isCreate && (
                        <Form.Item label={geti18nText('application.edit.refid')} name="refId">
                            <Input disabled={true} />
                        </Form.Item>
                    )}
                    <Form.Item
                        label={geti18nText('application.edit.name')}
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default ApplicationEdit;
