import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISelectedDeviceGroupSlice {
    device: any;
    refreshDevice: number;
}

const initialState: ISelectedDeviceGroupSlice = {
    device: undefined,
    refreshDevice: 0,
};

const selectedDeviceGroupSlice = createSlice({
    name: 'selectedDeviceGroupSlice',
    initialState,
    reducers: {
        selectedDeviceInit(state) {
            state.device = {};
            state.refreshDevice = 0;
        },
        selectedDeviceSet(state, action: PayloadAction<ISelectedDeviceGroupSlice>) {
            const { device, refreshDevice } = action.payload;
            state.device = device;
            state.refreshDevice = refreshDevice;
        },
        refreshDeviceList(state) {
            state.refreshDevice++;
        },
    },
});

export const { selectedDeviceInit, selectedDeviceSet, refreshDeviceList } = selectedDeviceGroupSlice.actions;

export default selectedDeviceGroupSlice.reducer;
