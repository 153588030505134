import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import {
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NySearchField,
    NyUtils,
} from '@nybble/nyreact';
import { RootState } from '../../../rootReducer';
import { GetEnum, GetEnumNameForValue } from '../../../utils/Enums';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import PayloadEnergyEdit from './PayloadEnergyEdit';
import FormatNumber, { formatNumberValueString } from '../../number-formatter';
import { Col, Form, Row } from 'antd';

const PayloadEnergyHistoryTable = () => {
    const { record } = useSelector((state: RootState) => state.deviceEnergyDetails);
    const [refresh, setRefresh] = useState<any>(null);
    const setDefaultFilterValue = () => {
        if (phaseValue != null && phaseValue != 'ALL') {
            return [
                { field: 'deviceEnergyConsumption.phase', condition: 'equals', value: parseInt(phaseValue) },
                { field: 'deviceId', condition: 'equals', value: record.id },
            ];
        }
        return [{ field: 'deviceId', condition: 'equals', value: record.id }];
    };
    const phases = [
        { id: '1', text: geti18nText('energyDetails.widget.select.phase', ['L1']) },
        { id: '2', text: geti18nText('energyDetails.widget.select.phase', ['L2']) },
        { id: '3', text: geti18nText('energyDetails.widget.select.phase', ['L3']) },
        { id: 'ALL', text: geti18nText('energyDetails.widget.select.phase.all') },
    ];

    const [phaseValue, setPhaseValue] = useState<any>('ALL');
    const [phaseSearchValue, setPhaseSearchValue] = useState<any>(phases[3]);

    const payloadTypes = () => {
        const aTypes = GetEnum('PAYLOAD_TYPE');
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.PAYLOAD_TYPE.' + key);
                types.push(ret);
            }
        }
        return types;
    };

    const csvCustomizationColumns: any = () => {
        return [
            {
                'deviceConsumption.ts': (value: any) => {
                    if (value) {
                        const ts: Date = new Date(value);
                        return ts.toLocaleString(NyUtils.getSelectedLocale());
                    }
                    return '';
                },
            },

            {
                'deviceConsumption.electricEnergy': (value: any) => {
                    return value ? formatNumberValueString(value, true) : '';
                },
            },

            {
                'deviceConsumption.totalPower': (value: any) => {
                    return value ? value + ' W' : '';
                },
            },
            {
                'deviceConsumption.voltage': (value: any) => {
                    return value ? value + ' V' : '';
                },
            },
            {
                'deviceConsumption.current': (value: any) => {
                    return value ? value + ' A' : '';
                },
            },
            {
                'deviceConsumption.activePower': (value: any) => {
                    return value ? value + ' kw' : '';
                },
            },
            {
                'deviceConsumption.reactivePower': (value: any) => {
                    return value ? value + ' kvar' : '';
                },
            },
        ];
    };
    function phaseOnChange(val: any) {
        setPhaseSearchValue(val);
        setPhaseValue(val?.id != undefined ? val.id : 'ALL');
        setRefresh(new Date());
    }
    return (
        <React.Fragment>
            <Row>
                <Col span={12}>
                    <Form.Item label={geti18nText('energyDetails.widget.select.title')}>
                        <NySearchField
                            value={phaseSearchValue}
                            onChange={phaseOnChange}
                            searchBy={'name'}
                            style={{ width: '90%' }}
                            options={phases}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <NyDataTable
                headerTitle={geti18nText('payloaEnergyHistory.table.header')}
                url={CONSTANTS_REQ.DEVICE.PAYLOAD_ENERGY_HISTORY}
                showRecordModal={true}
                scroll={{ x: 1200 }}
                exportCSV={true}
                hideButtons={true}
                modalComponent={PayloadEnergyEdit}
                fetchWhenChange={refresh}
                setDefaultFilterValue={setDefaultFilterValue}
                colCSVCustomization={csvCustomizationColumns()}
                showRowSelection={false}
                tableClassName={'phase-table-row-select'}
                setRowClassName={(record: any) => {
                    if (record.deviceConsumption?.phase == 1) {
                        return 'table-row-phase1';
                    }
                    if (record.deviceConsumption?.phase == 2) {
                        return 'table-row-phase2';
                    }
                    if (record.deviceConsumption?.phase == 3) {
                        return 'table-row-phase3';
                    }
                    return '';
                }}
                columns={[
                    {
                        title: geti18nText('payloaEnergyHistory.table.id'),
                        dataIndex: 'id',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('payloaEnergyHistory.table.electricEnergy'),
                        dataIndex: ['deviceConsumption', 'electricEnergy'],
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.deviceConsumption?.electricEnergy === undefined) {
                                if (record.deviceConsumption == undefined) record.deviceConsumption = {};
                                record.deviceConsumption.electricEnergy = 0;
                            }
                            return <FormatNumber value={record.deviceConsumption?.electricEnergy}></FormatNumber>;
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('payloaEnergyHistory.table.totalPower'),
                        dataIndex: ['deviceConsumption', 'totalPower'],
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.deviceConsumption?.totalPower === undefined) {
                                if (record.deviceConsumption == undefined) record.deviceConsumption = {};
                                record.deviceConsumption.totalPower = 0;
                            }
                            return <div>{NyUtils.formatNumber(record.deviceConsumption?.totalPower, 2) + ' W'}</div>;
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('payloaEnergyHistory.table.voltage'),
                        dataIndex: ['deviceConsumption', 'voltage'],
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.voltage
                                        ? NyUtils.formatNumber(record.deviceConsumption?.voltage, 2)
                                        : '0,0'}
                                    &nbsp;V
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('payloaEnergyHistory.table.current'),
                        dataIndex: ['deviceConsumption', 'current'],
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.current
                                        ? NyUtils.formatNumber(record.deviceConsumption.current, 2)
                                        : '0,0'}
                                    &nbsp;A
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('payloaEnergyHistory.table.activePower'),
                        dataIndex: ['deviceConsumption', 'activePower'],
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.activePower
                                        ? NyUtils.formatNumber(record.deviceConsumption.activePower, 2)
                                        : '0,0'}
                                    &nbsp;kW
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('payloaEnergyHistory.table.reactivePower'),
                        dataIndex: ['deviceConsumption', 'reactivePower'],
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.reactivePower
                                        ? NyUtils.formatNumber(record.deviceConsumption.reactivePower, 2)
                                        : '0,0'}
                                    &nbsp;kvar
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('payloaEnergyHistory.table.frequency'),
                        dataIndex: ['deviceConsumption', 'frequency'],
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.frequency
                                        ? NyUtils.formatNumber(record.deviceConsumption.frequency, 2)
                                        : '0,0'}
                                    &nbsp;Hz
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('payloaEnergyHistory.table.phase'),
                        dataIndex: ['deviceConsumption', 'phase'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('payloaEnergyHistory.table.payloadType'),
                        dataIndex: ['deviceConsumption', 'payloadType'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.deviceConsumption?.payloadType) {
                                return (
                                    <div>
                                        <b>
                                            {geti18nText(
                                                'app.enum.PAYLOAD_TYPE.' +
                                                    GetEnumNameForValue(
                                                        'PAYLOAD_TYPE',
                                                        record.deviceConsumption.payloadType
                                                    )
                                            )}
                                        </b>
                                    </div>
                                );
                            }
                            return <div></div>;
                        },
                        ...getColumnSearchOption(payloadTypes()),
                    },
                    {
                        title: geti18nText('payloaEnergyHistory.table.ts'),
                        dataIndex: ['deviceConsumption', 'ts'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.deviceConsumption?.ts) {
                                const ts: Date = new Date(record.deviceConsumption.ts);
                                return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                            }
                        },
                    },

                    {
                        title: geti18nText('payloaEnergyHistory.table.payload'),
                        dataIndex: 'payload',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('payloaEnergyHistory.table.mac'),
                        dataIndex: ['payloadInfo', 'mac'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                ]}
            />
        </React.Fragment>
    );
};

export default PayloadEnergyHistoryTable;
