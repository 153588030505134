import React from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import PayloadHistoryEdit from './PayloadHistoryEdit';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { GetEnumNameForValue, GetEnum } from '../../utils/Enums';
import { getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NyUtils } from '@nybble/nyreact';

const PayloadHistoryTable = () => {
    const { item } = useSelector((state: RootState) => state.selectedItem);

    const setDefaultFilterValue = () => {
        return [{ field: 'deviceId', condition: 'equals', value: item }];
    };

    const payloadTypes = () => {
        const aTypes = GetEnum('PAYLOAD_TYPE');
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.PAYLOAD_TYPE.' + key);
                types.push(ret);
            }
        }
        return types;
    };

    return (
        <NyDataTable
            headerTitle={geti18nText('payloadHistory.table.header')}
            url={CONSTANTS_REQ.DEVICE.PAYLOADHISTORY}
            showRecordModal={true}
            scroll={{ x: 800 }}
            exportCSV={true}
            modalComponent={PayloadHistoryEdit}
            hideButtons={true}
            setDefaultFilterValue={setDefaultFilterValue}
            columns={[
                {
                    title: geti18nText('payloadHistory.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('payloadHistory.table.column.deviceVolume'),
                    dataIndex: ['deviceVolume', 'volume'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('payloadHistory.table.column.payloadType'),
                    dataIndex: ['deviceVolume', 'payloadType'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.deviceVolume.payloadType != undefined) {
                            return (
                                <div>
                                    <b>
                                        {geti18nText(
                                            'app.enum.PAYLOAD_TYPE.' +
                                                GetEnumNameForValue('PAYLOAD_TYPE', record.deviceVolume.payloadType)
                                        )}
                                    </b>
                                </div>
                            );
                        }
                        return <></>;
                    },
                    ...getColumnSearchOption(payloadTypes()),
                },
                {
                    title: geti18nText('payloadHistory.table.column.ts'),
                    dataIndex: ['deviceVolume', 'ts'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.deviceVolume?.ts) {
                            const ts: Date = new Date(record.deviceVolume.ts);
                            return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                        }
                    },
                },
                {
                    title: geti18nText('payloadHistory.table.column.status'),
                    dataIndex: ['deviceVolume', 'status'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    render: (text: string, record: { [index: string]: any }) => {
                        return (
                            <div>
                                <b>
                                    {geti18nText(
                                        'app.enum.ALARM_STATUS.' +
                                            GetEnumNameForValue('ALARM_STATUS', record.deviceVolume.status)
                                    )}
                                </b>
                            </div>
                        );
                    },
                },
                {
                    title: geti18nText('payloadHistory.table.column.payload'),
                    dataIndex: 'payload',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('payloadHistory.table.column.mac'),
                    dataIndex: ['payloadInfo', 'mac'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
            ]}
        />
    );
};

export default PayloadHistoryTable;
