import React, { useEffect } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { Form, Modal, InputNumber, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../rootReducer';
import { deviceLocationInit } from '../../slices/deviceLocationSlice';
import { refreshMap, refreshWatermeterList } from '../../slices/dashboardSlice';
import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';

const DeviceEditLocation: React.FC = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { deviceId, data, visible } = useSelector((state: RootState) => state.deviceLocation);
    const hideModal = () => {
        dispatch(deviceLocationInit());
    };

    useEffect(() => {
        if (data != undefined) form.setFieldsValue(data);
    }, [data]);

    function save(values: any) {
        NyRequestResolver.requestPut(CONSTANTS_REQ.DASHBOARD.UPDATECOORDINATES + '/' + deviceId, undefined, {
            id: deviceId,
            lat: values.latitude,
            lng: values.longitude,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('app.default.save.ok.desc'),
                    duration: 3,
                });
                dispatch(refreshMap());
                dispatch(refreshWatermeterList());
                hideModal();
            } else {
                notification.error({ message: geti18nText('app.default.save.nok'), description: '' });
            }
        });
    }

    return (
        <React.Fragment>
            <Modal
                zIndex={1009}
                visible={visible}
                title={geti18nText('dashboard.widget.watermeter.edit.location.title')}
                onCancel={() => hideModal()}
                okText={geti18nText('app.default.button.save')}
                width={600}
                onOk={() => {
                    form.validateFields().then((values) => {
                        save(values);
                    });
                }}
            >
                <Form form={form} layout="horizontal" labelCol={{ span: 10 }}>
                    <Form.Item
                        name="latitude"
                        label={geti18nText('dashboard.widget.watermeter.edit.location.latitude')}
                        initialValue={data && data.latitude ? data.latitude : undefined}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                            {
                                type: 'float',
                                message: geti18nText('dashboard.widget.watermeter.edit.location.validation'),
                            },
                        ]}
                    >
                        <InputNumber decimalSeparator="." min={-90} max={90} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="longitude"
                        label={geti18nText('dashboard.widget.watermeter.edit.location.longitude')}
                        initialValue={data && data.longitude ? data.longitude : undefined}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                            {
                                type: 'float',
                                message: geti18nText('dashboard.widget.watermeter.edit.location.validation'),
                            },
                        ]}
                    >
                        <InputNumber decimalSeparator="." min={-180} max={180} style={{ width: '100%' }} />
                    </Form.Item>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default DeviceEditLocation;
