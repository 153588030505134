import React from 'react';
import { Typography } from 'antd';

import { geti18nText, NyDataTable, NyUtils } from '@nybble/nyreact';
import { CONSTANTS_REQ, WindDirectionDescription } from '../../../utils/Constants';
import { parametarKeyName, toCamelCase } from '../../../utils/Utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';

const { Title } = Typography;

interface IChartWeatherStateDataConsumption {
    period?: any;
    title: string | null;
    deviceId: number | null;
    dateFrom?: any;
    dateTo?: any;
    params?: string[];
}

interface ColumnTitleProps {
    text: string;
}

const ColumnTitle: React.FC<ColumnTitleProps> = ({ text }) => {
    return <div style={{ whiteSpace: 'nowrap' }}>{text}</div>;
};

const ChartWeatherStateDataConsumption: React.FC<IChartWeatherStateDataConsumption> = ({
    period,
    title,
    deviceId,
    dateFrom,
    dateTo,
    params = [],
}) => {
    const weatherParameters = useSelector((state: RootState) => state.dashboard.weatherParameters);
    const userWeatherWidgets = useSelector((state: RootState) => state.dashboard.userWeatherWidgets);

    const categoryColumns: any = (csv: boolean = false) => {
        let columns: any = [];
        // const checkedCards =
        //     userWeatherWidgets?.checkedCard instanceof Array
        //         ? userWeatherWidgets.checkedCard.find((i: any) => i.category === deviceId)?.items
        //         : [];
        weatherParameters?.forEach((item: any) => {
            //  if (checkedCards?.includes(item.key)) {
            let column = {
                title: csv ? item[parametarKeyName('name')] : <ColumnTitle text={item[parametarKeyName('name')]} />,
                dataIndex: item.dbColumn,
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    let value =
                        record[item.dbColumn] != undefined
                            ? record[item.dbColumn]
                            : record[toCamelCase(item.dbColumn)] != undefined
                            ? record[toCamelCase(item.dbColumn)]
                            : undefined;

                    return (
                        <div>
                            {value ? NyUtils.formatNumber(value, 0) : '0'}
                            &nbsp;{item.unit}
                        </div>
                    );
                },
            };
            columns.push(column);
            // }
        });
        return columns;
    };
    const columns = [
        {
            title: geti18nText('chart.data.consumption.table.ts'),
            dataIndex: 'ts',
            sorter: (a: string, b: string) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.ts) {
                    const ts: Date = new Date(record.ts);
                    return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                }
            },
        },
    ];

    const columnFilter = (csv: boolean = false) => {
        let columnsAll = [...categoryColumns(), ...columns];
        console.log('---> ', columnsAll);
        let cols = columnsAll.filter((col: any) => col.dataIndex === 'ts' || params?.includes(col.dataIndex));
        console.log('params ', params);
        console.log('cols ', cols);
        return cols;
    };

    return (
        <div>
            <Title level={4}>{geti18nText('weatherStatePayloadHistory.table.title') + ' - ' + title}</Title>
            <NyDataTable
                url={CONSTANTS_REQ.DEVICE.WEATHER_CONSUMPTION_DATA}
                hideButtons={true}
                addedData={{ ...period, deviceId: deviceId, timestampFrom: dateFrom, timestampTo: dateTo }}
                scroll={{ x: 800 }}
                readonly={true}
                fetchWhenChange={dateFrom}
                columns={columnFilter()}
                exportCSV={true}
                CSVFileName={geti18nText('weatherStatePayloadHistory.table.title') + ' - ' + title}
                colCSVCustomization={columnFilter(true)}
            />
        </div>
    );
};

export default ChartWeatherStateDataConsumption;
