import React from 'react';
import DeviceDetails from '../device-details/index';
import AlarmEditDescription from '../dashboard/AlarmEditDescription';

const ModalList: React.FC = () => {
    return (
        <React.Fragment>
            <DeviceDetails />
            <AlarmEditDescription />
        </React.Fragment>
    );
};

export default ModalList;
