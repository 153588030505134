import { Col, Row } from 'antd';
import ModalList from './ModalList';
import WidgetMap from './WidgetMap';
import React, { useEffect, useState } from 'react';
import { getUserCategoryType } from '../../utils/Enums';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { filterReset } from '../../slices/dashboardSlice';
import CategoryCard from './category/card';
import { RootState } from '../../rootReducer';

const DashboardMain = (props: any) => {
    const [userCategoryType, setUserCategoryType] = useState<any>(undefined);
    const history = useHistory();
    const dispatch = useDispatch();
    const { deviceCategories } = useSelector((state: RootState) => state.dashboard);
    const [categories, setCategories] = useState<any>([]);

    useEffect(() => {
        let userCategoryType = getUserCategoryType();
        if (userCategoryType != undefined && userCategoryType.length > 0 && userCategoryType.length === 1) {
            switch (userCategoryType[0]) {
                case 'WATERMETER':
                    history.push('waterMeterDashboard');
                    break;
                case 'LIGHTS':
                    history.push('lightsDashboard');
                    break;
                case 'ENERGYMETER':
                    history.push('energyDashboard');
                    break;
                case 'WEATHER':
                    // history.push('weatherDashboard');
                    break;
            }
        }
        setUserCategoryType(userCategoryType);
        dispatch(filterReset());
        let root = document.documentElement;
        root.style.setProperty('--margin-top-dashboard-main', '84px');
        root.style.setProperty('--padding-dashboard-main', '0px');
        root.style.setProperty('--background-dashboard-main', 'transparent');
        root.style.setProperty('--padding-dashboard-main-container', '0px');
        root.style.setProperty('--border-dashboard-main-container', '0px');
        return () => {
            root.style.setProperty('--margin-top-dashboard-main', '');
            root.style.setProperty('--padding-dashboard-main', '');
            root.style.setProperty('--background-dashboard-main', '');
            root.style.setProperty('--padding-dashboard-main-container', '');
            root.style.setProperty('--border-dashboard-main-container', '');
        };
    }, []);

    useEffect(() => {
        if (deviceCategories) {
            let list = [...deviceCategories];
            const sortedCategories: any = list.sort((a: any, b: any) => {
                if (a.ord !== b.ord) return a.ord > b.ord ? -1 : 1;
                return 0;
                //return a.ord > b.ord ? 1 : -1;
            });
            setCategories(sortedCategories);
        }
    }, [deviceCategories]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: 'calc(100vh - 84px)',
                    overflow: 'hidden',
                }}
            >
                <div className="card-container thin-scrollbar" style={{ overflowY: 'auto' }}>
                    {categories
                        ?.slice()
                        .reverse()
                        .map((category: any) => {
                            return <CategoryCard item={category} />;
                        })}
                </div>

                <div
                    style={{
                        marginTop: '10px',
                        height: '95px',
                        backgroundColor: 'black',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px',
                    }}
                >
                    <div style={{ color: 'white', textAlign: 'center', fontSize: '18px' }}>Supported by</div>
                    <img
                        style={{ maxWidth: '200px', maxHeight: '70px', verticalAlign: 'middle', marginBottom: '10px' }}
                        src={require(`../../assets/img/infra_logo.png`)}
                        alt="Infrastruktura"
                    />
                </div>
            </div>

            <ModalList />
        </>
    );
};

export default DashboardMain;
