import React from 'react';

import { useSelector } from 'react-redux';

import { getColumnSearch, geti18nText, NyDataTable, NyUtils } from '@nybble/nyreact';
import { RootState } from '../../../rootReducer';
import { CONSTANTS_REQ, WindDirectionDescription } from '../../../utils/Constants';
import PayloadWeatherStateHistoryEdit from './PayloadWeatherStateHistoryEdit';
import { parametarKeyName, toCamelCase } from '../../../utils/Utils';

interface ColumnTitleProps {
    text: string;
}

const ColumnTitle: React.FC<ColumnTitleProps> = ({ text }) => {
    return <div style={{ whiteSpace: 'nowrap' }}>{text}</div>;
};

const PayloadWeatherStateHistoryTable = () => {
    const { record } = useSelector((state: RootState) => state.deviceWeatherStateDetails);
    const weatherParameters = useSelector((state: RootState) => state.dashboard.weatherParameters);

    const setDefaultFilterValue = () => {
        return [{ field: 'deviceId', condition: 'equals', value: record.id }];
    };

    const csvCustomizationColumns: any = () => {
        return [
            {
                'deviceConsumption.ts': (value: any) => {
                    if (value) {
                        const ts: Date = new Date(value);
                        return ts.toLocaleString(NyUtils.getSelectedLocale());
                    }
                    return '';
                },
            },

            {
                'deviceConsumption.avgWindDirection': (value: any) => {
                    return value ? value + '°' : '';
                },
            },

            {
                'deviceConsumption.avgWindSpeed': (value: any) => {
                    return value ? value + '°' : '';
                },
            },
            {
                'deviceConsumption.temp': (value: any) => {
                    return value ? value + '°C' : '';
                },
            },
            {
                'deviceConsumption.humidity': (value: any) => {
                    return value ? value + '%' : '';
                },
            },
            {
                'deviceConsumption.airPressure': (value: any) => {
                    return value ? value + ' HPa' : '';
                },
            },
            {
                'deviceConsumption.pm_10': (value: any) => {
                    return value ? value + ' ug/m3' : '';
                },
            },
            {
                'deviceConsumption.pm_25': (value: any) => {
                    return value ? value + ' ug/m3' : '';
                },
            },
        ];
    };

    const categoryColumns: any = (csv: boolean = false) => {
        let columns: any = [];

        weatherParameters?.forEach((item: any) => {
            let column = {
                title: csv ? item[parametarKeyName('name')] : <ColumnTitle text={item[parametarKeyName('name')]} />,
                dataIndex: item.dbColumn,
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    let value =
                        record[item.dbColumn] != undefined
                            ? record[item.dbColumn]
                            : record[toCamelCase(item.dbColumn)] != undefined
                            ? record[toCamelCase(item.dbColumn)]
                            : undefined;

                    return (
                        <div>
                            {value ? NyUtils.formatNumber(value, 0) : '0'}
                            &nbsp;{item.unit}
                        </div>
                    );
                },
            };
            columns.push(column);
        });
        return columns;
    };

    const columns: any = () => {
        let deviceColumns = [
            {
                title: geti18nText('weatherStatePayloadHistory.table.id'),
                dataIndex: 'id',
                width: '10%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
        ];

        let actionColumns = [
            {
                title: geti18nText('weatherStatePayloadHistory.table.ts'),
                dataIndex: ['deviceConsumption', 'ts'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.deviceConsumption?.ts) {
                        const ts: Date = new Date(record.deviceConsumption.ts);
                        return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                    }
                },
            },

            {
                title: geti18nText('weatherStatePayloadHistory.table.payload'),
                dataIndex: 'payload',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('weatherStatePayloadHistory.table.mac'),
                dataIndex: ['payloadInfo', 'mac'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
        ];
        return [...deviceColumns, ...categoryColumns(), ...actionColumns];
    };

    return (
        <React.Fragment>
            <NyDataTable
                headerTitle={geti18nText('weatherStatePayloadHistory.table.header')}
                url={CONSTANTS_REQ.DEVICE.PAYLOAD_WEATHER_STATE_HISTORY}
                showRecordModal={true}
                scroll={{ x: 1200 }}
                exportCSV={true}
                hideButtons={true}
                setDefaultFilterValue={setDefaultFilterValue}
                colCSVCustomization={csvCustomizationColumns()}
                showRowSelection={false}
                modalComponent={PayloadWeatherStateHistoryEdit}
                columns={columns()}
            />
        </React.Fragment>
    );
};

export default PayloadWeatherStateHistoryTable;
