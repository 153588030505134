import React, { useState, useEffect } from 'react';
import { Typography, Button, Row, Col, DatePicker, Modal } from 'antd';
import { FullscreenOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Label } from 'recharts';
import moment from 'moment';

import { useSelector } from 'react-redux';

import { geti18nText, NyRequestResolver, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';
import { RootState } from '../../../rootReducer';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import ChartDataConsumption from './ChartDataConsumption';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const ChartLightSpan = () => {
    const { record } = useSelector((state: RootState) => state.deviceLightDetails);

    const [params, setParams] = useState<any>({});
    const [graphData, setGraphData] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visibleDataVolumes, setVisibleDataVolumes] = useState(false);
    const [visibleDataButton, setVisibleDataButton] = useState(false);

    useEffect(() => {
        if (params && params.period) {
            fetch();
        }
    }, [params]);

    const fetch = () => {
        setGraphData([]);
        if (
            params &&
            ((params.period !== 'initial' && params.period !== 'span') ||
                (params.period === 'initial' && params.deviceId !== null) ||
                (params.period === 'span' && params.timestampFrom !== null && params.timestampTo !== null))
        ) {
            setVisibleDataButton(true);
            setLoading(true);
            NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_LIGHT_CONSUMPTION, {
                ...params,
                lang: NyUtils.getSelectedLanguage(),
                deviceId: record.id,
            }).then((response: any) => {
                setLoading(false);
                if (response.status === RESPONSE.OK) {
                    setGraphData(response.data);
                }
            });
        } else {
            setVisibleDataButton(false);
        }
    };

    function onRangeChange(dates: any) {
        const timestampFrom = dates[0] ? moment(dates[0]).valueOf() : null;
        const timestampTo = dates[1] ? moment(dates[1]).valueOf() : null;
        setParams((prevState: any) => {
            return { ...prevState, period: 'span', timestampFrom: timestampFrom, timestampTo: timestampTo };
        });
    }

    function disabledDate(current: any) {
        const rangeStart = moment().subtract(1, 'years');
        const rangeEnd = moment().endOf('day');
        return !(rangeStart.isSameOrBefore(current) && rangeEnd.isAfter(current));
    }

    return (
        <div className="box" style={{ height: '95%' }}>
            <Title level={4}>
                {geti18nText('deviceDetails.graph.light.title') + ' - ' + geti18nText('report.time_range.span')}
            </Title>
            {visibleDataButton && (
                <Button
                    style={{ position: 'absolute', right: '50px', top: '20px' }}
                    shape="circle"
                    onClick={() => setVisibleDataVolumes(true)}
                    icon={<UnorderedListOutlined />}
                />
            )}
            <Button
                style={{ position: 'absolute', right: '20px', top: '20px' }}
                shape="circle"
                icon={<FullscreenOutlined />}
                onClick={() => setVisible(true)}
            />
            <Row gutter={24} style={{ marginBottom: '10px' }}>
                <Col span={24}>
                    <RangePicker
                        disabledDate={disabledDate}
                        showTime
                        format="DD.MM.YYYY HH:mm:ss"
                        onChange={onRangeChange}
                    />
                </Col>
            </Row>
            <ResponsiveContainer width="100%" height={212}>
                {loading ? (
                    <NySpinner />
                ) : (
                    <BarChart data={graphData}>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="converted" />
                        <YAxis>
                            <Label
                                value={geti18nText('deviceDetails.graph.light.y.value')}
                                position="insideLeft"
                                angle={-90}
                                style={{ textAnchor: 'middle' }}
                            />
                        </YAxis>
                        {graphData && <Tooltip />}
                        <Legend />
                        <Bar
                            dataKey="barValue"
                            name={geti18nText('deviceDetails.graph.light.y.value')}
                            fill="#FFD700"
                        />
                    </BarChart>
                )}
            </ResponsiveContainer>

            <Modal
                title={geti18nText('deviceDetails.graph.light.title') + ' - ' + geti18nText('report.time_range.span')}
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={'80%'}
                zIndex={1010}
                centered={true}
            >
                <Row gutter={24} style={{ marginBottom: '10px' }}>
                    <Col span={24}>
                        <RangePicker
                            disabledDate={disabledDate}
                            showTime
                            format="DD.MM.YYYY HH:mm:ss"
                            onChange={onRangeChange}
                        />
                    </Col>
                </Row>
                <ResponsiveContainer width="100%" height={600}>
                    {loading ? (
                        <NySpinner />
                    ) : (
                        <BarChart data={graphData}>
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis dataKey="converted" />
                            <YAxis
                                label={{
                                    value: geti18nText('deviceDetails.graph.light.y.value'),
                                    angle: -90,
                                    position: 'insideLeft',
                                }}
                            />
                            {graphData && <Tooltip />}
                            <Legend />
                            <Bar
                                dataKey="barValue"
                                name={geti18nText('deviceDetails.graph.light.y.value')}
                                fill="#FFD700"
                            />
                        </BarChart>
                    )}
                </ResponsiveContainer>
            </Modal>
            <Modal
                visible={visibleDataVolumes}
                onCancel={() => setVisibleDataVolumes(false)}
                width={'80%'}
                centered={true}
                footer={null}
            >
                <ChartDataConsumption
                    title={geti18nText('report.time_range.span')}
                    deviceId={record.id}
                    period={params}
                />
            </Modal>
        </div>
    );
};

export default ChartLightSpan;
