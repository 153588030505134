import React from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import DashboardByCategoryType from './dashboardByCategoryType';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';

const WaterMeterDashboard = () => {
    return <DashboardByCategoryType type={'WATERMETER'} />;
};

export default WaterMeterDashboard;
