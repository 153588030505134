import React, { useState } from 'react';
import {
    Layout,
    Input,
    Divider,
    Row,
    Col,
    Typography,
    List,
    Alert,
    Button,
    Card,
    Statistic,
    Form,
    Tooltip,
} from 'antd';
import './index.scss';
import logo from '../../assets/img/logo.png';
import { CONSTANTS_REQ } from '../../utils/Constants';
import Chart from '../device-details/Chart';
import ChartSpan from './ChartSpan';
import { HomeOutlined, PhoneOutlined, MailOutlined, LineChartOutlined, BellTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { deviceAlarmThrasholdOpen } from '../../slices/deviceAlarmThrasholdSlice';
import ModalList from '../dashboard/ModalList';
import { geti18nText, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';

const { Header, Footer, Content } = Layout;
const { Search } = Input;
const { Title, Paragraph } = Typography;

const Monitor = () => {
    const [serialNumber, setSerialNumber] = useState<any | null>(null);
    const [customerCode, setCustomerCode] = useState<any | null>(null);
    const [deviceId, setDeviceId] = useState<any | null>(null);
    const [endCustomerId, setEndCustomerId] = useState<any | null>(null);
    const [searchValue, setSearchValue] = useState<any | null>(null);
    const [checkSNValue, setCheckSNValue] = useState<boolean | null>(null);
    const [last24hData, setLast24hData] = useState<any>(null);
    const [currentWeekData, setCurrentWeekData] = useState<any>(null);
    const [lastWeekData, setLastWeekData] = useState<any>(null);
    const [currentMonthData, setCurrentMonthData] = useState<any>(null);
    const [currentYearData, setCurrentYearData] = useState<any>(null);
    const [contactData, setContactData] = useState<any>(null);
    const [widgetData, setWidgetData] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const checkSN = (value: any) => {
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.CUSTOMER_CONSUMPTION.CHECK_SN +
                '?customerCode=' +
                value.customerCode +
                '&serialNumber=' +
                value.serialNumber
        ).then((response: any) => {
            if (response.status === 200) {
                setCheckSNValue(true);
                setDeviceId(response.data.deviceId);
                setEndCustomerId(response.data.endCustomerId);
                setCustomerCode(value.customerCode);
                setSerialNumber(value.serialNumber);
                setSearchValue(value.serialNumber);
                fetchLast24hValue(value.serialNumber);
                fetchCurrentWeekData(value.serialNumber);
                fetchLastWeekData(value.serialNumber);
                fetchCurrentMonthData(value.serialNumber);
                fetchCurrentYearData(value.serialNumber);
                fetchContactValue(value.customerCode);
                fetchWidgetValue(value.serialNumber);
            } else {
                setCheckSNValue(false);
            }
        });
    };

    const fetchLast24hValue = (value: any) => {
        setLast24hData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.CUSTOMER_CONSUMPTION.CHARTS_CONSUMPTION, {
            period: 'last-24h',
            lang: NyUtils.getSelectedLanguage(),
            serialNumber: value,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setLast24hData(response.data);
            }
        });
    };

    const fetchCurrentWeekData = (value: any) => {
        setCurrentWeekData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.CUSTOMER_CONSUMPTION.CHARTS_CONSUMPTION, {
            period: 'current-week',
            lang: NyUtils.getSelectedLanguage(),
            serialNumber: value,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setCurrentWeekData(response.data);
            }
        });
    };

    const fetchLastWeekData = (value: any) => {
        setLastWeekData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.CUSTOMER_CONSUMPTION.CHARTS_CONSUMPTION, {
            period: 'last-week',
            lang: NyUtils.getSelectedLanguage(),
            serialNumber: value,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setLastWeekData(response.data);
            }
        });
    };

    const fetchCurrentMonthData = (value: any) => {
        setCurrentMonthData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.CUSTOMER_CONSUMPTION.CHARTS_CONSUMPTION, {
            period: 'current-month',
            lang: NyUtils.getSelectedLanguage(),
            serialNumber: value,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setCurrentMonthData(response.data);
            }
        });
    };

    const fetchCurrentYearData = (value: any) => {
        setCurrentYearData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.CUSTOMER_CONSUMPTION.CHARTS_CONSUMPTION, {
            period: 'current-year',
            lang: NyUtils.getSelectedLanguage(),
            serialNumber: value,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setCurrentYearData(response.data);
            }
        });
    };

    const fetchContactValue = (value: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.END_CUSTOMER.CONTACT_DATA_END_CUSTOMER + '/' + value).then(
            (response: any) => {
                if (response.status === RESPONSE.OK) {
                    setContactData(response.data);
                } else {
                    setContactData(null);
                }
            }
        );
    };

    const fetchWidgetValue = (value: any) => {
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.CUSTOMER_CONSUMPTION.CONSUMPTION_FOR_TODAY_AND_CURRENT_WEEK_FOR_DEVICE,
            {
                serialNumber: value,
            }
        ).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setWidgetData(response.data);
            }
        });
    };

    const goBack = () => {
        setSearchValue(null);
        setCheckSNValue(null);
        setCustomerCode(null);
        form.resetFields();
    };

    const onFinish = (values: any) => {
        checkSN(values);
    };

    const dispatch = useDispatch();

    const editAlarm = (deviceId: string, endCustomerId: string, customerCode: string) => {
        dispatch(
            deviceAlarmThrasholdOpen({
                deviceId: deviceId,
                endCustomerId: endCustomerId,
                customerCode: customerCode,
                visible: true,
            })
        );
    };

    return (
        <Layout>
            <Header>
                <img className="logo" src={logo} />
            </Header>
            <Content style={{ padding: '0 50px' }}>
                <div className="site-layout-content">
                    {(!checkSNValue || checkSNValue == null) && (
                        <Row>
                            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                <div className="search-right">
                                    <React.Fragment>
                                        <Title level={4}>Provjera stanja vodomjera</Title>
                                        <Paragraph>
                                            Za provjeru stanja vodomjera unesite serijski broj vodomjera.
                                        </Paragraph>
                                        <Form form={form} layout="vertical" onFinish={onFinish}>
                                            <Form.Item
                                                name="customerCode"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Šifra potrošača je obavezno polje!',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder="Upišite šifru potrošača"
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="serialNumber"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Serijski broj uređaja je obavezno polje!',
                                                    },
                                                ]}
                                            >
                                                <Input
                                                    placeholder="Upišite serijski broj uređaja"
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                                                    Pogledaj
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </React.Fragment>
                                    {!checkSNValue && checkSNValue != null && (
                                        <Alert
                                            style={{ marginTop: '5px' }}
                                            message="Uneseni serijski broj vodomjera ne postoji"
                                            type="error"
                                            showIcon
                                            closable
                                            onClose={() => setCheckSNValue(null)}
                                        />
                                    )}
                                </div>
                            </Col>
                        </Row>
                    )}

                    {checkSNValue && (
                        <React.Fragment>
                            <Row>
                                <Col span={2}>
                                    <Button size="large" onClick={goBack}>
                                        {geti18nText('app.default.button.back')}
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Divider>
                                        {geti18nText('monitor.title')} ( {searchValue} )
                                    </Divider>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                                    <div className="box" style={{ marginRight: '10px', height: '95%' }}>
                                        <Card style={{ height: '45%' }}>
                                            <Tooltip
                                                title={geti18nText('dashboard.widget.watermeter.table.alarm.tooltip')}
                                            >
                                                <BellTwoTone
                                                    twoToneColor="#faad14"
                                                    style={{ fontSize: '20px', marginRight: '10px', float: 'right' }}
                                                    onClick={() => editAlarm(deviceId, endCustomerId, customerCode)}
                                                />
                                            </Tooltip>
                                            <Statistic
                                                title={geti18nText('deviceDetails.widget1.title')}
                                                value={
                                                    widgetData != null
                                                        ? NyUtils.formatNumber(widgetData['today'], 2)
                                                        : ' '
                                                }
                                                precision={2}
                                                valueStyle={{ color: '#1890ff', fontSize: '24px', fontWeight: 'bold' }}
                                                prefix={<LineChartOutlined />}
                                                suffix="m³"
                                            />
                                        </Card>
                                        <Card style={{ marginTop: '10px', height: '45%' }}>
                                            <Statistic
                                                title={geti18nText('deviceDetails.widget2.title')}
                                                value={
                                                    widgetData != null
                                                        ? NyUtils.formatNumber(widgetData['current-week'], 2)
                                                        : ' '
                                                }
                                                precision={2}
                                                valueStyle={{ color: '#1890ff', fontSize: '24px', fontWeight: 'bold' }}
                                                prefix={<LineChartOutlined />}
                                                suffix="m³"
                                            />
                                        </Card>
                                    </div>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                                    <div className="box" style={{ marginRight: '10px', height: '95%' }}>
                                        <Title level={4}>{geti18nText('deviceDetails.contact.title')}</Title>
                                        <h3>{searchValue}</h3>
                                        <List.Item>
                                            <List.Item.Meta
                                                title={
                                                    <b>
                                                        <HomeOutlined /> {geti18nText('deviceDetails.contact.address')}
                                                    </b>
                                                }
                                                description={
                                                    contactData != null && contactData.city + ', ' + contactData.address
                                                }
                                            />
                                        </List.Item>
                                        <List.Item>
                                            <List.Item.Meta
                                                title={
                                                    <b>
                                                        <PhoneOutlined /> {geti18nText('deviceDetails.contact.phone')}
                                                    </b>
                                                }
                                                description={contactData != null && contactData.phone}
                                            />
                                        </List.Item>
                                        <List.Item>
                                            <List.Item.Meta
                                                title={
                                                    <b>
                                                        <MailOutlined /> {geti18nText('deviceDetails.contact.email')}
                                                    </b>
                                                }
                                                description={contactData != null && contactData.email}
                                            />
                                        </List.Item>
                                    </div>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                                    <Chart
                                        loading={loading}
                                        data={last24hData}
                                        title={geti18nText('report.time_range.last24h')}
                                    />
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                                    <Chart
                                        loading={loading}
                                        data={currentWeekData}
                                        title={geti18nText('report.time_range.current_week')}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                                    <Chart
                                        loading={loading}
                                        data={lastWeekData}
                                        title={geti18nText('report.time_range.last_week')}
                                    />
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                                    <Chart
                                        loading={loading}
                                        data={currentMonthData}
                                        title={geti18nText('report.time_range.current_month')}
                                    />
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                                    <Chart
                                        loading={loading}
                                        data={currentYearData}
                                        title={geti18nText('report.time_range.current_year')}
                                    />
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                                    <ChartSpan serialNumber={searchValue} />
                                </Col>
                            </Row>
                        </React.Fragment>
                    )}
                </div>
                <ModalList />
            </Content>
            <Footer style={{ textAlign: 'center' }}>Chameleon IoT platform ©2020</Footer>
        </Layout>
    );
};

export default Monitor;
