import React, { useState, useEffect } from 'react';
import { Typography, List } from 'antd';
import { HomeOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';

const { Title } = Typography;

const Contact = () => {
    const { record, visible } = useSelector((state: RootState) => state.deviceDetails);

    const [contactData, setContactData] = useState<any>(null);

    useEffect(() => {
        if (visible) {
            fetchContactValue();
        }
    }, [visible]);

    const fetchContactValue = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CONTACT_DATA_FOR_DEVICE + '/' + record.id).then(
            (response: any) => {
                if (response.status === RESPONSE.OK) {
                    setContactData(response.data);
                }
            }
        );
    };

    return (
        <div className="box" style={{ marginRight: '10px', height: '95%' }}>
            <Title level={4}>{geti18nText('deviceDetails.contact.title')}</Title>
            <h3>{record != null && record.serialNumber}</h3>
            <List.Item>
                <List.Item.Meta
                    title={
                        <b>
                            <HomeOutlined /> {geti18nText('deviceDetails.contact.address')}
                        </b>
                    }
                    description={contactData != null && contactData.address}
                />
            </List.Item>
            <List.Item>
                <List.Item.Meta
                    title={
                        <b>
                            <PhoneOutlined /> {geti18nText('deviceDetails.contact.phone')}
                        </b>
                    }
                    description={contactData != null && contactData.phone}
                />
            </List.Item>
            <List.Item>
                <List.Item.Meta
                    title={
                        <b>
                            <MailOutlined /> {geti18nText('deviceDetails.contact.email')}
                        </b>
                    }
                    description={contactData != null && contactData.email}
                />
            </List.Item>
        </div>
    );
};

export default Contact;
