import { NyUtils } from '@nybble/nyreact';
import { Popover } from 'antd';
import React from 'react';

const FormatNumber = (props: any) => {
    const content = (
        <div>
            {props.value ? (
                <>
                    {' '}
                    {NyUtils.formatNumber(props.value, 2)}
                    <span className="widget-cards-number-lights-units">{' Wh'}</span>
                </>
            ) : undefined}
        </div>
    );

    return (
        <>
            <Popover content={content} trigger="hover">
                {formatNumberValue(props.value, true)}
            </Popover>
        </>
    );
};

export default FormatNumber;

function formatNumberShowUnit(value: any) {
    if (value >= 1000000000) {
        return (
            <span>
                {' '}
                {NyUtils.formatNumber(value / 1000000000, 2)}
                <span className="widget-cards-number-lights-units">{' GWh'}</span>
            </span>
        );
    } else if (value >= 1000000) {
        return (
            <span>
                {' '}
                {NyUtils.formatNumber(value / 1000000, 2)}
                <span className="widget-cards-number-lights-units">{' MWh'}</span>
            </span>
        );
    } else if (value >= 1000) {
        return (
            <span>
                {' '}
                {NyUtils.formatNumber(value / 1000, 2)}
                <span className="widget-cards-number-lights-units">{' KWh'}</span>
            </span>
        );
    } else
        return (
            <span>
                {' '}
                {NyUtils.formatNumber(value, 2)}
                <span className="widget-cards-number-lights-units">{' Wh'}</span>
            </span>
        );
}
function formatNumberShowUnitString(value: any) {
    if (value >= 1000000000) {
        return NyUtils.formatNumber(value / 1000000000, 2) + ' GWh';
    } else if (value >= 1000000) {
        return NyUtils.formatNumber(value / 1000000, 2) + ' MWh';
    } else if (value >= 1000) {
        return NyUtils.formatNumber(value / 1000, 2) + ' KWh';
    } else return NyUtils.formatNumber(value, 2) + ' Wh';
}

function formatNumber(value: any) {
    if (value >= 1000000000) {
        return NyUtils.formatNumber(value / 1000000000, 2);
    } else if (value >= 1000000) {
        return NyUtils.formatNumber(value / 1000000, 2);
    } else if (value >= 1000) {
        return NyUtils.formatNumber(value / 1000, 2);
    } else return NyUtils.formatNumber(value, 2);
}

export function formatNumberValue(value: any, showUnit?: boolean) {
    if (value) return showUnit && showUnit === true ? formatNumberShowUnit(value) : formatNumber(value);
    return showUnit && showUnit === true ? '0 Wh' : '0';
}
export function formatNumberValueString(value: any, showUnit?: boolean) {
    return showUnit && showUnit === true ? formatNumberShowUnitString(value) : formatNumber(value);
}
