import React, { useState } from 'react';
import { Upload, Button, Modal, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { geti18nText, NySearchField, NySession } from '@nybble/nyreact';

const { Dragger } = Upload;

interface IImportCSV {
    addedData?: { [index: string]: any };
    save: () => void;
    url: string;
}

const ImportCSV: React.FC<IImportCSV> = ({ addedData, save, url }) => {
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(addedData);
    const [customer, setCustomer] = useState<any>(undefined);

    const showModal = () => {
        setVisible(true);
    };

    const closeModal = () => {
        setVisible(false);
    };

    const uploadFile = {
        name: 'file',
        action: url,
        showUploadList: false,
        multiple: false,
        data: { ...data, customerId: customer != null && customer.key },
        headers: {
            Authorization: 'Bearer ' + NySession.getUserToken(),
        },
        onChange(info: any) {
            if (info.file.status === 'uploading') {
                message.destroy();
                message.loading(`${info.file.name} ${geti18nText('import.text.uploading')}`);
            }
            if (info.file.status === 'done') {
                message.destroy();
                message.success(`${info.file.name} ${geti18nText('import.text.done')}`);
                save();
                setVisible(false);
            } else if (info.file.status === 'error') {
                message.destroy();
                message.error(`${info.file.name} ${geti18nText('import.text.error')}`);
            }
        },
    };

    const onCustomerChange = (value: any) => {
        if (value != null) {
            setCustomer(value.id);
        }
    };

    return (
        <React.Fragment>
            <Button type="primary" className="margin-left" onClick={showModal}>
                {geti18nText('import.title')}
            </Button>

            <Modal
                title={geti18nText('import.title')}
                visible={visible}
                onCancel={closeModal}
                destroyOnClose={true}
                footer={null}
            >
                <NySearchField
                    style={{ width: '100%', marginBottom: '10px' }}
                    value={customer}
                    onChange={onCustomerChange}
                    url={CONSTANTS_REQ.CUSTOMER.SEARCH}
                    map={{ id: 'id', label: 'name' }}
                    searchBy="name"
                />
                <Dragger {...uploadFile}>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-hint">{geti18nText('import.text')}</p>
                </Dragger>
            </Modal>
        </React.Fragment>
    );
};

export default ImportCSV;
