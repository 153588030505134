import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Form, Tabs, Select, Checkbox } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';
import TextArea from 'antd/lib/input/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { selectedCustomerInit } from '../../slices/selectedCustomerSlice';
import { RootState } from '../../rootReducer';
import AlarmTable from '../user/AlarmTable';
import { selectedItemSet } from '../../slices/selectedItemSlice';
import { geti18nText, NyDataEdit, NyRequestResolver, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';

const { TabPane } = Tabs;

const UserEdit = (props: any) => {
    const dispatch = useDispatch();
    const { customer, categoryType } = useSelector((state: RootState) => state.selectedCustomer);

    const [editHeader, setEditHeader] = useState(geti18nText('user.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [rolesList, setRolesList] = useState(undefined);
    const [isCreate, setIsCreate] = useState(false);
    const [languageList, setlanguageList] = useState<any>(undefined);
    const [catTypes, setCatTypes] = useState<any>([]);
    const [selCategoryType, setSelCategoryType] = useState([]);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    useEffect(() => {
        if (categoryType != null) {
            let tmpType = [];
            if (categoryType.includes(',')) {
                tmpType = categoryType.split(',');
            } else tmpType.push(categoryType);
            setCatTypes(tmpType);
        }
        NyRequestResolver.requestGet(CONSTANTS_REQ.ROLE.SEARCH).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setRolesList(
                    response.data
                        .filter((role: { [index: string]: any }) => {
                            switch (role.text) {
                                case 'ROLE_ADMIN':
                                    return false;
                                case 'ROLE_CUSTOMER_ADMIN':
                                    return false;
                                case 'ROLE_CUSTOMER':
                                    if (NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'])) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                default:
                                    return true;
                            }
                        })
                        .map((userRole: { [index: string]: any }) => {
                            let ret: { [index: string]: any } = {};
                            ret.id = userRole.id + '';
                            ret.text = userRole.text;
                            return ret;
                        })
                );
            }
        });
        // SET LANGUAGE
        let languages = NySession.getAppValue('I18N').language.available;
        let languageList = [];
        for (var lang in languages) {
            let ret = { value: undefined, label: undefined };
            ret.value = languages[lang];
            ret.label = languages[lang].toUpperCase();
            languageList.push(ret);
        }
        setlanguageList(languageList);
    }, []);

    useEffect(() => {
        dispatch(selectedItemSet({ item: dataForm, refreshItem: 0 }));
    }, [dataForm]);

    function setValues(dataForm: any) {
        setEditHeader(dataForm.id);
        setDataForm(dataForm.id);
        if (dataForm.categoryType) {
            dataForm.catType = dataForm.categoryType.split(',');
            setSelCategoryType(dataForm.catType);
        }
        if (Array.isArray(dataForm.roles)) {
            dataForm.roles = dataForm.roles.map((userRole: { [index: string]: any }) => {
                let ret: { [index: string]: any } = {};
                ret.key = userRole.id + '';
                ret.label = userRole.authority;
                return ret;
            });
        }
        delete dataForm.active;
        delete dataForm.password;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('user.edit.new'));
    };

    function onChange(checkedValues: any) {
        setSelCategoryType(checkedValues);
    }

    function normalizeValues(values: any) {
        let normalized = { ...values };
        normalized.customer = { id: customer };
        normalized.categoryType =
            selCategoryType != null && selCategoryType.length > 0 ? selCategoryType.join(',') : categoryType;
        normalized.active = 'active' in values ? values['active'] : true;
        normalized.roles = Array.isArray(form.getFieldValue('roles'))
            ? form.getFieldValue('roles').map((selectedRole: any) => {
                  let ret: { [index: string]: any } = {};
                  if (selectedRole.hasOwnProperty('value')) {
                      ret.id = parseInt(selectedRole.value, 10);
                  } else if (selectedRole.hasOwnProperty('id')) {
                      ret.id = parseInt(selectedRole.id, 10);
                  } else if (selectedRole.hasOwnProperty('key')) {
                      ret.id = parseInt(selectedRole.key, 10);
                  }
                  return ret;
              })
            : null;
        if (!form.getFieldValue('password') || form.getFieldValue('password').length == 0) {
            delete normalized.password;
        }

        return normalized;
    }

    const validation = (_: any, value: any) => {
        if (selCategoryType != null && selCategoryType.length > 0) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(geti18nText('app.default.required')));
    };

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.USER.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            onSave={() => dispatch(selectedCustomerInit())}
            {...props}
            normalize={normalizeValues}
            hideButtons={!NySession.hasAnyRole(['ROLE_AUTH_ADMIN'])}
        >
            <Tabs type="card">
                <TabPane tab={geti18nText('user.tab.general')} key="1">
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={geti18nText('user.edit.firstName')} name="firstName">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('user.edit.lastName')} name="lastName">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('user.edit.username')} name="username">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('user.edit.password')} name="password">
                                <Input.Password />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('user.edit.roles')} name="roles">
                                <NySearchField
                                    options={rolesList}
                                    map={{ id: 'id', label: 'text' }}
                                    searchBy="text"
                                    mode="multiple"
                                    onChange={onchange}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('user.edit.language')}
                                name="language"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 6 }}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <Select options={languageList}></Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('user.edit.email')} name="email">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('user.edit.phone')} name="phone">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('user.edit.description')} name="description">
                                <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {catTypes && catTypes.length > 1 && (
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('customer.edit.device.type')}
                                    //    required={true}
                                    name="catType"
                                    rules={[{ validator: validation }]}
                                >
                                    <Checkbox.Group
                                        onChange={onChange}
                                        options={catTypes.map((e: any) => {
                                            return { value: e, label: geti18nText('app.enum.DEVICE_CATEGORY.' + e) };
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                </TabPane>
                <TabPane tab={geti18nText('user.tab.alarm')} key="2" disabled={isCreate || loading}>
                    <AlarmTable />
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default UserEdit;
