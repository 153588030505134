import React, { useState, useEffect, useRef } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import L from 'leaflet';
import { MapContainer, Marker, Popup, TileLayer,useMap } from 'react-leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { iconGenerator } from '../dashboard/WidgetMap';
import { Button } from 'antd';
import { setMapBounds } from '../../slices/dashboardSlice';
import { gatewayDetailsOpen } from '../../slices/gatewayDetailsSlice';
import { geti18nText, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';

interface iDevicePins {
    items: { [index: string]: any }[];
    statusChanged?: { [index: string]: string }[];
}

const GatewayMap = () => {
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
    const [gatewayItems, setGatewayItems] = useState<{ [index: string]: any }[]>([]);
    const mapRef = useRef<L.Map>();
    const dispatch = useDispatch();
    const { mapRefresh, mapBounds, mapPanTo } = useSelector((state: RootState) => state.dashboard);
    const mapOptions = {    
                            className: "markercluster-map",
                            center: [44.3057, 16.3366],
                            zoom: 6,
                            maxZoom: 18,
                            gestureHandling: true,
                            style: {
                                height: !NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) ? '85vh' : '50vh',
                                zIndex: 0,
                            }
                        }

    useEffect(() => {
        fetchPins();
    }, [mapRefresh]);

    useEffect(() => {
        if (mapRef.current && mapBounds != undefined) {
            let west, south, east, north;
            [west, south, east, north] = mapBounds.split(',').map(parseFloat);
            var bounds = new L.LatLngBounds(new L.LatLng(south, west), new L.LatLng(north, east));
            mapRef.current?.flyToBounds(bounds);
        }
    }, [mapBounds]);

    useEffect(() => {
        if (mapRef.current && mapPanTo != undefined) {
            mapRef.current.flyTo(mapPanTo, 18);
        }
    }, [mapPanTo]);

    useEffect(() => {
        fetchPins();
    }, []);

    function showDetails(deviceId: string) {
        dispatch(gatewayDetailsOpen({ record: { id: deviceId }, visible: true }));
    }

    function fetchPins() {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DASHBOARD.GATEWAYMAPPINS).then((result) => {
            if (result && result.status == RESPONSE.OK) {
                if (Array.isArray(result.data)) {
                    setGatewayItems(result.data);

                    var bounds: L.LatLngBounds | undefined = undefined;

                    if (Array.isArray(result.data)) {
                        for (let pinData of result.data) {
                            if (bounds === undefined)
                                bounds = L.latLngBounds([pinData.lat, pinData.lng], [pinData.lat, pinData.lng]);
                            else bounds.extend([pinData.lat, pinData.lng]);
                        }
                    }
                    if (mapRef.current && bounds != undefined) {
                        dispatch(setMapBounds({ bounds: bounds.toBBoxString() }));
                        //mapRef.current.leafletElement.fitBounds(bounds);
                    }
                }
            }
        });
    }

    const GatewayPins: React.FC<iDevicePins> = ({ items }) => {
        return (
            <React.Fragment>
                {items.map((item, index) => (
                    <Marker key={index} icon={iconGenerator('gateway', item, [])} position={[item.lat, item.lng]}>
                        <Popup offset={[0, -30]}>
                            <b>{item.name}</b>
                            <br />
                            <br />
                            <b>{geti18nText('dashboard.widget.map.popup.mac')}: </b>
                            {item.mac}
                            <br />
                            <b>{geti18nText('dashboard.widget.map.popup.latitude')}: </b>
                            {item.lat}
                            <br />
                            <b>{geti18nText('dashboard.widget.map.popup.Longitude')}: </b>
                            {item.lng}
                            <br />
                            <b>{geti18nText('dashboard.widget.map.popup.altitude')}: </b>
                            {item.altitude}
                            <br />
                            <br />
                            <Button onClick={() => showDetails(item.id)}>
                                {geti18nText('dashboard.widget.map.popup.details')}
                            </Button>
                        </Popup>
                    </Marker>
                ))}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            <MapContainer
                {...mapOptions as any}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                <GatewayPins items={gatewayItems} />
            </MapContainer>
        </React.Fragment>
    );
};

export default GatewayMap;
