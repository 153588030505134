import React from 'react';
import { WidthProvider, Layout, ItemCallback, Responsive, Layouts } from 'react-grid-layout';
import { Row, Col, Space, Switch, Typography, Checkbox, Popover, Button } from 'antd';
import WidgetCards from './WidgetCards';
import WidgetMap from './WidgetMap';
import WidgetAlarms from './WidgetAlarms';
import WidgetWatermeterList from './WidgetWatermeterList';
import { APPLICATION_KEY, CONSTANTS_REQ } from '../../utils/Constants';
import ModalList from './ModalList';
import { geti18nText, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import WidgetLightsList from './WidgetLightsList';
import { GetEnum, getUserCategoryType } from '../../utils/Enums';
import { ExperimentOutlined } from '@ant-design/icons';
import WidgetCardsLights from './WidgetCardLights';
import WidgetEnrgyMeterList from './WidgetEnergyMeterList';
import WidgetCardsEnergy from './energy/WidgetCardEnergy';
import WidgetWeatherMeterList from './WidgetWeatherMeterList';
import WidgetCardsWeatherState from './weather/charts/WidgetCardsWeatherState';
import DashboardList from './dashboardLists/dashboardList';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export const defaultWidgetWatermeter: any = [
    {
        key: 'c2',
        component: 'WidgetCards',
        dataGrid: { w: 4, h: 10, x: 8, y: 0, minW: 2, minH: 2, moved: false, static: true },
        onResizeRedraw: false,
        categoryType: ['WATERMETER'],
        static: true,
    },
    {
        key: 'c5',
        component: 'WidgetMap',
        dataGrid: { w: 12, h: 13, x: 0, y: 18, minW: 1, minH: 1, moved: false, static: true },
        onResizeRedraw: false,
        categoryType: ['WATERMETER'],
        static: true,
    },
    {
        key: 'c1',
        component: 'DashboardList',
        dataGrid: { w: 12, h: 12, x: 0, y: 12, minW: 4, minH: 4, moved: false, static: true },
        onResizeRedraw: false,
        categoryType: ['WATERMETER'],
        static: true,
    },
    {
        key: 'c3',
        component: 'WidgetAlarms',
        dataGrid: { w: 8, h: 12, x: 0, y: 0, minW: 4, minH: 4, moved: false, static: true },
        onResizeRedraw: false,
        categoryType: ['WATERMETER'],
        static: true,
    },
];

export const defaultWidgetLights: any = [
    {
        key: 'c6',
        component: 'WidgetCardsLights',
        dataGrid: { w: 4, h: 13, x: 8, y: 0, minW: 2, minH: 2, moved: false, static: true },
        onResizeRedraw: false,
        categoryType: ['LIGHTS'],
        static: true,
    },
    {
        key: 'c5',
        component: 'WidgetMap',
        dataGrid: { w: 12, h: 12, x: 0, y: 22, minW: 1, minH: 1, moved: false, static: true },
        onResizeRedraw: true,
        categoryType: ['WATERMETER', 'LIGHTS', 'ENERGYMETER', 'WEATHER'],
        static: true,
    },
    {
        key: 'c1',
        component: 'DashboardList',
        dataGrid: { w: 12, h: 10, x: 0, y: 12, minW: 4, minH: 4, moved: false, static: true },
        onResizeRedraw: true,
        categoryType: ['WATERMETER', 'LIGHTS', 'ENERGYMETER', 'WEATHER'],
        static: true,
    },
    {
        key: 'c3',
        component: 'WidgetAlarms',
        dataGrid: { w: 8, h: 12, x: 0, y: 0, minW: 4, minH: 4, moved: false, static: true },
        onResizeRedraw: false,
        categoryType: ['WATERMETER', 'LIGHTS'],
        static: true,
    },
];

export const defaultWidgetEnergymeter: any = [
    {
        key: 'c5',
        component: 'WidgetMap',
        dataGrid: { w: 12, h: 13, x: 0, y: 24, minW: 1, minH: 1, moved: false, static: true },
        onResizeRedraw: true,
        categoryType: ['WATERMETER', 'LIGHTS', 'ENERGYMETER', 'WEATHER'],
        static: true,
    },
    {
        key: 'c1',
        component: 'DashboardList',
        dataGrid: { w: 12, h: 12, x: 0, y: 12, minW: 4, minH: 4, static: true },
        onResizeRedraw: true,
        categoryType: ['WATERMETER', 'LIGHTS', 'ENERGYMETER', 'WEATHER'],
    },
    {
        key: 'c3',
        component: 'WidgetAlarms',
        dataGrid: { w: 10, h: 13, x: 0, y: 0, minW: 4, minH: 4, moved: false, static: true },
        onResizeRedraw: false,
        categoryType: ['WATERMETER', 'LIGHTS', 'WEATHER'],
        static: true,
    },
    {
        key: 'c8',
        component: 'WidgetCardsEnergy',
        dataGrid: { w: 2, h: 12, x: 10, y: 0, minW: 2, minH: 4, static: true },
        onResizeRedraw: false,
        categoryType: ['ENERGYMETER'],
    },
];

export const defaultWidgetWeatherState: any = [
    {
        key: 'c3',
        component: 'WidgetAlarms',
        dataGrid: { w: 6, h: 12, x: 0, y: 0, minW: 4, minH: 4, moved: false, static: true },
        onResizeRedraw: false,
        categoryType: ['WATERMETER', 'LIGHTS', 'WEATHER'],
        static: true,
    },
    {
        key: 'c10',
        component: 'WidgetCardsWeatherState',
        dataGrid: { w: 4, h: 12, x: 8, y: 0, minW: 2, minH: 2, moved: false, static: false },
        onResizeRedraw: false,
        categoryType: ['WEATHER'],
    },
    {
        key: 'c5',
        component: 'WidgetMap',
        dataGrid: { w: 12, h: 13, x: 0, y: 12, minW: 1, minH: 1, moved: false, static: true },
        onResizeRedraw: true,
        categoryType: ['WATERMETER', 'LIGHTS', 'ENERGYMETER', 'WEATHER'],
        static: true,
    },
    {
        key: 'c1',
        component: 'DashboardList',
        dataGrid: { w: 12, h: 12, x: 0, y: 6, minW: 4, minH: 4, static: true },
        onResizeRedraw: true,
        categoryType: ['WEATHER'],
    },
];

export const defaultAllWidgets: any = [
    {
        key: 'c2',
        component: 'WidgetCards',
        dataGrid: { w: 4, h: 12, x: 0, y: 0, minW: 2, minH: 2, moved: false, static: true },
        onResizeRedraw: false,
        categoryType: ['WATERMETER'],
        static: true,
    },
    {
        key: 'c6',
        component: 'WidgetCardsLights',
        dataGrid: { w: 4, h: 12, x: 8, y: 0, minW: 2, minH: 2, moved: false, static: true },
        onResizeRedraw: false,
        categoryType: ['LIGHTS'],
        static: true,
    },
    {
        key: 'c5',
        component: 'WidgetMap',
        dataGrid: { w: 12, h: 15, x: 0, y: 43, minW: 1, minH: 1, moved: false, static: true },
        onResizeRedraw: true,
        categoryType: ['WATERMETER', 'LIGHTS', 'ENERGYMETER', 'WEATHER'],
        static: true,
    },

    {
        key: 'c1',
        component: 'DashboardList',
        dataGrid: { w: 12, h: 11, x: 0, y: 21, minW: 4, minH: 4, moved: false, static: true },
        onResizeRedraw: true,
        categoryType: ['WATERMETER', 'LIGHTS', 'ENERGYMETER', 'WEATHER'],
        static: true,
    },
    // {
    //     key: 'c1',
    //     component: 'WidgetWatermeterList',
    //     dataGrid: { w: 12, h: 11, x: 0, y: 21, minW: 4, minH: 4, moved: false, static: true },
    //     onResizeRedraw: true,
    //     categoryType: ['WATERMETER'],
    //     static: true,
    // },
    // {
    //     key: 'c4',
    //     component: 'WidgetLightsList',
    //     dataGrid: { w: 12, h: 11, x: 0, y: 32, minW: 4, minH: 4, moved: false, static: true },
    //     onResizeRedraw: true,
    //     categoryType: ['LIGHTS'],
    //     static: true,
    // },
    {
        key: 'c3',
        component: 'WidgetAlarms',
        dataGrid: { w: 12, h: 9, x: 0, y: 12, minW: 4, minH: 4, moved: false, static: true },
        onResizeRedraw: false,
        categoryType: ['WATERMETER', 'LIGHTS', 'WEATHER'],
        static: true,
    },
    // {
    //     key: 'c7',
    //     component: 'WidgetEnrgyMeterList',
    //     dataGrid: { w: 12, h: 12, x: 0, y: 10, minW: 4, minH: 4, static: true },
    //     onResizeRedraw: true,
    //     categoryType: ['ENERGYMETER'],
    // },
    {
        key: 'c8',
        component: 'WidgetCardsEnergy',
        dataGrid: { w: 4, h: 12, x: 4, y: 0, minW: 2, minH: 4, static: true },
        onResizeRedraw: false,
        categoryType: ['ENERGYMETER'],
    },
    // {
    //     key: 'c9',
    //     component: 'WidgetWeatherMeterList',
    //     dataGrid: { w: 12, h: 12, x: 0, y: 10, minW: 4, minH: 4, static: true },
    //     onResizeRedraw: true,
    //     categoryType: ['WEATHER'],
    // },
    {
        key: 'c10',
        component: 'WidgetCardsWeatherState',
        dataGrid: { w: 2, h: 12, x: 6, y: 12, minW: 2, minH: 2, moved: false, static: false },
        onResizeRedraw: false,
        categoryType: ['WEATHER'],
    },
];

export const defaultAllWidgetLayout: any = {
    lg: [
        { w: 4, h: 12, x: 0, y: 0, i: 'c2', minW: 2, minH: 2, moved: false, static: true },
        { w: 4, h: 12, x: 8, y: 0, i: 'c6', minW: 2, minH: 2, moved: false, static: true },
        { w: 2, h: 12, x: 4, y: 0, i: 'c8', minW: 2, minH: 2, moved: false, static: true },
        { w: 2, h: 12, x: 6, y: 0, i: 'c10', minW: 2, minH: 2, moved: false, static: true },
        { w: 12, h: 15, x: 0, y: 32, i: 'c5', minW: 1, minH: 1, moved: false, static: true },
        { w: 12, h: 11, x: 0, y: 21, i: 'c1', minW: 4, minH: 4, moved: false, static: true },
        //    { w: 12, h: 11, x: 0, y: 32, i: 'c4', minW: 4, minH: 4, moved: false, static: true },
        { w: 12, h: 9, x: 0, y: 12, i: 'c3', minW: 4, minH: 4, moved: false, static: true },
        //  { w: 12, h: 9, x: 0, y: 12, i: 'c7', minW: 4, minH: 4, moved: false, static: true },
        //{ w: 12, h: 9, x: 0, y: 12, i: 'c9', minW: 4, minH: 4, moved: false, static: true },
    ],
};
export const defaultLayoutLights: any = {
    lg: [
        { w: 4, h: 12, x: 8, y: 0, i: 'c6', minW: 2, minH: 2, moved: false, static: true },
        { w: 8, h: 12, x: 0, y: 0, i: 'c3', minW: 4, minH: 4, moved: false, static: true },
        { w: 12, h: 10, x: 0, y: 12, i: 'c1', minW: 4, minH: 4, moved: false, static: true },
        { w: 12, h: 13, x: 0, y: 22, i: 'c5', minW: 1, minH: 1, moved: false, static: true },
    ],
};
export const defaultLayoutWatermeter: any = {
    lg: [
        { w: 4, h: 12, x: 8, y: 0, i: 'c2', minW: 2, minH: 2, moved: false, static: true },
        { w: 12, h: 13, x: 0, y: 24, i: 'c5', minW: 1, minH: 1, moved: false, static: true },
        { w: 12, h: 12, x: 0, y: 12, i: 'c1', minW: 4, minH: 4, moved: false, static: true },
        { w: 8, h: 12, x: 0, y: 0, i: 'c3', minW: 4, minH: 4, moved: false, static: true },
    ],
};
export const defaultLayoutEnergymeter: any = {
    lg: [
        { w: 2, h: 12, x: 10, y: 0, i: 'c8', minW: 2, minH: 2, moved: false, static: true },

        { w: 26, h: 10, x: 0, y: 12, i: 'c1', minW: 4, minH: 4, moved: false, static: true },
        { w: 10, h: 12, x: 0, y: 0, i: 'c3', minW: 4, minH: 4, moved: false, static: true }, //alarm
        { w: 12, h: 13, x: 0, y: 22, i: 'c5', minW: 1, minH: 1, moved: false, static: true }, //karta
    ],
};

export const defaultLayoutWeatherState: any = {
    lg: [
        { w: 10, h: 12, x: 0, y: 0, i: 'c3', minW: 4, minH: 4, moved: false, static: true }, //alarm
        { w: 2, h: 12, x: 10, y: 0, i: 'c10', minW: 2, minH: 2, moved: false, static: true }, //card
        { w: 12, h: 9, x: 0, y: 12, i: 'c1', minW: 4, minH: 4, moved: false, static: true },
        { w: 12, h: 13, x: 0, y: 21, i: 'c5', minW: 1, minH: 1, moved: false, static: true }, //karta

        // { w: 12, h: 10, x: 0, y: 13, i: 'c4', minW: 4, minH: 4, moved: false, static: true },
    ],
};

export const defaultCardsItems: any = ['c10', 'c8', 'c2', 'c6'];

export const WidgetsComponentsMap: any = {
    WidgetCards: WidgetCards,
    WidgetCardsLights: WidgetCardsLights,
    WidgetMap: WidgetMap,
    WidgetWatermeterList: WidgetWatermeterList,
    WidgetLightsList: WidgetLightsList,
    WidgetEnrgyMeterList: WidgetEnrgyMeterList,
    WidgetAlarms: WidgetAlarms,
    WidgetCardsEnergy: WidgetCardsEnergy,
    WidgetWeatherMeterList: WidgetWeatherMeterList,
    WidgetCardsWeatherState: WidgetCardsWeatherState,
    DashboardList: DashboardList,
};

interface IState {
    widgets: {
        key: string;
        component: string;
        dataGrid: { [index: string]: string | number | boolean };
        onResizeRedraw: boolean;
        categoryType: string[];
    }[];
    lastLayout: Layout[];
    layouts: Layouts;
    dashboardKey: string;
    lock: boolean;
    categoryType: string[];
    visibleDashboradPopup: boolean;
    showGridLayout: boolean;
    filter: boolean;
}

export interface IWidget {
    dataGrid: { [index: string]: string | number | boolean };
    elementKey: string;
    showLightsOn: boolean;
    showEnergyOn: boolean;
    showWatermeterOn: boolean;
    showWeatherStateOn: boolean;
    refreshFilter?: string;
    refreshCategory?: string;
    showOnlyCategory?: boolean;
    categoryType?: any;
}
export interface DashProps {
    type?: string;
    queryId?: any;
}

export default class DashboardByCategoryType extends React.Component<DashProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            categoryType: [],
            widgets: [],
            lastLayout: NyUtils.loadFromSession(APPLICATION_KEY + '-dashboard-last') || {},
            layouts: {},
            dashboardKey: '' + Date.now(),
            lock: true,
            visibleDashboradPopup: false,
            showGridLayout: true,
            filter: false,
        };
        this.redraw = this.redraw.bind(this);
    }

    componentDidMount() {
        let categoryType = this.props.type;
        if (categoryType === 'WATERMETER') {
            this.setState({
                widgets: defaultWidgetWatermeter,
                layouts: defaultLayoutWatermeter,
                categoryType: ['WATERMETER'],
                lock: true,
            });
        }
        if (categoryType === 'LIGHTS') {
            this.setState({
                widgets: defaultWidgetLights,
                layouts: defaultLayoutLights,
                categoryType: ['LIGHTS'],
                lock: true,
            });
        }
        if (categoryType === 'ENERGYMETER') {
            this.setState({
                widgets: defaultWidgetEnergymeter,
                layouts: defaultLayoutEnergymeter,
                categoryType: ['ENERGYMETER'],
                lock: true,
            });
        }
        if (categoryType === 'WEATHER') {
            this.setState({
                widgets: defaultWidgetWeatherState,
                layouts: defaultLayoutWeatherState,
                categoryType: ['WEATHER'],
                lock: true,
            });
        }
    }

    redraw() {
        this.forceUpdate();
    }

    createElement(obj: {
        key: string;
        component: string;
        dataGrid: { [index: string]: string | number | boolean };
        onResizeRedraw: boolean;
    }) {
        let lastLayout: { [index: string]: any } | undefined = undefined;
        if (this.state.lastLayout.length > 0) {
            const ly = this.state.lastLayout.find(({ i }) => i === obj.key);
            if (ly != undefined) lastLayout = { ...ly };
            delete lastLayout?.i;
        }

        let Tagname = WidgetsComponentsMap[obj.component];
        let settings: IWidget = {
            dataGrid: lastLayout != undefined ? lastLayout : obj.dataGrid,
            elementKey: obj.key,
            showLightsOn: this.props.type === 'LIGHTS' ? true : false,
            showWatermeterOn: this.props.type === 'WATERMETER' ? true : false,
            showEnergyOn: this.props.type === 'ENERGYMETER' ? true : false,
            showWeatherStateOn: this.props.type === 'WEATHER' ? true : false,
            showOnlyCategory: true,
            categoryType: [this.props.type],
        };

        if (this.state.filter === true) {
            settings.refreshFilter = new Date().getTime().toString();
            this.setState({ filter: false });
        }
        settings.refreshCategory = new Date().getTime().toString();

        const w = React.createElement(Tagname, settings);
        return React.createElement(
            'div',
            {
                id: 'container_' + obj.key,
                key: obj.key,
                'data-grid': obj.dataGrid,
                className: 'dashboard-widget-box',
            },
            w
        );
    }

    deviceCategoryEnum = () => {
        const aTypes = GetEnum('DEVICE_CATEGORY');
        let types: any = [];
        const userCategoryTypes = getUserCategoryType();
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret: any = {};
                ret.value = aTypes[key];
                ret.label = geti18nText('app.enum.DEVICE_CATEGORY.' + key);
                if (userCategoryTypes.includes(key)) {
                    types.push(ret);
                }
            }
        }
        return types;
    };

    getWidgetsAndLayout(checkedValues: string[]) {
        if (checkedValues == null) {
            return null;
        }
        let defaultlayoutValues: any = [];
        let defaultWidgetValues: any = [];
        if (checkedValues.includes('ENERGYMETER')) {
            defaultlayoutValues = defaultlayoutValues.concat(JSON.parse(JSON.stringify(defaultLayoutEnergymeter.lg)));
            defaultWidgetValues = defaultWidgetValues.concat(defaultWidgetEnergymeter);
        }
        if (checkedValues.includes('WEATHER')) {
            defaultlayoutValues = defaultlayoutValues.concat(JSON.parse(JSON.stringify(defaultLayoutWeatherState.lg)));
            defaultWidgetValues = defaultWidgetValues.concat(defaultWidgetWeatherState);
        }
        if (checkedValues.includes('LIGHTS')) {
            defaultlayoutValues = defaultlayoutValues.concat(JSON.parse(JSON.stringify(defaultLayoutLights.lg)));
            defaultWidgetValues = defaultWidgetValues.concat(defaultWidgetLights);
        }
        if (checkedValues.includes('WATERMETER')) {
            defaultWidgetValues = defaultWidgetValues.concat(defaultWidgetWatermeter);
            defaultlayoutValues = defaultlayoutValues.concat(JSON.parse(JSON.stringify(defaultLayoutWatermeter.lg)));
        }

        defaultlayoutValues = defaultlayoutValues.filter(
            (v: any, i: any, a: any) => a.findIndex((v2: any) => v2.i === v.i) === i
        );

        defaultWidgetValues = defaultWidgetValues.filter(
            (v: any, i: any, a: any) => a.findIndex((v2: any) => v2.key === v.key) === i
        );

        let cards = defaultlayoutValues.filter((item: any) => defaultCardsItems.includes(item.i));
        let sumX: number = 0;
        cards.forEach((element: any) => {
            sumX += element.w;
        });

        let alarm = defaultlayoutValues.filter((item: any) => item.i === 'c3');
        let currentH = 12;
        if (sumX <= 6) {
            alarm[0].x = 0;

            let startWidth = 12 - sumX;
            alarm[0].w = startWidth;
            cards.forEach((element: any) => {
                let width = element.w;
                element.x = startWidth;

                startWidth += width;
            });
        } else {
            let startWidth = 0;
            const cof = 12 / sumX;
            cards.forEach((element: any) => {
                element.w = element.w * cof;
                let width = element.w;

                element.x = startWidth;

                startWidth += width;
            });
            alarm[0].y = 12;
            currentH += 12;
            alarm[0].x = 0;
            alarm[0].w = 12;
        }
        let mapIndex = defaultlayoutValues.indexOf((item: any) => item.i === 'c5');
        if (mapIndex > -1) {
            defaultlayoutValues.push(defaultlayoutValues.splice(mapIndex, 1)[0]);
        }

        defaultlayoutValues.forEach((element: any) => {
            if (!defaultCardsItems.includes(element.i) && element.i != 'c3') {
                element.y = currentH;
                currentH += element.h;
            }
        });

        return {
            tmpLayout: { lg: defaultlayoutValues },
            newWidgets: defaultWidgetValues,
        };
    }

    handleVisibleChange(visible: any) {
        this.setState({
            visibleDashboradPopup: visible,
        });
    }

    clearFilter() {
        this.setState({
            filter: true,
        });
    }

    render() {
        return (
            <>
                <Row>
                    <Col span={24}>
                        {this.state.showGridLayout &&
                            this.state.widgets &&
                            Array.isArray(this.state.widgets) &&
                            this.state.widgets.length > 0 && (
                                <ResponsiveReactGridLayout
                                    key={this.state.dashboardKey}
                                    className="layout"
                                    cols={{ lg: 12, md: 12, sm: 2, xs: 2, xxs: 2 }}
                                    layouts={this.state.layouts}
                                    rowHeight={30}
                                    margin={[10, 10]}
                                >
                                    {this.state.widgets &&
                                        this.state.widgets.map((el) => {
                                            return this.createElement(el);
                                        })}
                                </ResponsiveReactGridLayout>
                            )}
                    </Col>
                </Row>

                <ModalList />
            </>
        );
    }
}
