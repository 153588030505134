import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Popconfirm, Button, notification } from 'antd';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { geti18nText, NyRequestResolver, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';

interface IGenerateTasks {
    groupId: number;
}

const GenerateTasks: React.FC<IGenerateTasks> = ({ groupId }) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.INACTIVE_DEVICES_WITHOUT_TASK + '/' + groupId, {
            max: 500,
            offset: 0,
        }).then((response: any) => {
            setLoading(false);
            if (response.status === RESPONSE.OK) {
                if (response.data && typeof response.data !== 'string' && response.data.content) {
                    setData(response.data.content);
                    if (response.data.numberOfElements != response.data.totalSize) {
                        setHasMore(true);
                    } else {
                        setHasMore(false);
                    }
                }
            }
        });
    };

    const remove = (id: number) => {
        var newArr = data.filter(function (rec: any) {
            return rec.id != id;
        });
        setData(newArr);
    };

    const saveTasks = () => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE.GENERATE_TASKS, undefined, {
            devices: data.map((obj: { [index: string]: any }) => obj.id),
        }).then((result) => {
            if (result && result.status === RESPONSE.CREATED) {
                setData([]);
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('generate.tasks.created'),
                    duration: 3,
                });
            } else {
                notification.error({
                    message: geti18nText('app.default.save.nok'),
                    description: geti18nText('generate.tasks.not.created'),
                    duration: 0,
                });
            }
        });
    };

    return (
        <Row>
            <Col span={24}>
                {loading ? (
                    <NySpinner />
                ) : (
                    <React.Fragment>
                        <Table
                            rowKey="id"
                            className="noselect"
                            size="small"
                            pagination={false}
                            dataSource={data}
                            loading={loading}
                            scroll={{ y: 500 }}
                            columns={[
                                {
                                    title: geti18nText('generate.tasks.table.name'),
                                    dataIndex: 'name',
                                },
                                {
                                    title: geti18nText('generate.tasks.table.serial'),
                                    dataIndex: 'serialNumber',
                                },
                                {
                                    title: geti18nText('generate.tasks.table.eui'),
                                    dataIndex: 'eui',
                                },
                                {
                                    title: geti18nText('generate.tasks.table.volume'),
                                    dataIndex: 'volume',
                                    align: 'right',
                                    render: (text: string, record: { [index: string]: any }) => {
                                        return (
                                            <div>
                                                {record.volume ? NyUtils.formatNumber(record.volume, 3) : '0,0'}
                                                &nbsp;m3
                                            </div>
                                        );
                                    },
                                },
                                {
                                    title: geti18nText('generate.tasks.table.ts'),
                                    dataIndex: 'ts',
                                    align: 'right',
                                    render: (text: string, record: { [index: string]: any }) => {
                                        if (record.ts) {
                                            const ts: Date = new Date(
                                                record.ts[0],
                                                record.ts[1] - 1,
                                                record.ts[2],
                                                record.ts[3],
                                                record.ts[4],
                                                record.ts[5]
                                            );
                                            return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                                        }
                                    },
                                },
                                {
                                    title: '',
                                    dataIndex: 'operation',
                                    align: 'center',
                                    render: (text: string, record: { [index: string]: any }) => {
                                        return (
                                            <Popconfirm
                                                title={geti18nText('generate.tasks.confirm')}
                                                onConfirm={() => {
                                                    remove(record.id);
                                                }}
                                            >
                                                <a>{geti18nText('generate.tasks.remove')}</a>
                                            </Popconfirm>
                                        );
                                    },
                                },
                            ]}
                        ></Table>
                        <Row>
                            <Col span={24}>
                                {data.length > 0 && (
                                    <Button type="primary" onClick={saveTasks}>
                                        {geti18nText('generate.tasks.button.create')}
                                    </Button>
                                )}
                                {hasMore && data.length == 0 && (
                                    <Button type="primary" onClick={fetch}>
                                        {geti18nText('generate.tasks.button.fetch')}
                                    </Button>
                                )}
                            </Col>
                        </Row>
                    </React.Fragment>
                )}
            </Col>
        </Row>
    );
};

export default GenerateTasks;
