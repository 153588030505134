import React, { useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { Row, Col, Form, Input, Select, InputNumber } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { geti18nText, NyColorPicker, NyDataEdit, NySearchField } from '@nybble/nyreact';
import NyImageUpload from '../../utils/NyImageUpload';
import { GetEnum, GetEnumNameForValue } from '../../utils/Enums';
const ParameterCatalogEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('category.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [uploadKey, setUploadKey] = useState<any>(Date.now());
    const [file, setFile] = useState<any>(null);
    const [defaultImage, setDefaultImage] = useState<any>(null);
    const [resolution, setResolution] = useState<any>(null);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setDataForm(dataForm.id);
        dataForm.maxRange = Number(dataForm.maxRange);
        dataForm.minRange = Number(dataForm.minRange);
        //dataForm.resolution = Number(dataForm.resolution);
        setResolution(dataForm.resolution);

        if (dataForm.icon != null) {
            dataForm.icon = 'data:image/jpeg;base64,' + dataForm.icon;
            setFile(dataForm.icon);
        }
        if (dataForm.chart != undefined) {
            dataForm.chart = {
                id: dataForm.chart,
                text: geti18nText('parameter.catalog.edit.chart.' + dataForm.chart),
            };
        }
        form.setFieldsValue(dataForm);

        setEditHeader(geti18nText('parameter.catalog.edit.catalog') + ': ' + dataForm.name);
    }

    const onModalClose = () => {
        form.resetFields();
        setDataForm(null);
        setResolution(null);
        setDefaultImage(null);
        setFile(null);
        setEditHeader(geti18nText('parameter.catalog.edit.catalog'));
    };

    const chartType = () => {
        const aTypes = GetEnum('CHART_TYPE');
        let types = [];
        for (var key in aTypes) {
            let ret = { id: undefined, text: '' };
            ret.id = aTypes[key];
            ret.text = geti18nText('parameter.catalog.edit.chart.' + ret.id);
            types.push(ret);
        }

        return types;
    };

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 5 }, wrapperCol: { span: 14 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.WEATHER_PARAMETER.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (file) {
                    values.file = file;
                }
                if (values.chart != undefined) {
                    values.chart = values.chart.constructor === Number ? values.chart : values.chart.id;
                }
                delete values.icon;
                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Col span={12}>
                        <Form.Item name="key" style={{ display: 'none' }}>
                            <InputNumber />
                        </Form.Item>
                    </Col>
                    <Form.Item label={geti18nText('parameter.catalog.edit.name')} name="name">
                        <Input />
                    </Form.Item>
                    <Form.Item label={geti18nText('parameter.catalog.edit.nameEn')} name="name_en">
                        <Input />
                    </Form.Item>

                    <Form.Item label={geti18nText('parameter.catalog.edit.icon')} name="icon">
                        <NyImageUpload
                            key={uploadKey}
                            files={file}
                            setFiles={setFile}
                            multiple={false}
                            value={file}
                            defaultImage={defaultImage}
                            accept={'image/*'}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item label={geti18nText('parameter.catalog.edit.minRange')} name="minRange">
                        <InputNumber step={resolution} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('parameter.catalog.edit.maxRange')} name="maxRange">
                        <InputNumber step={resolution} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item label={geti18nText('alarm.settings.edit.widget.lowerLimit')} name="lowerLimit">
                        <InputNumber step={resolution} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('alarm.settings.edit.widget.upperLimit')} name="upperLimit">
                        <InputNumber step={resolution} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item label={geti18nText('parameter.catalog.edit.resolution')} name="resolution">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('parameter.catalog.edit.unit')} name="unit">
                        <InputNumber />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item label={geti18nText('parameter.catalog.edit.chart')} name="chart">
                        <NySearchField
                            options={chartType()}
                            map={{ id: 'id', label: 'text' }}
                            searchBy="text"
                            onChange={onchange}
                            disabled
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Form.Item label={geti18nText('parameter.catalog.edit.color')} name="color">
                        <NyColorPicker></NyColorPicker>
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default ParameterCatalogEdit;
