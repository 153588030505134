import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Radio } from 'antd';
import { useDispatch } from 'react-redux';

import { NyUtils, geti18nText } from '@nybble/nyreact';
import { IWidget } from '..';
import WidgetWatermeterList from '../WidgetWatermeterList';
import { GetEnum, getUserCategoryType } from '../../../utils/Enums';
import { APPLICATION_KEY, DeviceCategoryType } from '../../../utils/Constants';
import WidgetEnrgyMeterList from '../WidgetEnergyMeterList';
import WidgetLightsList from '../WidgetLightsList';
import WidgetWeatherMeterList from '../WidgetWeatherMeterList';

const DashboardList: React.FC<IWidget> = ({
    dataGrid,
    elementKey,
    showLightsOn,
    showEnergyOn,
    showWatermeterOn,
    showWeatherStateOn,
    refreshCategory,
    showOnlyCategory,
    categoryType
}) => {
    const [typeCategory, setTypeCategory] = useState<any>(categoryType);
    const [deviceCategoryOptions, setDeviceCategoryOptions] = useState<any>();
    const [showFilter, setShowFilter] = useState<any>(null);
    /* useEffect(() => {
        const categoryType: any =
            NyUtils.load(APPLICATION_KEY + '-CATEGORY_TYPE') != null
                ? NyUtils.load(APPLICATION_KEY + '-CATEGORY_TYPE')
                : getUserCategoryType();
        getDeviceCategoryOptions(categoryType);
        setShowFilter(categoryType && categoryType.length > 1 ? true : false);
        console.log("CATTYPE",getUserCategoryType());
        if (categoryType != undefined && categoryType.length > 0) {
            setTypeCategory(categoryType[0]);
        }
    }, [refreshCategory]);
 */
    const getDeviceCategoryOptions: any = (categoryType: any) => {
        const aTypes = GetEnum('DEVICE_CATEGORY');

        let types: any = [];

        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key) && categoryType.includes(key)) {
                let ret: any = { label: undefined, value: undefined };
                ret.value = aTypes[key];
                ret.label = geti18nText('app.enum.DEVICE_CATEGORY.' + key);
                types.push(ret);
            }
        }
        setDeviceCategoryOptions(types);
    };

    function onTypeCategoryChange(val: any) {
        setTypeCategory(val.target.value);
    }

    return (
        <React.Fragment>
            {showFilter &&  !showOnlyCategory&& (
                <div style={{ marginLeft: '18px' }}>
                    <Form.Item label={geti18nText('deviceModel.table.filter')}>
                        <Row style={{ marginLeft: '18px' }}>
                            <Radio.Group
                                onChange={onTypeCategoryChange}
                                value={typeCategory}
                                options={deviceCategoryOptions}
                            />
                        </Row>
                    </Form.Item>
                </div>
            )}

            {typeCategory == DeviceCategoryType.WATERMETER && (
                <WidgetWatermeterList
                    dataGrid={dataGrid}
                    elementKey={elementKey}
                    showLightsOn={false}
                    showEnergyOn={false}
                    showWatermeterOn={showWatermeterOn}
                    showWeatherStateOn={false}
                />
            )}
            {typeCategory == DeviceCategoryType.ENERGYMETER && (
                <WidgetEnrgyMeterList
                    dataGrid={dataGrid}
                    elementKey={elementKey}
                    showLightsOn={false}
                    showEnergyOn={showEnergyOn}
                    showWatermeterOn={false}
                    showWeatherStateOn={false}
                />
            )}
            {typeCategory == DeviceCategoryType.LIGHTS && (
                <WidgetLightsList
                    dataGrid={dataGrid}
                    elementKey={elementKey}
                    showLightsOn={showLightsOn}
                    showEnergyOn={false}
                    showWatermeterOn={false}
                    showWeatherStateOn={false}
                />
            )}
            {typeCategory == DeviceCategoryType.WEATHER && (
                <WidgetWeatherMeterList
                    dataGrid={dataGrid}
                    elementKey={elementKey}
                    showLightsOn={false}
                    showEnergyOn={false}
                    showWatermeterOn={false}
                    showWeatherStateOn={showWeatherStateOn}
                />
            )}
        </React.Fragment>
    );
};

export default DashboardList;
