import React, { useState, useEffect } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { IWidget } from './index';
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    EditOutlined,
    PushpinOutlined,
    BellOutlined,
    ZoomInOutlined,
    CloudDownloadOutlined,
    ProfileOutlined,
    FullscreenOutlined,
    CommentOutlined,
} from '@ant-design/icons';
import { deviceDetailsOpen } from '../../slices/deviceDetailsSlice';
import { deviceContactOpen } from '../../slices/deviceContactSlice';
import { deviceLocationOpen } from '../../slices/deviceLocationSlice';
import { deviceManualConsumtionOpen } from '../../slices/deviceManualConsumtionSlice';
import { RootState } from '../../rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
    refreshWatermeterList,
    filterDeviceWithAlarmStatus,
    setMapPanTo,
    filterDeviceDevices,
    filterReset,
} from '../../slices/dashboardSlice';
import { Tooltip, Modal } from 'antd';
import { GetEnumNameForValue, GetEnum } from '../../utils/Enums';
import { getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession, NyUtils } from '@nybble/nyreact';

const getScrolls = (dataGrid: { [index: string]: string | number | boolean | undefined }) => {
    let scr: { [index: string]: any } = { x: 1350 };
    if (typeof dataGrid.h == 'number') {
        scr.y = 40 * dataGrid.h - 170 - 50;
    }
    return scr;
};

interface ColumnTitleProps {
    text: string;
}

const ColumnTitle: React.FC<ColumnTitleProps> = ({ text }) => {
    return <div style={{ whiteSpace: 'nowrap' }}>{text}</div>;
};

const WidgetWatermeterList: any = ({ dataGrid }: any) => {
    const [loadedDevices, setLoadedDevices] = useState<string[]>([]);
    const [visible, setVisible] = useState(false);

    const {
        watermeterListRefresh,
        lastDeviceStatusChanged,
        filterActive,
        filterInactive,
        filterLowBattery,
        filterDevice,
        filterAlarmStatus,
        filterSource,
        mapSelectedBounds,
    } = useSelector((state: RootState) => state.dashboard);
    const dispatch = useDispatch();

    useEffect(() => {
        if (lastDeviceStatusChanged != undefined && loadedDevices.includes(lastDeviceStatusChanged)) {
            dispatch(refreshWatermeterList());
        }
    }, [lastDeviceStatusChanged]);

    useEffect(() => {
        dispatch(refreshWatermeterList());
    }, [mapSelectedBounds]);

    const editContactInfo = (deviceId: string, data?: { [index: string]: any }) => {
        dispatch(deviceContactOpen({ deviceId: deviceId, data: data, visible: true }));
    };

    const manualConsumptionEntry = (deviceId: string, data?: { [index: string]: any }) => {
        dispatch(deviceManualConsumtionOpen({ deviceId: deviceId, data: data, visible: true }));
    };

    const editLocation = (deviceId: string, data?: { [index: string]: any }) => {
        dispatch(deviceLocationOpen({ deviceId: deviceId, data: data, visible: true }));
    };

    const editAlarm = (deviceId: string, data?: { [index: string]: any }) => {
        //dispatch(deviceAlarmThrasholdOpen({ deviceId: deviceId, data: data, visible: true }));
    };

    const onRowSelect = (record: { [index: string]: any }) => {
        dispatch(deviceDetailsOpen({ record: record, visible: true }));
    };

    const onDataLoaded = (records: { [index: string]: any } | string, params: { [index: string]: any } | undefined) => {
        /*if (records != undefined && Array.isArray(records)) {
            let ids: string[] = [];
            for (let record of records) {
                ids.push('' + record.id);
            }
            setLoadedDevices(ids);
        }*/
        if (params && params.search) {
            const obj = JSON.parse('{"search":' + decodeURI(params.search) + '}');
            let filterAStatus: number | undefined = undefined;
            let filterASource: number | undefined = undefined;
            for (const searchParam of obj.search) {
                if (searchParam.condition == 'equals' && searchParam.value == '1') {
                    switch (searchParam.field) {
                        case 'backflow':
                            filterAStatus = 5;
                            break;
                        case 'burst':
                            filterAStatus = 7;
                            break;
                        case 'dry':
                            filterAStatus = 4;
                            break;
                        case 'leakage':
                            filterAStatus = 8;
                            break;
                        case 'lowTemperature':
                            filterAStatus = 9;
                            break;
                    }
                }
                if (searchParam.condition == 'equals' && searchParam.field == 'source') {
                    filterASource = searchParam.value;
                }
            }
            if (filterAStatus !== filterAlarmStatus || filterASource !== filterSource) {
                dispatch(filterDeviceWithAlarmStatus({ status: filterAStatus, source: filterASource }));
            }
        }
    };

    const filterErrorOptions = [
        { id: '0', text: geti18nText('dashboard.widget.watermeter.filer.error.ok') },
        { id: '1', text: geti18nText('dashboard.widget.watermeter.filer.error.nok') },
    ];

    const setDefaultFilterValue = () => {
        if (filterActive) {
            return [{ field: 'alarmStatus', condition: 'not_equals', value: -48 }];
        }
        if (filterInactive) {
            return [{ field: 'alarmStatus', condition: 'equals', value: -48 }];
        }
        if (filterLowBattery) {
            return [{ field: 'alarmStatus', condition: 'equals', value: 1 }];
        }
        return [];
    };

    const generateKey = () => {
        return '1-' + filterActive + filterInactive + filterLowBattery;
    };

    const showOnMap = (record: { [index: string]: any }) => {
        if (record.lat && record.lng) {
            dispatch(setMapPanTo({ lat: record.lat, lng: record.lng }));
        }
    };

    const sourceTypes = () => {
        const aTypes = GetEnum('PAYLOAD_TYPE');
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.PAYLOAD_TYPE.' + key);
                if (ret.id == 100 || ret.id == 200 || ret.id == 300 || ret.id == 400) {
                    types.push(ret);
                }
            }
        }
        return types;
    };

    const getSourceColor = (source: number) => {
        switch (source) {
            case 100:
                return '#00a900';
                break;
            case 200:
                return '#1890ff';
                break;
            case 300:
                return '#ff9900';
                break;
            case 400:
                return '#cc33ff';
                break;
            default:
                return '#000000';
        }
    };

    const showOnMapFilter = (item: any) => {
        if (filterDevice != item.id) {
            showOnMap(item);
            dispatch(filterDeviceDevices({ id: item.id }));
        } else {
            dispatch(filterReset());
        }
    };

    const createDate = (data: any[]) => {
        if (data.length >= 3 && data.length < 5) {
            return new Date(data[0], data[1] - 1, data[2]);
        } else if (data.length == 5) {
            return new Date(data[0], data[1] - 1, data[2], data[3], data[4]);
        } else if (data.length > 5) {
            return new Date(data[0], data[1] - 1, data[2], data[3], data[4], data[5]);
        } else {
            return '';
        }
    };

    return (
        <React.Fragment>
            <FullscreenOutlined
                style={{ position: 'absolute', right: '5px', top: '5px' }}
                onClick={() => setVisible(true)}
            />
            <NyDataTable
                key={generateKey()}
                headerTitle={geti18nText('dashboard.widget.watermeter.table.title')}
                url={CONSTANTS_REQ.DASHBOARD.DEVICELIST}
                hideButtons={true}
                exportCSV={true}
                onRowSelect={onRowSelect}
                addedData={
                    mapSelectedBounds != null
                        ? {
                              latNorthEast: mapSelectedBounds.latNorthEast,
                              lngNorthEast: mapSelectedBounds.lngNorthEast,
                              latSouthWest: mapSelectedBounds.latSouthWest,
                              lngSouthWest: mapSelectedBounds.lngSouthWest,
                          }
                        : undefined
                }
                setDefaultFilterValue={setDefaultFilterValue}
                scroll={getScrolls(dataGrid)}
                fetchWhenChange={watermeterListRefresh}
                onDataLoaded={onDataLoaded}
                columns={[
                    {
                        dataIndex: 'source',
                        width: 30,
                        ...getColumnSearchOption(sourceTypes()),
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.source) {
                                return (
                                    <div>
                                        <React.Fragment>
                                            <Tooltip
                                                title={
                                                    geti18nText('dashboard.widget.watermeter.table.source.tooltip') +
                                                    ' ' +
                                                    geti18nText(
                                                        'app.enum.PAYLOAD_TYPE.' +
                                                            GetEnumNameForValue('PAYLOAD_TYPE', record.source)
                                                    )
                                                }
                                            >
                                                <CloudDownloadOutlined
                                                    style={{
                                                        fontSize: '18px',
                                                        marginRight: '10px',
                                                        color: getSourceColor(record.source),
                                                    }}
                                                />
                                            </Tooltip>
                                        </React.Fragment>
                                    </div>
                                );
                            }
                        },
                    },
                    {
                        title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.name')} />,
                        dataIndex: 'name',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: number }) => {
                            if ('comment' in record) {
                                return (
                                    <div>
                                        {record.name}
                                        <Tooltip title={record.comment}>
                                            <CommentOutlined
                                                style={{
                                                    fontSize: '16px',
                                                    marginLeft: '10px',
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                );
                            } else {
                                return <div> {record.name} </div>;
                            }
                        },
                        ...getColumnSearch('string'),
                    },
                    {
                        title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.serial')} />,
                        dataIndex: 'eui',
                        sorter: (a: string, b: string) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.address')} />,
                        dataIndex: 'address',
                        sorter: (a: string, b: string) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.waterusage')} />,
                        dataIndex: 'volume',
                        align: 'right',
                        sorter: (a: number, b: number) => {},
                        render: (text: string, record: { [index: string]: number }) => {
                            return (
                                <div>
                                    {record.volume ? NyUtils.formatNumber(record.volume, 3) : '0,0'}
                                    &nbsp;m3
                                </div>
                            );
                        },
                    },
                    {
                        title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.reverse')} />,
                        dataIndex: 'backflow',
                        align: 'center',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.backflow == 0) {
                                return <CheckCircleOutlined style={{ color: 'green', fontSize: '18px' }} />;
                            } else {
                                return <ExclamationCircleOutlined style={{ color: 'red', fontSize: '18px' }} />;
                            }
                        },
                        ...getColumnSearchOption(filterErrorOptions),
                    },
                    {
                        title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.rupture')} />,
                        dataIndex: 'burst',
                        align: 'center',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.burst == 0) {
                                return <CheckCircleOutlined style={{ color: 'green', fontSize: '18px' }} />;
                            } else {
                                return <ExclamationCircleOutlined style={{ color: 'red', fontSize: '18px' }} />;
                            }
                        },
                        ...getColumnSearchOption(filterErrorOptions),
                    },
                    {
                        title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.nowater')} />,
                        dataIndex: 'dry',
                        align: 'center',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.dry == 0) {
                                return <CheckCircleOutlined style={{ color: 'green', fontSize: '18px' }} />;
                            } else {
                                return <ExclamationCircleOutlined style={{ color: 'red', fontSize: '18px' }} />;
                            }
                        },
                        ...getColumnSearchOption(filterErrorOptions),
                    },
                    {
                        title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.leakage')} />,
                        dataIndex: 'leakage',
                        align: 'center',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.leakage == 0) {
                                return <CheckCircleOutlined style={{ color: 'green', fontSize: '18px' }} />;
                            } else {
                                return <ExclamationCircleOutlined style={{ color: 'red', fontSize: '18px' }} />;
                            }
                        },
                        ...getColumnSearchOption(filterErrorOptions),
                    },
                    {
                        title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.lowtemperature')} />,
                        dataIndex: 'lowTemperature',
                        align: 'center',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.lowTemperature == 0) {
                                return <CheckCircleOutlined style={{ color: 'green', fontSize: '18px' }} />;
                            } else {
                                return <ExclamationCircleOutlined style={{ color: 'red', fontSize: '18px' }} />;
                            }
                        },
                        ...getColumnSearchOption(filterErrorOptions),
                    },
                    {
                        title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.lastactivity')} />,
                        dataIndex: 'ts',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.ts) {
                                var ts: any = createDate(record.ts);
                                if (ts !== '') {
                                    return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                                } else {
                                    return <div></div>;
                                }
                            }
                        },
                    },
                    {
                        title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.dma')} />,
                        dataIndex: 'deviceGroupName',
                        sorter: (a: string, b: string) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        dataIndex: 'operation',
                        width: NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER']) ? 125 : 30,
                        align: 'left',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.id) {
                                return (
                                    <div>
                                        {NySession.hasAnyRole([
                                            'ROLE_ADMIN',
                                            'ROLE_CUSTOMER_ADMIN',
                                            'ROLE_CUSTOMER',
                                        ]) && (
                                            <React.Fragment>
                                                <Tooltip
                                                    title={geti18nText(
                                                        'dashboard.widget.watermeter.table.manual.data.tooltip'
                                                    )}
                                                >
                                                    <ProfileOutlined
                                                        style={{
                                                            fontSize: '18px',
                                                            marginRight: '10px',
                                                            color: getSourceColor(400),
                                                        }}
                                                        onClick={() =>
                                                            manualConsumptionEntry(record.id, {
                                                                name: record.name,
                                                                serial: record.eui,
                                                                volume: record.volume,
                                                                address: record.address,
                                                            })
                                                        }
                                                    />
                                                </Tooltip>
                                                <Tooltip
                                                    title={geti18nText(
                                                        'dashboard.widget.watermeter.table.contact.tooltip'
                                                    )}
                                                >
                                                    <EditOutlined
                                                        style={{ fontSize: '18px', marginRight: '10px' }}
                                                        onClick={() =>
                                                            editContactInfo(record.id, {
                                                                address: record.address,
                                                                email: record.email,
                                                                phone: record.phone,
                                                            })
                                                        }
                                                    />
                                                </Tooltip>
                                                <Tooltip
                                                    title={geti18nText(
                                                        'dashboard.widget.watermeter.table.location.tooltip'
                                                    )}
                                                >
                                                    <PushpinOutlined
                                                        style={{ fontSize: '18px', marginRight: '10px' }}
                                                        onClick={() =>
                                                            editLocation(record.id, {
                                                                latitude: record.lat,
                                                                longitude: record.lng,
                                                            })
                                                        }
                                                    />
                                                </Tooltip>
                                                {/*<Tooltip
                                                    title={geti18nText(
                                                        'dashboard.widget.watermeter.table.alarm.tooltip'
                                                    )}
                                                >
                                                    <BellOutlined
                                                        style={{ fontSize: '18px', marginRight: '10px' }}
                                                        onClick={() => editAlarm(record.id, { weekly: '3.3' })}
                                                    />
                                                    </Tooltip>*/}
                                            </React.Fragment>
                                        )}
                                        {record.lat != undefined && (
                                            <Tooltip
                                                title={geti18nText(
                                                    'dashboard.widget.watermeter.table.showonmap.tooltip'
                                                )}
                                            >
                                                {filterDevice === record.id ? (
                                                    <ZoomInOutlined
                                                        style={{ fontSize: '18px', color: 'red' }}
                                                        onClick={() => showOnMapFilter(record)}
                                                    />
                                                ) : (
                                                    <ZoomInOutlined
                                                        style={{ fontSize: '18px' }}
                                                        onClick={() => showOnMapFilter(record)}
                                                    />
                                                )}
                                            </Tooltip>
                                        )}
                                    </div>
                                );
                            }
                        },
                    },
                ]}
            />
            <Modal
                title={geti18nText('dashboard.widget.watermeter.table.title')}
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={'90%'}
                centered={true}
                footer={null}
            >
                <NyDataTable
                    key={generateKey()}
                    headerTitle={geti18nText('dashboard.widget.watermeter.table.title')}
                    url={CONSTANTS_REQ.DASHBOARD.DEVICELIST}
                    hideButtons={true}
                    exportCSV={true}
                    onRowSelect={onRowSelect}
                    setDefaultFilterValue={setDefaultFilterValue}
                    scroll={getScrolls(dataGrid)}
                    fetchWhenChange={watermeterListRefresh}
                    onDataLoaded={onDataLoaded}
                    columns={[
                        {
                            dataIndex: 'source',
                            width: 30,
                            ...getColumnSearchOption(sourceTypes()),
                            render: (text: string, record: { [index: string]: any }) => {
                                if (record.source) {
                                    return (
                                        <div>
                                            <React.Fragment>
                                                <Tooltip
                                                    title={
                                                        geti18nText(
                                                            'dashboard.widget.watermeter.table.source.tooltip'
                                                        ) +
                                                        ' ' +
                                                        geti18nText(
                                                            'app.enum.PAYLOAD_TYPE.' +
                                                                GetEnumNameForValue('PAYLOAD_TYPE', record.source)
                                                        )
                                                    }
                                                >
                                                    <CloudDownloadOutlined
                                                        style={{
                                                            fontSize: '18px',
                                                            marginRight: '10px',
                                                            color: getSourceColor(record.source),
                                                        }}
                                                    />
                                                </Tooltip>
                                            </React.Fragment>
                                        </div>
                                    );
                                }
                            },
                        },
                        {
                            title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.name')} />,
                            dataIndex: 'name',
                            sorter: (a: string, b: string) => {},
                            ...getColumnSearch('string'),
                        },
                        {
                            title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.serial')} />,
                            dataIndex: 'eui',
                            sorter: (a: string, b: string) => {},
                            ...getColumnSearch('string'),
                        },
                        {
                            title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.address')} />,
                            dataIndex: 'address',
                            sorter: (a: string, b: string) => {},
                            ...getColumnSearch('string'),
                        },
                        {
                            title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.waterusage')} />,
                            dataIndex: 'volume',
                            align: 'right',
                            sorter: (a: string, b: string) => {},
                            render: (text: string, record: { [index: string]: any }) => {
                                return (
                                    <div>
                                        {record.volume ? NyUtils.formatNumber(record.volume, 3) : '0,0'}
                                        &nbsp;m3
                                    </div>
                                );
                            },
                        },
                        {
                            title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.reverse')} />,
                            dataIndex: 'backflow',
                            align: 'center',
                            render: (text: string, record: { [index: string]: any }) => {
                                if (record.backflow == 0) {
                                    return <CheckCircleOutlined style={{ color: 'green', fontSize: '18px' }} />;
                                } else {
                                    return <ExclamationCircleOutlined style={{ color: 'red', fontSize: '18px' }} />;
                                }
                            },
                            ...getColumnSearchOption(filterErrorOptions),
                        },
                        {
                            title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.rupture')} />,
                            dataIndex: 'burst',
                            align: 'center',
                            render: (text: string, record: { [index: string]: any }) => {
                                if (record.burst == 0) {
                                    return <CheckCircleOutlined style={{ color: 'green', fontSize: '18px' }} />;
                                } else {
                                    return <ExclamationCircleOutlined style={{ color: 'red', fontSize: '18px' }} />;
                                }
                            },
                            ...getColumnSearchOption(filterErrorOptions),
                        },
                        {
                            title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.nowater')} />,
                            dataIndex: 'dry',
                            align: 'center',
                            render: (text: string, record: { [index: string]: any }) => {
                                if (record.dry == 0) {
                                    return <CheckCircleOutlined style={{ color: 'green', fontSize: '18px' }} />;
                                } else {
                                    return <ExclamationCircleOutlined style={{ color: 'red', fontSize: '18px' }} />;
                                }
                            },
                            ...getColumnSearchOption(filterErrorOptions),
                        },
                        {
                            title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.leakage')} />,
                            dataIndex: 'leakage',
                            align: 'center',
                            render: (text: string, record: { [index: string]: any }) => {
                                if (record.leakage == 0) {
                                    return <CheckCircleOutlined style={{ color: 'green', fontSize: '18px' }} />;
                                } else {
                                    return <ExclamationCircleOutlined style={{ color: 'red', fontSize: '18px' }} />;
                                }
                            },
                            ...getColumnSearchOption(filterErrorOptions),
                        },
                        {
                            title: (
                                <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.lowtemperature')} />
                            ),
                            dataIndex: 'lowTemperature',
                            align: 'center',
                            render: (text: string, record: { [index: string]: any }) => {
                                if (record.lowTemperature == 0) {
                                    return <CheckCircleOutlined style={{ color: 'green', fontSize: '18px' }} />;
                                } else {
                                    return <ExclamationCircleOutlined style={{ color: 'red', fontSize: '18px' }} />;
                                }
                            },
                            ...getColumnSearchOption(filterErrorOptions),
                        },
                        {
                            title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.lastactivity')} />,
                            dataIndex: 'ts',
                            sorter: (a: string, b: string) => {},
                            render: (text: string, record: { [index: string]: any }) => {
                                if (record.ts) {
                                    const ts: Date = new Date(
                                        record.ts[0],
                                        record.ts[1] - 1,
                                        record.ts[2],
                                        record.ts[3],
                                        record.ts[4],
                                        record.ts[5]
                                    );
                                    return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                                }
                            },
                        },
                        {
                            title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.dma')} />,
                            dataIndex: 'deviceGroupName',
                            sorter: (a: string, b: string) => {},
                            ...getColumnSearch('string'),
                        },
                        {
                            dataIndex: 'operation',
                            width: NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'])
                                ? 125
                                : 30,
                            align: 'left',
                            render: (text: string, record: { [index: string]: any }) => {
                                if (record.id) {
                                    return (
                                        <div>
                                            {NySession.hasAnyRole([
                                                'ROLE_ADMIN',
                                                'ROLE_CUSTOMER_ADMIN',
                                                'ROLE_CUSTOMER',
                                            ]) && (
                                                <React.Fragment>
                                                    <Tooltip
                                                        title={geti18nText(
                                                            'dashboard.widget.watermeter.table.manual.data.tooltip'
                                                        )}
                                                    >
                                                        <ProfileOutlined
                                                            style={{
                                                                fontSize: '18px',
                                                                marginRight: '10px',
                                                                color: getSourceColor(400),
                                                            }}
                                                            onClick={() =>
                                                                manualConsumptionEntry(record.id, {
                                                                    name: record.name,
                                                                    serial: record.eui,
                                                                    volume: record.volume,
                                                                    address: record.address,
                                                                })
                                                            }
                                                        />
                                                    </Tooltip>
                                                    <Tooltip
                                                        title={geti18nText(
                                                            'dashboard.widget.watermeter.table.contact.tooltip'
                                                        )}
                                                    >
                                                        <EditOutlined
                                                            style={{ fontSize: '18px', marginRight: '10px' }}
                                                            onClick={() =>
                                                                editContactInfo(record.id, {
                                                                    address: record.address,
                                                                    email: record.email,
                                                                    phone: record.phone,
                                                                })
                                                            }
                                                        />
                                                    </Tooltip>
                                                    <Tooltip
                                                        title={geti18nText(
                                                            'dashboard.widget.watermeter.table.location.tooltip'
                                                        )}
                                                    >
                                                        <PushpinOutlined
                                                            style={{ fontSize: '18px', marginRight: '10px' }}
                                                            onClick={() =>
                                                                editLocation(record.id, {
                                                                    latitude: record.lat,
                                                                    longitude: record.lng,
                                                                })
                                                            }
                                                        />
                                                    </Tooltip>
                                                    {/*<Tooltip
                                                        title={geti18nText(
                                                            'dashboard.widget.watermeter.table.alarm.tooltip'
                                                        )}
                                                    >
                                                        <BellOutlined
                                                            style={{ fontSize: '18px', marginRight: '10px' }}
                                                            onClick={() => editAlarm(record.id, { weekly: '3.3' })}
                                                        />
                                                        </Tooltip>*/}
                                                </React.Fragment>
                                            )}
                                            {record.lat != undefined && (
                                                <Tooltip
                                                    title={geti18nText(
                                                        'dashboard.widget.watermeter.table.showonmap.tooltip'
                                                    )}
                                                >
                                                    {filterDevice === record.id ? (
                                                        <ZoomInOutlined
                                                            style={{ fontSize: '18px', color: 'red' }}
                                                            onClick={() => showOnMapFilter(record)}
                                                        />
                                                    ) : (
                                                        <ZoomInOutlined
                                                            style={{ fontSize: '18px' }}
                                                            onClick={() => showOnMapFilter(record)}
                                                        />
                                                    )}
                                                </Tooltip>
                                            )}
                                        </div>
                                    );
                                }
                            },
                        },
                    ]}
                />
            </Modal>
        </React.Fragment>
    );
};

export default WidgetWatermeterList;
