import React, { useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import {
    CheckOutlined,
    CloseOutlined,
    FormOutlined,
    EditOutlined,
    PushpinOutlined,
    ZoomInOutlined,
    BellOutlined,
    PoweroffOutlined,
} from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import { Tooltip, Form, Input, Modal, InputNumber, notification, Popconfirm } from 'antd';
import { useDispatch } from 'react-redux';
import { refreshMap } from '../../slices/dashboardSlice';
import { gatewayDetailsOpen } from '../../slices/gatewayDetailsSlice';
import {
    ENUMS,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    RESPONSE,
} from '@nybble/nyreact';

const GatewayList = () => {
    const [visibleContactModal, setVisibleContactModal] = useState(false);
    const [visibleLocationModal, setVisibleLocationModal] = useState(false);
    const [visibleInActivityTimerModal, setVisibleInActivityTimerModal] = useState(false);
    const [gatewayId, setGatewayId] = useState<Number | undefined>(undefined);
    const [gatewayListRefresh, setGatewayListRefresh] = useState(0);

    const dispatch = useDispatch();

    const [formContact] = Form.useForm();
    const [formLocation] = Form.useForm();
    const [formInActivityTimer] = Form.useForm();

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    function saveContact(values: any) {
        NyRequestResolver.requestPost(CONSTANTS_REQ.GATEWAY.CONTACT_DATA_FOR_GATEWAY, undefined, {
            refId: gatewayId,
            address: values.address,
            phone: values.phone,
            email: values.email,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('app.default.save.ok.desc'),
                    duration: 3,
                });
                setVisibleContactModal(false);
                setGatewayListRefresh(gatewayListRefresh + 1);
            } else {
                notification.error({ message: geti18nText('app.default.save.nok'), description: '' });
            }
        });
    }

    function updateGatewayState(gatewayId: any, active: any) {
        NyRequestResolver.requestPut(CONSTANTS_REQ.GATEWAY.UPDATE_ACTIVE_GATEWAY + '/' + gatewayId, undefined, {
            id: gatewayId,
            active: active,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('app.default.save.ok.desc'),
                    duration: 3,
                });
                dispatch(refreshMap());
                setGatewayListRefresh(gatewayListRefresh + 1);
            } else {
                notification.error({ message: geti18nText('app.default.save.nok'), description: '' });
            }
        });
    }

    function saveLocation(values: any) {
        NyRequestResolver.requestPut(CONSTANTS_REQ.GATEWAY.UPDATECOORDINATES + '/' + gatewayId, undefined, {
            id: gatewayId,
            lat: values.latitude,
            lng: values.longitude,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('app.default.save.ok.desc'),
                    duration: 3,
                });
                setVisibleLocationModal(false);
                dispatch(refreshMap());
                setGatewayListRefresh(gatewayListRefresh + 1);
            } else {
                notification.error({ message: geti18nText('app.default.save.nok'), description: '' });
            }
        });
    }

    function saveInActivity(values: any) {
        NyRequestResolver.requestGet(CONSTANTS_REQ.GATEWAY.EDIT + '/' + gatewayId).then((result: any) => {
            if (result && result.status == RESPONSE.OK) {
                result.data.inactivityTime = values.inactivity_time;
                NyRequestResolver.requestPut(CONSTANTS_REQ.GATEWAY.EDIT + '/' + gatewayId, undefined, result.data).then(
                    (result: any) => {
                        if (result && result.status === RESPONSE.CREATED) {
                            notification.success({
                                message: geti18nText('app.default.save.ok'),
                                description: geti18nText('app.default.save.ok.desc'),
                                duration: 3,
                            });
                            hideNotActivityTimerModal();
                            setGatewayListRefresh(gatewayListRefresh + 1);
                        } else {
                            notification.error({ message: geti18nText('app.default.save.nok'), description: '' });
                        }
                    }
                );
            }
        });
    }

    function showInActivityData(id: number) {
        NyRequestResolver.requestGet(CONSTANTS_REQ.GATEWAY.EDIT + '/' + id).then((result: any) => {
            if (result && result.status == RESPONSE.OK && result.data.inactivityTime != null) {
                formInActivityTimer.setFieldsValue({ inactivity_time: result.data.inactivityTime });
            }
        });
    }

    const hideContactModal = () => {
        setVisibleContactModal(false);
        formContact.resetFields();
    };

    const hideNotActivityTimerModal = () => {
        setVisibleInActivityTimerModal(false);
        formInActivityTimer.resetFields();
    };

    const editContact = (id: number, address: string, email: string, phone: string) => {
        setGatewayId(id);

        if (address != null) {
            formContact.setFieldsValue({ address: address });
        }

        if (email != null) {
            formContact.setFieldsValue({ email: email });
        }

        if (phone != null) {
            formContact.setFieldsValue({ phone: phone });
        }

        setVisibleContactModal(true);
    };

    const hideLocationModal = () => {
        setVisibleLocationModal(false);
        formLocation.resetFields();
    };

    const editLocation = (id: number, lat: any, lng: any) => {
        setGatewayId(id);

        if (lat != null) {
            formLocation.setFieldsValue({ latitude: lat });
        }

        if (lat != null) {
            formLocation.setFieldsValue({ longitude: lng });
        }

        setVisibleLocationModal(true);
    };

    const editNotActivityTimer = (id: number, inactivityTime: number) => {
        setGatewayId(id);
        if (inactivityTime != null) {
            formInActivityTimer.setFieldsValue({ inactivity_time: inactivityTime });
        }
        showInActivityData(id);
        setVisibleInActivityTimerModal(true);
    };

    const onRowSelect = (record: { [index: string]: any }) => {
        dispatch(gatewayDetailsOpen({ record: record, visible: true }));
    };

    return (
        <React.Fragment>
            <Title level={4}>{geti18nText('gateway.table.title')}</Title>
            <NyDataTable
                url={CONSTANTS_REQ.GATEWAY.LIST}
                scroll={{ y: '37.5vh' }}
                onRowSelect={onRowSelect}
                hideButtons={true}
                fetchWhenChange={gatewayListRefresh}
                setDefaultFilterValue={setDefaultFilterValue}
                columns={[
                    {
                        title: geti18nText('gateway.table.column.mac'),
                        dataIndex: 'mac',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                        render: (text: any, record: { active: any }) => {
                            return <b style={{ color: '#fc6603' }}>{text}</b>;
                        },
                    },
                    {
                        title: geti18nText('gateway.table.column.name'),
                        dataIndex: 'name',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('gateway.table.column.address'),
                        dataIndex: ['contact', 'address'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('gateway.table.column.active'),
                        dataIndex: 'active',
                        width: '10%',
                        render: (text: any, record: { id: any; active: any }) => {
                            if (record.active) {
                                return (
                                    <Tooltip title={geti18nText('app.default.button.deactivate')}>
                                        <Popconfirm
                                            title={geti18nText('app.default.button.deactivate') + '?'}
                                            onConfirm={() => {
                                                updateGatewayState(record.id, false);
                                            }}
                                        >
                                            <PoweroffOutlined style={{ fontSize: '18px', color: 'green' }} />
                                        </Popconfirm>
                                    </Tooltip>
                                );
                            } else {
                                return (
                                    <Tooltip title={geti18nText('app.default.button.activate')}>
                                        <Popconfirm
                                            title={geti18nText('app.default.button.activate') + '?'}
                                            onConfirm={() => {
                                                updateGatewayState(record.id, true);
                                            }}
                                        >
                                            <PoweroffOutlined style={{ fontSize: '18px', color: 'red' }} />
                                        </Popconfirm>
                                    </Tooltip>
                                );
                            }
                        },
                        ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                        defaultFilteredValue: setDefaultFilterValue(),
                    },
                    {
                        dataIndex: 'operation',
                        width: '15%',
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <React.Fragment>
                                    <Tooltip title={geti18nText('dashboard.widget.watermeter.table.contact.tooltip')}>
                                        <EditOutlined
                                            style={{ fontSize: '18px', marginRight: '10px' }}
                                            onClick={() =>
                                                editContact(
                                                    record.id,
                                                    record.contact != null && record.contact.address != null
                                                        ? record.contact.address
                                                        : null,
                                                    record.contact != null && record.contact.email != null
                                                        ? record.contact.email
                                                        : null,
                                                    record.contact != null && record.contact.phone != null
                                                        ? record.contact.phone
                                                        : null
                                                )
                                            }
                                        />
                                    </Tooltip>
                                    <Tooltip title={geti18nText('dashboard.widget.watermeter.table.location.tooltip')}>
                                        <PushpinOutlined
                                            style={{ fontSize: '18px', marginRight: '10px' }}
                                            onClick={() => {
                                                editLocation(
                                                    record.id,
                                                    record.lat != null ? record.lat : null,
                                                    record.lng != null ? record.lng : null
                                                );
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip
                                        title={geti18nText('dashboard.widget.watermeter.table.in.activity.tooltip')}
                                    >
                                        <BellOutlined
                                            style={{ fontSize: '18px' }}
                                            onClick={() => {
                                                editNotActivityTimer(record.id, record.inactiveTime);
                                            }}
                                        />
                                    </Tooltip>
                                </React.Fragment>
                            );
                        },
                    },
                ]}
            />

            <Modal
                visible={visibleContactModal}
                title={geti18nText('dashboard.widget.watermeter.edit.contact.title')}
                onCancel={hideContactModal}
                okText={geti18nText('app.default.button.save')}
                width={600}
                onOk={() => {
                    formContact.validateFields().then((values) => {
                        saveContact(values);
                    });
                }}
            >
                <Form form={formContact} layout="horizontal" labelCol={{ span: 4 }}>
                    <Form.Item name="address" label={geti18nText('dashboard.widget.watermeter.edit.contact.address')}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label={geti18nText('dashboard.widget.watermeter.edit.contact.email')}
                        rules={[
                            {
                                type: 'email',
                                message: geti18nText('dashboard.widget.watermeter.edit.contact.email.validation'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="phone" label={geti18nText('dashboard.widget.watermeter.edit.contact.phone')}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                visible={visibleLocationModal}
                title={geti18nText('dashboard.widget.watermeter.edit.location.title')}
                onCancel={hideLocationModal}
                okText={geti18nText('app.default.button.save')}
                width={600}
                onOk={() => {
                    formLocation.validateFields().then((values) => {
                        saveLocation(values);
                    });
                }}
            >
                <Form form={formLocation} layout="horizontal" labelCol={{ span: 10 }}>
                    <Form.Item
                        name="latitude"
                        label={geti18nText('dashboard.widget.watermeter.edit.location.latitude')}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                            {
                                type: 'float',
                                message: geti18nText('dashboard.widget.watermeter.edit.location.validation'),
                            },
                        ]}
                    >
                        <InputNumber decimalSeparator="." min={-90} max={90} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="longitude"
                        label={geti18nText('dashboard.widget.watermeter.edit.location.longitude')}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                            {
                                type: 'float',
                                message: geti18nText('dashboard.widget.watermeter.edit.location.validation'),
                            },
                        ]}
                    >
                        <InputNumber decimalSeparator="." min={-180} max={180} style={{ width: '100%' }} />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                visible={visibleInActivityTimerModal}
                title={geti18nText('dashboard.widget.watermeter.table.in.activity.tooltip')}
                onCancel={hideNotActivityTimerModal}
                okText={geti18nText('app.default.button.save')}
                width={600}
                onOk={() => {
                    formInActivityTimer.validateFields().then((values) => {
                        saveInActivity(values);
                    });
                }}
            >
                <Form form={formInActivityTimer} layout="horizontal" labelCol={{ span: 14 }}>
                    <Form.Item
                        name="inactivity_time"
                        label={geti18nText('dashboard.widget.watermeter.edit.in.activity.time')}
                    >
                        <InputNumber />
                    </Form.Item>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default GatewayList;
