import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { Form, Modal, InputNumber, Select, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../rootReducer';
import { deviceAlarmThrasholdInit } from '../../slices/deviceAlarmThrasholdSlice';
import { geti18nText, NyRequestResolver, NySession, NySpinner, RESPONSE } from '@nybble/nyreact';

const DeviceEditAlarmThrashold: React.FC = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [id, setId] = useState<any | null>(null);
    const [data, setData] = useState<any | null>(null);
    const [loading, setLoading] = useState(true);
    const { deviceId, endCustomerId, customerCode, visible } = useSelector(
        (state: RootState) => state.deviceAlarmThrashold
    );
    const [languageList, setlanguageList] = useState<any>(undefined);

    function save(values: any) {
        if (id != '') {
            NyRequestResolver.requestPut(
                CONSTANTS_REQ.CUSTOMER_CONSUMPTION.CONSUMPTION_THRESHOLD + '/' + id,
                undefined,
                {
                    id: id,
                    device: { id: deviceId },
                    endCustomer: { id: endCustomerId },
                    daily: values.daily,
                    weekly: values.weekly,
                    monthly: values.monthly,
                    language: values.language,
                }
            ).then((result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        description: geti18nText('app.default.save.ok.desc'),
                        duration: 3,
                    });
                    hideModal();
                } else {
                    notification.error({ message: geti18nText('app.default.save.nok'), description: '' });
                }
            });
        } else {
            NyRequestResolver.requestPost(CONSTANTS_REQ.CUSTOMER_CONSUMPTION.CONSUMPTION_THRESHOLD, undefined, {
                device: { id: deviceId },
                customerCode: customerCode,
                daily: values.daily,
                weekly: values.weekly,
                monthly: values.monthly,
                language: values.language,
            }).then((result: any) => {
                if (result && result.status === RESPONSE.CREATED) {
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        description: geti18nText('app.default.save.ok.desc'),
                        duration: 3,
                    });
                    hideModal();
                } else {
                    notification.error({ message: geti18nText('app.default.save.nok'), description: '' });
                }
            });
        }
    }

    useEffect(() => {
        if(customerCode != undefined){
            NyRequestResolver.requestGet(
                CONSTANTS_REQ.CUSTOMER_CONSUMPTION.CONSUMPTION_THRESHOLD + '/' + customerCode
            ).then((response: any) => {
                if (response.status === RESPONSE.OK) {
                    form.setFieldsValue(response.data);
                    setData(response.data);
                    setId(response.data.id);
                } else {
                    setId('');
                }
                setLoading(false);
            });
        }

        let languages = NySession.getAppValue('I18N').language.available;
        let languageList = [];
        for (var lang in languages) {
            let ret = { value: undefined, label: undefined };
            ret.value = languages[lang];
            ret.label = languages[lang].toUpperCase();
            languageList.push(ret);
        }
        setlanguageList(languageList);
    }, [visible]);

    const hideModal = () => {
        dispatch(deviceAlarmThrasholdInit());
    };

    return (
        <React.Fragment>
            <Modal
                visible={visible}
                title={geti18nText('dashboard.widget.watermeter.edit.alarm.title')}
                onCancel={() => hideModal()}
                okText={geti18nText('app.default.button.save')}
                destroyOnClose={true}
                width={600}
                onOk={() => {
                    form.validateFields().then((values) => {
                        save(values);
                    });
                }}
            >
                {loading && <NySpinner />}
                {!loading && (
                    <Form form={form} layout="horizontal" labelCol={{ span: 6 }}>
                        <Form.Item
                            name="daily"
                            label={geti18nText('dashboard.widget.watermeter.edit.alarm.daily')}
                            initialValue={data && data.daily ? data.daily : ''}
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('dashboard.widget.watermeter.edit.alarm.validation'),
                                },
                            ]}
                        >
                            <InputNumber step={0.1} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                            name="weekly"
                            label={geti18nText('dashboard.widget.watermeter.edit.alarm.weekly')}
                            initialValue={data && data.weekly ? data.weekly : ''}
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('dashboard.widget.watermeter.edit.alarm.validation'),
                                },
                            ]}
                        >
                            <InputNumber step={0.1} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                            name="monthly"
                            label={geti18nText('dashboard.widget.watermeter.edit.alarm.monthly')}
                            initialValue={data && data.monthly ? data.monthly : ''}
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('dashboard.widget.watermeter.edit.alarm.validation'),
                                },
                            ]}
                        >
                            <InputNumber step={0.1} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                            label={geti18nText('user.edit.language')}
                            name="language"
                            initialValue={data && data.language ? data.language : ''}
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 6 }}
                            rules={[
                                {
                                    required: true,
                                    message: geti18nText('dashboard.widget.watermeter.edit.alarm.validation'),
                                },
                            ]}
                        >
                            <Select options={languageList}></Select>
                        </Form.Item>
                    </Form>
                )}
            </Modal>
        </React.Fragment>
    );
};

export default DeviceEditAlarmThrashold;
