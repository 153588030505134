import React from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import UserEdit from './UserEdit';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import { formCategoryIcons } from '.';

const UserTable = () => {
    const { customer,categoryType } = useSelector((state: RootState) => state.selectedCustomer);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'customerId', condition: 'equals', value: customer },
        ];
    };

    return (
        <NyDataTable
            headerTitle={geti18nText('user.table.header')}
            url={CONSTANTS_REQ.USER.LIST}
            addNewButtonText={geti18nText('user.table.add')}
            showRecordModal={true}
            scroll={{ x: 800 }}
            exportCSV={true}
            modalComponent={UserEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            hideButtons={!NySession.hasAnyRole(['ROLE_AUTH_ADMIN'])}
            columns={[
                {
                    title: geti18nText('user.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    render: (text: any, record: { id: any, categoryType:any }) => {
                        
                        if (record.id) {
                            let catType=record.categoryType ? record.categoryType.split(',') : undefined;
                            if(catType != undefined && catType.length >0) {
                                return <div> 
                                        {record.id}                           
                                        
                                        {formCategoryIcons(catType)}
                                        
                                    </div>
                            }

                            else return <> {record.id}</>
                        } 
                    },
                },
                {
                    title: geti18nText('user.table.column.firstName'),
                    dataIndex: 'first_name',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('user.table.column.lastName'),
                    dataIndex: 'last_name',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('user.table.column.username'),
                    dataIndex: 'username',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('user.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default UserTable;
