import React, { useEffect, useState } from 'react';
import { Typography, Button, Modal } from 'antd';
import {
    ResponsiveContainer,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Tooltip,
    Bar,
    Label,
    LineChart,
    Line,
} from 'recharts';
import { FullscreenOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { geti18nText, NySpinner } from '@nybble/nyreact';
import ChartWeatherStateDataConsumption from '../ChartWeatherStateDataConsumption';

const { Title } = Typography;
interface IWeatherChartSettings {
    title: string;
    yLabel: string;
    color: string;
}

interface IWeatherChart {
    loading: boolean | null;
    data: any;
    title: string | null;
    deviceId?: number;
    period?: string;
    showYLabel?: boolean;
    settings?: IWeatherChartSettings;
    params?: string[];
    domain?: any;
}

const WeatherChart: React.FC<IWeatherChart> = ({
    loading,
    data,
    title,
    deviceId,
    period,
    showYLabel = true,
    settings,
    params = [],
    domain,
}) => {
    // const watterMeterSettings: any = {
    //     title: geti18nText('deviceDetails.graph.title'),
    //     'y-title': geti18nText('deviceDetails.graph.y.value'),
    //     color: '#1890ff',
    // };

    const [visible, setVisible] = useState(false);
    const [visibleDataVolumes, setVisibleDataVolumes] = useState(false);

    return (
        <div className="box" style={{ height: '95%' }}>
            <Title style={{ fontSize: '1.4em', width: '75%' }}>{settings?.title + ' - ' + title}</Title>
            {deviceId && (
                <Button
                    style={{ position: 'absolute', right: '50px', top: '20px' }}
                    shape="circle"
                    onClick={() => setVisibleDataVolumes(true)}
                    icon={<UnorderedListOutlined />}
                />
            )}
            <Button
                style={{ position: 'absolute', right: '20px', top: '20px' }}
                shape="circle"
                onClick={() => setVisible(true)}
                icon={<FullscreenOutlined />}
            />
            <ResponsiveContainer width="100%" height={250}>
                {loading ? (
                    <NySpinner />
                ) : (
                    <LineChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="converted" />
                        <YAxis domain={['auto', 'auto']}>
                            {showYLabel == true && (
                                <Label
                                    value={settings?.yLabel}
                                    position="insideLeft"
                                    angle={-90}
                                    style={{ textAnchor: 'middle' }}
                                />
                            )}
                        </YAxis>
                        <Tooltip />
                        <Legend />

                        <Line
                            dataKey="barValue"
                            type="monotone"
                            name={settings?.yLabel}
                            fill={settings?.color}
                            stroke={settings?.color}
                            strokeWidth={2}
                        />
                    </LineChart>
                )}
            </ResponsiveContainer>

            <Modal
                title={settings?.title + ' - ' + title}
                visible={visible}
                zIndex={1010}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={'80%'}
                centered={true}
            >
                <ResponsiveContainer width="100%" height={600}>
                    {loading ? (
                        <NySpinner />
                    ) : (
                        <LineChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis dataKey="converted" />
                            <YAxis domain={['auto', 'auto']}>
                                {showYLabel == true && (
                                    <Label
                                        value={settings?.yLabel}
                                        position="insideLeft"
                                        angle={-90}
                                        style={{ textAnchor: 'middle' }}
                                    />
                                )}
                            </YAxis>
                            <Tooltip />
                            <Legend />

                            <Line
                                dataKey="barValue"
                                type="monotone"
                                name={settings?.yLabel}
                                fill={settings?.color}
                                stroke={settings?.color}
                                strokeWidth={3}
                            />
                        </LineChart>
                    )}
                </ResponsiveContainer>
            </Modal>
            {deviceId && (
                <Modal
                    visible={visibleDataVolumes}
                    zIndex={1010}
                    onCancel={() => setVisibleDataVolumes(false)}
                    width={'80%'}
                    centered={true}
                    footer={null}
                >
                    <ChartWeatherStateDataConsumption
                        title={title}
                        deviceId={deviceId}
                        period={{ period: period }}
                        params={params}
                    />
                </Modal>
            )}
        </div>
    );
};

export default WeatherChart;
