import React from 'react';
import { Typography } from 'antd';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { geti18nText, NyDataTable, NyUtils } from '@nybble/nyreact';

const { Title } = Typography;

interface IEndCustomerDevices {
    endCustomerId: number;
}

const EndCustomerDevices: React.FC<IEndCustomerDevices> = ({ endCustomerId }) => {
    const setDefaultFilterValue = () => {
        return [{ field: 'endCustomerId', condition: 'equals', value: endCustomerId }];
    };

    return (
        <div>
            <NyDataTable
                headerTitle={geti18nText('end.customer.devices.title')}
                url={CONSTANTS_REQ.END_CUSTOMER.DEVICES}
                hideButtons={true}
                exportCSV={true}
                scroll={{ x: 800 }}
                setDefaultFilterValue={setDefaultFilterValue}
                columns={[
                    {
                        title: geti18nText('end.customer.devices.table.name'),
                        dataIndex: ['device', 'name'],
                        sorter: (a: string, b: string) => {},
                    },
                    {
                        title: geti18nText('end.customer.devices.table.serial'),
                        dataIndex: ['device', 'serialNumber'],
                        sorter: (a: string, b: string) => {},
                    },
                    {
                        title: geti18nText('end.customer.devices.table.from'),
                        dataIndex: 'tsFrom',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.tsFrom) {
                                const ts: Date = new Date(record.tsFrom);
                                return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                            }
                        },
                    },
                    {
                        title: geti18nText('end.customer.devices.table.to'),
                        dataIndex: 'tsTo',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.tsTo) {
                                const ts: Date = new Date(record.tsTo);
                                return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                            }
                        },
                    },
                    {
                        title: geti18nText('end.customer.devices.table.initial.volume'),
                        dataIndex: 'initialVolume',
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.initialVolume ? NyUtils.formatNumber(record.initialVolume, 3) : ''}
                                    &nbsp;m3
                                </div>
                            );
                        },
                    },
                ]}
            />
        </div>
    );
};

export default EndCustomerDevices;
