import { NyRequestResolver, NySession, NyUtils, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Badge, Layout, Menu } from 'antd';
import React, { useRef } from 'react';
import { useEffect, useState } from 'react';
import './index.scss';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { setAlarmCount } from '../../../slices/alarmDescriptionSlice';
import { Link } from 'react-router-dom';
import { setQueryId, setSelectedDeviceCategorie } from '../../../slices/dashboardSlice';
//import logo from '../../assets/img/logo.png';
//import logoMini from '../../assets/img/logo_mali.png';
const LayoutMenu = ({
    menuHistory,
    menuItems,
    menuLocation,
    menuTheme = 'light',
    menuWidth = 200,
    siderClassName,
    menuClassName,
    menuLogo,
    menuLogo1,
    noRoles = false,
}: any) => {
    const [selectedKeys, setSelectedKeys] = useState<any>([]);
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [breakpoint, setBreakpoint] = useState<boolean>(false);
    const [badges, setBadges] = useState<any>([]);
    const { Sider } = Layout;
    const { SubMenu } = Menu;
    const { deviceAlarmListRefresh } = useSelector((state: RootState) => state.alarmDescriptionSlice);
    const { filterDevice, alarmListRefresh, refreshMenu } = useSelector((state: RootState) => state.dashboard);
    const timerRef = useRef<any>(null);
    const [count, setCount] = useState<number>(0);
    const dispatch = useDispatch();
    let root = document.documentElement;
    const menuRef = useRef<any>();

    useEffect(() => {
        setSelectedKeys(findKey(menuItems, undefined, menuLocation.pathname));
        if (collapsed) {
            root.style.setProperty('--sticky-width', '80px');
        } else {
            root.style.setProperty('--sticky-width', '250px');
        }
    }, []);

    useEffect(() => {
        if (timerRef.current != null) {
            clearTimeout(timerRef.current);
        }
        fetchAlarmCount();
        timerRef.current = setTimeout(() => {
            setCount(count + 1);
        }, 60 * 1000);
    }, [deviceAlarmListRefresh, alarmListRefresh, count]);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                if (!collapsed) {
                    setCollapsed(true);
                    root.style.setProperty('--sticky-width', '80px');
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    const fetchAlarmCount = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DASHBOARD.ALARM_COUNT).then((result: any) => {
            if (result && result.status == RESPONSE.OK) {
                setBadges(result.data != undefined ? result.data : []);
                dispatch(setAlarmCount(result.data != undefined ? result.data : []));
            }
        });
    };

    function generateMenu(menus: any) {
        let retValue = [];
        let menu: any;
        for (menu of menus) {
            //console.log('menu ', menu);
            if (NySession.hasAnyRole(menu.role) || noRoles) {
                if (menu.submenu) {
                    if (collapsed) {
                        retValue.push(
                            <SubMenu
                                key={menu.key}
                                onTitleClick={menuSelected}
                                icon={
                                    menu.root === true &&
                                    menu.notificationType != undefined &&
                                    badges[menu.notificationType] != undefined ? (
                                        <Badge
                                            dot={true}
                                            offset={[-20, 10]}
                                            count={
                                                badges[menu.notificationType] != undefined
                                                    ? badges[menu.notificationType]
                                                    : menu.badge
                                            }
                                            style={{
                                                borderColor: 'red',
                                                backgroundColor: 'red',
                                            }}
                                        >
                                            <Link className="menu-links" to={menu.path}>
                                                {menu.icon}
                                            </Link>
                                        </Badge>
                                    ) : (
                                        <Link className="menu-links" to={menu.path}>
                                            {menu.icon}
                                        </Link>
                                    )
                                }
                                title={
                                    menu.path != undefined ? (
                                        <Link className="menu-links" to={menu.path}>
                                            {menu.i18n ? geti18nText(menu.i18n) : menu.name}
                                            {((menu.notificationType != undefined &&
                                                badges[menu.notificationType] != undefined) ||
                                                menu.badge) && (
                                                <Badge
                                                    count={
                                                        badges[menu.notificationType] != undefined
                                                            ? badges[menu.notificationType]
                                                            : menu.badge
                                                    }
                                                    overflowCount={99}
                                                    offset={[10, -2]}
                                                    style={{
                                                        fontSize: '10px',

                                                        height: '18px',
                                                        lineHeight: '18px',
                                                        borderColor: 'red',
                                                        fontWeight: 'bold',
                                                        borderRadius: '18px',
                                                        backgroundColor: 'red',
                                                    }}
                                                ></Badge>
                                            )}
                                        </Link>
                                    ) : menu.i18n ? (
                                        geti18nText(menu.i18n)
                                    ) : (
                                        menu.name
                                    )
                                }
                            >
                                <Menu.ItemGroup
                                    className="menu-items-generated-color"
                                    title={
                                        <div className="collapsed-menu-title">
                                            {menu.i18n ? geti18nText(menu.i18n) : menu.name}
                                        </div>
                                    }
                                >
                                    {generateMenu(menu.submenu)}
                                </Menu.ItemGroup>
                            </SubMenu>
                        );
                    } else {
                        retValue.push(
                            <SubMenu
                                key={menu.key}
                                onTitleClick={menuSelected}
                                icon={menu.icon}
                                title={
                                    <div
                                        className="menu-element"
                                        style={{ width: menu.width ? `${menu.width}px` : '100px' }}
                                    >
                                        <span>
                                            <span className="menu-text" style={{ textOverflow: 'ellipsis' }}>
                                                {menu.i18n ? geti18nText(menu.i18n) : menu.name}
                                            </span>
                                        </span>
                                        {((menu.notificationType != undefined &&
                                            badges[menu.notificationType] != undefined) ||
                                            menu.badge) && (
                                            <Badge
                                                count={
                                                    badges[menu.notificationType] != undefined
                                                        ? badges[menu.notificationType]
                                                        : menu.badge
                                                }
                                                overflowCount={99}
                                                offset={[10, -2]}
                                                style={{
                                                    fontSize: '10px',

                                                    height: '18px',
                                                    lineHeight: '18px',
                                                    borderColor: 'red',
                                                    fontWeight: 'bold',
                                                    borderRadius: '18px',
                                                    backgroundColor: 'red',
                                                }}
                                            ></Badge>
                                        )}
                                    </div>
                                }
                            >
                                {generateMenu(menu.submenu)}
                            </SubMenu>
                        );
                    }
                } else {
                    retValue.push(
                        <Menu.Item icon={menu.icon} key={menu.key}>
                            <Link className="menu-links" to={menu.path}>
                                {collapsed ? (
                                    <>
                                        {menu.i18n ? geti18nText(menu.i18n) : menu.name}
                                        {((menu.notificationType != undefined &&
                                            badges[menu.notificationType] != undefined) ||
                                            menu.badge) && (
                                            <Badge
                                                count={
                                                    badges[menu.notificationType] != undefined
                                                        ? badges[menu.notificationType]
                                                        : menu.badge
                                                }
                                                overflowCount={99}
                                                offset={[10, -2]}
                                                style={{
                                                    fontSize: '10px',

                                                    height: '18px',
                                                    lineHeight: '18px',
                                                    borderColor: 'red',
                                                    fontWeight: 'bold',
                                                    borderRadius: '18px',
                                                    backgroundColor: 'red',
                                                }}
                                            ></Badge>
                                        )}
                                    </>
                                ) : (
                                    <div
                                        className="menu-element"
                                        style={{ width: menu.width ? `${menu.width}px` : '100px' }}
                                    >
                                        <span>
                                            <span className="menu-text" style={{ textOverflow: 'ellipsis' }}>
                                                {menu.i18n ? geti18nText(menu.i18n) : menu.name}
                                            </span>
                                        </span>

                                        {((menu.notificationType != undefined &&
                                            badges[menu.notificationType] != undefined) ||
                                            menu.badge) && (
                                            <Badge
                                                count={
                                                    badges[menu.notificationType] != undefined
                                                        ? badges[menu.notificationType]
                                                        : menu.badge
                                                }
                                                overflowCount={99}
                                                offset={[10, -2]}
                                                style={{
                                                    fontSize: '10px',

                                                    height: '18px',
                                                    lineHeight: '18px',
                                                    borderColor: 'red',
                                                    fontWeight: 'bold',
                                                    borderRadius: '18px',
                                                    backgroundColor: 'red',
                                                }}
                                            ></Badge>
                                        )}
                                    </div>
                                )}
                            </Link>
                        </Menu.Item>
                    );
                }
            }
        }
        return retValue;
    }

    const checkOverflow = () => {
        let elements = Array.from(document.getElementsByClassName('menu-element'));
        elements.forEach((element: any) => {
            if (0 > element.clientWidth - element.scrollWidth) {
                element.classList.add('marquee');
            }
        });
    };

    const findQueryIdInMenu = (menuItems: any, keyToFind: any) => {
        const searchMenu = (items: any) => {
            for (const item of items) {
                if (item.key === keyToFind) {
                    return { id: item.queryId, name: item.name, key: item.key, showHeaderTitle: item.showHeaderTitle };
                }

                if (item.submenu) {
                    const result: any = searchMenu(item.submenu);
                    if (result !== undefined) {
                        return result;
                    }
                }
            }
            return undefined;
        };

        return searchMenu(menuItems);
    };

    const menuSelected = (selected: any) => {
        let sel = findKey(menuItems, selected.key);
        const queryItem = findQueryIdInMenu(menuItems, selected.key);
        dispatch(setQueryId(queryItem?.id));
        queryItem.showHeaderTitle = queryItem.showHeaderTitle != null ? queryItem.showHeaderTitle : true;

        dispatch(setSelectedDeviceCategorie(queryItem));
        setSelectedKeys(sel);
    };

    function findKey(menus: any, key: any, path = undefined) {
        let menu;
        for (menu of menus) {
            if (menu.submenu) {
                let submenuMatch: any = findKey(menu.submenu, key, path);
                if (submenuMatch) {
                    return [menu.key, ...submenuMatch];
                } else if (key && menu.key === key) {
                    if (menu.path) menuHistory.push(menu.path);
                    return [menu.key];
                }
            } else if (key && menu.key === key) {
                menuHistory.push(menu.path);
                return [menu.key];
            } else if (path && menu.path === path) {
                return [menu.key];
            }
        }
        return undefined;
    }
    const MenuLogo = () => {
        const settings = NyUtils.loadFromSession('IoTSettings');

        return (
            <div className="menu-search" style={{ textAlign: 'center' }}>
                {settings != null && settings.appLogo != null ? (
                    <img
                        src={CONSTANTS_REQ.DISK_FILE.DOWNLOAD + `?fileName=${settings.appLogo}`}
                        alt="Chameleon"
                        style={{ width: '200px', maxHeight: '70px', objectFit: 'cover', marginTop: '2px' }}
                    />
                ) : (
                    <img src={menuLogo} alt="Chameleon" style={{ width: '200px' }} />
                )}
            </div>
        );
    };

    const MenuLogoMini = () => {
        return (
            <div className="menu-search-min" style={{ textAlign: 'center' }}>
                <img src={menuLogo1} alt="Chameleon" style={{ width: '30px', marginTop: '2px' }} />
            </div>
        );
    };

    return (
        <Sider
            // breakpoint="lg"
            // collapsedWidth="0"
            collapsed={collapsed}
            collapsible
            width={menuWidth}
            className={siderClassName}
            onCollapse={(collapsed) => {
                if (collapsed) {
                    root.style.setProperty('--sticky-width', '80px');
                } else {
                    root.style.setProperty('--sticky-width', '250px');
                }
                setCollapsed(collapsed);
            }}
            defaultCollapsed={false}
            onBreakpoint={(broken) => {
                setBreakpoint(broken);
            }}
            style={{
                height: '100vh',
                position: 'fixed',
                left: 0,
            }}
            ref={menuRef}
        >
            <Menu
                theme={menuTheme}
                mode="inline"
                className={menuClassName}
                onClick={menuSelected}
                selectedKeys={selectedKeys}
                // openKeys={selectedKeys}
                subMenuCloseDelay={0.1}
                style={{ height: collapsed ? 'calc(100% - 20px)' : 'calc(100% - 20px)' }}
            >
                {collapsed == true ? MenuLogoMini() : MenuLogo()}
                {generateMenu(menuItems)}
            </Menu>
        </Sider>
    );
};

export default LayoutMenu;
