import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { geti18nText, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import { RootState } from '../../../rootReducer';
import { deviceEnergyDetailsInit } from '../../../slices/deviceEnergyDetailsSlice';
import DeviceEnergyStatisticDetail from './DeviceEnergyStatisticDetail';
import PayloadEnergyHistoryTable from './PayloadEnergyHistoryTable';
import AlarmsTable from '../../device/AlarmsTable';
import { DeviceCategoryType } from '../../../utils/Constants';

const { TabPane } = Tabs;

const DeviceEnergyDetail = () => {
    const dispatch = useDispatch();
    const { record, visible } = useSelector((state: RootState) => state.deviceEnergyDetails);
    const [modalTitle, setModalTitle] = useState(geti18nText('energyDetails.modal.title'));

    const hideModal = () => {
        dispatch(deviceEnergyDetailsInit());
    };

    return (
        <Modal
            zIndex={1009}
            title={modalTitle}
            destroyOnClose={true}
            visible={visible}
            onOk={hideModal}
            onCancel={hideModal}
            width="90%"
            footer={null}
        >
            <Tabs type="card">
                <TabPane tab={geti18nText('deviceDetails.tab.overview')} key="1">
                    <Row>
                        <Col span={24}>
                            <DeviceEnergyStatisticDetail />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('deviceDetails.tab.alarms')} key="2">
                    <Row>
                        <Col span={24}>
                            <AlarmsTable previewType="BY_DEVICE" type={DeviceCategoryType.ENERGYMETER} />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('deviceDetails.tab.payload')} key="3">
                    <Row>
                        <Col span={24}>
                            <PayloadEnergyHistoryTable />
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
        </Modal>
    );
};

export default DeviceEnergyDetail;
