import React, { useState } from 'react';
import { CONSTANTS_REQ, DeviceCategoryType } from '../../utils/Constants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import DeviceEdit from './edit';
import { GetEnumNameForValue, GetEnum } from '../../utils/Enums';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import ModalList from './ModalList';

const Device = (props: any) => {
    const [type, setType] = useState(props.type ? props.type : DeviceCategoryType.WATERMETER);
    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const deviceStatus = () => {
        const aTypes = GetEnum('DEVICE_STATUS');
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.DEVICE_STATUS.' + key);
                types.push(ret);
            }
        }
        return types;
    };

    const columns = [
        ...[
            {
                title: geti18nText('device.table.column.id'),
                dataIndex: 'id',
                width: '10%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('device.table.column.name'),
                dataIndex: 'name',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('device.table.column.deviceType'),
                dataIndex: ['deviceType', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('device.table.column.deviceGroup'),
                dataIndex: ['deviceGroup', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
        ],
        ...(props.type == DeviceCategoryType.WEATHER
            ? [
                  {
                      title: geti18nText('device.edit.category'),
                      dataIndex: ['category', 'name'],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('string'),
                  },
              ]
            : []),
        ...[
            {
                title: geti18nText('device.table.column.status'),
                dataIndex: 'status',
                sorter: (a: string, b: string) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    return (
                        <div>
                            <b>
                                {geti18nText(
                                    'app.enum.DEVICE_STATUS.' + GetEnumNameForValue('DEVICE_STATUS', record.status)
                                )}
                            </b>
                        </div>
                    );
                },
                ...getColumnSearchOption(deviceStatus()),
            },
            {
                title: geti18nText('device.table.column.active'),
                dataIndex: 'active',
                width: '10%',
                render: (text: any, record: { active: any }) => {
                    if (record.active) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
                },
                ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                defaultFilteredValue: setDefaultFilterValue(),
            },
        ],
    ];

    const columnsAdmin = [
        ...[
            {
                title: geti18nText('device.table.column.id'),
                dataIndex: 'id',
                width: '10%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('device.table.column.name'),
                dataIndex: 'name',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('device.table.column.deviceType'),
                dataIndex: ['deviceType', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('device.table.column.deviceGroup'),
                dataIndex: ['deviceGroup', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
        ],
        ...(props.type == DeviceCategoryType.WEATHER
            ? [
                  {
                      title: geti18nText('device.edit.category'),
                      dataIndex: ['category', 'name'],
                      sorter: (a: any, b: any) => {},
                      ...getColumnSearch('string'),
                  },
              ]
            : []),
        ...[
            {
                title: geti18nText('device.table.column.customer'),
                dataIndex: ['customer', 'name'],
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('device.table.column.status'),
                dataIndex: 'status',
                sorter: (a: string, b: string) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    return (
                        <div>
                            <b>
                                {geti18nText(
                                    'app.enum.DEVICE_STATUS.' + GetEnumNameForValue('DEVICE_STATUS', record.status)
                                )}
                            </b>
                        </div>
                    );
                },
                ...getColumnSearchOption(deviceStatus()),
            },
            {
                title: geti18nText('device.table.column.active'),
                dataIndex: 'active',
                width: '10%',
                render: (text: any, record: { active: any }) => {
                    if (record.active) {
                        return <CheckOutlined style={{ color: 'green' }} />;
                    } else {
                        return <CloseOutlined style={{ color: 'red' }} />;
                    }
                },
                ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                defaultFilteredValue: setDefaultFilterValue(),
            },
        ],
    ];

    const checkColumns = () => {
        if (NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'])) {
            return columnsAdmin;
        } else {
            return columns;
        }
    };

    return (
        <>
            <NyDataTable
                headerTitle={geti18nText('device.table.header')}
                url={CONSTANTS_REQ.DEVICE.LIST}
                showRecordModal={true}
                scroll={{ x: 800 }}
                exportCSV={true}
                addedData={{ type: type }}
                modalComponent={DeviceEdit}
                hideButtons={NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) ? false : true}
                setDefaultFilterValue={setDefaultFilterValue}
                columns={checkColumns()}
            />
            <ModalList />
        </>
    );
};

export default Device;
