import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Tabs, Form, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { geti18nText, NyRequestResolver, NySearchField, NyUtils, RESPONSE } from '@nybble/nyreact';
import { RootState } from '../../../rootReducer';
import DeviceWeatherStateStatisticDetail from './DeviceWeatherStateStatisticDetail';
import { deviceWeatherStateDetailsChartInit } from '../../../slices/deviceWeatherStateDetailsReducer';
import moment from 'moment';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import WeatherChart from './charts/WeatherChart';
import WeatherRainfallBarChart from './charts/WatherRainfallBarChart';
import WeatherPolarChart from './charts/WeatherPolarChart';
import { parametarKeyName } from '../../../utils/Utils';

type IDataResponse = {
    converted?: string;
    barValue?: number;
};

const DeviceWeatherChartDetail = (props: any) => {
    const { RangePicker } = DatePicker;
    const dispatch = useDispatch();
    const { chartRecord, chartVisible, chartParmKey, chartParmeter, category } = useSelector(
        (state: RootState) => state.deviceWeatherStateDetails
    );
    const [modal, setModal] = useState(props.modal != undefined ? props.modal : true);
    const [modalTitle, setModalTitle] = useState(geti18nText('weatherStateDetails.modal.title'));
    const [period, setPeriod] = useState<any>({ id: 'last-24h', text: geti18nText('report.time_range.last24h') });
    const [spanPeriod, setSpanPeriod] = useState<any>({ dateFrom: null, dateTo: null });
    const [tempData, setTempData] = useState<any>(null);

    const [loading, setLoading] = useState(false);
    const [selectedParameter, setSelectedParameter] = useState<any>(
        props.selectedParameter != undefined ? props.selectedParameter : undefined
    );
    const [selectedDevice, setSelectedDevice] = useState<any>(
        props.selectedDevice != undefined ? props.selectedDevice.id : undefined
    );
    const periods = [
        { id: 'last-24h', text: geti18nText('report.time_range.last24h') },
        { id: 'current-week', text: geti18nText('report.time_range.current_week') },
        { id: 'last-week', text: geti18nText('report.time_range.last_week') },
        { id: 'current-month', text: geti18nText('report.time_range.current_month') },
        { id: 'current-year', text: geti18nText('report.time_range.current_year') },
        { id: 'span', text: geti18nText('report.time_range.span') },
    ];
    const hideModal = () => {
        dispatch(deviceWeatherStateDetailsChartInit());
        setTempData(undefined);
    };

    useEffect(() => {
        if (chartRecord?.id != undefined) {
            setSelectedDevice(chartRecord.id);
        }
    }, [chartRecord]);

    useEffect(() => {
        let key = chartParmKey ? chartParmKey : props.chartParmKey;
        let parameter = chartParmeter ? chartParmeter : props.parameter;
        if (key != undefined) {
            setSelectedParameter(key);
            fetchChartData(key, parameter);
        }
    }, [props.chartParmKey, chartParmKey]);

    useEffect(() => {
        fetchChartData(chartParmKey, selectedParameter);
    }, [period, spanPeriod]);

    function fetchChartData(chartKey: any, parameter: any) {
        if (chartKey != null) {
            const dateFrom = spanPeriod != null && spanPeriod.dateFrom ? spanPeriod.dateFrom : null;
            const dateTo = spanPeriod != null && spanPeriod.dateTo ? spanPeriod.dateTo : null;
            fetchData(selectedDevice, period.id, chartKey?.toLocaleLowerCase(), setTempData, dateFrom, dateTo).then(
                (tempData) => {
                    const item = {
                        parametar: parameter,
                        data: tempData,
                    };
                    setTempData(item);
                }
            );
        }
    }

    function disabledDate(current: any) {
        const rangeStart = moment().subtract(1, 'years');
        const rangeEnd = moment().endOf('day');
        return !(rangeStart.isSameOrBefore(current) && rangeEnd.isAfter(current));
    }
    function onRangeChange(dates: any) {
        if (dates != null) {
            const timestampFrom = dates[0] ? moment(dates[0]).valueOf() : null;
            const timestampTo = dates[1] ? moment(dates[1]).valueOf() : null;
            setSpanPeriod({ dateFrom: timestampFrom, dateTo: timestampTo });
        }
    }
    function periodOnChange(val: any) {
        console.log(val);
        setPeriod(val);
    }

    const fetchData = async (deviceId: any, period: any, parName: any, setValue: any, dateFrom: any, dateTo: any) => {
        let data: any = {
            period: period,
            lang: NyUtils.getSelectedLanguage(),

            parName: parName,
        };

        if (category) {
            data.categoryId = category;
        }
        if (deviceId != null) {
            data.deviceId = deviceId;
        }

        if (dateFrom != null) {
            data['timestampFrom'] = dateFrom;
        }

        if (dateTo != null) {
            data['timestampTo'] = dateTo;
        }
        const response: any = await NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_WEATHER_CONSUMPTION, data);
        if (response.status === RESPONSE.OK) {
            let dataRes: IDataResponse[] = response.data;
            return !parName.includes('wind') ? dataRes.filter((x: any) => x.barValue != null) : dataRes;
        }
        return [];
    };

    const renderChart = (chart: any) => {
        switch (chart.parametar.chart) {
            case 0:
                return (
                    <WeatherChart
                        loading={loading}
                        data={chart.data}
                        title={period.text}
                        deviceId={selectedDevice}
                        period={period.id}
                        showYLabel={true}
                        params={[chart.parametar.dbColumn]}
                        settings={{
                            title: chart.parametar[parametarKeyName('name')],
                            yLabel: chart.parametar[parametarKeyName('ylabel')],
                            color: chart.parametar.color,
                        }}
                    />
                );
            case 1:
                return (
                    <WeatherRainfallBarChart
                        period={period.id}
                        params={[chart.parametar.dbColumn]}
                        deviceId={selectedDevice}
                        data={chart.data}
                        loading={loading}
                        title={period.text}
                    ></WeatherRainfallBarChart>
                );
            case 2:
                return (
                    <WeatherPolarChart
                        loading={loading}
                        data={chart.data}
                        title={period.text}
                        deviceId={selectedDevice}
                        period={period.id}
                        showYLabel={true}
                        params={['avg_wind_direction', 'avg_wind_speed']}
                        settings={{
                            title: chart.parametar[parametarKeyName('name')],
                            yLabel: chart.parametar[parametarKeyName('ylabel')],
                            color: chart.parametar.color,
                        }}
                    />
                );
            default:
                return null;
        }
    };

    const content = () => {
        return (
            <>
                <Row>
                    <Col span={12}>
                        <Form.Item label={geti18nText('report.time_range.select_period')}>
                            <NySearchField
                                value={period}
                                onChange={periodOnChange}
                                searchBy={'name'}
                                style={{ width: '90%' }}
                                options={periods}
                            />
                        </Form.Item>
                    </Col>
                    {period != undefined && period.id == 'span' && (
                        <Col>
                            <Row gutter={24} style={{ marginBottom: '10px' }}>
                                <Col span={24}>
                                    <RangePicker
                                        disabledDate={disabledDate}
                                        showTime
                                        format="DD.MM.YYYY HH:mm:ss"
                                        onChange={onRangeChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
                <Row>
                    {' '}
                    <Col span={24}>{tempData && renderChart(tempData)}</Col>
                </Row>
            </>
        );
    };

    return modal == true ? (
        <Modal
            zIndex={1009}
            title={modalTitle}
            destroyOnClose={true}
            visible={chartVisible}
            onOk={hideModal}
            onCancel={hideModal}
            width="90%"
            footer={null}
        >
            {content()}
        </Modal>
    ) : (
        content()
    );
};

export default DeviceWeatherChartDetail;
