import { useDispatch } from 'react-redux';
import { geti18nText, NyFileUpload, NyRequestResolver, NySession, NySpinner, RESPONSE } from '@nybble/nyreact';
import { Col, Row, Form, Modal, Button, Upload, message, Input, notification } from 'antd';
import React, { useState, useEffect } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import ImportModal from './ImportModal';
import { refreshImportExportList } from '../../slices/importExportSlice';
const { Dragger } = Upload;
interface IImporExport {
    save: () => void;
}
const ImportExportModal: React.FC<IImporExport> = ({ save }) => {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [visibleExport, setVisibleExport] = useState(false);
    const [form] = Form.useForm();
    const [formExport] = Form.useForm();
    const [url, setUrl] = useState<any>(null);
    const [data, setData] = useState<any>(0);
    const [loadinExport, setLoadingExport] = useState<any>(false);

    const showModal = () => {
        setVisible(true);
    };
    const showModalExport = () => {
        setVisibleExport(true);
    };

    const closeModal = () => {
        setVisible(false);
    };
    const closeModalExport = () => {
        setVisibleExport(false);
    };

    const object = {
        korisnik: '01',
        Sifra_Ocitanja: '',
        sifra_brojila: 0,
        datum_ocitanja: 0,
        stanje: 0.0,
        obracun: 0,
        S1: 'None',
        S2: 'None',
        S3: 'None',
        N2: 0,
        N3: 0,
        R1: 0,
        R2: 0,
        R3: 0,
        XVRIJEME: 0,
        xnadnevak: new Date(),
        operater: '',
        AdresaBrojila: 'None',
        KbrBrojila: 0,
        NazivObjekta: 'None',
        StavkeOcitanjaID: 0,
        MjernoMjestoID: 0,
        Potrosnja: 0,
    };

    function dateFormat(date: Date) {
        let formatedDate =
            date.getFullYear() +
            '-' +
            ('0' + (date.getMonth() + 1)).slice(-2) +
            '-' +
            ('0' + date.getDate()).slice(-2) +
            ' ' +
            ('0' + date.getHours()).slice(-2) +
            ':' +
            ('0' + date.getMinutes()).slice(-2) +
            ':' +
            ('0' + date.getSeconds()).slice(-2) +
            '.' +
            ('0' + date.getMilliseconds()).slice(-4);
        return formatedDate;
    }

    function updateLog(logId: any, rowNumber: number, totalNumber: number, error: any) {
        NyRequestResolver.requestPut(CONSTANTS_REQ.IMPORT_EXPORT.UPDATE_LOG + '/' + logId, undefined, {
            id: logId,
            rowNumber: rowNumber,
            totalNumber: totalNumber,
            log: error,
        });
    }

    const onFinish = (values: any) => {
        let params = {};
        setLoadingExport(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.IMPORT_EXPORT.EXPORT, { ...params, ...values }).then(
            (response: any) => {
                if (response && response.status === RESPONSE.OK) {
                    const logId = response.data.logId;
                    if (response.data && typeof response.data !== 'string') {
                        const numberOfElements = response.data.content.numberOfElements;
                        var d: any = [];
                        var date = new Date();
                        let dateNow = dateFormat(new Date());
                        let lastInMonth = dateFormat(new Date(date.getFullYear(), date.getMonth() + 1, 0));
                        response.data.content.content?.forEach((element: any) => {
                            if (values.payloadCode != undefined) {
                                let o = JSON.parse(JSON.stringify(object));
                                o.operater = values.operater;
                                o.Sifra_Ocitanja = values.payloadCode;
                                o.datum_ocitanja = lastInMonth;
                                o.xnadnevak = dateNow;
                                o.stanje = element.volume != undefined ? element.volume : 0;
                                o.sifra_brojila = element.deviceCode;
                                d.push(o);
                            }
                        });

                        const items = d;
                        var totalNumberForExport = 0;
                        const filename = `export_ ${date.getFullYear()}_ ${date.getMonth() + 1}.csv`;
                        if (items.length > 0) {
                            const replacer = (key: any, value: any) => (value === null ? '' : value);
                            const header = Object.keys(items[0]);
                            var csv: any = [
                                //  header.join(','), // header row first
                                ...items.map((row: any) =>
                                    header
                                        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                                        .join(',')
                                        .replace(/"/g, '')
                                ),
                            ];
                            totalNumberForExport = csv.length;
                            csv = csv.join('\r\n');

                            const blob = new Blob([csv], { type: 'text/csv' });
                            const url = window.URL.createObjectURL(blob);

                            const a = document.createElement('a');
                            a.setAttribute('hidden', '');
                            a.setAttribute('href', url);
                            a.setAttribute('download', filename);
                            document.body.appendChild(a);
                            a?.remove();
                            a.click();
                        }

                        updateLog(logId, totalNumberForExport, numberOfElements, '');
                        setLoadingExport(false);
                        setVisibleExport(false);
                        message.destroy();
                        message.success(`${filename} ${geti18nText('importExport.export.text.done')}`);
                        dispatch(refreshImportExportList());
                    } else {
                        var date = new Date();
                        const filename = `export_ ${date.getFullYear()}_ ${date.getMonth() + 1}.csv`;
                        message.destroy();
                        message.error(`${filename} ${geti18nText('importExport.export.text.error')}`);
                        updateLog(logId, 0, 0, 'Error response');
                    }
                } else {
                    message.destroy();
                    message.error(`${geti18nText('upload.text.download.error')}`);
                    setLoadingExport(false);
                }
            }
        );
    };

    return (
        <React.Fragment>
            <Button type="primary" className="margin-left" onClick={showModal}>
                {geti18nText('importExport.table.column.import')}
            </Button>
            <Button type="primary" className="margin-left" onClick={showModalExport}>
                {geti18nText('importExport.table.column.export')}
            </Button>

            <ImportModal visible={visible} closeModal={closeModal} />

            <Modal
                title={geti18nText('importExport.table.column.export')}
                visible={visibleExport}
                onCancel={closeModalExport}
                destroyOnClose={true}
                footer={null}
            >
                {loadinExport ? (
                    <NySpinner />
                ) : (
                    <Form form={formExport} onFinish={onFinish} labelCol={{ span: 7 }}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('importExport.export.payload.code')}
                                    name="payloadCode"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('importExport.export.operator.code')}
                                    name="operater"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={24} style={{ textAlign: 'right' }}>
                                <Form.Item>
                                    <Button style={{ marginRight: '1em' }} type="primary" htmlType="submit">
                                        {geti18nText('importExport.export.done')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Modal>
        </React.Fragment>
    );
};

export default ImportExportModal;
