import React, { useEffect, useState } from 'react';
import { Typography, Button, Modal, Col, Form, Row, DatePicker } from 'antd';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Legend, Tooltip, Bar, Label } from 'recharts';
import { FullscreenOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySearchField, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';
import {
    CONSTANTS_REQ,
    DeviceCategoryType,
    DeviceEnergySettings,
    DeviceEnergySettingsByPhase,
} from '../../../utils/Constants';

import { Line } from 'recharts';
import { VictoryChart, VictoryZoomContainer, VictoryLine, VictoryAxis, VictoryLabel, VictoryLegend } from 'victory';
import moment from 'moment';

const { Title } = Typography;

interface IZoomChart {
    loading: boolean | null;
    deviceId?: number;
    parm: string;
    phases?: 'L1' | 'L2' | 'L3' | 'ALL';
    nameL1?: any;
    nameL2?: any;
    nameL3?: any;
}

const ZoomEnergyChart: React.FC<IZoomChart> = ({
    loading,
    deviceId,
    parm,
    phases = 'ALL',
    nameL1 = 'L1',
    nameL2 = 'L2',
    nameL3 = 'L3',
}) => {
    const [dataL1, setDataL1] = useState<any>([]);
    const [dataL2, setDataL2] = useState<any>([]);
    const [dataL3, setDataL3] = useState<any>([]);
    const [periodValue, setPeriodValue] = useState<any>({
        id: '14',
        text: '14 ' + geti18nText('deviceDetails.graph.light.consumption.y.period.days'),
    });
    const [showRange, setShowRange] = useState(false);
    const [rangeValue, setRangeValue] = useState<any>(null);
    const [timestampFrom, setTimestampFrom] = useState<any>(null);
    const [timestampTo, setTimestampTo] = useState<any>(null);
    const [energySettings, setEnergySettings] = useState<any>(DeviceEnergySettings);
    useEffect(() => {
        setEnergySettings(DeviceEnergySettingsByPhase(nameL1, nameL2, nameL3));
    }, [nameL1, nameL2, nameL3]);

    useEffect(() => {
        fetchData();
    }, [parm, periodValue, rangeValue]);

    const fetchData = () => {
        setDataL1(null);
        setDataL2(null);
        setDataL3(null);
        if (deviceId != null && parm != null) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_ENERGY_CONSUMPTION, {
                period: periodValue?.id,
                lang: NyUtils.getSelectedLanguage(),
                deviceId: deviceId,
                parName: parm,
                timestampFrom: timestampFrom,
                timestampTo: timestampTo,
            }).then((response: any) => {
                if (response.status === RESPONSE.OK) {
                    var arry: any = [];
                    response.data.forEach((element: any) => {
                        let ts = new Date(
                            element.ts[0],
                            element.ts[1] - 1,
                            element.ts[2],
                            element.ts[3],
                            element.ts[4] ? element.ts[4] : 0,
                            element.ts[5] ? element.ts[5] : 0
                        );
                        arry.push({
                            volume: element.volume,
                            converted: ts,
                            phase: element.phase,
                        });
                    });

                    const maxX = new Date(
                        Math.max.apply(
                            Math,
                            arry.map((el: any) => {
                                return new Date(el.converted);
                            })
                        )
                    );
                    const minX = new Date(
                        Math.min.apply(
                            Math,
                            arry.map((el: any) => {
                                return new Date(el.converted);
                            })
                        )
                    );

                    setXDomain([minX, new Date(maxX.setHours(maxX.getHours() + 6))]);

                    const maxY = Math.max.apply(
                        Math,
                        arry.map((el: any) => {
                            return el.volume;
                        })
                    );
                    const minY = Math.min.apply(
                        Math,
                        arry.map((el: any) => {
                            return el.volume;
                        })
                    );
                    if (minY < 0) {
                        setYDomain([minY + 0.1 * minY, maxY > 0 ? maxY + 0.1 * maxY : 0]);
                    } else {
                        setYDomain([minY - 0.1 * minY, maxY + 0.1 * maxY]);
                    }

                    setDataL1(arry.filter((x: any) => x.phase == 1));
                    setDataL2(arry.filter((x: any) => x.phase == 2));
                    setDataL3(arry.filter((x: any) => x.phase == 3));
                }
            });
        }
    };

    const period = [
        { id: 'today', text: geti18nText('deviceDetails.graph.light.consumption.y.period.today') },
        { id: '24h', text: geti18nText('deviceDetails.graph.light.consumption.y.period.24h') },
        { id: '3', text: '3 ' + geti18nText('deviceDetails.graph.light.consumption.y.period.days') },
        { id: '7', text: '7 ' + geti18nText('deviceDetails.graph.light.consumption.y.period.days') },
        { id: '14', text: '14 ' + geti18nText('deviceDetails.graph.light.consumption.y.period.days') },
        { id: '30', text: '30 ' + geti18nText('deviceDetails.graph.light.consumption.y.period.days') },
        { id: 'span', text: geti18nText('deviceDetails.graph.light.consumption.y.period.range') },
    ];

    function periodOnChange(val: any) {
        if (val.id === 'span') {
            setPeriodValue(val);
            setShowRange(true);
        } else if (val.id === -1) {
            setPeriodValue({
                id: '14',
                text: '14 ' + geti18nText('deviceDetails.graph.light.consumption.y.period.days'),
            });
            setShowRange(false);
            setRangeValue(null);
        } else {
            setPeriodValue(val);
            setShowRange(false);
            setRangeValue(null);
        }
    }

    function onRangeChange(dates: any) {
        if (dates != null) {
            setTimestampFrom(dates[0] ? moment(dates[0]).valueOf() : null);
            setTimestampTo(dates[1] ? moment(dates[1]).valueOf() : null);
            setRangeValue(dates);
        } else {
            setPeriodValue({
                id: '14',
                text: '14 ' + geti18nText('deviceDetails.graph.light.consumption.y.period.days'),
            });
            setTimestampFrom(null);
            setTimestampTo(null);
            setRangeValue(dates);
            setShowRange(false);
        }
    }

    const [visible, setVisible] = useState(false);
    const [visibleDataVolumes, setVisibleDataVolumes] = useState(false);
    const [currenDate, setCurrentDate] = useState<string>('');
    const [xDomain, setXDomain] = useState<any>(null);
    const [yDomain, setYDomain] = useState<any>(null);

    return (
        <div className="box" style={{ height: '95%' }}>
            <Title style={{ fontSize: '1.4em', width: '75%' }}>{energySettings['title']}</Title>
            <Form.Item
                rules={[{ required: true, message: geti18nText('app.default.required') }]}
                name="period"
                label={geti18nText('deviceDetails.graph.light.consumption.period')}
            >
                <Row>
                    <Col span={8}>
                        <NySearchField
                            value={periodValue}
                            onChange={periodOnChange}
                            searchBy={'name'}
                            style={{ width: '90%' }}
                            options={period}
                        />
                    </Col>
                    <Col hidden={!showRange} span={10}>
                        <FormatedRangePicker
                            value={rangeValue}
                            showTime={true}
                            onChange={onRangeChange}
                        ></FormatedRangePicker>
                    </Col>
                    <Col>
                        {/* {deviceId && (
                            <Button
                    
                                shape="circle"
                                onClick={() => setVisibleDataVolumes(true)}
                                icon={<UnorderedListOutlined />}
                            />
                        )} */}
                        {/* <Button
                            style={{ marginLeft: '5px' }}
                            shape="circle"
                            onClick={() => setVisible(true)}
                            icon={<FullscreenOutlined />}
                        /> */}
                    </Col>
                </Row>
            </Form.Item>

            <ResponsiveContainer width="100%" height={250}>
                {loading ? (
                    <NySpinner />
                ) : (
                    <VictoryChart
                        height={470}
                        scale={{ x: 'time' }}
                        domain={{ y: yDomain }}
                        containerComponent={<VictoryZoomContainer zoomDimension="x" />}
                    >
                        <VictoryAxis
                            domain={xDomain}
                            tickFormat={(tick, index, tickets) => {
                                const date1 = tickets[0];
                                const date2 = tickets[tickets.length - 1];
                                var sameDay = false;
                                setCurrentDate('');
                                if (date1 && date2) {
                                    if (
                                        date1.getFullYear() === date2.getFullYear() &&
                                        date1.getMonth() === date2.getMonth() &&
                                        date1.getDate() === date2.getDate()
                                    ) {
                                        sameDay = true;
                                        setCurrentDate(tick.toLocaleDateString('hr-HR'));
                                    }
                                }

                                return sameDay == true
                                    ? new Date(tick).toLocaleTimeString('hr-HR', {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                      })
                                    : tick.toLocaleDateString('hr-HR');
                            }}
                            style={{
                                axisLabel: { fontSize: 12 },
                                tickLabels: { fontSize: 12 },
                            }}
                            label={currenDate}
                        />

                        <VictoryAxis
                            dependentAxis
                            label={energySettings['y-title']}
                            style={{
                                axisLabel: { fontSize: 12, margin: 10 },
                                tickLabels: { fontSize: 10, marginLeft: 10 },
                            }}
                            tickFormat={(tick) => {
                                return tick;
                            }}
                        />
                        {(phases === 'ALL' || phases === 'L1') && (
                            <VictoryLine
                                style={{
                                    data: { stroke: energySettings['color'] },
                                }}
                                data={dataL1}
                                x="converted"
                                y="volume"
                            />
                        )}
                        {(phases === 'ALL' || phases === 'L2') && (
                            <VictoryLine
                                style={{
                                    data: { stroke: energySettings['color1'] },
                                }}
                                data={dataL2}
                                x="converted"
                                y="volume"
                            />
                        )}
                        {(phases === 'ALL' || phases === 'L3') && (
                            <VictoryLine
                                style={{
                                    data: { stroke: energySettings['color2'] },
                                }}
                                data={dataL3}
                                x="converted"
                                y="volume"
                            />
                        )}

                        {(phases === 'ALL' || phases === 'L1') && (
                            <VictoryLegend
                                y={0}
                                orientation="horizontal"
                                titleOrientation="left"
                                gutter={20}
                                style={{ labels: { color: energySettings['color'] } }}
                                data={[
                                    {
                                        name: energySettings['y-title'],
                                        symbol: { fill: energySettings['color'], type: 'square' },
                                        labels: { fill: energySettings['color'] },
                                    },
                                ]}
                            />
                        )}

                        {(phases === 'ALL' || phases === 'L2') && (
                            <VictoryLegend
                                y={0}
                                x={250}
                                orientation="horizontal"
                                titleOrientation="left"
                                gutter={20}
                                style={{ labels: { color: energySettings['color1'] } }}
                                data={[
                                    {
                                        name: energySettings['y-title1'],
                                        symbol: { fill: energySettings['color1'], type: 'square' },
                                        labels: { fill: energySettings['color1'] },
                                    },
                                ]}
                            />
                        )}
                        {(phases === 'ALL' || phases === 'L3') && (
                            <VictoryLegend
                                y={0}
                                x={450}
                                orientation="horizontal"
                                titleOrientation="left"
                                gutter={20}
                                style={{ labels: { color: energySettings['color2'] } }}
                                data={[
                                    {
                                        name: energySettings['y-title2'],
                                        symbol: { fill: energySettings['color2'], type: 'square' },
                                        labels: { fill: energySettings['color2'] },
                                    },
                                ]}
                            />
                        )}
                    </VictoryChart>
                )}
            </ResponsiveContainer>
        </div>
    );
};

export default ZoomEnergyChart;

export const FormatedRangePicker = (props: any) => {
    const FORMAT_DATE_TIME_HR = 'DD.MM.YYYY HH:mm:ss';
    const FORMAT_DATE_TIME_EN = 'MM/DD/YYYY, HH:mm:ss';
    const FORMAT_DATE_EN = 'MM/DD/YYYY';
    const FORMAT_DATE_HR = 'DD.MM.YYYY';
    const { RangePicker } = DatePicker;

    function setFormat(showTime: boolean) {
        if (showTime) {
            if (NyUtils.getSelectedLocale() == 'hr-HR') return FORMAT_DATE_TIME_HR;
            else return FORMAT_DATE_TIME_EN;
        } else {
            if (NyUtils.getSelectedLocale() == 'hr-HR') return FORMAT_DATE_HR;
            else return FORMAT_DATE_EN;
        }
    }

    return (
        <RangePicker
            value={props.value}
            showTime={props.showTime}
            format={setFormat(props.showTime != null ? props.showTime : false)}
            onChange={props.onChange}
        />
    );
};
