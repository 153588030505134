import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import DeviceModelEdit from './edit';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import { Form, Row, Col, Radio } from 'antd';
import { GetEnum } from '../../utils/Enums';

const DeviceModelIndex = () => {
    const [typeCategory, setTypeCategory] = useState<any>(GetEnum('DEVICE_CATEGORY')['WATERMETER']);
    const [refresh, setRefresh] = useState<any>(null);
    const [deviceCategoryOptions, setDeviceCategoryOptions] = useState<any>();

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'device_category', condition: 'equals', value: typeCategory },
        ];
    };

    useEffect(() => {
        getDeviceCategoryOptions();
    }, [typeCategory]);

    useEffect(() => {
        setRefresh(new Date().toString());
    }, [typeCategory]);

    const getDeviceCategoryOptions: any = () => {
        const aTypes = GetEnum('DEVICE_CATEGORY');
        let types: any = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret: any = { label: undefined, value: undefined };
                ret.value = aTypes[key];
                ret.label = geti18nText('app.enum.DEVICE_CATEGORY.' + key);
                types.push(ret);
            }
        }
        setDeviceCategoryOptions(types);
    };

    function onTypeCategoryChange(val: any) {
        setTypeCategory(val.target.value);
    }

    return (
        <>
            <Form.Item label={geti18nText('deviceModel.table.filter')}>
                <Row>
                    <Col offset={1} span={24}>
                        <Radio.Group
                            onChange={onTypeCategoryChange}
                            value={typeCategory}
                            options={deviceCategoryOptions}
                        />
                    </Col>
                </Row>
            </Form.Item>
            <NyDataTable
                headerTitle={geti18nText('deviceModel.table.header')}
                url={CONSTANTS_REQ.DEVICE_MODEL.LIST}
                addNewButtonText={geti18nText('deviceModel.table.add')}
                showRecordModal={true}
                exportCSV={true}
                modalComponent={DeviceModelEdit}
                setDefaultFilterValue={setDefaultFilterValue}
                columns={[
                    {
                        title: geti18nText('deviceModel.table.column.id'),
                        dataIndex: 'id',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('deviceModel.table.column.name'),
                        dataIndex: 'name',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('deviceModel.table.column.active'),
                        dataIndex: 'active',
                        width: '10%',
                        render: (text: any, record: { active: any }) => {
                            if (record.active) {
                                return <CheckOutlined style={{ color: 'green' }} />;
                            } else {
                                return <CloseOutlined style={{ color: 'red' }} />;
                            }
                        },
                        ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                        defaultFilteredValue: setDefaultFilterValue(),
                    },
                ]}
            />
        </>
    );
};

export default DeviceModelIndex;
