import React, { ReactText } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import UserEdit from './edit';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import { Button, Col, Row, Tabs } from 'antd';
const { TabPane } = Tabs;
const UserAlarmSelect = ({
    showRowSelection = true,
    hideNewButton = true,
    setSelectedValues = undefined,
    seRemovedValues = undefined,
    //defaultSelectedRowKey = [],
    forAlarm = false,
    alarmId = undefined,
    save,
    refresh
}: any) => {
    const setDefaultFilterValue = () => {
        if (NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) && forAlarm == false) {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'customer.id', condition: 'is_null', value: '' },
                { field: 'category_type', condition: 'contains', value: 'WEATHER' },
            ];
        } else {
            return [
                { field: 'active', condition: 'equals_bool', value: 1 },
                { field: 'category_type', condition: 'contains', value: 'WEATHER' },
            ];
        }
    };

    let clearKeys: any;

    const setSelectedValuesFunc = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        const selected = new Set(selectedRowKeys);
        if (setSelectedValues != undefined) {
            setSelectedValues(Array.from(selected));
        }
        clearKeys = clearSelectedRowKeys;
    };

    const setRemovedValuesFunc = (
        hasSelected: boolean,
        selectedRowKeys: ReactText[],
        selectedRows: any,
        onPopupSave: () => void,
        clearSelectedRowKeys: () => void
    ) => {
        const selected = new Set(selectedRowKeys);
        if (seRemovedValues != undefined) {
            seRemovedValues(Array.from(selected));
        }
        clearKeys = clearSelectedRowKeys;
    };

    return (
        <Tabs
            type="card"
            onTabClick={(e) => {
                //    setTabIndex(e);
            }}
            destroyInactiveTabPane={true}
        >
            <TabPane tab={geti18nText('alarm.settings.edit.btn.users.tab.selected')} key="1">
                <NyDataTable
                    url={CONSTANTS_REQ.USER.LIST_FOR_ALARM_SETTINGS}
                    addNewButtonText={geti18nText('user.table.add')}
                    scroll={{ x: 800 }}
                    exportCSV={false}
                    showRecordModal={false}
                    modalComponent={UserEdit}
                    setDefaultFilterValue={setDefaultFilterValue}
                    hideButtons={true}
                    readonly={true}
                    hideNewButton={hideNewButton}
                    showRowSelection={showRowSelection}
                    setDefaultPageSize={100}
                    rowSelectionModal={setRemovedValuesFunc as any}
                    rowSelectionType={'checkbox'}
                    addedData={{ selected: true, alarmId: alarmId }}
                    fetchWhenChange={refresh}
                    columns={[
                        {
                            title: geti18nText('user.table.column.id'),
                            dataIndex: 'id',
                            width: '10%',
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('number'),
                        },
                        {
                            title: geti18nText('user.table.column.firstName'),
                            dataIndex: 'first_name',
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('string'),
                        },
                        {
                            title: geti18nText('user.table.column.lastName'),
                            dataIndex: 'last_name',
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('string'),
                        },
                        {
                            title: geti18nText('user.table.column.username'),
                            dataIndex: 'username',
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('string'),
                        },

                        {
                            title: geti18nText('user.table.column.email'),
                            dataIndex: 'email',
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('string'),
                        },
                    ]}
                />
                <Row className={'buttons-sticky'}>
                    <Col span={24} style={{ textAlign: 'left', paddingTop: '15px' }}></Col>
                    <Button onClick={() => save('REMOVE')} type="primary">
                        {geti18nText('alarm.settings.edit.btn.users.btn.remove')}
                    </Button>
                </Row>
            </TabPane>
            <TabPane tab={geti18nText('alarm.settings.edit.btn.users.tab.notSelected')} key="2">
                <NyDataTable
                    url={CONSTANTS_REQ.USER.LIST_FOR_ALARM_SETTINGS}
                    addNewButtonText={geti18nText('user.table.add')}
                    scroll={{ x: 800 }}
                    exportCSV={false}
                    showRecordModal={false}
                    modalComponent={UserEdit}
                    setDefaultFilterValue={setDefaultFilterValue}
                    hideButtons={true}
                    readonly={true}
                    hideNewButton={hideNewButton}
                    showRowSelection={showRowSelection}
                    setDefaultPageSize={100}
                    rowSelectionModal={setSelectedValuesFunc as any}
                    rowSelectionType={'checkbox'}
                    addedData={{ selected: false, alarmId: alarmId }}
                    fetchWhenChange={refresh}
                    columns={[
                        {
                            title: geti18nText('user.table.column.id'),
                            dataIndex: 'id',
                            width: '10%',
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('number'),
                        },
                        {
                            title: geti18nText('user.table.column.firstName'),
                            dataIndex: 'first_name',
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('string'),
                        },
                        {
                            title: geti18nText('user.table.column.lastName'),
                            dataIndex: 'last_name',
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('string'),
                        },
                        {
                            title: geti18nText('user.table.column.username'),
                            dataIndex: 'username',
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('string'),
                        },

                        {
                            title: geti18nText('user.table.column.email'),
                            dataIndex: 'email',
                            sorter: (a: any, b: any) => {},
                            ...getColumnSearch('string'),
                        },
                    ]}
                />
                <Row className={'buttons-sticky'}>
                    <Col span={24} style={{ textAlign: 'left', paddingTop: '15px' }}></Col>
                    <Button onClick={() => save('ADD')} type="primary">
                        {geti18nText('alarm.settings.edit.btn.users.btn.add')}
                    </Button>
                </Row>
            </TabPane>
        </Tabs>
    );
};

export default UserAlarmSelect;
