import React, { useEffect } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { Form, Modal, Input, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../rootReducer';
import { refreshWatermeterList } from '../../slices/dashboardSlice';
import { deviceContactInit } from '../../slices/deviceContactSlice';
import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';

const DeviceEditContactInfo: React.FC = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { deviceId, data, visible } = useSelector((state: RootState) => state.deviceContact);

    useEffect(() => {
        if (data != undefined) form.setFieldsValue(data);
    }, [data]);

    function save(values: any) {
        NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE.CONTACT_DATA_FOR_DEVICE, undefined, {
            refId: deviceId,
            address: values.address,
            phone: values.phone,
            email: values.email,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('app.default.save.ok.desc'),
                    duration: 3,
                });
                dispatch(refreshWatermeterList());
                hideModal();
            } else {
                notification.error({ message: geti18nText('app.default.save.nok'), description: '' });
            }
        });
    }

    const hideModal = () => {
        dispatch(deviceContactInit());
    };

    return (
        <React.Fragment>
            <Modal
                zIndex={1009}
                visible={visible}
                title={geti18nText('dashboard.widget.watermeter.edit.contact.title')}
                onCancel={() => hideModal()}
                okText={geti18nText('app.default.button.save')}
                width={600}
                onOk={() => {
                    form.validateFields().then((values) => {
                        form.resetFields();
                        save(values);
                    });
                }}
            >
                <Form form={form} layout="horizontal" labelCol={{ span: 4 }}>
                    <Form.Item
                        name="address"
                        label={geti18nText('dashboard.widget.watermeter.edit.contact.address')}
                        initialValue={data && data.address ? data.address : ''}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label={geti18nText('dashboard.widget.watermeter.edit.contact.email')}
                        initialValue={data && data.email ? data.email : ''}
                        rules={[
                            {
                                type: 'email',
                                message: geti18nText('dashboard.widget.watermeter.edit.contact.email.validation'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label={geti18nText('dashboard.widget.watermeter.edit.contact.phone')}
                        initialValue={data && data.phone ? data.phone : ''}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default DeviceEditContactInfo;
