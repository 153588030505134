import React, { Fragment, useState } from 'react';
import { geti18nText, NySession } from '@nybble/nyreact';
import { Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

interface iNyFileUpload {
    accept?: string;
    multiple?: boolean;
    files: any;
    setFiles: (file: any) => void;
    value?: any;
    defaultImage?: any;
}

const NyImageUpload = ({ accept, multiple, files, setFiles, value, defaultImage }: iNyFileUpload) => {
    const [fileNames, setFileNames] = useState<any>([]);

    const uploadData = {
        name: 'attachment',
        accept: accept,
        showUploadList: true,
        multiple: multiple != undefined ? multiple : false,
        headers: {
            Authorization: 'Bearer ' + NySession.getUserToken(),
        },
        onRemove: (file: any) => {
            let newFileNames = [...fileNames];
            let newFiles = [...files];

            var index = newFileNames.indexOf(file.name);
            if (index !== -1) {
                newFileNames.splice(index, 1);
                newFiles.splice(index, 1);
            }

            if (multiple != undefined && multiple) {
                setFileNames(newFileNames);
                setFiles(newFiles);
            } else {
                setFileNames([]);
                setFiles([]);
            }
        },
        beforeUpload: (file: any) => {
            console.log('before upload', file);
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                console.error('You can only upload JPG/PNG file!');
            }
            /*const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                console.error('Image must smaller than 2MB!');
            }*/
            return true;
        },

        fileNames,
    };

    function getBase64(img: any, callback: any) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleChange = (info: any) => {
        console.log('info ', info.file.status);
        if (info.file.status === 'uploading') {
            getBase64(info.file.originFileObj, (imageUrl: any) => {
                //console.log('Img');
                setFiles(imageUrl);
            });
            return;
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, (imageUrl: any) => {
                //  console.log('Img');
                setFiles(imageUrl);
            });
        }
    };

    return (
        <React.Fragment>
            <Upload {...uploadData} showUploadList={false} onChange={handleChange}>
                {defaultImage != null && defaultImage}
                {files != null && (
                    <img style={{ maxWidth: '200px', maxHeight: '200px', margin: '5px' }} src={files}></img>
                )}

                <Button icon={<UploadOutlined />}>{geti18nText('attachment.select.button')}</Button>
            </Upload>
        </React.Fragment>
    );
};

export default NyImageUpload;
