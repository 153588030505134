import React from 'react';
import { DeviceCategoryType } from '../../utils/Constants';
import DeviceGroup from '../device-groups';

const WeatherMeterGroup = () => {
    return (
        <>
            <DeviceGroup type={DeviceCategoryType.WEATHER}></DeviceGroup>
        </>
    );
};

export default WeatherMeterGroup;
