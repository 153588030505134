import {
    ApiFilled,
    AreaChartOutlined,
    BellOutlined,
    RocketOutlined,
    SignalFilled,
    ThunderboltOutlined,
} from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Card, Row, Switch } from 'antd';
import { drawVersion } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../rootReducer';
import {
    filterActiveLights,
    filterActiveLightsReset,
    filterAlarmLights,
    filterAlarmLightsReset,
    refresLightsList,
} from '../../slices/dashboardSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getUserCategoryType } from '../../utils/Enums';
import FormatNumber from '../number-formatter';
import { IWidget } from './index';

const WidgetCardsLights: React.FC<IWidget> = ({ showLightsOn, dataGrid }) => {
    const [statsLights, setStatsLights] = useState<{ [index: string]: any }>({});
    const [loading, setLoading] = useState(true);
    const { filterActive, filterInactive, filterLowBattery, mapBounds, mapSelectedBounds } = useSelector(
        (state: RootState) => state.dashboard
    );
    const history = useHistory();
    const dispatch = useDispatch();
    /* useEffect(() => {
        fetchStat();
        const interval = setInterval(() => {
            fetchStat();
        }, 3 * 60 * 1000);

        return () => {
            clearInterval(interval);
            dispatch(filterReset());
        };
    }, []); */

    useEffect(() => {
        setLoading(true);
        if (getUserCategoryType().includes('LIGHTS')) {
            fetchStatLights();
        }
    }, [mapSelectedBounds]);

    useEffect(() => {
        console.log(dataGrid);
    }, [dataGrid]);

    const fetchStatLights = () => {
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.DASHBOARD.STATISTIC_LIGHTS,
            mapSelectedBounds != null
                ? {
                      latNorthEast: mapSelectedBounds.latNorthEast,
                      lngNorthEast: mapSelectedBounds.lngNorthEast,
                      latSouthWest: mapSelectedBounds.latSouthWest,
                      lngSouthWest: mapSelectedBounds.lngSouthWest,
                  }
                : {}
        ).then((result) => {
            if (result && result.status == RESPONSE.OK) {
                if (Array.isArray(result.data)) {
                    setLoading(false);
                    setStatsLights(result.data[0]);
                }
            } else {
                setLoading(false);
            }
        });
    };

    const gotoLightDetails = () => {
        history.push('/light-details');
    };

    const filterActiveLightOnClick = (e: any) => {
        if (e === true) {
            dispatch(filterActiveLights());
        } else {
            dispatch(filterActiveLightsReset());
            
        }
        dispatch(refresLightsList());
    };
    const filterLightWithAlarmOnClick = (e: any) => {
        if (e === true) {
            dispatch(filterAlarmLights());
            
        } else {
            dispatch(filterAlarmLightsReset());
        }
        dispatch(refresLightsList());
    };

    function getHeight() {
        console.log(dataGrid);
        if (typeof dataGrid.h == 'number') {
            return dataGrid.h * 10;
        }
    }
    function getWidth() {
        console.log(dataGrid);

        if (typeof dataGrid.w == 'number') {
            if (dataGrid.w * 133 > 300 && dataGrid.w * 133 < 960) return { width: (dataGrid.w * 133 - 20) / 3 };
            /* else if(dataGrid.w * 133 >  960) {
                return {width: (dataGrid.w * 133 - 40) / 6};
            }
            else return undefined; */
        }

        return undefined;
    }

    return (
        <React.Fragment>
            {getUserCategoryType() &&
                getUserCategoryType().length > 0 &&
                getUserCategoryType().includes('LIGHTS') &&
                showLightsOn &&
                statsLights && (
                    <div className="widget-cards-card-container-lights">
                        <Card className="widget-cards-card-lights" style={getWidth()}>
                            <div className="widget-cards-text-bottom">
                                <div className="widget-cards-number-lights">
                                    {loading && <NySpinner />}
                                    {statsLights.totalLights || statsLights.totalLights === 0
                                        ? NyUtils.formatNumber(statsLights.totalLights)
                                        : '0'}
                                </div>
                                <div className="widget-cards-title-lights">
                                    <span>{geti18nText('dashboard.widget.card.active.lights')}</span>
                                </div>
                            </div>
                            <div className="widget-cards-background-icon-lights">
                                <Switch size="small" onChange={(e: any) => filterActiveLightOnClick(e)} />
                            </div>
                            <div className="widget-cards-background-icon-box">
                                <ApiFilled className="widget-cards-background-icon-box-lights" />
                            </div>
                        </Card>

                        <Card className="widget-cards-card-lights" style={getWidth()}>
                            <div className="widget-cards-text-bottom">
                                <div className="widget-cards-number-lights">
                                    {loading && <NySpinner />}
                                    {statsLights.totalAlarm || statsLights.totalAlarm === 0
                                        ? NyUtils.formatNumber(statsLights.totalAlarm)
                                        : '0'}
                                </div>
                                <div className="widget-cards-title-lights">
                                    <span>{geti18nText('dashboard.widget.card.active.lights.alarm')}</span>
                                </div>
                            </div>
                            <div className="widget-cards-background-icon-lights">
                                <Switch size="small" onChange={(e: any) => filterLightWithAlarmOnClick(e)} />
                            </div>
                            <div className="widget-cards-background-icon-box">
                                <BellOutlined className="widget-cards-background-icon-box-lights" />
                            </div>
                        </Card>

                        <Card className="widget-cards-card-lights" style={getWidth()}>
                            <div className="widget-cards-text-bottom">
                                <div className="widget-cards-number-lights">
                                    {loading && <NySpinner />}
                                    {statsLights.totalPower || statsLights.totalPower === 0 ? (
                                        <>
                                            {NyUtils.formatNumber(statsLights.totalPower, 1)}
                                            <span className="widget-cards-number-lights-units">{' W'}</span>
                                        </>
                                    ) : (
                                        <>
                                            {'0 '}
                                            <span className="widget-cards-number-lights-units">{'W'}</span>
                                        </>
                                    )}
                                </div>
                                <div className="widget-cards-title-lights">
                                    <span>{geti18nText('dashboard.widget.card.active.lights.total.power')}</span>
                                </div>
                            </div>

                            <div className="widget-cards-background-icon-box">
                                <RocketOutlined className="widget-cards-background-icon-box-lights" />
                            </div>
                        </Card>

                        <Card className="widget-cards-card-lights" style={getWidth()}>
                            <div className="widget-cards-text-bottom">
                                <div className="widget-cards-number-lights">
                                    {loading && <NySpinner />}
                                    {statsLights.totalCurrent || statsLights.totalCurrent === 0 ? (
                                        <>
                                            {' '}
                                            {NyUtils.formatNumber(statsLights.totalCurrent, 1)}
                                            <span className="widget-cards-number-lights-units">{' A'}</span>
                                        </>
                                    ) : (
                                        <>
                                            {'0 '} <span className="widget-cards-number-lights-units">{' A'}</span>
                                        </>
                                    )}
                                </div>
                                <div className="widget-cards-title-lights">
                                    <span>{geti18nText('dashboard.widget.card.active.lights.total.current')}</span>
                                </div>
                            </div>

                            <div className="widget-cards-background-icon-box">
                                <ThunderboltOutlined className="widget-cards-background-icon-box-lights" />
                            </div>
                        </Card>

                        {getUserCategoryType().length == 1 && (
                            <Card className="widget-cards-card-lights" style={getWidth()}>
                                <div className="widget-cards-text-bottom">
                                    <div className="widget-cards-number-lights">
                                        {loading && <NySpinner />}
                                        {statsLights.totalElectricEnergy || statsLights.totalElectricEnergy === 0
                                            ? NyUtils.formatNumber(statsLights.totalElectricEnergy, 1)
                                            : '0'}
                                    </div>
                                    <div className="widget-cards-title-lights">
                                        <span>
                                            {geti18nText(
                                                'dashboard.widget.card.active.lights.current.consumption.last.hour'
                                            )}
                                        </span>
                                    </div>
                                </div>

                                <div className="widget-cards-background-icon-box">
                                    <AreaChartOutlined className="widget-cards-background-icon-box-lights" />
                                </div>
                            </Card>
                        )}

                        {getUserCategoryType().length == 1 && (
                            <Card className="widget-cards-card-lights" style={getWidth()}>
                                <div className="widget-cards-text-bottom">
                                    <div className="widget-cards-number-lights">
                                        {loading && <NySpinner />}
                                        {statsLights.totalEnergyCurrentWeek ||
                                        statsLights.totalEnergyCurrentWeek === 0 ? (
                                            <FormatNumber value={statsLights.totalEnergyCurrentWeek}></FormatNumber>
                                        ) : (
                                            '0'
                                        )}
                                    </div>
                                    <div className="widget-cards-title-lights">
                                        <span>
                                            {geti18nText('dashboard.widget.card.active.lights.total.week.consumption')}
                                        </span>
                                    </div>
                                </div>
                                <div className="widget-cards-background-icon-lights">
                                    <SignalFilled
                                        className="widget-cards-background-icon-box-details"
                                        onClick={gotoLightDetails}
                                    />
                                </div>
                                <div className="widget-cards-background-icon-box">
                                    <AreaChartOutlined className="widget-cards-background-icon-box-lights" />
                                </div>
                            </Card>
                        )}
                    </div>
                )}
        </React.Fragment>
    );
};

export default WidgetCardsLights;
