import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Card, Statistic, Form, DatePicker } from 'antd';
import { LineChartOutlined, ThunderboltOutlined, WarningOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { geti18nText, NyRequestResolver, NySearchField, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';
import { RootState } from '../../../rootReducer';
import { CONSTANTS_REQ, DeviceCategoryType } from '../../../utils/Constants';
import WindCard from './charts/wind';
import TempCard from './charts/tempWidget';
import WeatherChart from './charts/WeatherChart';
import AirCard from './charts/AirWidget';
import moment from 'moment';
import WeatherPolarChart from './charts/WeatherPolarChart';
import { Legend } from 'recharts';
import WeatherRainfallBarChart from './charts/WatherRainfallBarChart';
import { parametarKeyName } from '../../../utils/Utils';

const { Title } = Typography;
const { RangePicker } = DatePicker;

type IWeatherStateResponse = {
    temp?: number;
    humidity?: number;
    uvintensity?: number;
    airPressure?: number;
    rainfall?: number;
    avgNoise?: number;
    maxNoise?: number;
    minNoise?: number;
    avgWindDirection?: number;
    maxWindDirection?: number;
    minWindDirection?: number;
    avgWindSpeed?: number;
    maxWindSpeed?: number;
    minWindSpeed?: number;
    solarRadiation?: number;
    pm_10?: number;
    pm_25?: number;
    lux?: number;
    parameters?: any;
};

type IDataResponse = {
    converted?: string;
    barValue?: number;
};

const DeviceWeatherStateStatisticDetail = () => {
    const { record } = useSelector((state: RootState) => state.deviceWeatherStateDetails);
    const [loading, setLoading] = useState(false);
    const [snapshotData, setSnapshotData] = useState<IWeatherStateResponse>({});
    const [period, setPeriod] = useState<any>({ id: 'last-24h', text: geti18nText('report.time_range.last24h') });
    const [spanPeriod, setSpanPeriod] = useState<any>({ dateFrom: null, dateTo: null });
    const [chartData, setChartData] = useState<any>([]);
    const periods = [
        { id: 'last-24h', text: geti18nText('report.time_range.last24h') },
        { id: 'current-week', text: geti18nText('report.time_range.current_week') },
        { id: 'last-week', text: geti18nText('report.time_range.last_week') },
        { id: 'current-month', text: geti18nText('report.time_range.current_month') },
        { id: 'current-year', text: geti18nText('report.time_range.current_year') },
        { id: 'span', text: geti18nText('report.time_range.span') },
    ];

    useEffect(() => {
        if (period != null && period.id != 'span') {
            fetchCurrentValue(record.id);
        }
    }, [period, spanPeriod]);

    const fetchChartData = async (spanDatePeriod: any, parameters: any[]) => {
        setLoading(true);
        const dateFrom = spanDatePeriod?.dateFrom || null;
        const dateTo = spanDatePeriod?.dateTo || null;
        try {
            let windKeyExist = false;
            parameters = Array.from(
                new Map(
                    parameters
                        .filter((item: any) => {
                            if (item.key.includes('WIND')) {
                                if (!windKeyExist) {
                                    windKeyExist = true;
                                    return true;
                                } else {
                                    return false;
                                }
                            }
                            return true;
                        })
                        .map((item: any) => [item.key, item])
                ).values()
            );
            const fetchPromises = parameters.map((parameter) =>
                fetchData(record.id, period.id, parameter.dbColumn, dateFrom, dateTo).then((tempData) => ({
                    parametar: parameter,
                    data: tempData,
                }))
            );

            const tempChartData = await Promise.all(fetchPromises);
            setChartData(tempChartData);
        } catch (error) {
            console.error('Error fetching data: ', error);
        }

        setLoading(false);
    };

    const fetchCurrentValue = (value: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CURRENT_WEATHER_STATE_CONSUMPTION_FOR, {
            deviceId: value,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                const data: IWeatherStateResponse = response.data;
                setSnapshotData(data);
                fetchChartData(spanPeriod, data.parameters);
            }
        });
    };

    const fetchData = async (deviceId: any, period: any, parName: any, dateFrom: any, dateTo: any) => {
        let data: any = {
            period: period,
            lang: NyUtils.getSelectedLanguage(),
            deviceId: deviceId,
            parName: parName,
        };
        if (dateFrom != null) {
            data['timestampFrom'] = dateFrom;
        }
        if (dateTo != null) {
            data['timestampTo'] = dateTo;
        }
        const response: any = await NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_WEATHER_CONSUMPTION, data);
        if (response.status === RESPONSE.OK) {
            let dataRes: IDataResponse[] = response.data;
            return !parName.includes('wind') ? dataRes.filter((x: any) => x.barValue != null) : dataRes;
        }
        return [];
    };

    function periodOnChange(val: any) {
        console.log(val);
        setPeriod(val);
    }

    function disabledDate(current: any) {
        const rangeStart = moment().subtract(1, 'years');
        const rangeEnd = moment().endOf('day');
        return !(rangeStart.isSameOrBefore(current) && rangeEnd.isAfter(current));
    }

    function onRangeChange(dates: any) {
        if (dates != null) {
            const timestampFrom = dates[0] ? moment(dates[0]).valueOf() : null;
            const timestampTo = dates[1] ? moment(dates[1]).valueOf() : null;
            setSpanPeriod({ dateFrom: timestampFrom, dateTo: timestampTo });
        }
    }

    const renderChart = (chart: any) => {
        switch (chart.parametar.chart) {
            case 0:
                return (
                    <WeatherChart
                        loading={loading}
                        data={chart.data}
                        title={period.text}
                        deviceId={record.id}
                        period={period.id}
                        showYLabel={true}
                        params={[chart.parametar.dbColumn]}
                        settings={{
                            title: chart.parametar[parametarKeyName('name')],
                            yLabel: chart.parametar[parametarKeyName('ylabel')],
                            color: chart.parametar.color,
                        }}
                    />
                );
            case 1:
                return (
                    <WeatherRainfallBarChart
                        period={period.id}
                        params={[chart.parametar.dbColumn]}
                        deviceId={record.id}
                        data={chart.data}
                        loading={loading}
                        title={period.text}
                    ></WeatherRainfallBarChart>
                );
            case 2:
                return (
                    <WeatherPolarChart
                        loading={loading}
                        data={chart.data}
                        title={period.text}
                        deviceId={record.id}
                        period={period.id}
                        showYLabel={true}
                        params={['avg_wind_direction', 'avg_wind_speed']}
                        settings={{
                            title: chart.parametar[parametarKeyName('name')],
                            yLabel: chart.parametar[parametarKeyName('ylabel')],
                            color: chart.parametar.color,
                        }}
                    />
                );
            default:
                return null;
        }
    };

    return loading ? (
        <NySpinner />
    ) : (
        <React.Fragment>
            <Row>
                <Col span={12}>
                    <Form.Item label={geti18nText('report.time_range.select_period')}>
                        <NySearchField
                            value={period}
                            onChange={periodOnChange}
                            searchBy={'name'}
                            style={{ width: '90%' }}
                            options={periods}
                        />
                    </Form.Item>
                </Col>
                {period != undefined && period.id == 'span' && (
                    <Col>
                        <Row gutter={24} style={{ marginBottom: '10px' }}>
                            <Col span={24}>
                                <RangePicker
                                    disabledDate={disabledDate}
                                    showTime
                                    format="DD.MM.YYYY HH:mm:ss"
                                    onChange={onRangeChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
            <Row>
                <TempCard data={snapshotData}></TempCard>
            </Row>
            <Row>
                {chartData.map((chart: any, index: number) => (
                    <Col xs={24} sm={12} md={12} lg={12} xl={8} key={index}>
                        {renderChart(chart)}
                    </Col>
                ))}
            </Row>
        </React.Fragment>
    );
};

export default DeviceWeatherStateStatisticDetail;
