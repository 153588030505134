import React, { useEffect } from 'react';
import WeatherDashboard from './WeatherDashboard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { setQueryId } from '../../slices/dashboardSlice';

const WeatherMainDashboard = () => {
    const { queryId, selectedWeatherDataType } = useSelector((state: RootState) => state.dashboard);
    const dispatch = useDispatch();
    function onQueryIdChange(id: number) {
        dispatch(setQueryId(id));
    }

    return (
        <WeatherDashboard
            queryId={queryId}
            onQueryIdChange={onQueryIdChange}
            selectedWeatherDataType={selectedWeatherDataType}
        />
    );
};

export default WeatherMainDashboard;
