import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Button, notification } from 'antd';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { geti18nText, NyRequestResolver, NySpinner, RESPONSE, NyFileUpload } from '@nybble/nyreact';

const SettingsEdit = () => {
    const { customer } = useSelector((state: RootState) => state.selectedCustomer);
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(true);
    const [isCreated, setIsCreated] = useState(false);

    useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.WHITELABEL.EDIT + '/' + customer).then((result) => {
            if (result.status === RESPONSE.OK) {
                setIsCreated(true);
                setValues(result.data);
            }
            setLoading(false);
        });
    };

    const setValues = (data: any) => {
        let addedData = data;
        let json;

        if (addedData.json_config) {
            json = JSON.parse(addedData.json_config);

            if (addedData.domain) {
                Object.assign(json, { domain: addedData.domain });
            }
        }
        if (addedData.id) {
            json.id = addedData.id;
        }

        if (json) {
            form.setFieldsValue(json);
        }
    };

    const onFinish = (values: any) => {
        if (isCreated) {
            NyRequestResolver.requestPut(CONSTANTS_REQ.WHITELABEL.EDIT + '/' + values.id, undefined, {
                json_config: JSON.stringify(values),
                customer: { id: customer },
                id: values.id,
            }).then((result) => {
                if (result && result.status === RESPONSE.CREATED) {
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        description: geti18nText('app.default.save.ok.desc'),
                        duration: 3,
                    });
                }
            });
        } else {
            NyRequestResolver.requestPost(CONSTANTS_REQ.WHITELABEL.EDIT, undefined, {
                json_config: JSON.stringify(values),
                customer: { id: customer },
            }).then((result) => {
                if (result && result.status === RESPONSE.CREATED) {
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        description: geti18nText('app.default.save.ok.desc'),
                        duration: 3,
                    });
                }
            });
        }
    };

    return loading ? (
        <NySpinner />
    ) : (
        <Form form={form} onFinish={onFinish} labelCol={{ span: 7 }}>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={geti18nText('customer.tab.settings.title')} name="title">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('customer.tab.settings.domain')} name="domain">
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('customer.tab.settings.logo')} name="appLogo">
                        <NyFileUpload CONSTANTS_REQ={CONSTANTS_REQ} customerId={customer} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={geti18nText('customer.tab.settings.loginLogo')} name="loginLogo">
                        <NyFileUpload CONSTANTS_REQ={CONSTANTS_REQ} customerId={customer} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item label={geti18nText('customer.tab.settings.favicon')} name="favicon">
                        <NyFileUpload CONSTANTS_REQ={CONSTANTS_REQ} customerId={customer} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            {geti18nText('customer.tab.settings.save')}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default SettingsEdit;
