import React, { useState, useEffect, useRef } from 'react';
import { APPLICATION_KEY, CONSTANTS_REQ, DeviceCategoryType } from '../../utils/Constants';
import { IWidget } from './index';
import { useDispatch, useSelector } from 'react-redux';
import {
    setMapPanTo,
    filterReset,
    filterDeviceDevices,
    refreshAlarmList,
    stopRefresAlarmList,
    startRefresAlarmList,
    refreshMenu,
} from '../../slices/dashboardSlice';
import { deviceDetailsOpen } from '../../slices/deviceDetailsSlice';
import { ZoomInOutlined, FullscreenOutlined, FormOutlined, CommentOutlined } from '@ant-design/icons';
import { GetEnumNameForValue, GetEnum, getUserCategoryType } from '../../utils/Enums';
import { Tooltip, Modal, Radio, Form, Row, Col, Checkbox, Popconfirm, Tag, Button } from 'antd';
import { RootState } from '../../rootReducer';
import { alarmDescriptionOpen, refreshDeviceAlarmList } from '../../slices/alarmDescriptionSlice';
import {
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { CallTracker } from 'assert';
import { deviceLightDetailsOpen } from '../../slices/deviceLightDetailsSlice';
import FormatNumber, { formatNumberValue, formatNumberValueString } from '../number-formatter';
import { deviceWeatherStateDetailsOpen } from '../../slices/deviceWeatherStateDetailsReducer';
import { getUnreadAlarmFilterType } from '../../utils/Utils';
import { deviceEnergyDetailsOpen } from '../../slices/deviceEnergyDetailsSlice';

const getScrolls = (dataGrid: { [index: string]: string | number | boolean }) => {
    let scr: { [index: string]: any } = { x: 800 };
    if (typeof dataGrid.h == 'number') {
        scr.y = 40 * dataGrid.h - 190;
    }
    return scr;
};

interface ColumnTitleProps {
    text: string;
}

const ColumnTitle: React.FC<ColumnTitleProps> = ({ text }) => {
    return <div style={{ whiteSpace: 'nowrap' }}>{text}</div>;
};

const WidgetAlarms: any = ({
    dataGrid,
    showLightsOn,
    showWatermeterOn,
    refreshCategory,
    showOnlyCategory,
    categoryType,
}: any) => {
    const [typeCategory, setTypeCategory] = useState<any>([]);
    const [visible, setVisible] = useState(false);
    const [refresh, setRefresh] = useState<any>(null);
    const [showFilter, setShowFilter] = useState<any>(null);
    const [deviceCategoryOptions, setDeviceCategoryOptions] = useState<any>();
    const { filterDevice, alarmListRefresh, mapSelectedBounds } = useSelector((state: RootState) => state.dashboard);
    const [showAlarmBtn, setShowAlarmBtn] = useState(false);
    const dispatch = useDispatch();
    const timerRef = useRef<any>(null);

    useEffect(() => {
        if (alarmListRefresh < 1) {
            if (timerRef.current != null) {
                clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(() => {
                console.log('refreshAlarm2');
                dispatch(startRefresAlarmList());
            }, 3 * 60 * 1000);
        }

        return function cleanup() {
            dispatch(stopRefresAlarmList());
        };
    }, []);

    useEffect(() => {
        if (timerRef.current != null) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            if (alarmListRefresh > 0) {
                console.log('refreshAlarm1');
                dispatch(refreshAlarmList());
            }
        }, 60 * 1000);
    }, [alarmListRefresh]);

    const onRowSelect = (record: { [index: string]: any }) => {
        let modifiedRecord = JSON.parse(JSON.stringify(record));
        modifiedRecord.id = record.device_id;
        if (record.deviceCategory == DeviceCategoryType.WATERMETER) {
            dispatch(deviceDetailsOpen({ record: modifiedRecord, visible: true }));
        } else if (record.deviceCategory == DeviceCategoryType.WEATHER) {
            dispatch(deviceWeatherStateDetailsOpen({ record: modifiedRecord, visible: true }));
        } else if (record.deviceCategory == DeviceCategoryType.ENERGYMETER) {
            dispatch(deviceEnergyDetailsOpen({ record: modifiedRecord, visible: true }));
        } else {
            dispatch(deviceLightDetailsOpen({ record: modifiedRecord, visible: true }));
        }
    };

    const showOnMap = (record: { [index: string]: any }) => {
        if (record.device.lat && record.device.lng) {
            dispatch(setMapPanTo({ lat: record.device.lat, lng: record.device.lng }));
        }
    };

    const showOnMapFilter = (item: any) => {
        if (filterDevice != item.device_id) {
            showOnMap(item);
            dispatch(filterDeviceDevices({ id: item.device_id }));
        } else {
            dispatch(filterReset());
        }
    };

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            {
                field: 'device_category',
                condition: 'in',
                value: categoryType.join(','),
            },
            { field: 'unread', condition: 'equals_bool', value: 1 },
        ];
    };

    useEffect(() => {
        // setRefresh(new Date());
        dispatch(refreshAlarmList());
    }, [typeCategory, showLightsOn, showWatermeterOn]);

    const getDeviceCategoryOptions: any = (categoryType: any) => {
        const aTypes = GetEnum('DEVICE_CATEGORY');
        let types: any = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key) && categoryType.includes(key)) {
                let ret: any = { label: undefined, value: undefined };
                ret.value = aTypes[key];
                ret.label = geti18nText('app.enum.DEVICE_CATEGORY.' + key);
                types.push(ret);
            }
        }
        setDeviceCategoryOptions(types);
    };

    function onTypeCategoryChange(val: any) {
        setTypeCategory(val);
    }

    const alarmTypes: any = () => {
        const aTypes = GetEnum('ALARM_STATUS');
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.ALARM_STATUS.' + key);
                types.push(ret);
            }
        }
        return types;
    };

    const lightsAlarmTypes: any = () => {
        var aTypes = GetEnum('TERMINAL_NODE_ALARM');
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.TERMINAL_NODE_ALARM.' + key);
                types.push(ret);
            }
        }
        aTypes = GetEnum('LAMP_ALARM');
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.LAMP_ALARM.' + key);
                types.push(ret);
            }
        }
        return types;
    };
    function getAlarmFilter() {
        if (typeCategory.includes(DeviceCategoryType.WATERMETER) && !typeCategory.includes(DeviceCategoryType.LIGHTS)) {
            return alarmTypes();
        }
        if (!typeCategory.includes(DeviceCategoryType.WATERMETER) && typeCategory.includes(DeviceCategoryType.LIGHTS)) {
            return lightsAlarmTypes();
        }

        return [];
    }

    const editDescription = (deviceId: string, data?: { [index: string]: any }, deviceType?: any) => {
        dispatch(
            alarmDescriptionOpen({
                deviceId: deviceId,
                data: data,
                visible: true,
                deviceAlarmListRefresh: 0,
                deviceType: deviceType,
            })
        );
    };

    useEffect(() => {
        dispatch(refreshAlarmList());
    }, [mapSelectedBounds]);

    const alarmEnumName: any = (status: number, category: string, type: number) => {
        if (category == DeviceCategoryType.WATERMETER) {
            return geti18nText('app.enum.ALARM_STATUS.' + GetEnumNameForValue('ALARM_STATUS', status));
        } else {
            if (type == 1) {
                return geti18nText(
                    'app.enum.TERMINAL_NODE_ALARM.' + GetEnumNameForValue('TERMINAL_NODE_ALARM', status)
                );
            } else {
                return geti18nText('app.enum.LAMP_ALARM.' + GetEnumNameForValue('LAMP_ALARM', status));
            }
        }
    };

    const columns: any = (modal: boolean) => {
        return [
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.alarm.table.name')} />,
                dataIndex: 'deviceName',
                sorter: (a: string, b: string) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    if ('comment' in record.device) {
                        return (
                            <div>
                                {record.deviceName}
                                <Tooltip title={record.device.comment}>
                                    <CommentOutlined
                                        style={{
                                            fontSize: '16px',
                                            marginLeft: '10px',
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        );
                    } else {
                        return <div> {record.deviceName} </div>;
                    }
                },
                ...getColumnSearch('string'),
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.alarm.table.serial')} />,
                dataIndex: 'deviceEui',
                sorter: (a: string, b: string) => {},
                ...getColumnSearch('string'),
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.alarm.table.address')} />,
                dataIndex: 'contactAddress',
                sorter: (a: string, b: string) => {},
                ...getColumnSearch('string'),
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.alarm.table.waterusage')} />,
                dataIndex: 'deviceVolumeVolume',
                align: 'right',
                sorter: (a: string, b: string) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.deviceVolume?.deviceCategory == DeviceCategoryType.WATERMETER) {
                        return (
                            <div>
                                {record.deviceVolume?.volume
                                    ? NyUtils.formatNumber(record.deviceVolume?.volume, 3)
                                    : '0,0'}
                                &nbsp;m3
                            </div>
                        );
                    }
                    return <FormatNumber value={record.deviceVolume?.volume}></FormatNumber>;
                },
            },
            typeCategory.length == 0 ||
            (typeCategory.includes(DeviceCategoryType.LIGHTS) && typeCategory.includes(DeviceCategoryType.WATERMETER))
                ? {
                      title: <ColumnTitle text={geti18nText('dashboard.widget.alarm.table.type')} />,
                      dataIndex: 'alarmStatus',
                      sorter: (a: string, b: string) => {},
                      render: (text: string, record: { [index: string]: any }) => {
                          return (
                              <div>
                                  <b style={{ color: 'red' }}>
                                      {alarmEnumName(record.alarmStatus, record.deviceCategory, record.typeAlarm)}
                                  </b>
                              </div>
                          );
                      },
                  }
                : {
                      title: <ColumnTitle text={geti18nText('dashboard.widget.alarm.table.type')} />,
                      dataIndex: 'alarmStatus',
                      sorter: (a: string, b: string) => {},
                      render: (text: string, record: { [index: string]: any }) => {
                          return (
                              <div>
                                  <b style={{ color: 'red' }}>
                                      {alarmEnumName(record.alarmStatus, record.deviceCategory, record.typeAlarm)}
                                  </b>
                              </div>
                          );
                      },
                      ...getColumnSearchOption(getAlarmFilter()),
                  },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.alarm.table.lastactivity')} />,
                dataIndex: 'deviceVolumeTs',
                sorter: (a: string, b: string) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.deviceVolumeTs) {
                        const ts: Date = new Date(record.deviceVolumeTs);
                        return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                    }
                },
            },
            {
                dataIndex: 'operation',
                width: '10%',
                render: (text: string, record: { [index: string]: any }) => {
                    return (
                        <React.Fragment>
                            <Tooltip title={geti18nText('dashboard.widget.alarm.table.description.tooltip')}>
                                {record.description ? (
                                    <FormOutlined
                                        style={{
                                            fontSize: '18px',
                                            marginRight: '10px',
                                            color: '#40a9ff',
                                        }}
                                        onClick={() =>
                                            editDescription(
                                                record.alarm_id,
                                                {
                                                    description: record.description,
                                                },
                                                record.deviceCategory
                                            )
                                        }
                                    />
                                ) : (
                                    <FormOutlined
                                        style={{
                                            fontSize: '18px',
                                            marginRight: '10px',
                                        }}
                                        onClick={() =>
                                            editDescription(
                                                record.alarm_id,
                                                {
                                                    description: record.description,
                                                },
                                                record.deviceCategory
                                            )
                                        }
                                    />
                                )}
                            </Tooltip>
                            {record.device_id && record.device.lat && record.device.lng && (
                                <Tooltip title={geti18nText('dashboard.widget.watermeter.table.showonmap.tooltip')}>
                                    {filterDevice === record.device_id ? (
                                        <ZoomInOutlined
                                            style={{ fontSize: '18px', color: 'red', marginRight: '10px' }}
                                            onClick={() => showOnMapFilter(record)}
                                        />
                                    ) : (
                                        <ZoomInOutlined
                                            style={{ fontSize: '18px', marginRight: '10px' }}
                                            onClick={() => showOnMapFilter(record)}
                                        />
                                    )}
                                </Tooltip>
                            )}
                        </React.Fragment>
                    );
                },
            },
            {
                dataIndex: 'unread',
                sorter: (a: string, b: string) => {},
                width: '5%',
                ...getColumnSearchOption(
                    getUnreadAlarmFilterType(),
                    'equals_bool',
                    setDefaultFilterValue()[0]['value']
                ),
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.unread == true) {
                        return (
                            <Popconfirm
                                title={geti18nText('dashboard.widget.alarm.table.unread.btn.msg')}
                                onConfirm={() => {
                                    onUnreadClick(record);
                                }}
                            >
                                <Tag color="red">{geti18nText('dashboard.widget.alarm.table.unread.btn')}</Tag>
                            </Popconfirm>
                        );
                    }
                },
            },
        ];
    };

    function getCsvColumns() {
        return [
            {
                title: geti18nText('dashboard.widget.alarm.table.name'),
                dataIndex: 'deviceName',
            },
            {
                title: geti18nText('dashboard.widget.alarm.table.serial'),
                dataIndex: 'deviceEui',
            },
            {
                title: geti18nText('dashboard.widget.alarm.table.address'),
                dataIndex: 'contact.address',
            },
            {
                title: geti18nText('dashboard.widget.alarm.table.waterusage'),
                dataIndex: 'deviceVolume',
            },
            {
                title: geti18nText('dashboard.widget.alarm.table.type'),
                dataIndex: 'alarm',
            },
            {
                title: geti18nText('dashboard.widget.alarm.table.lastactivity'),
                dataIndex: 'deviceVolumeTs',
            },
        ];
    }

    const csvCustomizationColumns: any = () => {
        return [
            {
                deviceVolumeTs: (value: any) => {
                    if (value) {
                        const ts: Date = new Date(value);
                        return ts.toLocaleString(NyUtils.getSelectedLocale());
                    }

                    return '';
                },
            },
            {
                deviceVolume: (value: any) => {
                    if (value.deviceCategory == DeviceCategoryType.WATERMETER) {
                        return value.volume ? NyUtils.formatNumber(value.volume, 3) + ' m3' : '0,0 m3';
                    }
                    const val = formatNumberValueString(value.volume, true);
                    return val;
                },
            },
            {
                alarm: (value: any) => {
                    if (value && value.alarmStatus != undefined) {
                        return alarmEnumName(value.alarmStatus, value.deviceCategory, value.typeAlarm);
                    }
                    return '';
                },
            },
        ];
    };

    const onUnreadClick = (record: any) => {
        const url = record.deviceCategory != 'LIGHTS' ? CONSTANTS_REQ.ALARM.READ : CONSTANTS_REQ.ALARM.LIGHT_READ;
        NyRequestResolver.requestPut(url + '/' + record.id, undefined, {
            id: record.id,
            category: record.deviceCategory,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                dispatch(refreshAlarmList());
                dispatch(refreshMenu());
            }
        });
    };

    const onUnreadAllClick = () => {
        console.log(categoryType);

        NyRequestResolver.requestPost(CONSTANTS_REQ.ALARM.READ_ALL_BY_CATEGORY, undefined, {
            categories: categoryType,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                dispatch(refreshAlarmList());
                dispatch(refreshMenu());
            }
        });
    };

    const onLoadData = (data: { [index: string]: any } | string, params: { [index: string]: any } | undefined) => {
        setShowAlarmBtn(data != undefined && data.length > 0 ? true : false);
        if (data != undefined && data.length > 0) {
            const items: any = data;
            const firstItem = items[0];
            if (firstItem.unread == false) {
                setShowAlarmBtn(false);
            }
        }
    };

    return (
        <React.Fragment>
            <FullscreenOutlined
                style={{ position: 'absolute', right: '5px', top: '5px', zIndex: 2 }}
                onClick={() => setVisible(true)}
            />
            <>
                {showAlarmBtn && (
                    <Row gutter={24} style={{ float: 'right' }}>
                        <Popconfirm
                            title={geti18nText('dashboard.widget.alarm.table.unread_all.btn.msg')}
                            onConfirm={() => {
                                onUnreadAllClick();
                            }}
                        >
                            <Button className="margin-right" danger style={{ right: '25px', top: '5px' }}>
                                {geti18nText('dashboard.widget.alarm.table.unread_all.btn')}
                            </Button>
                        </Popconfirm>
                    </Row>
                )}
                {showFilter == true && !showOnlyCategory && (
                    <Row gutter={24}>
                        <Form.Item style={{ marginLeft: '18px' }} label={geti18nText('deviceModel.table.filter')}>
                            <div>
                                <Col offset={1} span={24}>
                                    <Checkbox.Group
                                        onChange={onTypeCategoryChange}
                                        value={typeCategory}
                                        options={deviceCategoryOptions}
                                    />
                                </Col>
                            </div>
                        </Form.Item>
                    </Row>
                )}

                <NyDataTable
                    headerTitle={geti18nText('dashboard.widget.alarm.table.title')}
                    url={CONSTANTS_REQ.DASHBOARD.ALARMS}
                    hideButtons={true}
                    exportCSV={true}
                    scroll={getScrolls(dataGrid)}
                    fetchWhenChange={alarmListRefresh}
                    setDefaultFilterValue={setDefaultFilterValue}
                    onRowSelect={onRowSelect}
                    onDataLoaded={onLoadData}
                    addedData={
                        mapSelectedBounds != null
                            ? {
                                  latNorthEast: mapSelectedBounds.latNorthEast,
                                  lngNorthEast: mapSelectedBounds.lngNorthEast,
                                  latSouthWest: mapSelectedBounds.latSouthWest,
                                  lngSouthWest: mapSelectedBounds.lngSouthWest,
                              }
                            : undefined
                    }
                    columns={columns()}
                    csvColumns={getCsvColumns()}
                    colCSVCustomization={csvCustomizationColumns()}
                />
            </>
            <Modal
                title={geti18nText('dashboard.widget.alarm.table.title')}
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={'90%'}
                centered={true}
                footer={null}
            >
                {showAlarmBtn && (
                    <Row gutter={24} style={{ float: 'right' }}>
                        <Popconfirm
                            title={geti18nText('dashboard.widget.alarm.table.unread_all.btn.msg')}
                            onConfirm={() => {
                                onUnreadAllClick();
                            }}
                        >
                            <Button className="margin-right" danger style={{ right: '25px', top: '5px' }}>
                                {geti18nText('dashboard.widget.alarm.table.unread_all.btn')}
                            </Button>
                        </Popconfirm>
                    </Row>
                )}
                <NyDataTable
                    headerTitle={geti18nText('dashboard.widget.alarm.table.title')}
                    url={CONSTANTS_REQ.DASHBOARD.ALARMS}
                    hideButtons={true}
                    exportCSV={true}
                    fetchWhenChange={alarmListRefresh}
                    scroll={getScrolls(dataGrid)}
                    setDefaultFilterValue={setDefaultFilterValue}
                    onRowSelect={onRowSelect}
                    columns={columns()}
                    csvColumns={getCsvColumns()}
                    colCSVCustomization={csvCustomizationColumns()}
                    onDataLoaded={onLoadData}
                />
            </Modal>
        </React.Fragment>
    );
};

export default WidgetAlarms;
