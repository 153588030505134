import React from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import ConsumersEdit from './edit';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';

const ConsumersIndex = () => {
    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    return (
        <NyDataTable
            headerTitle={geti18nText('consumer.table.header')}
            url={CONSTANTS_REQ.END_CUSTOMER.LIST}
            addNewButtonText={geti18nText('consumer.table.add')}
            showRecordModal={true}
            exportCSV={true}
            scroll={{ x: 800 }}
            modalComponent={ConsumersEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            columns={[
                {
                    title: geti18nText('consumer.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('consumer.table.column.firstName'),
                    dataIndex: 'firstName',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('consumer.table.column.lastName'),
                    dataIndex: 'lastName',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('consumer.table.column.oib'),
                    dataIndex: 'oib',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('consumer.table.column.code'),
                    dataIndex: 'code',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                ...(NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'])
                    ? [
                          {
                              title: geti18nText('application.table.customer'),
                              dataIndex: ['customer', 'name'],
                              sorter: (a: any, b: any) => {},
                              ...getColumnSearch('string'),
                          },
                      ]
                    : []),
                {
                    title: geti18nText('consumer.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default ConsumersIndex;
