import React, { useEffect, useState } from 'react';
import { Typography, Button, Modal, Col, Form, Row, DatePicker } from 'antd';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Legend, Tooltip, Bar, Label } from 'recharts';
import { FullscreenOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySearchField, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';
import { CONSTANTS_REQ, DeviceCategoryType } from '../../../utils/Constants';
import ChartDataVolumes from '../../device-details/ChartDataVolumes';
import ChartDataConsumption from './ChartDataConsumption';
import { Line } from 'recharts';
import { VictoryChart, VictoryZoomContainer, VictoryLine, VictoryAxis, VictoryLabel, VictoryLegend } from 'victory';
import moment from 'moment';

const { Title } = Typography;

interface IZoomChart {
    loading: boolean | null;
    deviceId?: number;
    parm: string;
}

const ZoomChart: React.FC<IZoomChart> = ({ loading, deviceId, parm }) => {
    const [data, setData] = useState<any>({
        title: geti18nText('deviceDetails.graph.light.consumption.' + parm),
        'y-title': geti18nText('deviceDetails.graph.light.consumption.y.' + parm),
        color: '#FFD700',
    });
    const [settings, setSettings] = useState<any>();
    const [periodValue, setPeriodValue] = useState<any>({
        id: '14',
        text: '14 ' + geti18nText('deviceDetails.graph.light.consumption.y.period.days'),
    });
    const [showRange, setShowRange] = useState(false);
    const [rangeValue, setRangeValue] = useState<any>(null);
    const [timestampFrom, setTimestampFrom] = useState<any>(null);
    const [timestampTo, setTimestampTo] = useState<any>(null);

    useEffect(() => {
        setSettings({
            title: geti18nText('deviceDetails.graph.light.consumption.' + parm),
            'y-title': geti18nText('deviceDetails.graph.light.consumption.y.' + parm),
            color: '#FFD700',
        });
        fetchData();
    }, [parm, periodValue, rangeValue]);

    const fetchData = () => {
        setData(null);
        if (deviceId != null && parm != null) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHART_LIGHTS_VOLUME, {
                period: periodValue?.id,
                lang: NyUtils.getSelectedLanguage(),
                deviceId: deviceId,
                parName: parm,
                timestampFrom: timestampFrom,
                timestampTo: timestampTo,
            }).then((response: any) => {
                if (response.status === RESPONSE.OK) {
                    var arry: any = [];
                    response.data.forEach((element: any) => {
                        let ts = new Date(
                            element.ts[0],
                            element.ts[1] - 1,
                            element.ts[2],
                            element.ts[3],
                            element.ts[4] ? element.ts[4] : 0,
                            element.ts[5] ? element.ts[5] : 0
                        );
                        arry.push({
                            volume: element.volume,
                            converted: ts,
                        });
                    });

                    const maxX = new Date(
                        Math.max.apply(
                            Math,
                            arry.map((el: any) => {
                                return new Date(el.converted);
                            })
                        )
                    );
                    const minX = new Date(
                        Math.min.apply(
                            Math,
                            arry.map((el: any) => {
                                return new Date(el.converted);
                            })
                        )
                    );

                    setXDomain([minX, new Date(maxX.setHours(maxX.getHours() + 6))]);

                    const maxY = Math.max.apply(
                        Math,
                        arry.map((el: any) => {
                            return el.volume;
                        })
                    );
                    const minY = Math.min.apply(
                        Math,
                        arry.map((el: any) => {
                            return el.volume;
                        })
                    );
                    if (minY < 0) {
                        setYDomain([minY + 0.1 * minY, maxY > 0 ? maxY + 0.1 * maxY : 0]);
                    } else {
                        setYDomain([minY - 0.1 * minY, maxY + 0.1 * maxY]);
                    }

                    setData(arry);
                }
            });
        }
    };

    const period = [
        { id: 'today', text: geti18nText('deviceDetails.graph.light.consumption.y.period.today') },
        { id: '24h', text: geti18nText('deviceDetails.graph.light.consumption.y.period.24h') },
        { id: '3', text: '3 ' + geti18nText('deviceDetails.graph.light.consumption.y.period.days') },
        { id: '7', text: '7 ' + geti18nText('deviceDetails.graph.light.consumption.y.period.days') },
        { id: '14', text: '14 ' + geti18nText('deviceDetails.graph.light.consumption.y.period.days') },
        { id: '30', text: '30 ' + geti18nText('deviceDetails.graph.light.consumption.y.period.days') },
        { id: 'span', text: geti18nText('deviceDetails.graph.light.consumption.y.period.range') },
    ];

    function periodOnChange(val: any) {
        if (val.id === 'span') {
            setPeriodValue(val);
            setShowRange(true);
        } else if (val.id === -1) {
            setPeriodValue({
                id: '14',
                text: '14 ' + geti18nText('deviceDetails.graph.light.consumption.y.period.days'),
            });
            setShowRange(false);
            setRangeValue(null);
        } else {
            setPeriodValue(val);
            setShowRange(false);
            setRangeValue(null);
        }
    }

    function onRangeChange(dates: any) {
        if (dates != null) {
            setTimestampFrom(dates[0] ? moment(dates[0]).valueOf() : null);
            setTimestampTo(dates[1] ? moment(dates[1]).valueOf() : null);
            setRangeValue(dates);
        } else {
            setPeriodValue({
                id: '14',
                text: '14 ' + geti18nText('deviceDetails.graph.light.consumption.y.period.days'),
            });
            setTimestampFrom(null);
            setTimestampTo(null);
            setRangeValue(dates);
            setShowRange(false);
        }
    }

    const [visible, setVisible] = useState(false);
    const [visibleDataVolumes, setVisibleDataVolumes] = useState(false);
    const [currenDate, setCurrentDate] = useState<string>('');
    const [xDomain, setXDomain] = useState<any>(null);
    const [yDomain, setYDomain] = useState<any>(null);

    return (
        <div className="box" style={{ height: '95%' }}>
            <Title style={{ fontSize: '1.4em', width: '75%' }}>{settings && settings['title']}</Title>
            <Form.Item
                rules={[{ required: true, message: geti18nText('app.default.required') }]}
                name="period"
                label={geti18nText('deviceDetails.graph.light.consumption.period')}
            >
                <Row>
                    <Col span={8}>
                        <NySearchField
                            value={periodValue}
                            onChange={periodOnChange}
                            searchBy={'name'}
                            style={{ width: '90%' }}
                            options={period}
                        />
                    </Col>
                    <Col hidden={!showRange} span={10}>
                        <FormatedRangePicker
                            value={rangeValue}
                            showTime={true}
                            onChange={onRangeChange}
                        ></FormatedRangePicker>
                    </Col>
                    <Col>
                        {deviceId && (
                            <Button
                                //style={{ position: 'absolute', right: '50px', top: '20px' }}
                                shape="circle"
                                onClick={() => setVisibleDataVolumes(true)}
                                icon={<UnorderedListOutlined />}
                            />
                        )}
                        <Button
                            style={{ marginLeft: '5px' }}
                            shape="circle"
                            onClick={() => setVisible(true)}
                            icon={<FullscreenOutlined />}
                        />
                    </Col>
                </Row>
            </Form.Item>

            <ResponsiveContainer width="100%" height={250}>
                {loading ? (
                    <NySpinner />
                ) : (
                    <VictoryChart
                        height={470}
                        scale={{ x: 'time' }}
                        domain={{ y: yDomain }}
                        containerComponent={<VictoryZoomContainer zoomDimension="x" />}
                    >
                        <VictoryAxis
                            domain={xDomain}
                            tickFormat={(tick, index, tickets) => {
                                const date1 = tickets[0];
                                const date2 = tickets[tickets.length - 1];
                                var sameDay = false;
                                setCurrentDate('');
                                if (date1 && date2) {
                                    if (
                                        date1.getFullYear() === date2.getFullYear() &&
                                        date1.getMonth() === date2.getMonth() &&
                                        date1.getDate() === date2.getDate()
                                    ) {
                                        sameDay = true;
                                        setCurrentDate(tick.toLocaleDateString('hr-HR'));
                                    }
                                }

                                return sameDay == true
                                    ? new Date(tick).toLocaleTimeString('hr-HR', {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                      })
                                    : tick.toLocaleDateString('hr-HR');
                            }}
                            style={{
                                axisLabel: { fontSize: 12 },
                                tickLabels: { fontSize: 12 },
                            }}
                            label={currenDate}
                        />

                        <VictoryAxis
                            dependentAxis
                            label={settings && settings['y-title']}
                            style={{
                                axisLabel: { fontSize: 12, margin: 10 },
                                tickLabels: { fontSize: 10, marginLeft: 10 },
                            }}
                            tickFormat={(tick) => {
                                // if (parm == 'dimming_value') {
                                if (tick < 0) {
                                    if (tick == -1) return 'OFF';
                                    return '';
                                }
                                //}

                                return tick;
                            }}
                        />

                        <VictoryLine
                            style={{
                                data: { stroke: settings && settings['color'] },
                            }}
                            data={data}
                            x="converted"
                            y="volume"
                        />

                        <VictoryLegend
                            y={0}
                            orientation="horizontal"
                            titleOrientation="left"
                            gutter={20}
                            style={{ labels: { color: settings && settings['color'] } }}
                            data={[
                                {
                                    name: settings && settings['y-title'],
                                    symbol: { fill: settings && settings['color'], type: 'square' },
                                    labels: { fill: settings && settings['color'] },
                                },
                            ]}
                        />
                    </VictoryChart>
                )}
            </ResponsiveContainer>

            <Modal
                title={settings && settings['title']}
                visible={visible}
                zIndex={1010}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={'90%'}
                centered={true}
            >
                <ResponsiveContainer width="100%" height={450}>
                    <VictoryChart
                        height={400}
                        scale={{ x: 'time' }}
                        domain={{ y: yDomain }}
                        containerComponent={<VictoryZoomContainer zoomDimension="x" />}
                    >
                        <VictoryAxis
                            domain={xDomain}
                            tickFormat={(tick, index, tickets) => {
                                const date1 = tickets[0];
                                const date2 = tickets[tickets.length - 1];
                                var sameDay = false;
                                setCurrentDate('');
                                if (date1 && date2) {
                                    if (
                                        date1.getFullYear() === date2.getFullYear() &&
                                        date1.getMonth() === date2.getMonth() &&
                                        date1.getDate() === date2.getDate()
                                    ) {
                                        sameDay = true;
                                        setCurrentDate(tick.toLocaleDateString(NyUtils.getSelectedLanguage()));
                                    }
                                }

                                return sameDay == true
                                    ? new Date(tick).toLocaleTimeString(NyUtils.getSelectedLanguage(), {
                                          hour: '2-digit',
                                          minute: '2-digit',
                                      })
                                    : tick.toLocaleDateString(NyUtils.getSelectedLanguage());
                            }}
                            style={{
                                axisLabel: { fontSize: 12 },
                                tickLabels: { fontSize: 12 },
                            }}
                            label={currenDate}
                        />

                        <VictoryAxis
                            dependentAxis
                            label={settings && settings['y-title']}
                            style={{
                                axisLabel: { fontSize: 12, margin: 10 },
                                tickLabels: { fontSize: 10, marginLeft: 10 },
                            }}
                            tickFormat={(tick) => {
                                if (parm == 'dimming_value') {
                                    if (tick < 0) {
                                        if (tick == -1) return 'OFF';
                                        return '';
                                    }
                                }

                                return tick;
                            }}
                        />

                        <VictoryLine
                            style={{
                                data: { stroke: settings && settings['color'] },
                            }}
                            data={data}
                            x="converted"
                            y="volume"
                        />

                        <VictoryLegend
                            y={0}
                            orientation="horizontal"
                            titleOrientation="left"
                            gutter={20}
                            style={{ labels: { color: settings && settings['color'] } }}
                            data={[
                                {
                                    name: settings && settings['y-title'],
                                    symbol: { fill: settings && settings['color'], type: 'square' },
                                    labels: { fill: settings && settings['color'] },
                                },
                            ]}
                        />
                    </VictoryChart>
                </ResponsiveContainer>
            </Modal>
            {deviceId && (
                <Modal
                    visible={visibleDataVolumes}
                    zIndex={1010}
                    onCancel={() => setVisibleDataVolumes(false)}
                    width={'80%'}
                    centered={true}
                    footer={null}
                >
                    <ChartDataConsumption
                        title={settings && settings.title}
                        deviceId={deviceId}
                        period={{ period: 'span' }}
                        dateFrom={xDomain && xDomain[0] ? moment(xDomain[0]).valueOf() : null}
                        dateTo={xDomain && xDomain[1] ? moment(xDomain[1]).valueOf() : null}
                    />
                </Modal>
            )}
        </div>
    );
};

export default ZoomChart;

export const FormatedRangePicker = (props: any) => {
    const FORMAT_DATE_TIME_HR = 'DD.MM.YYYY HH:mm:ss';
    const FORMAT_DATE_TIME_EN = 'MM/DD/YYYY, HH:mm:ss';
    const FORMAT_DATE_EN = 'MM/DD/YYYY';
    const FORMAT_DATE_HR = 'DD.MM.YYYY';
    const { RangePicker } = DatePicker;

    function setFormat(showTime: boolean) {
        if (showTime) {
            if (NyUtils.getSelectedLocale() == 'hr-HR') return FORMAT_DATE_TIME_HR;
            else return FORMAT_DATE_TIME_EN;
        } else {
            if (NyUtils.getSelectedLocale() == 'hr-HR') return FORMAT_DATE_HR;
            else return FORMAT_DATE_EN;
        }
    }

    return (
        <RangePicker
            value={props.value}
            showTime={props.showTime}
            format={setFormat(props.showTime != null ? props.showTime : false)}
            onChange={props.onChange}
        />
    );
};
