import React, { useState } from 'react';
import { CONSTANTS_REQ, DeviceCategoryType } from '../../utils/Constants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import CategoryEdit from './edit';
import { Tag } from 'antd';
import { parametarKeyName } from '../../utils/Utils';

const CategoryIndex = (props: any) => {
    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const columns = [
        {
            title: geti18nText('category.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },

        {
            title: geti18nText('category.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('category.table.column.parent'),
            dataIndex: ['parent', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('category.table.column.parameters'),
            dataIndex: 'parameters',
            render: (text: any, record: any) => {
                if (record.parameters) {
                    let array = record[parametarKeyName('parameters')].split(',');

                    return (
                        <div>
                            {array.map((x: any) => {
                                return <Tag color="blue">{x}</Tag>;
                            })}
                        </div>
                    );
                }
            },
        },
        {
            title: geti18nText('category.table.column.ord'),
            dataIndex: 'ord',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('category.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
            defaultFilteredValue: setDefaultFilterValue(),
        },
    ];

    return (
        <>
            <NyDataTable
                headerTitle={geti18nText('category.table.header')}
                url={CONSTANTS_REQ.CATEGORY.LIST}
                addNewButtonText={geti18nText('category.table.add')}
                modalComponent={CategoryEdit}
                showRecordModal={true}
                exportCSV={true}
                scroll={{ x: 500 }}
                setDefaultFilterValue={setDefaultFilterValue}
                columns={columns}
                setDefaultPageSize={20}
            />
        </>
    );
};

export default CategoryIndex;
