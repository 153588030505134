import { geti18nText } from '@nybble/nyreact';
import { Tabs, Row, Col } from 'antd';
import React from 'react';
import EmailSettingsIndex from './emailSettings';
const { TabPane } = Tabs;

const ApplicationSettingsIndex = () => {
    return (
        <Tabs type="card">
            <TabPane tab={geti18nText('app.setting.mail')} key="1">
                <Row>
                    <Col span={24}>
                        <EmailSettingsIndex />
                    </Col>
                </Row>
            </TabPane>
        </Tabs>
    );
};

export default ApplicationSettingsIndex;
