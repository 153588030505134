import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, List, Card, Statistic } from 'antd';
import { CONSTANTS_REQ } from '../../utils/Constants';
import Chart from '../device-details/Chart';
import { HomeOutlined, PhoneOutlined, MailOutlined, LineChartOutlined } from '@ant-design/icons';
import ChartSpan from './ChartSpan';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { geti18nText, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';

const { Title } = Typography;

const DeviceDetail = () => {
    const { item } = useSelector((state: RootState) => state.selectedItem);

    const [last24hData, setLast24hData] = useState<any>(null);
    const [currentWeekData, setCurrentWeekData] = useState<any>(null);
    const [lastWeekData, setLastWeekData] = useState<any>(null);
    const [currentMonthData, setCurrentMonthData] = useState<any>(null);
    const [currentYearData, setCurrentYearData] = useState<any>(null);
    const [contactData, setContactData] = useState<any>(null);
    const [widgetData, setWidgetData] = useState<any>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchLast24hValue(item);
        fetchCurrentWeekData(item);
        fetchLastWeekData(item);
        fetchCurrentMonthData(item);
        fetchCurrentYearData(item);
        fetchContactValue(item);
        fetchWidgetValue(item);
    }, []);

    const fetchLast24hValue = (value: any) => {
        setLast24hData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_CONSUMPTION, {
            period: 'last-24h',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: value,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setLast24hData(response.data);
            }
        });
    };

    const fetchCurrentWeekData = (value: any) => {
        setCurrentWeekData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_CONSUMPTION, {
            period: 'current-week',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: value,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setCurrentWeekData(response.data);
            }
        });
    };

    const fetchLastWeekData = (value: any) => {
        setLastWeekData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_CONSUMPTION, {
            period: 'last-week',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: value,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setLastWeekData(response.data);
            }
        });
    };

    const fetchCurrentMonthData = (value: any) => {
        setCurrentMonthData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_CONSUMPTION, {
            period: 'current-month',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: value,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setCurrentMonthData(response.data);
            }
        });
    };

    const fetchCurrentYearData = (value: any) => {
        setCurrentYearData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_CONSUMPTION, {
            period: 'current-year',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: value,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setCurrentYearData(response.data);
            }
        });
    };

    const fetchContactValue = (value: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CONTACT_DATA_FOR_DEVICE + '/' + value).then(
            (response: any) => {
                if (response.status === RESPONSE.OK) {
                    setContactData(response.data);
                }
            }
        );
    };

    const fetchWidgetValue = (value: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CONSUMPTION_FOR_TODAY_AND_CURRENT_WEEK_FOR_DEVICE, {
            deviceId: value,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setWidgetData(response.data);
            }
            setLoading(false);
        });
    };

    return (
        <React.Fragment>
            <Row>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <div className="box" style={{ marginRight: '10px', height: '95%' }}>
                        <Card style={{ height: '45%' }}>
                            <Statistic
                                title={geti18nText('deviceDetails.widget1.title')}
                                value={widgetData != null ? NyUtils.formatNumber(widgetData['today'], 2) : ' '}
                                precision={2}
                                valueStyle={{ color: '#1890ff', fontSize: '24px', fontWeight: 'bold' }}
                                prefix={<LineChartOutlined />}
                                suffix="m³"
                            />
                        </Card>
                        <Card style={{ marginTop: '10px', height: '45%' }}>
                            <Statistic
                                title={geti18nText('deviceDetails.widget2.title')}
                                value={widgetData != null ? NyUtils.formatNumber(widgetData['current-week'], 2) : ' '}
                                precision={2}
                                valueStyle={{ color: '#1890ff', fontSize: '24px', fontWeight: 'bold' }}
                                prefix={<LineChartOutlined />}
                                suffix="m³"
                            />
                        </Card>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <div className="box" style={{ marginRight: '10px', height: '95%' }}>
                        <Title level={4}>{geti18nText('deviceDetails.contact.title')}</Title>
                        <List.Item>
                            <List.Item.Meta
                                title={
                                    <b>
                                        <HomeOutlined /> {geti18nText('deviceDetails.contact.address')}
                                    </b>
                                }
                                description={contactData != null && contactData.address}
                            />
                        </List.Item>
                        <List.Item>
                            <List.Item.Meta
                                title={
                                    <b>
                                        <PhoneOutlined /> {geti18nText('deviceDetails.contact.phone')}
                                    </b>
                                }
                                description={contactData != null && contactData.phone}
                            />
                        </List.Item>
                        <List.Item>
                            <List.Item.Meta
                                title={
                                    <b>
                                        <MailOutlined /> {geti18nText('deviceDetails.contact.email')}
                                    </b>
                                }
                                description={contactData != null && contactData.email}
                            />
                        </List.Item>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <Chart
                        loading={loading}
                        data={last24hData}
                        title={geti18nText('report.time_range.last24h')}
                        deviceId={item}
                        period="last-24h"
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <Chart
                        loading={loading}
                        data={currentWeekData}
                        title={geti18nText('report.time_range.current_week')}
                        deviceId={item}
                        period="current-week"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <Chart
                        loading={loading}
                        data={lastWeekData}
                        title={geti18nText('report.time_range.last_week')}
                        deviceId={item}
                        period="last-week"
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <Chart
                        loading={loading}
                        data={currentMonthData}
                        title={geti18nText('report.time_range.current_month')}
                        deviceId={item}
                        period="current-month"
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <Chart
                        loading={loading}
                        data={currentYearData}
                        title={geti18nText('report.time_range.current_year')}
                        deviceId={item}
                        period="current-year"
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <ChartSpan />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default DeviceDetail;
