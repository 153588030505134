import { geti18nText, NyDataEdit, NySearchField } from '@nybble/nyreact';
import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, Row, Tabs } from 'antd';
import { RuleObject } from 'antd/lib/form';
import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { selectedCustomerSet } from '../../slices/selectedCustomerSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { GetEnum } from '../../utils/Enums';
import SettingsEdit from './SettingsEdit';
import UserTable from './UserTable';

const { TabPane } = Tabs;

const CustomersEdit = (props: any) => {
    const dispatch = useDispatch();

    const [editHeader, setEditHeader] = useState(geti18nText('customer.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState<any>(null);
    const [isCreate, setIsCreate] = useState(false);
    const [categoryType, setCategoryType] = useState<any>([]);
    const [modalVisible, setModalVisible] = useState(false);
    const mapRef = useRef<L.Map>();

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const { setFieldsValue } = form;

    useEffect(() => {
        if (dataForm != null) {
            console.log(dataForm.categoryType);
            dispatch(selectedCustomerSet({ customer: dataForm.id, categoryType: dataForm.categoryType }));
        }
    }, [dataForm]);

    function setValues(dataForm: any) {
        setEditHeader(dataForm.id);
        if (dataForm.categoryType) {
            dataForm.catType = dataForm.categoryType.split(',');
            setCategoryType(dataForm.catType);
        }

        setDataForm(dataForm);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('customer.edit.new'));
    };

    const getGlobalCoordinates = () => {
        setFieldsValue({
            lngFrom: mapRef.current?.getBounds().getNorthEast().lng,
            lngTo: mapRef.current?.getBounds().getSouthWest().lng,
            latFrom: mapRef.current?.getBounds().getNorthEast().lat,
            latTo: mapRef.current?.getBounds().getSouthWest().lat,
        });
        setModalVisible(false);
    };

    const deviceCategoryEnum = () => {
        const aTypes = GetEnum('DEVICE_CATEGORY');
        let types: any = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret: any = {};
                ret.value = aTypes[key];
                ret.label = geti18nText('app.enum.DEVICE_CATEGORY.' + key);
                types.push(ret);
            }
        }
        return types;
    };

    function onChange(checkedValues: any) {
        setCategoryType(checkedValues);
    }

    const validation = (_: any, value: any) => {
        if (categoryType != null && categoryType.length > 0) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(geti18nText('app.default.required')));
    };

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.CUSTOMER.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            setIsCreate={setIsCreate}
            {...props}
            normalize={(values: any) => {
                if (values.catType) delete values.catType;
                return {
                    ...values,
                    country:
                        values.country != null && values.country.constructor === Number
                            ? { id: parseInt(values.country) }
                            : values.country,
                    categoryType: categoryType.join(','),
                };
            }}
        >
            <Tabs type="card">
                <TabPane tab={geti18nText('customer.tab.general')} key="1">
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={geti18nText('customer.edit.name')} name="name">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('customer.edit.country')} name="country">
                                <NySearchField
                                    url={CONSTANTS_REQ.COUNTRY.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('customer.edit.state')} name="state">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('customer.edit.city')} name="city">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('customer.edit.postalCode')} name="postalCode">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('customer.edit.address')} name="address">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('customer.edit.address2')} name="address2">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('customer.edit.email')} name="email">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('customer.edit.phone')} name="phone">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('customer.edit.longtitudeFrom')} name="lngFrom">
                                <InputNumber decimalSeparator="." />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('customer.edit.latitudeFrom')} name="latFrom">
                                <InputNumber decimalSeparator="." />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('customer.edit.longtitudeTo')} name="lngTo">
                                <InputNumber decimalSeparator="." />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('customer.edit.latitudeTo')} name="latTo">
                                <InputNumber decimalSeparator="." />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('customer.edit.device.type')}
                                required={true}
                                name="catType"
                                rules={[{ validator: validation }]}
                            >
                                <Checkbox.Group
                                    value={categoryType}
                                    options={deviceCategoryEnum()}
                                    onChange={onChange}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={12} offset={2}>
                            <Form.Item>
                                <Button type="primary" onClick={() => setModalVisible(true)}>
                                    {geti18nText('customer.edit.showMap')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('customer.tab.users')} key="2" disabled={isCreate || loading}>
                    <UserTable />
                </TabPane>
                <TabPane tab={geti18nText('customer.tab.settings')} key="3" disabled={isCreate || loading}>
                    <SettingsEdit />
                </TabPane>
            </Tabs>
            <Modal
                title={geti18nText('customer.edit.ModalTitle')}
                visible={modalVisible}
                onCancel={() => setModalVisible(false)}
                onOk={getGlobalCoordinates}
                destroyOnClose={true}
                okText={geti18nText('customer.edit.ModalOkText')}
                width={'800px'}
            >
                <MapContainer
                    center={[45.74497120551588, 16.10733032226563]}
                    bounds={[
                        form.getFieldValue('latFrom') != null && form.getFieldValue('lngFrom') != null
                            ? [form.getFieldValue('latFrom'), form.getFieldValue('lngFrom')]
                            : [46.29950860500902, 17.100219726562504],
                        form.getFieldValue('latTo') != null && form.getFieldValue('lngTo') != null
                            ? [form.getFieldValue('latTo'), form.getFieldValue('lngTo')]
                            : [45.34249365462379, 14.990844726562502],
                    ]}
                    zoom={8}
                    style={{ height: '500px', width: '100%' }}
                    ref={mapRef}
                >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </MapContainer>
            </Modal>
        </NyDataEdit>
    );
};

export default CustomersEdit;
