import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
    Row,
    Col,
    Input,
    Form,
    Tabs,
    InputNumber,
    Select,
    Slider,
    Card,
    Button,
    notification,
    Statistic,
    Spin,
    Modal,
    Descriptions,
} from 'antd';
import { geti18nText, NyDataEdit, NyRequestResolver, NySession, NyUtils, RESPONSE } from '@nybble/nyreact';
import { useSelector } from 'react-redux';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { ReactComponent as LightBulb } from '../../../assets/img/bulb-light.svg';
import { RootState } from '../../../rootReducer';
import SSE from './see';
import {
    ThunderboltOutlined,
    WarningOutlined,
    LineChartOutlined,
    BulbOutlined,
    FallOutlined,
    ReloadOutlined,
    LoadingOutlined,
    SettingOutlined,
} from '@ant-design/icons';
import LightParamSettings from './LightParamSettings';
import { formatNumberValueString } from '../../number-formatter';

const LightEdit = (props: any) => {
    const { record } = useSelector((state: RootState) => state.deviceLightDetails);
    const eventSourceRef = useRef<any>(null);
    const [snapshotData, setSnapshotData] = useState<any>(null);
    const intervalRef = useRef<any>(null);
    const [progressBar, setProgressBar] = useState<any>(false);
    const [visible, setVisible] = useState(false);
    const [dimmingValue, setDimmingValue] = useState(30);
    useEffect(() => {
        register(true);
    }, []);

    function register(init = false) {
        eventSourceRef.current = new SSE(
            CONSTANTS_REQ.DEVICE.LIGHT_STREAM + '/' + record.id + '?init=' + init + '&type=0',
            {
                headers: {
                    Authorization: 'Bearer ' + NySession.getUserToken(),
                },
            }
        );

        eventSourceRef.current.onmessage = (e: any) => {
            console.log('message ', e);
            const response = JSON.parse(e.data);
            const data = response.data;
            const error = response.error;
            const command = response.command;
            eventSourceRef.current?.close();
            if (data) {
                setSnapshotData(data);
                if (progressBar == true && command == true) {
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        description: geti18nText('deviceDetails.widget.light.command.success.msg'),
                        duration: 3,
                    });
                }

                setProgressBar(false);
                clearInterval(intervalRef.current);
            }
            if (error) {
                onError();
            }
        };

        eventSourceRef.current.onerror = (e: any) => {
            console.log('Error ', e);
            clearInterval(intervalRef.current);
            eventSourceRef.current?.close();
        };

        eventSourceRef.current.stream();
    }

    function onError() {
        setProgressBar(false);
        clearInterval(intervalRef.current);
        eventSourceRef.current?.close();
        notification.error({
            message: geti18nText('app.default.save.nok'),
            description: geti18nText('deviceDetails.widget.light.command.error.msg'),
        });
    }

    const createDate = (data: any[]) => {
        if (data.length >= 3 && data.length < 5) {
            return new Date(data[0], data[1] - 1, data[2]).toLocaleString(NyUtils.getSelectedLocale());
        } else if (data.length == 5) {
            return new Date(data[0], data[1] - 1, data[2], data[3], data[4]).toLocaleString(
                NyUtils.getSelectedLocale()
            );
        } else if (data.length > 5) {
            return new Date(data[0], data[1] - 1, data[2], data[3], data[4], data[5]).toLocaleString(
                NyUtils.getSelectedLocale()
            );
        } else {
            return '';
        }
    };

    const marks = {
        0: geti18nText("geti18nText('deviceDetails.widget.light.off')"),
        25: '25',
        50: '50',
        75: '75',
        100: '100',
    };

    const style = {
        flexGrow: '1',
        width: '20%',
    };

    function turnOff() {
        sendCommand('turnOff', { deviceId: record.id });
    }

    function turnOn() {
        sendCommand('turnOn', { deviceId: record.id });
    }

    function query() {
        eventSourceRef.current?.close();
        setProgressBar(true);
        clearInterval(intervalRef.current);
        register(false);
        intervalRef.current = setInterval(onError, 60000);
        sendCommand('query', { deviceId: record.id }, false);
    }

    function sendCommand(command: string, params: any, showRes: boolean = true) {
        NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE.EDIT + '/' + command, undefined, params).then((result) => {
            if (showRes === true) {
                if (result && result.status === RESPONSE.OK) {
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        description: geti18nText('deviceDetails.widget.light.command.success.msg'),
                        duration: 3,
                    });
                } else {
                    notification.error({
                        message: geti18nText('app.default.save.nok'),
                        description: '',
                    });
                }
            }
        });
    }

    const slideOnChange = (value: any) => {
        if (snapshotData != null) {
            if (value != snapshotData['dimmingValue']) {
                snapshotData.dimmingValue = value;
                setSnapshotData(JSON.parse(JSON.stringify(snapshotData)));
                sendCommand('dimming', { deviceId: record.id, value: value });
            }
        }
    };

    return (
        <Fragment>
            <Row>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <div
                        className="box"
                        style={{
                            display: 'flex',
                            margin: '10px',
                            height: '300px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <LightBulb
                            opacity={progressBar == true ? '5%' : '100%'}
                            height={'280px'}
                            fill="yellow"
                            fillOpacity={snapshotData != null ? snapshotData.dimmingValue + '%' : '100%'}
                            stroke="black"
                        />
                        {progressBar == true && (
                            <Spin
                                style={{ position: 'absolute', margin: 'auto auto' }}
                                indicator={<LoadingOutlined style={{ fontSize: 124 }} spin />}
                            ></Spin>
                        )}
                        <Button
                            style={{ position: 'absolute', top: '270px', right: '20px' }}
                            icon={<SettingOutlined />}
                            onClick={() => {
                                setVisible(true);
                            }}
                        ></Button>
                    </div>
                    {snapshotData && snapshotData['device'] != null && (
                        <div className="box" style={{ marginRight: '15px' }}>
                            <Descriptions column={1} layout="horizontal">
                                <Descriptions.Item label="UID">
                                    {snapshotData['device']['commandUid']}
                                </Descriptions.Item>
                                <Descriptions.Item label="EUI">{snapshotData['device']['eui']}</Descriptions.Item>
                            </Descriptions>
                        </div>
                    )}
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <div className="box" style={{ marginRight: '10px', height: '95%' }}>
                        <Card>
                            <Statistic
                                title={geti18nText('lightDetails.table.column.volume')}
                                value={
                                    snapshotData != null
                                        ? formatNumberValueString(snapshotData['electricEnergy'], true)
                                        : ' '
                                }
                                precision={2}
                                valueStyle={{
                                    color: '#808080',
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<LineChartOutlined />}
                            />
                        </Card>
                        <Card>
                            <Statistic
                                title={geti18nText('deviceDetails.widget3.light.title')}
                                value={snapshotData != null ? NyUtils.formatNumber(snapshotData['current'], 2) : ' '}
                                precision={2}
                                valueStyle={{
                                    color: '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<ThunderboltOutlined />}
                                suffix="A"
                            />
                        </Card>
                        <Card style={{ cursor: 'pointer' }}>
                            <Statistic
                                title={geti18nText('deviceDetails.widget4.light.title')}
                                value={snapshotData != null ? NyUtils.formatNumber(snapshotData['voltage'], 2) : ' '}
                                precision={2}
                                valueStyle={{
                                    color: '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<WarningOutlined />}
                                suffix="V"
                            />
                        </Card>
                        <Card style={{ cursor: 'pointer' }}>
                            <Statistic
                                title={geti18nText('deviceDetails.widget5.light.title')}
                                value={snapshotData != null ? NyUtils.formatNumber(snapshotData['power'], 2) : ' '}
                                precision={2}
                                valueStyle={{
                                    color: '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<LineChartOutlined />}
                                suffix="W"
                            />
                        </Card>
                        <Card style={{ cursor: 'pointer' }}>
                            <Statistic
                                title={geti18nText('deviceDetails.widget6.light.title')}
                                value={
                                    snapshotData != null
                                        ? snapshotData['dimmingValue'] == -1
                                            ? geti18nText('dashboard.widget.light.dimming.off')
                                            : NyUtils.formatNumber(snapshotData['dimmingValue'], 2)
                                        : ' '
                                }
                                precision={2}
                                valueStyle={{
                                    color: '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<BulbOutlined />}
                                suffix=""
                            />
                        </Card>
                        <Card style={{ cursor: 'pointer' }}>
                            <Statistic
                                title={geti18nText('deviceDetails.widget7.light.title')}
                                value={
                                    snapshotData != null ? NyUtils.formatNumber(snapshotData['leakageVoltage'], 2) : ' '
                                }
                                precision={2}
                                valueStyle={{
                                    color: '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<FallOutlined />}
                                suffix=""
                            />
                        </Card>
                        <Card style={{ cursor: 'pointer' }}>
                            <Statistic
                                title={geti18nText('deviceDetails.widget8.light.title')}
                                value={snapshotData != null ? createDate(snapshotData['ts']) : ''}
                                precision={2}
                                valueStyle={{
                                    color: '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<FallOutlined />}
                                suffix={<Button icon={<ReloadOutlined />} onClick={(e) => query()} />}
                            />
                        </Card>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <div
                        className="box"
                        style={{ marginRight: '10px', height: '300px', display: 'flex', flexWrap: 'wrap' }}
                    >
                        <div style={style}>
                            {snapshotData && (
                                <Slider
                                    style={{ height: '200px' }}
                                    onAfterChange={slideOnChange}
                                    vertical
                                    defaultValue={snapshotData != null ? snapshotData.dimmingValue : 100}
                                    step={5}
                                    marks={marks}
                                />
                            )}
                        </div>
                        <div style={style}>
                            {snapshotData && (
                                <>
                                    <Button
                                        type="primary"
                                        style={{ width: '75px', background: '#87d068', color: 'white' }}
                                        onClick={(e) => turnOn()}
                                    >
                                        {geti18nText('deviceDetails.widget.light.on')}
                                    </Button>
                                    <Button type="primary" danger style={{ width: '75px' }} onClick={(e) => turnOff()}>
                                        {geti18nText('deviceDetails.widget.light.off')}
                                    </Button>
                                </>
                            )}
                        </div>
                        <div style={style}></div>
                    </div>
                </Col>
            </Row>
            <Modal
                title={geti18nText('deviceDetails.widget.light.settings')}
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={'70%'}
                centered={true}
                footer={null}
            >
                <LightParamSettings></LightParamSettings>
            </Modal>
        </Fragment>
    );
};

export default LightEdit;
