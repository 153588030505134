import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IDeviceContactState {
    deviceId: string | undefined;
    data: { [index: string]: any } | undefined;
    visible: boolean;
}

const initialState: IDeviceContactState = {
    deviceId: undefined,
    data: {},
    visible: false,
};

const deviceContact = createSlice({
    name: 'deviceContact',
    initialState,
    reducers: {
        deviceContactInit(state) {
            state.deviceId = undefined;
            state.data = {};
            state.visible = false;
        },
        deviceContactOpen(state, action: PayloadAction<IDeviceContactState>) {
            const { data, visible = true, deviceId } = action.payload;
            state.deviceId = deviceId;
            state.data = data;
            state.visible = visible;
        },
    },
});

export const { deviceContactInit, deviceContactOpen } = deviceContact.actions;

export default deviceContact.reducer;
