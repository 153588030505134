import React, { useState, useEffect } from 'react';

import { PushpinOutlined, ZoomInOutlined, FullscreenOutlined, UnorderedListOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';

import { Tooltip, Modal, Popover } from 'antd';

import { getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession, NyUtils } from '@nybble/nyreact';
import { RootState } from '../../../rootReducer';
import {
    setMapPanTo,
    filterDeviceDevices,
    filterReset,
    setSelectedIdToShowDetails,
    setSelectedDefaultDeviceToShowDetails,
} from '../../../slices/dashboardSlice';
import { deviceLocationOpen } from '../../../slices/deviceLocationSlice';
import { deviceWeatherStateDetailsOpen } from '../../../slices/deviceWeatherStateDetailsReducer';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnumNameForValue } from '../../../utils/Enums';
import { parametarKeyName, toCamelCase } from '../../../utils/Utils';

const getScrolls = (dataGrid: { [index: string]: string | number | boolean | undefined }) => {
    let scr: { [index: string]: any } = {};
    if (typeof dataGrid.h == 'number') {
        scr.y = 40 * dataGrid.h - 170;
    }
    return scr;
};

const getModalScrolls = (dataGrid: { [index: string]: string | number | boolean | undefined }) => {
    let scr: { [index: string]: any } = { x: 1400 };

    return scr;
};

interface ColumnTitleProps {
    text: string;
}

const ColumnTitle: React.FC<ColumnTitleProps> = ({ text }) => {
    return <div style={{ whiteSpace: 'nowrap' }}>{text}</div>;
};

const WidgetWeatherDeviceList: any = ({}: any) => {
    const [visible, setVisible] = useState(false);

    const [refresh, setRefresh] = useState<any>(0);
    const mapSelectedBounds = useSelector((state: RootState) => state.dashboard.mapSelectedBounds);
    const filterDevice = useSelector((state: RootState) => state.dashboard.filterDevice);
    const weatherStateActive = useSelector((state: RootState) => state.dashboard.weatherStateActive);
    const weatherStateInactive = useSelector((state: RootState) => state.dashboard.weatherStateInactive);
    const weatherParameters = useSelector((state: RootState) => state.dashboard.weatherParameters);
    const queryId = useSelector((state: RootState) => state.dashboard.queryId);
    const userWeatherWidgets = useSelector((state: RootState) => state.dashboard.userWeatherWidgets);
    const dispatch = useDispatch();

    useEffect(() => {
        setRefresh(new Date());
    }, [mapSelectedBounds, filterDevice, weatherStateActive, weatherStateInactive, weatherParameters, queryId]);

    const editLocation = (deviceId: string, data?: { [index: string]: any }) => {
        dispatch(deviceLocationOpen({ deviceId: deviceId, data: data, visible: true }));
    };

    const onRowSelect = (record: { [index: string]: any }) => {
        dispatch(deviceWeatherStateDetailsOpen({ record: record, visible: true }));
    };

    const setDefaultFilterValue = () => {
        if (weatherStateActive) {
            return [{ field: 'alarmStatus', condition: 'not_equals', value: -48 }];
        }
        if (weatherStateInactive) {
            return [{ field: 'alarmStatus', condition: 'equals', value: -48 }];
        }

        return [];
    };

    const generateKey = () => {
        return 'WidgetWeatherMeterList-K1';
    };

    const showOnMap = (record: { [index: string]: any }) => {
        if (record.lat && record.lng) {
            dispatch(setMapPanTo({ lat: record.lat, lng: record.lng }));
        }
    };

    const showOnMapFilter = (item: any) => {
        if (filterDevice != item.id) {
            showOnMap(item);
            dispatch(filterDeviceDevices({ id: item.id }));
        } else {
            dispatch(filterReset());
        }
    };

    const categoryColumns: any = (csv: boolean = false) => {
        let columns: any = [];
        const checkedCards =
            userWeatherWidgets?.checkedCard instanceof Array
                ? userWeatherWidgets.checkedCard.find((i: any) => i.category === queryId)?.items
                : [];
        weatherParameters?.forEach((item: any) => {
            if (checkedCards?.includes(item.key)) {
                let column = {
                    title: csv ? item[parametarKeyName('name')] : <ColumnTitle text={item[parametarKeyName('name')]} />,
                    dataIndex: item.dbColumn,
                    align: 'right',
                    sorter: (a: number, b: number) => {},
                    render: (text: string, record: { [index: string]: number }) => {
                        let value =
                            record[item.dbColumn] != undefined
                                ? record[item.dbColumn]
                                : record[toCamelCase(item.dbColumn)] != undefined
                                ? record[toCamelCase(item.dbColumn)]
                                : undefined;

                        return (
                            <div>
                                {value ? NyUtils.formatNumber(value, 0) : '0'}
                                &nbsp;{item.unit}
                            </div>
                        );
                    },
                };
                columns.push(column);
            }
        });
        return columns;
    };

    const columns: any = () => {
        let deviceColumns = [
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.name')} />,
                dataIndex: ['device', 'name'],
                sorter: (a: string, b: string) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    return <div> {record.device?.name} </div>;
                },
                ...getColumnSearch('string'),
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.serial')} />,
                dataIndex: ['device', 'eui'],
                sorter: (a: string, b: string) => {},
                ...getColumnSearch('string'),
            },
        ];

        let actionColumns = [
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.lastactivity')} />,
                dataIndex: 'ts',
                sorter: (a: string, b: string) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.ts) {
                        var ts: any = new Date(record.ts);
                        if (ts !== '') {
                            return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                        } else {
                            return <div></div>;
                        }
                    }
                },
            },

            {
                dataIndex: 'operation',
                align: 'left',

                render: (text: string, record: { [index: string]: any }) => {
                    if (record.id) {
                        return (
                            <div>
                                {NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER']) && (
                                    <React.Fragment>
                                        <Tooltip
                                            title={geti18nText('dashboard.widget.watermeter.table.location.tooltip')}
                                        >
                                            <PushpinOutlined
                                                style={{ fontSize: '18px', marginRight: '10px' }}
                                                onClick={() =>
                                                    editLocation(record.id, {
                                                        latitude: record.lat,
                                                        longitude: record.lng,
                                                    })
                                                }
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                )}
                                {record.lat != undefined && (
                                    <Tooltip title={geti18nText('dashboard.widget.watermeter.table.showonmap.tooltip')}>
                                        {filterDevice === record.id ? (
                                            <ZoomInOutlined
                                                style={{ fontSize: '18px', color: 'red' }}
                                                onClick={() => showOnMapFilter(record)}
                                            />
                                        ) : (
                                            <ZoomInOutlined
                                                style={{ fontSize: '18px' }}
                                                onClick={() => showOnMapFilter(record)}
                                            />
                                        )}
                                    </Tooltip>
                                )}

                                <UnorderedListOutlined
                                    style={{ fontSize: '18px', color: 'blue', marginLeft: '5px' }}
                                    onClick={() => onRowSelect(record)}
                                />
                            </div>
                        );
                    }
                },
            },
        ];
        return [...deviceColumns, ...categoryColumns(), ...actionColumns];
    };

    const hideColumnsInMainTable: any = [
        'minWindDirection',
        'maxWindDirection',
        'minWindSpeed',
        'maxWindSpeed',
        'minNoise',
        'maxNoise',
    ];
    function getCsvColumns() {
        let deviceColumns = [
            {
                title: geti18nText('dashboard.widget.light.table.name'),
                dataIndex: 'device.name',
            },
            {
                title: geti18nText('dashboard.widget.light.table.serial'),
                dataIndex: 'device.eui',
            },
        ];
        return [...deviceColumns, ...categoryColumns(true)];
    }

    const csvCustomizationColumns: any = () => {
        return [
            {
                ts: (value: any) => {
                    if (value) {
                        var ts: any = new Date(value);
                        if (ts !== '') {
                            return ts.toLocaleString(NyUtils.getSelectedLocale());
                        }
                    }

                    return '';
                },
            },
            {
                current: (value: any) => {
                    if (value != undefined) {
                        return NyUtils.formatNumber(value, 2);
                    }
                    return value;
                },
            },
            {
                action: (value: any) => {
                    if (value != undefined) {
                        return geti18nText(
                            'app.enum.PAYLOAD_STATUS_ACTION.' + GetEnumNameForValue('PAYLOAD_STATUS_ACTION', value)
                        );
                    }
                    return '';
                },
            },
        ];
    };

    const addData = () => {
        let item: any =
            mapSelectedBounds != null
                ? {
                      latNorthEast: mapSelectedBounds.latNorthEast,
                      lngNorthEast: mapSelectedBounds.lngNorthEast,
                      latSouthWest: mapSelectedBounds.latSouthWest,
                      lngSouthWest: mapSelectedBounds.lngSouthWest,
                  }
                : undefined;
        if (queryId != undefined && queryId > 0) {
            item != undefined ? (item['categoryId'] = queryId) : (item = { categoryId: queryId });
        }
        return item;
    };

    function onRowClick(item: any) {
        dispatch(setSelectedIdToShowDetails(item.device));
    }

    function onDataLoaded(data: any) {
        if (data && data.length > 0) {
            dispatch(setSelectedDefaultDeviceToShowDetails(data[0].device));
        } else {
            dispatch(setSelectedDefaultDeviceToShowDetails(undefined));
            dispatch(setSelectedIdToShowDetails(undefined));
        }
    }

    return (
        <React.Fragment>
            <FullscreenOutlined
                style={{ position: 'absolute', right: '5px', top: '5px' }}
                onClick={() => setVisible(true)}
            />
            <NyDataTable
                key={generateKey()}
                headerTitle={geti18nText('dashboard.widget.weather.device.title')}
                url={CONSTANTS_REQ.DASHBOARD.WEATHER_METER_LIST}
                hideButtons={true}
                exportCSV={true}
                // onRowSelect={onRowSelect}
                showRecordModal={false}
                readonly={true}
                scroll={{ x: 800 }}
                onRowClickHandler={onRowClick}
                addedData={addData()}
                setDefaultFilterValue={setDefaultFilterValue}
                fetchWhenChange={refresh}
                columns={columns().filter((x: any) => !hideColumnsInMainTable.includes(x.dataIndex))}
                csvColumns={getCsvColumns()}
                colCSVCustomization={csvCustomizationColumns()}
                onDataLoaded={onDataLoaded}
                setDefaultSortOrder={{ order: 'device.id', orderType: 'desc' }}
            />
            <Modal
                title={geti18nText('dashboard.widget.weather.device.title')}
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={'100%'}
                footer={null}
            >
                {visible && (
                    <NyDataTable
                        key={generateKey()}
                        headerTitle={geti18nText('dashboard.widget.weather.device.title')}
                        url={CONSTANTS_REQ.DASHBOARD.WEATHER_METER_LIST}
                        hideButtons={true}
                        exportCSV={true}
                        // onRowSelect={onRowSelect}
                        onRowClickHandler={onRowClick}
                        setDefaultFilterValue={setDefaultFilterValue}
                        addedData={addData()}
                        //   scroll={getModalScrolls(dataGrid)}
                        fetchWhenChange={refresh}
                        columns={columns()}
                        csvColumns={getCsvColumns()}
                        colCSVCustomization={csvCustomizationColumns()}
                        setDefaultSortOrder={{ order: 'device.id', orderType: 'desc' }}
                    />
                )}
            </Modal>
        </React.Fragment>
    );
};

export default WidgetWeatherDeviceList;
