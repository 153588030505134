import React from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import CountryEdit from './edit';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';

const Country = () => {
    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    return (
        <NyDataTable
            headerTitle={geti18nText('country.table.header')}
            url={CONSTANTS_REQ.COUNTRY.LIST}
            addNewButtonText={geti18nText('county.table.add')}
            showRecordModal={true}
            exportCSV={true}
            modalComponent={CountryEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            // addedData={{ projectId: Session.getProjectId() }}
            columns={[
                {
                    title: geti18nText('country.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('country.table.column.name'),
                    dataIndex: 'name',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('country.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default Country;
