import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Descriptions, DatePicker } from 'antd';
import { RootState } from '../../rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { gatewayDetailsInit } from '../../slices/gatewayDetailsSlice';
import Title from 'antd/lib/typography/Title';
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Legend, Tooltip, Line } from 'recharts';
import { CONSTANTS_REQ } from '../../utils/Constants';
import moment from 'moment';
import { geti18nText, NyRequestResolver, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';

const { RangePicker } = DatePicker;

const GatewayDetails = () => {
    const dispatch = useDispatch();
    const { record, visible } = useSelector((state: RootState) => state.gatewayDetails);

    const [loading, setLoading] = useState(false);
    const [graphData, setGraphData] = useState<any>(null);
    const [gatewayInfo, setGatewayInfo] = useState<any>(undefined);
    const [period, setPeriod] = useState<any>({ period: 'last-24h' });

    useEffect(() => {
        if (visible) {
            setLoading(true);
            fetch();
            getGatewayInfo();
        }
    }, [visible, period]);

    const fetch = () => {
        setGraphData(null);
        if (
            period &&
            (period.period == 'last-24h' ||
                (period.period === 'span' && period.timestampFrom !== null && period.timestampTo !== null))
        ) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.GATEWAY.STATISTICS, {
                ...period,
                lang: NyUtils.getSelectedLanguage(),
                gatewayId: record.id,
            }).then((response: any) => {
                if (response.status === RESPONSE.OK) {
                    setGraphData(response.data);
                }
                setLoading(false);
            });
        }
    };

    const getGatewayInfo = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.GATEWAY.EDIT + '/' + record.id).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setGatewayInfo(response.data);
            }
            setLoading(false);
        });
    };

    const hideModal = () => {
        dispatch(gatewayDetailsInit());
    };

    function onRangeChange(dates: any) {
        const timestampFrom = dates[0] ? moment(dates[0]).valueOf() : null;
        const timestampTo = dates[1] ? moment(dates[1]).valueOf() : null;
        setPeriod((prevState: any) => {
            return { ...prevState, period: 'span', timestampFrom: timestampFrom, timestampTo: timestampTo };
        });
    }

    return (
        <Modal
            title={geti18nText('gateway.details.title')}
            visible={visible}
            width="90%"
            centered={true}
            footer={null}
            destroyOnClose={true}
            onCancel={hideModal}
        >
            <Row>
                <Col span={24}>
                    <div className="box">
                        <Title level={4}>{geti18nText('gateway.details.graph.title')}</Title>
                        <RangePicker
                            style={{ marginBottom: '15px' }}
                            showTime
                            format="DD.MM.YYYY HH:mm:ss"
                            onChange={onRangeChange}
                        />
                        <ResponsiveContainer width="100%" height={550}>
                            {loading ? (
                                <NySpinner />
                            ) : (
                                <LineChart data={graphData}>
                                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line dot={false} type="monotone" dataKey="loraSnr" stroke="#2d56b5" />
                                    <Line dot={false} type="monotone" dataKey="payloadLength" stroke="#cc860c" />
                                    <Line dot={false} type="monotone" dataKey="rssi" stroke="#1fa61b" />
                                </LineChart>
                            )}
                        </ResponsiveContainer>
                    </div>
                </Col>
                <Col>
                    <div className="box">
                        <Descriptions title={geti18nText('gateway.details.info.title')}>
                            {gatewayInfo != null && (
                                <React.Fragment>
                                    <Descriptions.Item label={geti18nText('gateway.table.column.mac')}>
                                        <b>{gatewayInfo.mac != null && gatewayInfo.mac}</b>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={geti18nText('gateway.table.column.name')}>
                                        <b>{gatewayInfo.name != null && gatewayInfo.name}</b>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={geti18nText('gateway.table.column.address')}>
                                        <b>
                                            {gatewayInfo.contact != null &&
                                                gatewayInfo.contact.address != null &&
                                                gatewayInfo.contact.address}
                                        </b>
                                    </Descriptions.Item>
                                </React.Fragment>
                            )}
                        </Descriptions>
                    </div>
                </Col>
            </Row>
        </Modal>
    );
};

export default GatewayDetails;
