import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Tabs } from 'antd';
import { CONSTANTS_REQ } from '../../utils/Constants';
import './index.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../rootReducer';
import { deviceDetailsInit } from '../../slices/deviceDetailsSlice';
import Chart from './Chart';
import Contact from './Contact';
import Alarms from './Alarms';
import StatisticWidget from './StatisticWidget';
import ChartSpan from './ChartSpan';
import PayloadHistoryTable from './PayloadHistoryTable';
import { geti18nText, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';

const { TabPane } = Tabs;

const DeviceDetails = (props: any) => {
    const dispatch = useDispatch();
    const { record, visible } = useSelector((state: RootState) => state.deviceDetails);
    const [last24hData, setLast24hData] = useState<any>(null);
    const [currentWeekData, setCurrentWeekData] = useState<any>(null);
    const [lastWeekData, setLastWeekData] = useState<any>(null);
    const [currentMonthData, setCurrentMonthData] = useState<any>(null);
    const [currentYearData, setCurrentYearData] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [modalTitle, setModalTitle] = useState(geti18nText('deviceDetails.modal.title'));

    useEffect(() => {
        if (visible) {
            fetchLast24hValue();
            fetchCurrentWeekData();
            fetchLastWeekData();
            fetchCurrentMonthData();
            fetchCurrentYearData();

            let title = geti18nText('deviceDetails.modal.title');
            if ('device' in record) {
                title += ' - ' + record.device.name + ('comment' in record.device ? ' - ' + record.device.comment : '');
            } else if ('name' in record) {
                title += ' - ' + record.name + ('comment' in record ? ' - ' + record.comment : '');
            }
            setModalTitle(title);
        }
    }, [visible]);

    const hideModal = () => {
        dispatch(deviceDetailsInit());
    };

    const fetchLast24hValue = () => {
        setLast24hData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_CONSUMPTION, {
            period: 'last-24h',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: record.id,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setLast24hData(response.data);
            }
        });
    };

    const fetchCurrentWeekData = () => {
        setCurrentWeekData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_CONSUMPTION, {
            period: 'current-week',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: record.id,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setCurrentWeekData(response.data);
            }
        });
    };

    const fetchLastWeekData = () => {
        setLastWeekData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_CONSUMPTION, {
            period: 'last-week',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: record.id,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setLastWeekData(response.data);
            }
        });
    };

    const fetchCurrentMonthData = () => {
        setCurrentMonthData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_CONSUMPTION, {
            period: 'current-month',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: record.id,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setCurrentMonthData(response.data);
            }
        });
    };

    const fetchCurrentYearData = () => {
        setCurrentYearData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_CONSUMPTION, {
            period: 'current-year',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: record.id,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setCurrentYearData(response.data);
            }
        });
    };

    return (
        <Modal
            zIndex={1009}
            title={modalTitle}
            destroyOnClose={true}
            visible={visible}
            onOk={hideModal}
            onCancel={hideModal}
            width="90%"
            footer={null}
        >
            <Tabs type="card">
                <TabPane tab={geti18nText('deviceDetails.tab.overview')} key="1">
                    <Row>
                        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                            <StatisticWidget />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                            <Contact />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                            <Chart
                                loading={loading}
                                data={last24hData}
                                title={geti18nText('report.time_range.last24h')}
                                deviceId={record.id}
                                period="last-24h"
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                            <Chart
                                loading={loading}
                                data={currentWeekData}
                                title={geti18nText('report.time_range.current_week')}
                                deviceId={record.id}
                                period="current-week"
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                            <Chart
                                loading={loading}
                                data={lastWeekData}
                                title={geti18nText('report.time_range.last_week')}
                                deviceId={record.id}
                                period="last-week"
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                            <Chart
                                loading={loading}
                                data={currentMonthData}
                                title={geti18nText('report.time_range.current_month')}
                                deviceId={record.id}
                                period="current-month"
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                            <Chart
                                loading={loading}
                                data={currentYearData}
                                title={geti18nText('report.time_range.current_year')}
                                deviceId={record.id}
                                period="current-year"
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                            <ChartSpan />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('deviceDetails.tab.alarms')} key="2">
                    <Row>
                        <Col span={24}>
                            <Alarms />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('deviceDetails.tab.payload')} key="3">
                    <Row>
                        <Col span={24}>
                            <PayloadHistoryTable />
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
        </Modal>
    );
};

export default DeviceDetails;
