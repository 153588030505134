import React from 'react';
import AlarmEditDescription from '../dashboard/AlarmEditDescription';

const ModalList: React.FC = () => {
    return (
        <React.Fragment>
            <AlarmEditDescription />

        </React.Fragment>
    );
};

export default ModalList;
