import { geti18nText } from '@nybble/nyreact';
import React from 'react';

// export const cardslabel = [
//     {
//         icon: (
//             <img
//                 style={{ position: 'absolute', height: 60, width: 60, top: '20px', left: '30px' }}
//                 src={require(`../../../assets/img/temprature.png`)}
//             ></img>
//         ),
//         filter: (
//             <img className="weather-data-type-div-img-select" src={require(`../../../assets/img/temprature.png`)}></img>
//         ),
//         key: 'TEMPERATURE',
//         label: geti18nText('weatherStateDetails.widget.title.temp'),
//         value: 'TEMPERATURE',
//         chartKey: 'temp',
//     },
//     {
//         icon: (
//             <img
//                 style={{ position: 'absolute', height: 60, width: 60, top: '20px', left: '30px' }}
//                 src={require(`../../../assets/img/water-stream.png`)}
//             ></img>
//         ),
//         filter: (
//             <img
//                 className="weather-data-type-div-img-select"
//                 src={require(`../../../assets/img/water-stream.png`)}
//             ></img>
//         ),
//         key: 'HUMIDITY',
//         label: geti18nText('weatherStateDetails.widget.title.humidity'),
//         value: 'HUMIDITY',
//         chartKey: 'humidity',
//     },
//     {
//         icon: (
//             <img
//                 style={{ position: 'absolute', height: 60, width: 60, top: '20px', left: '30px' }}
//                 src={require(`../../../assets/img/barometer.png`)}
//             ></img>
//         ),
//         filter: (
//             <img className="weather-data-type-div-img-select" src={require(`../../../assets/img/barometer.png`)}></img>
//         ),
//         key: 'AIR_PRESSURE',
//         label: geti18nText('weatherStateDetails.widget.title.airPressure'),
//         value: 'AIR_PRESSURE',
//         chartKey: 'air_pressure',
//     },
//     {
//         icon: (
//             <img
//                 style={{ position: 'absolute', height: 60, width: 60, top: '20px', left: '30px' }}
//                 src={require(`../../../assets/img/rain.png`)}
//             ></img>
//         ),
//         filter: <img className="weather-data-type-div-img-select" src={require(`../../../assets/img/rain.png`)}></img>,
//         key: 'RAINFALL',
//         label: geti18nText('weatherStateDetails.widget.title.rainfall'),
//         value: 'RAINFALL',
//         chartKey: 'rainfall',
//     },
//     {
//         icon: (
//             <img
//                 style={{ position: 'absolute', height: 60, width: 60, top: '20px', left: '30px' }}
//                 src={require(`../../../assets/img/icons8-sun-100.png`)}
//             ></img>
//         ),
//         filter: (
//             <img
//                 className="weather-data-type-div-img-select"
//                 src={require(`../../../assets/img/icons8-sun-100.png`)}
//             ></img>
//         ),
//         key: 'RADIATION',
//         label: geti18nText('weatherStateDetails.widget.title.solarRadiation'),
//         value: 'RADIATION',
//         chartKey: 'solar_radiation',
//     },
//     {
//         icon: (
//             <img
//                 style={{ position: 'absolute', height: 60, width: 60, top: '20px', left: '30px' }}
//                 src={require(`../../../assets/img/anemometer.png`)}
//             ></img>
//         ),
//         filter: (
//             <img className="weather-data-type-div-img-select" src={require(`../../../assets/img/anemometer.png`)}></img>
//         ),
//         key: 'WIND_SPEED',
//         label: geti18nText('weatherStateDetails.widget.title.windSpeed'),
//         value: 'WIND_SPEED',
//         chartKey: 'wind',
//     },
//     {
//         icon: (
//             <img
//                 style={{ position: 'absolute', height: 60, width: 60, top: '20px', left: '30px' }}
//                 src={require(`../../../assets/img/air-pollution.png`)}
//             ></img>
//         ),
//         filter: (
//             <img
//                 className="weather-data-type-div-img-select"
//                 src={require(`../../../assets/img/air-pollution.png`)}
//             ></img>
//         ),
//         key: 'PM10',
//         label: geti18nText('weatherStateDetails.widget.title.pm_10'),
//         value: 'PM10',
//         chartKey: 'pm_10',
//     },
//     {
//         icon: (
//             <img
//                 style={{ position: 'absolute', height: 60, width: 60, top: '20px', left: '30px' }}
//                 src={require(`../../../assets/img/air-quality.png`)}
//             ></img>
//         ),
//         filter: (
//             <img
//                 className="weather-data-type-div-img-select"
//                 src={require(`../../../assets/img/air-quality.png`)}
//             ></img>
//         ),
//         key: 'PM2.5',
//         label: geti18nText('weatherStateDetails.widget.title.pm_25'),
//         value: 'PM2.5',
//         chartKey: 'pm_25',
//     },
//     {
//         icon: (
//             <img
//                 style={{ position: 'absolute', height: 60, width: 60, top: '20px', left: '30px' }}
//                 src={require(`../../../assets/img/megaphone.png`)}
//             ></img>
//         ),
//         filter: (
//             <img className="weather-data-type-div-img-select" src={require(`../../../assets/img/megaphone.png`)}></img>
//         ),
//         key: 'NOISE',
//         label: geti18nText('weatherStateDetails.widget.title.noise'),
//         value: 'NOISE',
//         chartKey: 'avg_noise',
//     },

//     {
//         icon: (
//             <img
//                 style={{ position: 'absolute', height: 60, width: 60, top: '20px', left: '30px' }}
//                 src={require(`../../../assets/img/sunshine_durartion.png`)}
//             ></img>
//         ),
//         filter: (
//             <img
//                 className="weather-data-type-div-img-select"
//                 src={require(`../../../assets/img/sunshine_durartion.png`)}
//             ></img>
//         ),
//         key: 'SUNSHINE_HOURS',
//         label: geti18nText('weatherStateDetails.widget.title.sunshine_hours'),
//         value: 'SUNSHINE_HOURS',
//         chartKey: 'sunshine_hours',
//     },
//     {
//         icon: (
//             <img
//                 style={{ position: 'absolute', height: 60, width: 60, top: '20px', left: '30px' }}
//                 src={require(`../../../assets/img/light_intensity.png`)}
//             ></img>
//         ),
//         filter: (
//             <img
//                 className="weather-data-type-div-img-select"
//                 src={require(`../../../assets/img/light_intensity.png`)}
//             ></img>
//         ),
//         key: 'LUX',
//         label: geti18nText('weatherStateDetails.widget.title.lux'),
//         value: 'LUX',
//         chartKey: 'lux',
//     },
//     {
//         icon: (
//             <img
//                 style={{ position: 'absolute', height: 60, width: 60, top: '20px', left: '30px' }}
//                 src={require(`../../../assets/img/soil_temperature.png`)}
//             ></img>
//         ),
//         filter: (
//             <img
//                 className="weather-data-type-div-img-select"
//                 src={require(`../../../assets/img/soil_temperature.png`)}
//             ></img>
//         ),
//         key: 'SOIL_TEMPERATURE',
//         label: geti18nText('weatherStateDetails.widget.title.soil_temperature'),
//         value: 'SOIL_TEMPERATURE',
//         chartKey: 'soil_temperature',
//     },
//     {
//         icon: (
//             <img
//                 style={{ position: 'absolute', height: 60, width: 60, top: '20px', left: '30px' }}
//                 src={require(`../../../assets/img/soil_moisture.webp`)}
//             ></img>
//         ),
//         filter: (
//             <img
//                 className="weather-data-type-div-img-select"
//                 src={require(`../../../assets/img/soil_moisture.webp`)}
//                 style={{ zoom: '160%' }}
//             ></img>
//         ),
//         key: 'SOIL_MOISTURE',
//         label: geti18nText('weatherStateDetails.widget.title.soil_moisture'),
//         value: 'SOIL_MOISTURE',
//         chartKey: 'soil_moisture',
//     },
// ];

export const calculatePercent = (item: any) => {
    if (!item.chartValue || item.chartValue < item.min) return 0;
    else {
        let max = item.max;
        let chartValue = item.chartValue;
        if (item.min < 0) {
            max = item.max + (0 - item.min);
            chartValue = chartValue + (0 - item.min);
        }
        return (chartValue / max) * 100;
    }
};
export const calculateProgressGradient = (item: any) => {
    let max = item.max;
    let lowerLimitTmp = item.lowerLimit;
    let upperLimitTmp = item.upperLimit;
    if (item.min < 0) {
        max = item.max + (0 - item.min);
        lowerLimitTmp = item.lowerLimit - item.min;
        upperLimitTmp = item.upperLimit - item.min;
    }
    if (item.lowerLimit) {
        let lowerLimit = 100 - (lowerLimitTmp / max) * 100 + '%';
        let upperLimit = 100 - (upperLimitTmp / max) * 100 + '%';
        let res: any = {
            '0%': '#a3264b',
            [upperLimit]: '#87d068',
            [lowerLimit]: '#87d068',
            '100%': '#108ee9',
        };
        return res;
    } else {
        let upperLimit = 100 - (upperLimitTmp / max) * 100 + '%';
        let res: any = {
            '0%': '#a3264b',
            [upperLimit]: '#87d068',
            '100%': '#87d068',
        };
        return res;
    }
};

export const formLabelCard = (card: any) => {
    if (card.showAlarm === true && (card.chartValue < card.lowerLimit || card.chartValue > card.upperLimit))
        return '#a3264b';
    return 'black';
};
