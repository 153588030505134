import React, { useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import TasksEdit from './edit';
import { GetEnumNameForValue, GetEnum } from '../../utils/Enums';
import { Descriptions, Tag, Tooltip, Popover, Divider } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { HistoryOutlined } from '@ant-design/icons';
import {
    ENUMS,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';

type ITasksIndex = {
    endCustomerId?: number;
};

const TasksIndex = ({ endCustomerId }: ITasksIndex) => {
    const [logContent, setLogContent] = useState([]);
    const setDefaultFilterValue = () => {
        if (endCustomerId) {
            return [{ field: 'endCustomerId', condition: 'equals', value: endCustomerId }];
        } else {
            return [{ field: 'active', condition: 'equals_bool', value: 1 }];
        }
    };

    const taskStatus = () => {
        const aTypes = GetEnum('TASK_STATUS');
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.TASK_STATUS.' + key);
                types.push(ret);
            }
        }
        return types;
    };

    const taskType = () => {
        const aTypes = GetEnum('TASK_TYPE');
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.TASK_TYPE.' + key);
                types.push(ret);
            }
        }
        return types;
    };

    const tasksLog = (taskId: bigint) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.TASK.LOG_LIST + taskId).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setLogContent(response.data);
            }
        });
    };

    return (
        <NyDataTable
            headerTitle={geti18nText('task.table.header')}
            url={CONSTANTS_REQ.TASK.LIST}
            addNewButtonText={geti18nText('task.table.add')}
            showRecordModal={true}
            exportCSV={true}
            scroll={{ x: 800 }}
            setDefaultFilterValue={setDefaultFilterValue}
            modalComponent={TasksEdit}
            hideButtons={endCustomerId ? true : false}
            columns={[
                {
                    title: geti18nText('task.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('task.table.column.name'),
                    dataIndex: 'name',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('task.table.column.device'),
                    dataIndex: ['device', 'name'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('task.table.column.taskType'),
                    dataIndex: 'taskType',
                    sorter: (a: string, b: string) => {},
                    render: (text: string, record: { [index: string]: any }) => {
                        return (
                            <div>
                                <b>
                                    {geti18nText(
                                        'app.enum.TASK_TYPE.' + GetEnumNameForValue('TASK_TYPE', record.taskType)
                                    )}
                                </b>
                            </div>
                        );
                    },
                    ...getColumnSearchOption(taskType()),
                },
                {
                    title: geti18nText('task.table.column.status'),
                    dataIndex: 'status',
                    sorter: (a: string, b: string) => {},
                    render: (text: string, record: { [index: string]: any }) => {
                        return (
                            <div>
                                {record.status === 0 && (
                                    <Tag color="success">
                                        {geti18nText(
                                            'app.enum.TASK_STATUS.' + GetEnumNameForValue('TASK_STATUS', record.status)
                                        )}
                                    </Tag>
                                )}
                                {record.status === 1 && (
                                    <Tag color="orange">
                                        {geti18nText(
                                            'app.enum.TASK_STATUS.' + GetEnumNameForValue('TASK_STATUS', record.status)
                                        )}
                                    </Tag>
                                )}
                                {record.status === 2 && (
                                    <Tag color="blue">
                                        {geti18nText(
                                            'app.enum.TASK_STATUS.' + GetEnumNameForValue('TASK_STATUS', record.status)
                                        )}
                                    </Tag>
                                )}
                                {record.status === 3 && (
                                    <Tag>
                                        {geti18nText(
                                            'app.enum.TASK_STATUS.' + GetEnumNameForValue('TASK_STATUS', record.status)
                                        )}
                                    </Tag>
                                )}
                            </div>
                        );
                    },
                    ...getColumnSearchOption(taskStatus()),
                },
                ...(NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'])
                    ? [
                          {
                              title: geti18nText('application.table.customer'),
                              dataIndex: ['customer', 'name'],
                              sorter: (a: any, b: any) => {},
                              ...getColumnSearch('string'),
                          },
                      ]
                    : []),
                {
                    title: geti18nText('task.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
                {
                    title: '',
                    dataIndex: 'operation',
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.id) {
                            return (
                                <React.Fragment>
                                    <Popover
                                        title={geti18nText('tasks.log.tooltip')}
                                        content={
                                            <Descriptions style={{ width: '250px' }} column={1}>
                                                {logContent &&
                                                    logContent.map((item: { [index: string]: any }) => (
                                                        <React.Fragment>
                                                            <Descriptions.Item label={geti18nText('tasks.log.updated')}>
                                                                <b>
                                                                    {new Date(item.updated).toLocaleString(
                                                                        NyUtils.getSelectedLocale()
                                                                    )}
                                                                </b>
                                                            </Descriptions.Item>
                                                            <Descriptions.Item label={geti18nText('task.edit.status')}>
                                                                <b>
                                                                    {geti18nText(
                                                                        'app.enum.TASK_STATUS.' +
                                                                            GetEnumNameForValue(
                                                                                'TASK_STATUS',
                                                                                item.status
                                                                            )
                                                                    )}
                                                                </b>
                                                            </Descriptions.Item>
                                                            {item.application && item.application.name && (
                                                                <Descriptions.Item
                                                                    label={geti18nText('task.edit.application')}
                                                                >
                                                                    <b>{item.application.name}</b>
                                                                </Descriptions.Item>
                                                            )}
                                                            <Descriptions.Item
                                                                style={{
                                                                    borderBottomColor: 'black',
                                                                    borderBottomWidth: 1,
                                                                    borderBottomStyle: 'solid',
                                                                }}
                                                            >
                                                                <b></b>
                                                            </Descriptions.Item>
                                                        </React.Fragment>
                                                    ))}
                                                {record.created && (
                                                    <Descriptions.Item label={geti18nText('task.edit.created')}>
                                                        <b>
                                                            {new Date(record.created).toLocaleString(
                                                                NyUtils.getSelectedLocale()
                                                            )}
                                                        </b>
                                                    </Descriptions.Item>
                                                )}
                                            </Descriptions>
                                        }
                                        trigger="click"
                                    >
                                        <HistoryOutlined
                                            style={{
                                                fontSize: '18px',
                                                marginRight: '10px',
                                            }}
                                            onClick={() => tasksLog(record.id)}
                                        />
                                    </Popover>
                                </React.Fragment>
                            );
                        }
                    },
                },
            ]}
        />
    );
};

export default TasksIndex;
