import React, { useEffect, useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import {
    BulbOutlined,
    CheckOutlined,
    CloseOutlined,
    CloudServerOutlined,
    DashboardOutlined,
    ThunderboltOutlined,
} from '@ant-design/icons';
import CustomersEdit from './edit';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import { Form, Row, Col, Radio, Checkbox, Tag } from 'antd';
import { GetEnum } from '../../utils/Enums';
import { type } from 'os';

const CustomersIndex = () => {
    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const getDeviceCategoryOptions: any = () => {
        const aTypes = GetEnum('DEVICE_CATEGORY');
        let types: any = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret: any = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.DEVICE_CATEGORY.' + key);
                types.push(ret);
            }
        }
        return types;
    };

    return (
        <>
            <NyDataTable
                headerTitle={geti18nText('customer.table.header')}
                url={CONSTANTS_REQ.CUSTOMER.LIST}
                addNewButtonText={geti18nText('customer.table.add')}
                showRecordModal={true}
                scroll={{ x: 800 }}
                exportCSV={true}
                modalComponent={CustomersEdit}
                setDefaultFilterValue={setDefaultFilterValue}
                columns={[
                    {
                        title: geti18nText('user.table.column.id'),
                        dataIndex: 'id',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                        // render: (text: any, record: { id: any; categoryType: any }) => {
                        //     if (record.id) {
                        //         let catType = record.categoryType ? record.categoryType.split(',') : undefined;
                        //         if (catType != undefined && catType.length > 0) {
                        //             return (
                        //                 <div>
                        //                     {record.id}

                        //                     {formCategoryIcons(catType)}
                        //                 </div>
                        //             );
                        //         } else return <> {record.id}</>;
                        //     }
                        // },
                    },
                    {
                        title: geti18nText('customer.table.column.name'),
                        dataIndex: 'name',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('customer.table.column.categoryType'),
                        dataIndex: 'categoryType',
                        sorter: (a: any, b: any) => {},
                        width: '20%',
                        render: (text: any, record: any) => {
                            let catType: any = record.categoryType ? record.categoryType.split(',') : undefined;
                            if (catType != undefined && catType.length > 0) {
                                return formCategoryTag(catType);
                            }
                        },
                        ...getColumnSearchOption(getDeviceCategoryOptions(), 'contains'),
                    },
                    {
                        title: geti18nText('customer.table.column.email'),
                        dataIndex: 'email',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('customer.table.column.country'),
                        dataIndex: ['country', 'name'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('customer.table.column.city'),
                        dataIndex: 'city',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('customer.table.column.active'),
                        dataIndex: 'active',
                        width: '10%',
                        render: (text: any, record: { active: any }) => {
                            if (record.active) {
                                return <CheckOutlined style={{ color: 'green' }} />;
                            } else {
                                return <CloseOutlined style={{ color: 'red' }} />;
                            }
                        },
                        ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                        defaultFilteredValue: setDefaultFilterValue(),
                    },
                ]}
            />
        </>
    );
};

export function formCategoryIcons(category: any) {
    return category.map((c: any) => {
        switch (c) {
            case 'WATERMETER':
                return <DashboardOutlined style={{ color: 'blue', marginLeft: '5px' }} />;
            case 'LIGHTS':
                return <BulbOutlined style={{ color: '#FFD700', marginLeft: '5px' }} />;
            case 'ENERGYMETER':
                return <ThunderboltOutlined style={{ color: '#FFA500', marginLeft: '5px' }} />;
            case 'WEATHER':
                return <CloudServerOutlined style={{ color: '#EA0D01', marginLeft: '5px' }} />;
        }
    });
}

export function formCategoryTag(category: any) {
    return category.map((c: any) => {
        switch (c) {
            case 'WATERMETER':
                return <Tag color="blue">{geti18nText('app.enum.DEVICE_CATEGORY.' + c)}</Tag>;
            case 'LIGHTS':
                return <Tag color="#FFD700">{geti18nText('app.enum.DEVICE_CATEGORY.' + c)}</Tag>;
            case 'ENERGYMETER':
                return <Tag color="#FFA500">{geti18nText('app.enum.DEVICE_CATEGORY.' + c)}</Tag>;
            case 'WEATHER':
                return <Tag color="#EA0D01">{geti18nText('app.enum.DEVICE_CATEGORY.' + c)}</Tag>;
        }
    });
}

export default CustomersIndex;
