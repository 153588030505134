import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { geti18nText, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import { RootState } from '../../../rootReducer';
import DeviceWeatherStateStatisticDetail from './DeviceWeatherStateStatisticDetail';
import { deviceWeatherStateDetailsInit } from '../../../slices/deviceWeatherStateDetailsReducer';
import PayloadWeatherStateHistoryTable from './PayloadWeatherStateHistory';
import AlarmsWeatherStateTable from './AlarmsWeatherStateTable';
import AlarmSettingsIndex from '../../alarm-settings';
import { AlarmSettingsType } from '../../../utils/Constants';

const { TabPane } = Tabs;

const DeviceWeatherDetail = () => {
    const dispatch = useDispatch();
    const { record, visible } = useSelector((state: RootState) => state.deviceWeatherStateDetails);
    const [modalTitle, setModalTitle] = useState(geti18nText('weatherStateDetails.modal.title'));

    const hideModal = () => {
        dispatch(deviceWeatherStateDetailsInit());
    };

    return (
        <Modal
            zIndex={1009}
            title={modalTitle}
            destroyOnClose={true}
            visible={visible}
            onOk={hideModal}
            onCancel={hideModal}
            width="90%"
            footer={null}
        >
            <Tabs type="card">
                <TabPane tab={geti18nText('deviceDetails.tab.overview')} key="1">
                    <Row>
                        <Col span={24}>
                            <DeviceWeatherStateStatisticDetail></DeviceWeatherStateStatisticDetail>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('deviceDetails.tab.alarms')} key="2">
                    <Row>
                        <Col span={24}>
                            <AlarmsWeatherStateTable previewType="BY_DEVICE" />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('deviceDetails.tab.payload')} key="3">
                    <Row>
                        <Col span={24}>
                            <PayloadWeatherStateHistoryTable />
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
        </Modal>
    );
};

export default DeviceWeatherDetail;
