import { NyRequestResolver, NyUtils, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Col, Divider, Radio, Row, Space, Switch, Typography } from 'antd';
import React from 'react';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import { APPLICATION_KEY, CONSTANTS_REQ } from '../../utils/Constants';
import WidgetWeatherMap from './WidgetWeatherMap';
import WeatherList from './WeatherList';
import ModalList from './ModalList';
import AlarmsWeatherStateTable from './weather/AlarmsWeatherStateTable';
import WeatherGroupList from './WeatherGroupList';
import SelectedWeatherValue from './weatherDataTypeFilter/SelectedWeatherValue';
import WeatherDataTypeFilter from './weatherDataTypeFilter/weatherDataTypeFilter';
import WeatherDataTypeCardPreview from './weatherDataTypeFilter/WeatherDataTypeCardPreview';
import WidgetWeatherMeterList from './WidgetWeatherMeterList';
import WidgetWeatherDeviceList from './weatherDataTypeFilter/WidgetWeatherDeviceList';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const { Text } = Typography;
export const defaultWidgetWeatherState: any = [
    {
        key: 'c5',
        component: 'WidgetMap',
        dataGrid: { w: 8, h: 13, x: 0, y: 0, minW: 1, minH: 1, moved: false, static: true },
        onResizeRedraw: true,
        categoryType: ['WEATHER'],
        static: true,
    },

    {
        key: 'c1',
        component: 'WeatherList',
        dataGrid: { w: 4, h: 13, x: 9, y: 0, minW: 1, minH: 1, moved: false, static: true },
        onResizeRedraw: true,
        categoryType: ['WEATHER'],
        static: true,
    },
    {
        key: 'c2',
        component: 'SelectedWeatherValue',
        dataGrid: { w: 12, h: 10, x: 0, y: 14, minW: 1, minH: 1, moved: false, static: true },
        onResizeRedraw: true,
        categoryType: ['WEATHER'],
        static: true,
    },

    {
        key: 'c3',
        component: 'WidgetAlarms',
        dataGrid: { w: 12, h: 13, x: 0, y: 25, minW: 4, minH: 4, moved: false, static: true },
        onResizeRedraw: true,
        categoryType: ['WATERMETER', 'LIGHTS', 'WEATHER'],
        static: true,
    },
];

export const defaultLayoutWeatherState: any = {
    lg: [
        { w: 8, h: 3, x: 0, y: 0, i: 'c5', minW: 1, minH: 1, moved: false, static: true }, //karta
        { w: 4, h: 13, x: 9, y: 0, i: 'c1', minW: 1, minH: 1, moved: false, static: true }, //list
        { w: 12, h: 10, x: 0, y: 13, i: 'c2', minW: 4, minH: 4, maxH: 16, moved: false, static: true }, //alarm
        { w: 12, h: 12, x: 0, y: 25, i: 'c3', minW: 4, minH: 4, moved: false, static: true }, //alarm
    ],
};

export const WidgetsComponentsMap: any = {
    WidgetMap: WidgetWeatherMap,
    WidgetAlarms: AlarmsWeatherStateTable,
    WeatherList: WeatherList,
    SelectedWeatherValue: SelectedWeatherValue,
};

interface IState {
    widgets: {
        key: string;
        component: string;
        dataGrid: { [index: string]: string | number | boolean };
        onResizeRedraw: boolean;
        categoryType: string[];
    }[];
    lastLayout: Layout[];
    layouts: Layouts;
    dashboardKey: string;
    lock: boolean;
    categoryType: string[];
    visibleDashboradPopup: boolean;
    showGridLayout: boolean;
    filter: boolean;
    userWidgets: any;
}

export interface IWidget {
    dataGrid: { [index: string]: string | number | boolean };
    elementKey: string;
    categoryType?: any;
    userWidgets?: any;
}

export interface DashProps {
    queryId?: any;
    onQueryIdChange?: (id: number) => void;
    selectedWeatherDataType?: any;
}

export default class WeatherDashboard extends React.Component<DashProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            categoryType: [],
            widgets: [],
            lastLayout: NyUtils.loadFromSession(APPLICATION_KEY + '-dashboard-last') || {},
            layouts: {},
            dashboardKey: '' + Date.now(),
            lock: true,
            visibleDashboradPopup: false,
            showGridLayout: true,
            filter: false,
            userWidgets: undefined,
        };
        this.redraw = this.redraw.bind(this);
    }

    async componentDidMount() {
        /* let result: any = await NyRequestResolver.requestGet(CONSTANTS_REQ.USER.WEATHER_WIDGETS);
        console.log('componentDidMount ', result);
        let userWidgetsTmp: any;
        if (result.status === RESPONSE.OK && result.data) {
            userWidgetsTmp = result.data;
            console.log('data ', userWidgetsTmp);
        }
        this.setState({
            widgets: defaultWidgetWeatherState,
            layouts: defaultLayoutWeatherState,
            categoryType: ['WEATHER'],
            lock: true,
            userWidgets: userWidgetsTmp ? userWidgetsTmp : undefined,
        });*/
    }

    componentDidUpdate(prevProps: DashProps) {
        if (prevProps.queryId !== this.props.queryId) {
        }
    }

    redraw() {
        this.forceUpdate();
    }

    createElement(obj: {
        key: string;
        component: string;
        dataGrid: { [index: string]: string | number | boolean };
        onResizeRedraw: boolean;
    }) {
        let lastLayout: { [index: string]: any } | undefined = undefined;
        if (this.state.lastLayout.length > 0) {
            const ly = this.state.lastLayout.find(({ i }) => i === obj.key);
            if (ly != undefined) lastLayout = { ...ly };
            delete lastLayout?.i;
        }

        let Tagname = WidgetsComponentsMap[obj.component];
        let settings: IWidget = {
            dataGrid: lastLayout != undefined ? lastLayout : obj.dataGrid,
            elementKey: obj.key,
            categoryType: ['WEATHER'],
            userWidgets: this.state.userWidgets,
        };

        console.log('kreiram element');
        const w = React.createElement(Tagname, settings);
        return React.createElement(
            'div',
            {
                id: 'container_' + obj.key,
                key: obj.key,
                'data-grid': obj.dataGrid,
                className: 'dashboard-widget-box',
            },
            w
        );
    }

    onChangeQueryId(value: any) {
        if (this.props.onQueryIdChange != undefined) {
            this.props.onQueryIdChange(value);
        }
    }

    render() {
        return (
            <>
                <Row style={{ width: '100%' }} gutter={[24, 2]}>
                    <WeatherDataTypeFilter></WeatherDataTypeFilter>
                </Row>
                <Row style={{ width: '100%' }} gutter={[12, 12]}>
                    <Col span={24}>
                        <WidgetWeatherMap />
                    </Col>
                </Row>
                <Row style={{ width: '100%' }} gutter={[12, 12]}>
                    <Col span={24}>
                        <Divider />
                        {this.props.selectedWeatherDataType == undefined ? (
                            <SelectedWeatherValue />
                        ) : (
                            <WeatherDataTypeCardPreview />
                        )}
                    </Col>
                </Row>
                <Row style={{ width: '100%' }} gutter={[12, 12]}>
                    <Col span={24}>
                        <WidgetWeatherDeviceList />
                    </Col>
                </Row>

                <Row style={{ width: '100%' }} gutter={[12, 12]}>
                    <Col span={24}>
                        <AlarmsWeatherStateTable />
                    </Col>
                </Row>

                <ModalList />
            </>
        );
    }
}
