import React, { useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { Row, Col, Form, Input, Select } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { geti18nText, NyDataEdit } from '@nybble/nyreact';
import { GetEnum } from '../../utils/Enums';
const { Option } = Select;
const DeviceTypeEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('deviceType.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setEditHeader(dataForm.id);
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('deviceType.edit.new'));
    };

    const deviceCategoryEnum = () => {
        const aTypes = GetEnum('DEVICE_CATEGORY');
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.DEVICE_CATEGORY.' + key);
                types.push(ret);
            }
        }
        return types;
    };

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 5 }, wrapperCol: { span: 14 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.DEVICE_TYPE.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={geti18nText('deviceType.edit.name')} name="name">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={geti18nText('deviceType.edit.category')}
                        name="deviceCategory"
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <Select>
                            {deviceCategoryEnum().map((option: { [index: string]: any }) => (
                                <Option key={option.id} value={option.id}>
                                    {option.text}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default DeviceTypeEdit;
