import React from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { Form, Modal, InputNumber, Descriptions, notification } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../rootReducer';
import { deviceManualConsumtionInit } from '../../slices/deviceManualConsumtionSlice';
import { refreshMap, refreshWatermeterList } from '../../slices/dashboardSlice';
import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';

const DeviceManualConsumption: React.FC = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { deviceId, data, visible } = useSelector((state: RootState) => state.deviceManualConsumtionSlice);
    const hideModal = () => {
        dispatch(deviceManualConsumtionInit());
    };

    function save(values: any) {
        NyRequestResolver.requestPost(CONSTANTS_REQ.DASHBOARD.MANUALCONSUMPTION, undefined, {
            device: { id: deviceId },
            volume: values.volume,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('app.default.save.ok.desc'),
                    duration: 3,
                });
                dispatch(refreshWatermeterList());
                dispatch(refreshMap());
                hideModal();
            } else {
                notification.error({ message: geti18nText('app.default.save.nok'), description: '' });
            }
        });
    }

    return (
        <React.Fragment>
            <Modal
                forceRender={true}
                visible={visible}
                title={geti18nText('dashboard.manual.consumption.title')}
                onCancel={() => hideModal()}
                okText={geti18nText('app.default.button.save')}
                width={600}
                onOk={() => {
                    form.validateFields().then((values) => {
                        save(values);
                    });
                }}
            >
                <Descriptions column={1}>
                    <Descriptions.Item label={geti18nText('dashboard.manual.consumption.name')}>
                        <b>{data && data.name ? data.name : ''}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label={geti18nText('dashboard.manual.consumption.serial')}>
                        <b>{data && data.serial ? data.serial : ''}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label={geti18nText('dashboard.manual.consumption.address')}>
                        <b>{data && data.address ? data.address : ''}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label={geti18nText('dashboard.manual.consumption.last.consumtion')}>
                        <b>{data && data.volume ? data.volume + ' m3' : ''}</b>
                    </Descriptions.Item>
                </Descriptions>
                <br />
                <Form form={form} layout="horizontal" labelCol={{ span: 4 }}>
                    <Form.Item
                        name="volume"
                        label={geti18nText('dashboard.manual.consumption.volume')}
                        initialValue={data && data.volume ? data.volume : undefined}
                        rules={[
                            {
                                required: true,
                                message: geti18nText('app.default.required'),
                            },
                        ]}
                    >
                        <InputNumber decimalSeparator="." min={0} style={{ width: '100%' }} />
                    </Form.Item>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default DeviceManualConsumption;
