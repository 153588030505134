import React, { useState } from 'react';
import { Form, Row, Col, Input, InputNumber, Divider, Tabs } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';
import TasksIndex from '../tasks/index';
import EndCustomerDevices from './EndCustomerDevices';
import { geti18nText, NyDataEdit, NySearchField, NySession } from '@nybble/nyreact';
import {isMobile} from 'react-device-detect';

const { TabPane } = Tabs;

const ConsumersEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('consumer.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [endCustomerId, setEndCustomerId] = useState(0);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setEndCustomerId(dataForm.id);
        setEditHeader(dataForm.firstName + ' ' + dataForm.lastName);
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        form.resetFields();
        setEndCustomerId(0);
        setEditHeader(geti18nText('consumer.edit.new'));
    };

    return (
        <NyDataEdit
            layout={(isMobile) ? "vertical" : "horizontal"}
            formProps={(!isMobile) ? { labelCol: { span: 7 }, wrapperCol: { span: 17 } } : {}}
            onModalClose={onModalClose}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.END_CUSTOMER.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            normalize={(values: any) => {
                if (values.customer) {
                    values.customer = {
                        id: values.customer.constructor === Number ? values.customer : values.customer.id,
                    };
                }
                if (values.contact.country) {
                    values.contact.country = {
                        id:
                            values.contact.country === Number
                                ? values.contact.country
                                : values.contact.country.key
                                ? values.contact.country.key
                                : values.contact.country.id,
                    };
                }
                return values;
            }}
            {...props}
        >
            <Tabs type="card">
                <TabPane tab={geti18nText('consumer.edit.tab.overview')} key="1">
                    {NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) && (
                        <Row gutter={24}>
                            <Col lg={12} md={12} sm={12} xs={24}>
                                <Form.Item
                                    label={geti18nText('consumer.edit.customer')}
                                    name="customer"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <NySearchField
                                        url={CONSTANTS_REQ.CUSTOMER.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}></Col>
                        </Row>
                    )}
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('consumer.edit.firstName')}
                                name="firstName"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('consumer.edit.lastName')}
                                name="lastName"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('consumer.edit.oib')}
                                name="oib"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                    {
                                        len: 11,
                                        message: geti18nText('consumer.edit.oib.length'),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('consumer.edit.code')}
                                name="code"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('consumer.edit.lat')}
                                name="lat"
                                rules={[
                                    {
                                        type: 'float',
                                        message: geti18nText('dashboard.widget.watermeter.edit.location.validation'),
                                        min: -90,
                                        max: 90,
                                    },
                                ]}
                            >
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('consumer.edit.lng')}
                                name="lng"
                                rules={[
                                    {
                                        type: 'float',
                                        message: geti18nText('dashboard.widget.watermeter.edit.location.validation'),
                                        min: -180,
                                        max: 180,
                                    },
                                ]}
                            >
                                <InputNumber />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider>{geti18nText('consumer.edit.contact')}</Divider>
                    <Row gutter={24}>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Form.Item name={['contact', 'id']} style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label={geti18nText('consumer.edit.address')}
                                name={['contact', 'address']}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={12} xs={24}>
                            <Form.Item label={geti18nText('consumer.edit.address2')} name={['contact', 'address2']}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('consumer.edit.postalCode')}
                                name={['contact', 'postalCode']}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('consumer.edit.city')}
                                name={['contact', 'city']}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('consumer.edit.state')} name={['contact', 'state']}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('consumer.edit.country')}
                                name={['contact', 'country']}
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <NySearchField
                                    url={CONSTANTS_REQ.COUNTRY.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('consumer.edit.email')} name={['contact', 'email']}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('consumer.edit.phone')} name={['contact', 'phone']}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('consumer.edit.tab.tasks')} key="2" disabled={endCustomerId == 0}>
                    <Row>
                        <Col span={24}>{endCustomerId > 0 && <TasksIndex endCustomerId={endCustomerId} />}</Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('consumer.edit.tab.devices')} key="3" disabled={endCustomerId == 0}>
                    <Row>
                        <Col span={24}>{endCustomerId > 0 && <EndCustomerDevices endCustomerId={endCustomerId} />}</Col>
                    </Row>
                </TabPane>
            </Tabs>
        </NyDataEdit>
    );
};

export default ConsumersEdit;
