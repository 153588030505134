import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { geti18nText, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import AlarmSettingsIndex from '.';
import AlarmsWeatherStateTable from '../dashboard/weather/AlarmsWeatherStateTable';

const { TabPane } = Tabs;

const WeatherDeviceAlarmTabsIndex = () => {
    return (
        <Tabs type="card">
            <TabPane tab={geti18nText('menu.weather_alarm_settings.allalarms')} key="1">
                <Row>
                    <Col span={24}>
                        <AlarmsWeatherStateTable previewType="ALL" />
                    </Col>
                </Row>
            </TabPane>
            <TabPane tab={geti18nText('menu.weather_alarm_settings.myalarms')} key="2">
                <Row>
                    <Col span={24}>
                        <AlarmsWeatherStateTable previewType="BY_USER" />
                    </Col>
                </Row>
            </TabPane>
            <TabPane tab={geti18nText('menu.weather_alarm_settings.settings')} key="3">
                <Row>
                    <Col span={24}>
                        <AlarmSettingsIndex></AlarmSettingsIndex>
                    </Col>
                </Row>
            </TabPane>
        </Tabs>
    );
};

export default WeatherDeviceAlarmTabsIndex;
