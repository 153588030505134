import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { Typography, Divider } from 'antd';
import {
    ResponsiveContainer,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    Bar,
    TooltipProps,
} from 'recharts';
import { geti18nText, NyRequestResolver, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';

const { Title } = Typography;

const DMAIndex = () => {
    const [stats, setStats] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    useEffect(() => {
        fetchStat();
    }, []);

    const fetchStat = () => {
        setLoading(true);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DASHBOARD.GROUPCONSUMPTION, { period: 'last-week' }).then(
            (result: any) => {
                setLoading(false);
                if (result && result.status == RESPONSE.OK) {
                    setStats(result.data);
                }
            }
        );
    };

    const CustomTooltip: React.FC<TooltipProps<0, ''>> = ({ payload }) => {
        let total: number = 0;
        let watermeter: number = 0;
        if (
            payload &&
            payload[0] != undefined &&
            typeof payload[0].value === 'number' &&
            typeof payload[1].value === 'number'
        ) {
            total = payload[0].value + payload[1].value;
            watermeter = payload[0].value;
        }

        if (payload != undefined && payload[1] != undefined) {
            return (
                <div style={{ backgroundColor: '#fff', border: '1px solid gray', padding: '10px' }}>
                    <div>
                        {payload[1].name}: {NyUtils.formatNumber(total, 2)} m3
                    </div>
                    <div>
                        {payload[0].name}: {NyUtils.formatNumber(watermeter, 2)} m3
                    </div>
                </div>
            );
        } else return <div></div>;
    };

    return (
        <React.Fragment>
            <Divider orientation="left" style={{ fontSize: '18px' }} plain>
                {geti18nText('dashboard.widget.GroupConsumption.title')}
            </Divider>
            <ResponsiveContainer width="100%" height={600}>
                {loading ? (
                    <NySpinner />
                ) : (
                    <BarChart data={stats}>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="converted" />
                        <YAxis
                            label={{
                                value: geti18nText('deviceDetails.graph.y.value'),
                                angle: -90,
                                position: 'insideLeft',
                            }}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Bar
                            dataKey="waterMeterValue"
                            name={geti18nText('dashboard.widget.waterMeterValue')}
                            stackId="a"
                            fill="#1890ff"
                        />
                        <Bar
                            dataKey="pumpValue"
                            name={geti18nText('dashboard.widget.pumpValue')}
                            stackId="a"
                            fill="#f01713"
                        />
                    </BarChart>
                )}
            </ResponsiveContainer>
        </React.Fragment>
    );
};

export default DMAIndex;
