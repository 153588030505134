import React from 'react';

import { useSelector } from 'react-redux';

import { getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NyUtils } from '@nybble/nyreact';
import { RootState } from '../../../rootReducer';
import { GetEnum, GetEnumNameForValue } from '../../../utils/Enums';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import PayloadLightEdit from './PayloadLightEdit';
import FormatNumber, { formatNumberValueString } from '../../number-formatter';

const PayloadLightHistoryTable = () => {
    const { record } = useSelector((state: RootState) => state.deviceLightDetails);

    const setDefaultFilterValue = () => {
        return [{ field: 'deviceId', condition: 'equals', value: record.id }];
    };

    const payloadTypes = () => {
        const aTypes = GetEnum('PAYLOAD_TYPE');
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.PAYLOAD_TYPE.' + key);
                types.push(ret);
            }
        }
        return types;
    };

    function actionTypes(value: any): any {
        const aTypes = GetEnum('PAYLOAD_STATUS_ACTION');
        let types = {};
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.PAYLOAD_STATUS_ACTION.' + key);
                if (value == aTypes[key]) {
                    types = ret;
                }
            }
        }
        return types;
    }

    const csvCustomizationColumns: any = () => {
        return [
            {
                'deviceConsumption.ts': (value: any) => {
                    if (value) {
                        const ts: Date = new Date(value);
                        return ts.toLocaleString(NyUtils.getSelectedLocale());
                    }
                    return '';
                },
            },
            {
                'deviceConsumption.current': (value: any) => {
                    if (value != undefined) {
                        return NyUtils.formatNumber(value, 2) + ' A';
                    }
                    return value;
                },
            },
            {
                'deviceConsumption.action': (value: any) => {
                    if (value != undefined) {
                        return geti18nText(
                            'app.enum.PAYLOAD_STATUS_ACTION.' + GetEnumNameForValue('PAYLOAD_STATUS_ACTION', value)
                        );
                    }
                    return '';
                },
            },
            {
                'deviceConsumption.electricEnergy': (value: any) => {
                    return value ? formatNumberValueString(value, true) : '';
                },
            },

            {
                'deviceConsumption.voltage': (value: any) => {
                    return value ? value + ' V' : '';
                },
            },
            {
                'deviceConsumption.leakageVoltage': (value: any) => {
                    return value ? value + ' V' : '';
                },
            },
            {
                'deviceConsumption.power': (value: any) => {
                    return value ? value + ' W' : '';
                },
            },
        ];
    };

    return (
        <NyDataTable
            headerTitle={geti18nText('payloadLightHistory.table.header')}
            url={CONSTANTS_REQ.DEVICE.PAYLOAD_LIGHT_HISTORY}
            showRecordModal={true}
            scroll={{ x: 1200 }}
            exportCSV={true}
            hideButtons={true}
            modalComponent={PayloadLightEdit}
            setDefaultFilterValue={setDefaultFilterValue}
            colCSVCustomization={csvCustomizationColumns()}
            columns={[
                {
                    title: geti18nText('payloadLightHistory.table.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('payloadLightHistory.table.electric.energy'),
                    dataIndex: ['deviceConsumption', 'electricEnergy'],
                    render: (text: string, record: { [index: string]: any }) => {
                        return <FormatNumber value={record.deviceConsumption?.electricEnergy}></FormatNumber>;
                    },

                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('payloadLightHistory.table.current'),
                    dataIndex: ['deviceConsumption', 'current'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('payloadLightHistory.table.voltage'),
                    dataIndex: ['deviceConsumption', 'voltage'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('payloadLightHistory.table.power'),
                    dataIndex: ['deviceConsumption', 'power'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('payloadLightHistory.table.power.factor'),
                    dataIndex: ['deviceConsumption', 'powerFactor'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('payloadLightHistory.table.dimming'),
                    dataIndex: ['deviceConsumption', 'dimmingValue'],
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.deviceConsumption?.dimmingValue == -1) {
                            return <div>{geti18nText('payloadLightHistory.light.dimming.off')}</div>;
                        }
                        return record.deviceConsumption?.dimmingValue;
                    },
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('payloadLightHistory.table.payloadType'),
                    dataIndex: ['deviceConsumption', 'payloadType'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.deviceConsumption?.payloadType) {
                            return (
                                <div>
                                    <b>
                                        {geti18nText(
                                            'app.enum.PAYLOAD_TYPE.' +
                                                GetEnumNameForValue(
                                                    'PAYLOAD_TYPE',
                                                    record.deviceConsumption.payloadType
                                                )
                                        )}
                                    </b>
                                </div>
                            );
                        }
                        return <div></div>;
                    },
                    ...getColumnSearchOption(payloadTypes()),
                },
                {
                    title: geti18nText('payloadLightHistory.table.ts'),
                    dataIndex: ['deviceConsumption', 'ts'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.deviceConsumption?.ts) {
                            const ts: Date = new Date(record.deviceConsumption.ts);
                            return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                        }
                    },
                },
                {
                    title: geti18nText('payloadLightHistory.table.action'),
                    dataIndex: ['deviceConsumption', 'action'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                    render: (text: string, record: { [index: string]: any }) => {
                        if (record.action) {
                            return <>{actionTypes(record['deviceConsumption']['action']).text}</>;
                        }
                    },
                },
                {
                    title: geti18nText('payloadHistory.table.column.payload'),
                    dataIndex: 'payload',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('payloadHistory.table.column.mac'),
                    dataIndex: ['payloadInfo', 'mac'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
            ]}
        />
    );
};

export default PayloadLightHistoryTable;
