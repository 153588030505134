import React, { useState } from 'react';
import { CONSTANTS_REQ, DeviceCategoryType } from '../../utils/Constants';
import DeviceGroupEdit from './edit';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import ModalList from './ModalList';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';

const DeviceGroup = (props: any) => {
    const { device, refreshDevice } = useSelector((state: RootState) => state.selectedDeviceGroup);
    const [type, setType] = useState(props.type ? props.type : DeviceCategoryType.WATERMETER);

    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const columns = [
        {
            title: geti18nText('deviceGroup.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('deviceGroup.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('deviceGroup.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
            defaultFilteredValue: setDefaultFilterValue(),
        },
    ];

    const columnsAdmin = [
        {
            title: geti18nText('deviceGroup.table.column.id'),
            dataIndex: 'id',
            width: '10%',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('deviceGroup.table.column.name'),
            dataIndex: 'name',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('deviceGroup.table.column.customer'),
            dataIndex: ['customer', 'name'],
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('deviceGroup.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
            defaultFilteredValue: setDefaultFilterValue(),
        },
    ];

    const checkColumns = () => {
        if (NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'])) {
            return columnsAdmin;
        } else {
            return columns;
        }
    };

    return (
        <>
            <NyDataTable
                headerTitle={geti18nText('deviceGroup.table.header')}
                url={CONSTANTS_REQ.DEVICE_GROUP.LIST}
                addNewButtonText={geti18nText('deviceGroup.table.add')}
                showRecordModal={true}
                exportCSV={true}
                fetchWhenChange={refreshDevice}
                scroll={{ x: 500 }}
                modalComponent={DeviceGroupEdit}
                setDefaultFilterValue={setDefaultFilterValue}
                addedData={{ type: type }}
                columns={checkColumns()}
            />
            <ModalList />
        </>
    );
};

export default DeviceGroup;
