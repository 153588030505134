import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IDeviceLocationState {
    deviceId: string | undefined;
    data: { [index: string]: any } | undefined;
    visible: boolean;
}

const initialState: IDeviceLocationState = {
    deviceId: undefined,
    data: {},
    visible: false,
};

const deviceLocation = createSlice({
    name: 'deviceLocation',
    initialState,
    reducers: {
        deviceLocationInit(state) {
            state.deviceId = undefined;
            state.data = {};
            state.visible = false;
        },
        deviceLocationOpen(state, action: PayloadAction<IDeviceLocationState>) {
            const { data, visible = true, deviceId } = action.payload;
            state.deviceId = deviceId;
            state.data = data;
            state.visible = visible;
        },
    },
});

export const { deviceLocationInit, deviceLocationOpen } = deviceLocation.actions;

export default deviceLocation.reducer;
