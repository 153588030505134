import React from 'react';
import GatewayMap from './GatewayMap';
import { Row, Col } from 'antd';
import AlarmsGateway from './AlarmsGateway';
import GatewayList from './GatewayList';
import GatewayDetails from './GatewayDetails';
import { NySession } from '@nybble/nyreact';

const GatewaysIndex = () => {
    return (
        <React.Fragment>
            <Row gutter={24}>
                <Col
                    md={{ span: NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) ? 12 : 24 }}
                    xs={{ span: 24 }}
                >
                    <div className="box">
                        <GatewayMap />
                    </div>
                </Col>
                <Col md={{ span: 12 }} xs={{ span: 24 }}>
                    {NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) && (
                        <div className="box">
                            <GatewayList />
                        </div>
                    )}
                </Col>
            </Row>
            <Row gutter={24}>
                {NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) && (
                    <Col span={24}>
                        <div className="box">
                            <AlarmsGateway />
                        </div>
                    </Col>
                )}
            </Row>
            <GatewayDetails />
        </React.Fragment>
    );
};

export default GatewaysIndex;
