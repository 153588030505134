import { geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import React from 'react';
import { CONSTANTS_REQ, DeviceCategoryType } from '../../utils/Constants';
import Device from '../device';

const EnergyMeters = () => {
    return (
        <>
            <Device type={DeviceCategoryType.ENERGYMETER}></Device>
        </>
    );
};

export default EnergyMeters;
