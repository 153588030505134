import React from 'react';
import { Popover, Typography } from 'antd';

import { getColumnSearchOption, geti18nText, NyDataTable, NyUtils } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnumNameForValue } from '../../../utils/Enums';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { deviceDetailsInit } from '../../../slices/deviceDetailsSlice';
import FormatNumber from '../../number-formatter';

const { Title } = Typography;

interface IChartDataConsumption {
    period?: any;
    title: string | null;
    deviceId: number | null;
    dateFrom?: any;
    dateTo?: any;
}

const ChartDataConsumption: React.FC<IChartDataConsumption> = ({ period, title, deviceId, dateFrom, dateTo }) => {
    const alarmList: any = (list: string, alarm_type: number) => {
        const alarms: any = list && list.split(',');
        var result: any = [];
        if (alarms != null && alarms.length > 0) {
            if (alarm_type === 1) {
                result = alarms.map((x: any) => GetEnumNameForValue('TERMINAL_NODE_ALARM', parseInt(x)));
            } else {
                result = alarms.map((x: any) => GetEnumNameForValue('LAMP_ALARM', parseInt(x)));
            }
        }
        return result;
    };

    return (
        <div>
            <Title level={4}>{geti18nText('chart.data.consumption.title') + ' - ' + title}</Title>
            <NyDataTable
                url={CONSTANTS_REQ.DEVICE.LIGHTS_CONSUMPTION_DATA}
                hideButtons={true}
                addedData={{ ...period, deviceId: deviceId, timestampFrom: dateFrom, timestampTo: dateTo }}
                scroll={{ x: 800 }}
                readonly={true}
                fetchWhenChange={dateFrom}
                columns={[
                    {
                        title: geti18nText('chart.data.consumption.table.volume'),
                        dataIndex: 'electricEnergy',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return <FormatNumber value={record.electricEnergy}></FormatNumber>;
                        },
                    },
                    {
                        title: geti18nText('chart.data.consumption.table.current'),
                        dataIndex: 'current',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.current ? NyUtils.formatNumber(record.current, 2) : '0,0'}
                                    &nbsp;A
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('chart.data.consumption.table.voltage'),
                        dataIndex: 'voltage',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.voltage ? NyUtils.formatNumber(record.voltage, 2) : '0,0'}
                                    &nbsp;V
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('chart.data.consumption.table.power'),
                        dataIndex: 'power',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.power ? NyUtils.formatNumber(record.power, 2) : '0,0'}
                                    &nbsp;W
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('chart.data.consumption.table.leakage_voltage'),
                        dataIndex: 'leakageVoltage',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.leakageVoltage ? NyUtils.formatNumber(record.leakageVoltage, 2) : '0,0'}
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('chart.data.consumption.table.ts'),
                        dataIndex: 'ts',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.ts) {
                                const ts: Date = new Date(record.ts);
                                return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                            }
                        },
                    },
                    {
                        title: geti18nText('chart.data.consumption.table.payloadType'),
                        dataIndex: 'payloadType',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {geti18nText(
                                        'app.enum.PAYLOAD_TYPE.' +
                                            GetEnumNameForValue('PAYLOAD_TYPE', record.payloadType)
                                    )}
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('chart.data.consumption.table.node_alarm'),
                        dataIndex: 'nodeAlarm',
                        align: 'center',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.nodeAlarm == 0) {
                                return <CheckCircleOutlined style={{ color: 'green', fontSize: '18px' }} />;
                            } else {
                                return (
                                    <Popover
                                        title={geti18nText('chart.data.consumption.table.node_alarm')}
                                        content={
                                            <div>
                                                {alarmList(record.alarmList, 1).map((element: any) => {
                                                    return (
                                                        <div>
                                                            {geti18nText('app.enum.TERMINAL_NODE_ALARM.' + element)}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        }
                                    >
                                        <ExclamationCircleOutlined style={{ color: 'red', fontSize: '18px' }} />
                                    </Popover>
                                );
                            }
                        },
                    },
                    {
                        title: geti18nText('chart.data.consumption.table.lamp_alarm'),
                        dataIndex: 'lampAlarm',
                        align: 'center',
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.lampAlarm == 0) {
                                return <CheckCircleOutlined style={{ color: 'green', fontSize: '18px' }} />;
                            } else {
                                return (
                                    <Popover
                                        title={geti18nText('chart.data.consumption.table.lamp_alarm')}
                                        content={
                                            <div>
                                                {alarmList(record.alarmList, 2).map((element: any) => {
                                                    return <div>{geti18nText('app.enum.LAMP_ALARM.' + element)}</div>;
                                                })}
                                            </div>
                                        }
                                    >
                                        <ExclamationCircleOutlined style={{ color: 'red', fontSize: '18px' }} />
                                    </Popover>
                                );
                            }
                        },
                    },
                ]}
            />
        </div>
    );
};

export default ChartDataConsumption;
