import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IDeviceManualConsumtionState {
    deviceId: string | undefined;
    data: { [index: string]: any } | undefined;
    visible: boolean;
}

const initialState: IDeviceManualConsumtionState = {
    deviceId: undefined,
    data: {},
    visible: false,
};

const deviceManualConsumtion = createSlice({
    name: 'deviceManualConsumtion',
    initialState,
    reducers: {
        deviceManualConsumtionInit(state) {
            state.deviceId = undefined;
            state.data = {};
            state.visible = false;
        },
        deviceManualConsumtionOpen(state, action: PayloadAction<IDeviceManualConsumtionState>) {
            const { data, visible = true, deviceId } = action.payload;
            state.deviceId = deviceId;
            state.data = data;
            state.visible = visible;
        },
    },
});

export const { deviceManualConsumtionInit, deviceManualConsumtionOpen } = deviceManualConsumtion.actions;

export default deviceManualConsumtion.reducer;
