import React from 'react';
import ImportCSV from '../../components/import-csv';
import { CONSTANTS_REQ } from '../../utils/Constants';
import ImportExportModal from './ImportEdit';

const ImportExportButtons = (onSave: () => void) => {
    return <ImportExportModal save={onSave} />;
};

export default ImportExportButtons;
