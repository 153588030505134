import React, { useEffect, useState } from 'react';
import { Typography, Button, Modal } from 'antd';
import {
    ResponsiveContainer,
    BarChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend,
    Tooltip,
    Bar,
    Label,
    LineChart,
    Line,
    Surface,
    Sector,
    PolarGrid,
    PolarAngleAxis,
    RadarChart,
    PolarRadiusAxis,
} from 'recharts';
import { FullscreenOutlined, InfoOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { geti18nText, NySpinner } from '@nybble/nyreact';
import ChartWeatherStateDataConsumption from '../ChartWeatherStateDataConsumption';
import { TickItem } from 'recharts/types/util/types';

const { Title } = Typography;
interface IWeatherChartSettings {
    title: string;
    yLabel: string;
    color: string;
}

interface IWeatherChart {
    loading: boolean | null;
    data: any;
    title: string | null;
    deviceId?: number;
    period?: string;
    showYLabel?: boolean;
    settings?: IWeatherChartSettings;
    params?: string[];
}

const WeatherPolarChart: React.FC<IWeatherChart> = ({
    loading,
    data,
    title,
    deviceId,
    period,
    showYLabel = true,
    settings,
    params = [],
}) => {
    const [visible, setVisible] = useState(false);
    const [visibleLegend, setVisibleLegend] = useState(false);
    const [visibleDataVolumes, setVisibleDataVolumes] = useState(false);
    const [dataP, setData] = useState<any>(null);

    const centerX = 200;
    const centerY = 200;
    const angle = 45;
    const startAngleMargin = 10;
    const endAngleMargin = 10;

    useEffect(() => {
        if (data != null) {
            const data2 = {
                rowFills: ['#63B24A', '#EFFD5F', '#FCD12A', '#F32013'],
                rowOuterRadius: data,
                numSegments: 8,
            };
            setData(data2);
        }
    }, [data]);

    const polarRadius = [10, 20, 40, 80, 100];
    const polarAngles = [0, 45, 90, 135, 180, 225, 270, 315];

    const ticks: TickItem[] = [
        { coordinate: 90, value: geti18nText('weatherStateDetails.windDirection.north.short') },
        { coordinate: 45, value: geti18nText('weatherStateDetails.windDirection.northeast.short') },
        { coordinate: 0, value: geti18nText('weatherStateDetails.windDirection.east.short') },
        { coordinate: 315, value: geti18nText('weatherStateDetails.windDirection.southeast.short') },
        { coordinate: 270, value: geti18nText('weatherStateDetails.windDirection.south.short') },
        { coordinate: 225, value: geti18nText('weatherStateDetails.windDirection.southwest.short') },
        { coordinate: 180, value: geti18nText('weatherStateDetails.windDirection.west.short') },
        { coordinate: 135, value: geti18nText('weatherStateDetails.windDirection.northwest.short') },
    ];
    const dataWithNone = [
        { value: geti18nText('weatherStateDetails.windSpeed.very_weak'), color: '#63B24A' },
        { value: geti18nText('weatherStateDetails.windSpeed.weak'), color: '#EFFD5F' },
        { value: geti18nText('weatherStateDetails.windSpeed.strong'), color: '#FCD12A' },
        { value: geti18nText('weatherStateDetails.windSpeed.very_strong'), color: '#F32013' },
    ];
    const renderTick = (props: any) => {
        const { payload, x, y } = props;

        return (
            <text x={x} y={y}>
                {payload.value}
            </text>
        );
    };

    const styles: any = {
        polar: {
            transform: 'rotate(-67deg)',
            transition: 'transform 150ms ease',
        },
        polar1: {
            // transform: 'scaleX(-1)',
            transform: 'rotate(-67deg)',
            transition: 'transform 200ms ease',
            marginLeft: '-20px',
        },
        legendTextColor: {
            color: 'black',
        },
    };

    return (
        <div className="box" style={{ height: '95%' }}>
            <Title style={{ fontSize: '1.4em', width: '75%' }}>{settings?.title + ' - ' + title}</Title>
            <Button
                style={{ position: 'absolute', right: '80px', top: '20px', backgroundColor: 'lightblue' }}
                shape="circle"
                onClick={() => setVisibleLegend(true)}
                icon={<InfoOutlined />}
            />
            {deviceId && (
                <Button
                    style={{ position: 'absolute', right: '50px', top: '20px' }}
                    shape="circle"
                    onClick={() => setVisibleDataVolumes(true)}
                    icon={<UnorderedListOutlined />}
                />
            )}
            <Button
                style={{ position: 'absolute', right: '20px', top: '20px' }}
                shape="circle"
                onClick={() => setVisible(true)}
                icon={<FullscreenOutlined />}
            />
            <ResponsiveContainer width="100%" height={350}>
                {loading || data == null ? (
                    <NySpinner />
                ) : (
                    <Surface height={200} width={0}>
                        {dataP?.rowOuterRadius?.map((outerRadius: any, index: any) => {
                            const outerRadiusIndex = index;
                            const prevOuterRadius =
                                outerRadiusIndex === 0
                                    ? new Array(dataP.numSegments).fill(1)
                                    : dataP.rowOuterRadius[outerRadiusIndex - 1];
                            const fill = dataP.rowFills[outerRadiusIndex];

                            return (
                                outerRadius != undefined &&
                                outerRadius.map((radius: any, index: any) => {
                                    const segmentIndex = index;
                                    const innerRadius = prevOuterRadius[segmentIndex];

                                    return (
                                        <Sector
                                            fill={fill}
                                            innerRadius={innerRadius}
                                            outerRadius={radius}
                                            cx={centerX}
                                            cy={centerY}
                                            startAngle={(segmentIndex - 1) * angle + startAngleMargin + 112}
                                            endAngle={segmentIndex * angle - endAngleMargin + 112}
                                            key={segmentIndex * outerRadiusIndex}
                                            scale={100}
                                            strokeWidth={100}
                                        ></Sector>
                                    );
                                })
                            );
                        })}
                        <PolarGrid
                            cx={centerX}
                            cy={centerY}
                            innerRadius={0}
                            outerRadius={150}
                            width={250}
                            height={250}
                            polarAngles={polarAngles}
                            polarRadius={polarRadius}
                            scale={100}
                        ></PolarGrid>

                        <PolarAngleAxis
                            cx={centerX}
                            cy={centerY}
                            radius={140}
                            ticks={ticks as TickItem[]}
                            tick={renderTick}
                        />
                    </Surface>
                )}
            </ResponsiveContainer>
            <Modal
                title={settings?.title + ' - ' + title}
                visible={visibleLegend}
                zIndex={1010}
                onOk={() => setVisibleLegend(false)}
                onCancel={() => setVisibleLegend(false)}
                width={'80%'}
                centered={true}
            >
                <ResponsiveContainer width="100%" height={150}>
                    <Legend
                        width={200}
                        height={30}
                        payload={dataWithNone as any}
                        formatter={(value, entry, index) => <span style={styles.legendTextColor}>{value}</span>}
                    ></Legend>
                </ResponsiveContainer>
            </Modal>

            <Modal
                title={settings?.title + ' - ' + title}
                visible={visible}
                zIndex={1010}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={'80%'}
                centered={true}
            >
                <ResponsiveContainer width="100%" height={350}>
                    {loading || data == null ? (
                        <NySpinner />
                    ) : (
                        <Surface height={200} width={0}>
                            {dataP?.rowOuterRadius?.map((outerRadius: any, index: any) => {
                                const outerRadiusIndex = index;
                                const prevOuterRadius =
                                    outerRadiusIndex === 0
                                        ? new Array(dataP.numSegments).fill(1)
                                        : dataP.rowOuterRadius[outerRadiusIndex - 1];
                                const fill = dataP.rowFills[outerRadiusIndex];

                                return (
                                    outerRadius != undefined &&
                                    outerRadius.map((radius: any, index: any) => {
                                        const segmentIndex = index;
                                        const innerRadius = prevOuterRadius[segmentIndex];

                                        return (
                                            <Sector
                                                fill={fill}
                                                innerRadius={innerRadius}
                                                outerRadius={radius}
                                                cx={centerX}
                                                cy={centerY}
                                                startAngle={(segmentIndex - 1) * angle + startAngleMargin + 112}
                                                endAngle={segmentIndex * angle - endAngleMargin + 112}
                                                key={segmentIndex * outerRadiusIndex}
                                            ></Sector>
                                        );
                                    })
                                );
                            })}
                            <PolarGrid
                                cx={centerX}
                                cy={centerY}
                                innerRadius={0}
                                outerRadius={150}
                                width={300}
                                height={300}
                                polarAngles={polarAngles}
                                polarRadius={polarRadius}
                                scale={100}
                            ></PolarGrid>

                            <PolarAngleAxis
                                cx={centerX}
                                cy={centerY}
                                radius={140}
                                ticks={ticks as TickItem[]}
                                tick={renderTick}
                            />
                        </Surface>
                    )}
                </ResponsiveContainer>
                <div style={{ position: 'absolute', right: '200px', top: '80px' }}>
                    <Legend width={200} height={30} payload={dataWithNone as any} layout="vertical"></Legend>
                </div>
            </Modal>
            {deviceId && (
                <Modal
                    visible={visibleDataVolumes}
                    zIndex={1010}
                    onCancel={() => setVisibleDataVolumes(false)}
                    width={'80%'}
                    centered={true}
                    footer={null}
                >
                    <ChartWeatherStateDataConsumption
                        title={title}
                        deviceId={deviceId}
                        period={{ period: period }}
                        params={params}
                    />
                </Modal>
            )}
        </div>
    );
};

export default WeatherPolarChart;
