import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { Button, Modal, notification } from 'antd';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { geti18nText, NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { refreshDeviceList } from '../../slices/selectedDeviceGroupSlice';

const DeleteFromGroup = (hasSelected: any, selectedRowKeys: any, onPopupSave: any, clearSelectedRowKeys: any) => {
    const { device } = useSelector((state: RootState) => state.selectedDeviceGroup);
    const [visible, setVisible] = useState(false);
    const dispatch = useDispatch();

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleOk = () => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE.DELETE_DEVICES_FROM_GROUP, undefined, {
            groupId: Number(device),
            devices: selectedRowKeys,
        }).then((result: any) => {
            if (result.status === RESPONSE.CREATED) {
                setVisible(false);
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('app.default.save.ok.desc'),
                    duration: 3,
                });
                clearSelectedRowKeys();
                dispatch(refreshDeviceList());
            }
        });
    };

    return (
        <React.Fragment>
            <Button type="primary" danger disabled={!hasSelected} onClick={showModal}>
                {geti18nText('deleteFromGroup.button')}
            </Button>

            <Modal
                title={geti18nText('deleteFromGroup.modal.title')}
                visible={visible}
                onCancel={handleCancel}
                okButtonProps={{ danger: true }}
                okText={geti18nText('deleteFromGroup.modal.okText')}
                onOk={handleOk}
            >
                {geti18nText('deleteFromGroup.modal.text')}
            </Modal>
        </React.Fragment>
    );
};

export default DeleteFromGroup;
