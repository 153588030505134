import React, { useState } from 'react';
import { Row, Col, Form, Input, Divider, Descriptions } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { geti18nText, NyDataEdit } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnum, GetEnumNameForValue } from '../../../utils/Enums';
import FormatNumber from '../../number-formatter';

const PayloadLightEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState('Payload');
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [item, setItem] = useState<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setEditHeader(dataForm.id);
        setDataForm(dataForm.id);

        delete dataForm.active;
        setItem(dataForm);
        form.setFieldsValue(dataForm);
    }

    function actionTypes(value: any): any {
        const aTypes = GetEnum('PAYLOAD_STATUS_ACTION');
        let types = {};
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.PAYLOAD_STATUS_ACTION.' + key);
                if (value == aTypes[key]) {
                    types = ret;
                }
            }
        }
        return types;
    }

    return (
        <NyDataEdit
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.DEVICE.PAYLOAD_LIGHT_HISTORY}
            setValues={setValues}
            form={form}
            width={900}
            goBack={() => history.goBack()}
            paramsId={id}
            hideActivationButtons={true}
            hideSubmitButton={true}
            {...props}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Divider orientation="left">{geti18nText('payloadLightHistory.table.title')}</Divider>
                    {item && item.deviceConsumption && (
                        <Descriptions column={2}>
                            <Descriptions.Item label={geti18nText('payloadLightHistory.table.electric.energy')}>
                                <FormatNumber value={item['deviceConsumption']['electricEnergy']}></FormatNumber>
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('payloadLightHistory.table.payloadType')}>
                                {item['deviceConsumption']['payloadType'] &&
                                    geti18nText(
                                        'app.enum.PAYLOAD_TYPE.' +
                                            GetEnumNameForValue(
                                                'PAYLOAD_TYPE',
                                                item['deviceConsumption']['payloadType']
                                            )
                                    )}
                            </Descriptions.Item>

                            <Descriptions.Item label={geti18nText('payloadLightHistory.table.dimming')}>
                                {item['deviceConsumption']['dimmingValue'] == -1
                                    ? geti18nText('payloadLightHistory.light.dimming.off')
                                    : item['deviceConsumption']['dimmingValue']}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('payloadLightHistory.table.current')}>
                                {item['deviceConsumption']['current']} A
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('payloadLightHistory.table.voltage')}>
                                {item['deviceConsumption']['voltage']} V
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('payloadLightHistory.table.power')}>
                                {item['deviceConsumption']['power']} W
                            </Descriptions.Item>

                            <Descriptions.Item label={geti18nText('payloadLightHistory.table.power.factor')}>
                                {item['deviceConsumption']['powerFactor']}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('payloadLightHistory.table.leakage.voltage')}>
                                {item['deviceConsumption']['leakageVoltage']}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('payloadLightHistory.table.lighting.time')}>
                                {item['deviceConsumption']['lightingTime']} h
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('payloadLightHistory.table.action')}>
                                {actionTypes(item['deviceConsumption']['action']).text}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('payloadLightHistory.table.ts')}>
                                {item['deviceConsumption']['ts']}
                            </Descriptions.Item>
                        </Descriptions>
                    )}

                    <Divider />

                    <Form.Item label={'payload'} name="payload">
                        <Input />
                    </Form.Item>
                    <Divider orientation="left">{geti18nText('payloadLightHistory.table.payload.info.title')}</Divider>
                    {item && item.payloadInfo && (
                        <Descriptions column={2}>
                            <Descriptions.Item label={'antenna'}>{item['payloadInfo']['antenna']}</Descriptions.Item>

                            <Descriptions.Item label={'rssi'}>{item['payloadInfo']['rssi']}</Descriptions.Item>
                            <Descriptions.Item label={'payloadLength'}>
                                {item['payloadInfo']['payloadLength']}
                            </Descriptions.Item>
                            <Descriptions.Item label={'channel'}>{item['payloadInfo']['channel']}</Descriptions.Item>

                            <Descriptions.Item label={'dataRateSpreadFactor'}>
                                {item['payloadInfo']['dataRateSpreadFactor']}
                            </Descriptions.Item>
                            <Descriptions.Item label={'code'}>{item['payloadInfo']['code']}</Descriptions.Item>
                            <Descriptions.Item label={'codeRate'}>{item['payloadInfo']['codeRate']}</Descriptions.Item>

                            <Descriptions.Item label={'dataRateModulation'}>
                                {item['payloadInfo']['dataRateModulation']}
                            </Descriptions.Item>

                            <Descriptions.Item label={'frequency'}>
                                {item['payloadInfo']['frequency']}
                            </Descriptions.Item>

                            <Descriptions.Item label={'size'}>{item['payloadInfo']['size']}</Descriptions.Item>

                            <Descriptions.Item label={'port'}>{item['payloadInfo']['port']}</Descriptions.Item>

                            <Descriptions.Item label={'size'}>{item['payloadInfo']['size']}</Descriptions.Item>

                            <Descriptions.Item label={'rfChain'}>{item['payloadInfo']['rfChain']}</Descriptions.Item>
                            <Descriptions.Item label={'dataRateBandwidth'}>
                                {item['payloadInfo']['dataRateBandwidth']}
                            </Descriptions.Item>
                            <Descriptions.Item label={'loraSnr'}>{item['payloadInfo']['loraSnr']}</Descriptions.Item>
                            <Descriptions.Item label={'crcStatus'}>
                                {item['payloadInfo']['crcStatus']}
                            </Descriptions.Item>
                        </Descriptions>
                    )}

                    <Divider />
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default PayloadLightEdit;
