import React, { useState, useEffect } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { GetEnumNameForValue, GetEnum } from '../../utils/Enums';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NyUtils } from '@nybble/nyreact';
import DeviceImport from '../warehouse/DeviceImport';
import { Popover, Tag, Typography, Tooltip } from 'antd';
import ImportExportButtons from './ImportExportButtons';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../rootReducer';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { refreshImportExportList } from '../../slices/importExportSlice';

const ImportExportList = () => {
    const { refresh } = useSelector((state: RootState) => state.importExport);
    const { Text } = Typography;
    const [isTypeExport, setIsTypeExport] = useState<any>(true);
    const dispatch = useDispatch();
    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(refreshImportExportList());
        }, 1000 * 60 * 5);
    }, [refresh]);

    const ieTypes = () => {
        const aTypes = GetEnum('IMPORT_EXPORT_TYPE');
        let types: any[] = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.IMPORT_EXPORT_TYPE.' + key);
                types.push(ret);
            }
        }
        return types;
    };
    const ieStatus = () => {
        const aTypes = GetEnum('IMPORT_EXPORT_STATUS');
        let types: any[] = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.IMPORT_EXPORT_STATUS.' + key);
                types.push(ret);
            }
        }
        return types;
    };
    const onRowSelect = (record: { [index: string]: any }) => {};
    const onDataLoaded = (records: { [index: string]: any } | string, params: { [index: string]: any } | undefined) => {
        if (params && params.search) {
            const obj = JSON.parse('{"search":' + decodeURI(params.search) + '}');
            for (const searchParam of obj.search) {
                if (searchParam.condition == 'equals' && searchParam.field == 'ieType') {
                    if (GetEnumNameForValue('IMPORT_EXPORT_TYPE', parseInt(searchParam.value)) === 'EXPORT') {
                        setIsTypeExport(true);
                    } else {
                        setIsTypeExport(false);
                    }
                }
            }
        }
    };

    const downloadLog = (filename: any, text: any) => {
        var element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
    };

    const getColumns = () => {
        const columns: any = [
            {
                title: geti18nText('importExport.table.column.id'),
                dataIndex: 'id',
                width: '10%',
                sorter: (a: any, b: any) => {},
                ...getColumnSearch('number'),
            },
            {
                title: geti18nText('importExport.table.column.status'),
                dataIndex: 'status',
                width: '10%',
                sorter: (a: any, b: any) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    return (
                        <div>
                            {record.status === 0 && (
                                <Tag>
                                    {geti18nText(
                                        'app.enum.IMPORT_EXPORT_STATUS.' +
                                            GetEnumNameForValue('IMPORT_EXPORT_STATUS', record.status)
                                    )}
                                </Tag>
                            )}
                            {record.status === 1 && (
                                <Tag color="orange">
                                    {geti18nText(
                                        'app.enum.IMPORT_EXPORT_STATUS.' +
                                            GetEnumNameForValue('IMPORT_EXPORT_STATUS', record.status)
                                    )}
                                </Tag>
                            )}
                            {record.status === 2 && (
                                <Tag color="success">
                                    {geti18nText(
                                        'app.enum.IMPORT_EXPORT_STATUS.' +
                                            GetEnumNameForValue('IMPORT_EXPORT_STATUS', record.status)
                                    )}
                                </Tag>
                            )}
                            {record.status === 3 && (
                                <Tag color="error">
                                    {geti18nText(
                                        'app.enum.IMPORT_EXPORT_STATUS.' +
                                            GetEnumNameForValue('IMPORT_EXPORT_STATUS', record.status)
                                    )}
                                </Tag>
                            )}
                        </div>
                    );
                },
                ...getColumnSearchOption(ieStatus()),
            },
            {
                title: geti18nText('importExport.table.column.created'),
                dataIndex: 'created',
                width: '15%',
                sorter: (a: any, b: any) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.created) {
                        const cDate: Date = new Date(record.created);
                        return <div>{cDate.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                    }
                },
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('importExport.table.column.ieType'),
                dataIndex: 'ieType',
                width: '10%',
                sorter: (a: any, b: any) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    return (
                        <div>
                            <Tag>
                                {geti18nText(
                                    'app.enum.IMPORT_EXPORT_TYPE.' +
                                        GetEnumNameForValue('IMPORT_EXPORT_TYPE', record.ieType)
                                )}
                            </Tag>
                        </div>
                    );
                },
                ...getColumnSearchOption(ieTypes()),
            },
            {
                title: geti18nText('importExport.table.column.log'),
                dataIndex: 'log',
                sorter: (a: any, b: any) => {},
                render: (text1: string, record: { [index: string]: any }) => {
                    let text = record.log;
                    return (
                        <>
                            {text && text.length > 45 && (
                                <Popover
                                    placement="left"
                                    content={text}
                                    overlayStyle={{
                                        whiteSpace: 'pre-line',
                                        maxHeight: '40vh',
                                        overflowY: 'auto',
                                        border: '1px solid #E0E0E0',
                                    }}
                                >
                                    <Text>{text.substring(0, 150)}</Text>
                                </Popover>
                            )}
                            {(text == null || text.length <= 45) && <Text mark>{text}</Text>}
                            {text != null && (
                                <Tooltip placement="top" title={geti18nText('upload.download')} arrowPointAtCenter>
                                    <CloudDownloadOutlined
                                        onClick={() =>
                                            downloadLog(isTypeExport ? 'exportLog.txt' : 'importLog.txt', record.log)
                                        }
                                        style={{
                                            fontSize: '18px',
                                            marginRight: '20px',
                                            marginLeft: '10px',
                                            float: 'right',
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </>
                    );
                },
                ...getColumnSearch('string'),
            },
        ];
        // if (isTypeExport) {
        //     let fileItem = {
        //         title: geti18nText('upload.text.file'),
        //         render: (text: string, record: { [index: string]: any }) => {
        //             return (
        //                 <CloudDownloadOutlined
        //                     onClick={() => downloadLog()}
        //                     style={{
        //                         fontSize: '18px',
        //                         marginRight: '10px',
        //                         marginLeft: '10px',
        //                     }}
        //                 />
        //             );
        //         },
        //     };
        //     columns.push(fileItem);
        // }

        return columns;
    };

    return (
        <React.Fragment>
            <NyDataTable
                url={CONSTANTS_REQ.IMPORT_EXPORT.LIST}
                scroll={{ y: '37.5vh' }}
                onRowSelect={onRowSelect}
                addNewButtonText={''}
                addedButtons={ImportExportButtons}
                onDataLoaded={onDataLoaded}
                columns={getColumns()}
                fetchWhenChange={refresh}
            ></NyDataTable>
        </React.Fragment>
    );
};

export default ImportExportList;
