import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, List, Card, Statistic } from 'antd';
import {
    HomeOutlined,
    PhoneOutlined,
    MailOutlined,
    LineChartOutlined,
    ThunderboltOutlined,
    FallOutlined,
    HeatMapOutlined,
    WarningOutlined,
    BulbOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { geti18nText, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import { RootState } from '../../../rootReducer';
import { CONSTANTS_REQ, DeviceCategoryType } from '../../../utils/Constants';
import Chart from '../../device-details/Chart';
import ChartSpan from '../ChartSpan';
import ChartLightSpan from './ChartLightSpan';
import ZoomChart from './ZoomChart';
import { formatNumberValueString } from '../../number-formatter';

const { Title } = Typography;

const DeviceLightStatisticDetail = () => {
    const { record } = useSelector((state: RootState) => state.deviceLightDetails);

    const [last24hData, setLast24hData] = useState<any>(null);
    const [currentWeekData, setCurrentWeekData] = useState<any>(null);
    const [lastWeekData, setLastWeekData] = useState<any>(null);
    const [currentMonthData, setCurrentMonthData] = useState<any>(null);
    const [currentYearData, setCurrentYearData] = useState<any>(null);
    const [snapshotData, setSnapshotData] = useState<any>(null);
    const [widgetData, setWidgetData] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [parName, setParName] = useState<any>();

    useEffect(() => {
        if (parName == null) {
            fetchLast24hValue(record.id);
            fetchCurrentWeekData(record.id);
            fetchLastWeekData(record.id);
            fetchCurrentMonthData(record.id);
            fetchCurrentYearData(record.id);
        }

        fetchCurrentValue(record.id);
        fetchWidgetValue(record.id);
    }, []);

    const fetchLast24hValue = (value: any) => {
        setLast24hData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_LIGHT_CONSUMPTION, {
            period: 'last-24h',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: value,
            parName: parName,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setLast24hData(response.data);
            }
        });
    };

    const fetchCurrentWeekData = (value: any) => {
        setCurrentWeekData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_LIGHT_CONSUMPTION, {
            period: 'current-week',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: value,
            parName: parName,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setCurrentWeekData(response.data);
            }
        });
    };

    const fetchLastWeekData = (value: any) => {
        setLastWeekData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_LIGHT_CONSUMPTION, {
            period: 'last-week',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: value,
            parName: parName,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setLastWeekData(response.data);
            }
        });
    };

    const fetchCurrentMonthData = (value: any) => {
        setCurrentMonthData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_LIGHT_CONSUMPTION, {
            period: 'current-month',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: value,
            parName: parName,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setCurrentMonthData(response.data);
            }
        });
    };

    const fetchCurrentYearData = (value: any) => {
        setCurrentYearData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_LIGHT_CONSUMPTION, {
            period: 'current-year',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: value,
            parName: parName,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setCurrentYearData(response.data.filter((x: any) => x.barValue != null));
            }
        });
    };

    const fetchCurrentValue = (value: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CURRENT_LIGHTS_CONSUMPTION_FOR, { deviceId: value }).then(
            (response: any) => {
                if (response.status === RESPONSE.OK) {
                    setSnapshotData(response.data);
                }
            }
        );
    };

    const fetchWidgetValue = (value: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CONSUMPTION_FOR_TODAY_AND_CURRENT_WEEK_FOR_DEVICE, {
            deviceId: value,
            type: DeviceCategoryType.LIGHTS,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setWidgetData(response.data);
            }
            setLoading(false);
        });
    };

    return (
        <React.Fragment>
            <Row>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <div className="box" style={{ marginRight: '10px', height: '95%' }}>
                        <Card style={{ height: '45%', cursor: 'pointer' }} onClick={() => setParName(null)}>
                            <Statistic
                                title={geti18nText('deviceDetails.widget1.light.title')}
                                value={widgetData != null ? formatNumberValueString(widgetData['today'], true) : ' '}
                                precision={2}
                                valueStyle={{
                                    color: parName == null ? '#FFD700' : '#808080',
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<LineChartOutlined />}
                            />
                        </Card>
                        <Card
                            style={{ marginTop: '10px', height: '45%', cursor: 'pointer' }}
                            onClick={() => setParName(null)}
                        >
                            <Statistic
                                title={geti18nText('deviceDetails.widget2.light.title')}
                                value={
                                    widgetData != null ? formatNumberValueString(widgetData['current-week'], true) : ' '
                                }
                                precision={2}
                                valueStyle={{
                                    color: parName == null ? '#FFD700' : '#808080',
                                    fontSize: '24px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<LineChartOutlined />}
                            />
                        </Card>
                    </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <div className="box" style={{ marginRight: '10px', height: '95%' }}>
                        <Card onClick={() => setParName('current')} style={{ cursor: 'pointer' }}>
                            <Statistic
                                title={geti18nText('deviceDetails.widget3.light.title')}
                                value={snapshotData != null ? NyUtils.formatNumber(snapshotData['current'], 2) : ' '}
                                precision={2}
                                valueStyle={{
                                    color: parName == 'current' ? '#FFD700' : '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<ThunderboltOutlined />}
                                suffix="A"
                            />
                        </Card>
                        <Card onClick={() => setParName('voltage')} style={{ cursor: 'pointer' }}>
                            <Statistic
                                title={geti18nText('deviceDetails.widget4.light.title')}
                                value={snapshotData != null ? NyUtils.formatNumber(snapshotData['voltage'], 2) : ' '}
                                precision={2}
                                valueStyle={{
                                    color: parName == 'voltage' ? '#FFD700' : '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<WarningOutlined />}
                                suffix="V"
                            />
                        </Card>
                        <Card onClick={() => setParName('power')} style={{ cursor: 'pointer' }}>
                            <Statistic
                                title={geti18nText('deviceDetails.widget5.light.title')}
                                value={snapshotData != null ? NyUtils.formatNumber(snapshotData['power'], 2) : ' '}
                                precision={2}
                                valueStyle={{
                                    color: parName == 'power' ? '#FFD700' : '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<LineChartOutlined />}
                                suffix="W"
                            />
                        </Card>
                        <Card onClick={() => setParName('dimming_value')} style={{ cursor: 'pointer' }}>
                            <Statistic
                                title={geti18nText('deviceDetails.widget6.light.title')}
                                value={
                                    snapshotData != null
                                        ? snapshotData['dimmingValue'] == -1
                                            ? geti18nText('dashboard.widget.light.dimming.off')
                                            : NyUtils.formatNumber(snapshotData['dimmingValue'], 2)
                                        : ' '
                                }
                                precision={2}
                                valueStyle={{
                                    color: parName == 'dimming_value' ? '#FFD700' : '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<BulbOutlined />}
                                suffix=""
                            />
                        </Card>
                        <Card onClick={() => setParName('leakage_voltage')} style={{ cursor: 'pointer' }}>
                            <Statistic
                                title={geti18nText('deviceDetails.widget7.light.title')}
                                value={
                                    snapshotData != null ? NyUtils.formatNumber(snapshotData['leakageVoltage'], 2) : ' '
                                }
                                precision={2}
                                valueStyle={{
                                    color: parName == 'leakage_voltage' ? '#FFD700' : '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<FallOutlined />}
                                suffix=""
                            />
                        </Card>
                    </div>
                </Col>
                {parName == null && (
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                        <Chart
                            loading={loading}
                            data={last24hData}
                            title={geti18nText('report.time_range.last24h')}
                            deviceId={record.id}
                            category={DeviceCategoryType.LIGHTS}
                            period="last-24h"
                            showYLabel={false}
                            formatNumber={true}
                        />
                    </Col>
                )}
                {parName == null && (
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                        <Chart
                            loading={loading}
                            data={currentWeekData}
                            title={geti18nText('report.time_range.current_week')}
                            deviceId={record.id}
                            category={DeviceCategoryType.LIGHTS}
                            period="current-week"
                            showYLabel={false}
                            formatNumber={true}
                        />
                    </Col>
                )}
            </Row>
            {parName == null ? (
                <Row>
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                        <Chart
                            loading={loading}
                            data={lastWeekData}
                            title={geti18nText('report.time_range.last_week')}
                            deviceId={record.id}
                            category={DeviceCategoryType.LIGHTS}
                            period="last-week"
                            showYLabel={false}
                            formatNumber={true}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                        <Chart
                            loading={loading}
                            data={currentMonthData}
                            title={geti18nText('report.time_range.current_month')}
                            deviceId={record.id}
                            category={DeviceCategoryType.LIGHTS}
                            period="current-month"
                            showYLabel={false}
                            formatNumber={true}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                        <Chart
                            loading={loading}
                            data={currentYearData}
                            title={geti18nText('report.time_range.current_year')}
                            deviceId={record.id}
                            category={DeviceCategoryType.LIGHTS}
                            period="current-year"
                            showYLabel={false}
                            formatNumber={true}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                        <ChartLightSpan />
                    </Col>
                </Row>
            ) : (
                <ZoomChart loading={loading} deviceId={record.id} parm={parName}></ZoomChart>
            )}
        </React.Fragment>
    );
};

export default DeviceLightStatisticDetail;
