import { ApiFilled, AreaChartOutlined, DisconnectOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Card, Row, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IWidget } from '../..';
import { RootState } from '../../../../rootReducer';
import { CONSTANTS_REQ } from '../../../../utils/Constants';
import { getUserCategoryType } from '../../../../utils/Enums';
import {
    filterActiveWeatherState,
    filterActiveWeatherStateReset,
    filterInactiveWeatherState,
    filterInactiveWeatherStateReset,
} from '../../../../slices/dashboardSlice';

const WidgetCardsWeatherState: React.FC<IWidget> = ({ showWeatherStateOn, dataGrid }) => {
    const [data, setData] = useState<any>({});
    const [loading, setLoading] = useState(true);
    const { weatherStateActive, weatherStateInactive, mapSelectedBounds } = useSelector(
        (state: RootState) => state.dashboard
    );
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        if (getUserCategoryType().includes('WEATHER')) {
            fetch();
        }
    }, [mapSelectedBounds]);

    const fetch = () => {
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.DASHBOARD.STATISTIC_WEATHER_STATE,
            mapSelectedBounds != null
                ? {
                      latNorthEast: mapSelectedBounds.latNorthEast,
                      lngNorthEast: mapSelectedBounds.lngNorthEast,
                      latSouthWest: mapSelectedBounds.latSouthWest,
                      lngSouthWest: mapSelectedBounds.lngSouthWest,
                  }
                : {}
        ).then((result) => {
            if (result && result.status == RESPONSE.OK) {
                setLoading(false);
                setData(result.data);

                setLoading(false);
            }
        });
    };

    const filterActiveWeatherStateOnClick = (e: any) => {
        if (e === true) {
            dispatch(filterActiveWeatherState());
        } else {
            dispatch(filterActiveWeatherStateReset());
        }
    };
    const filterInactiveWeatherStateOnClick = (e: any) => {
        if (e === true) {
            dispatch(filterInactiveWeatherState());
        } else {
            dispatch(filterInactiveWeatherStateReset());
        }
    };

    function getHeight() {
        console.log(dataGrid);
        if (typeof dataGrid.h == 'number') {
            return dataGrid.h * 10;
        }
    }
    function getWidth() {
        console.log(dataGrid);

        // if (typeof dataGrid.w == 'number') {
        //     if (dataGrid.w * 133 > 300 && dataGrid.w * 133 < 960) return { width: (dataGrid.w * 133 - 20) / 3 };
        //     /* else if(dataGrid.w * 133 >  960) {
        //         return {width: (dataGrid.w * 133 - 40) / 6};
        //     }
        //     else return undefined; */
        // }

        return undefined;
    }

    return (
        <React.Fragment>
            {getUserCategoryType() &&
                getUserCategoryType().length > 0 &&
                getUserCategoryType().includes('WEATHER') &&
                showWeatherStateOn &&
                data && (
                    <div className="widget-cards-card-container-weather">
                        <Card className="widget-cards-card-weather" style={getWidth()}>
                            <div className="widget-cards-text-bottom">
                                <div className="widget-cards-number-weather">
                                    {loading && <NySpinner />}
                                    {data.active}
                                </div>
                                <div className="widget-cards-title-energy">
                                    <span>{geti18nText('dashboard.widget.card.active.weatherstate')}</span>
                                </div>
                            </div>
                            <div className="widget-cards-background-icon-watermeter">
                                <Switch size="small" onChange={(e: any) => filterActiveWeatherStateOnClick(e)} />
                            </div>
                            <div className="widget-cards-background-icon-box">
                                <ApiFilled
                                    style={weatherStateActive ? { color: 'black' } : {}}
                                    className="widget-cards-background-icon-box-watermeter"
                                />
                            </div>
                        </Card>
                        <Card className="widget-cards-card-weather" style={getWidth()}>
                            <div className="widget-cards-text-bottom">
                                <div className="widget-cards-number-weather">
                                    {loading && <NySpinner />}
                                    {data.inactive}
                                </div>
                                <div className="widget-cards-title-energy">
                                    <span>{geti18nText('dashboard.widget.card.active.inactivestate')}</span>
                                </div>
                            </div>
                            <div className="widget-cards-background-icon-watermeter">
                                <Switch size="small" onChange={(e: any) => filterInactiveWeatherStateOnClick(e)} />
                            </div>
                            <div className="widget-cards-background-icon-box">
                                <DisconnectOutlined
                                    style={weatherStateInactive ? { color: 'black' } : {}}
                                    className="widget-cards-background-icon-box-watermeter"
                                />
                            </div>
                        </Card>
                    </div>
                )}
        </React.Fragment>
    );
};

export default WidgetCardsWeatherState;
