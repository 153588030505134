import React from 'react';
import DeviceDetails from '../device-details';
import DeviceEditContactInfo from './DeviceEditContactInfo';
import DeviceEditLocation from './DeviceEditLocation';
import DeviceEditAlarmThrashold from './DeviceEditAlarmThrashold';
import DeviceManualConsumption from './DeviceManualConsumption';
import AlarmEditDescription from './AlarmEditDescription';
import GenerateTasksForDevices from './GenerateTasksForDevices';
import DeviceLightDetail from './lights/DeviceLightDetail';
import DeviceEnergyDetail from './energy';
import DeviceWeatherDetail from './weather';
import DeviceWeatherChartDetail from './weather/deviceWeatherChartDetail';

const ModalList: React.FC = () => {
    return (
        <React.Fragment>
            <AlarmEditDescription />
            <DeviceDetails />
            <DeviceLightDetail />
            <DeviceEnergyDetail />
            <DeviceEditContactInfo />
            <DeviceEditLocation />
            <DeviceEditAlarmThrashold />
            <DeviceManualConsumption />
            <GenerateTasksForDevices />
            <DeviceWeatherDetail />
            <DeviceWeatherChartDetail />
        </React.Fragment>
    );
};

export default ModalList;
