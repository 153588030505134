import React, { useState } from 'react';
import Title from 'antd/lib/typography/Title';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { GetEnum, GetEnumNameForValue } from '../../utils/Enums';
import { FormOutlined } from '@ant-design/icons';
import { Form, Modal, notification, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import {
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    RESPONSE,
} from '@nybble/nyreact';

const AlarmsGateway = () => {
    const [form] = Form.useForm();
    const [visible, setVisible] = useState(false);
    const [alarmId, setAlarmId] = useState<Number | undefined>(undefined);
    const [alarmListRefresh, setAlarmListRefresh] = useState(0);

    const alarmTypes = () => {
        const aTypes = GetEnum('ALARM_STATUS');
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.ALARM_STATUS.' + key);
                types.push(ret);
            }
        }
        return types;
    };

    const editDescription = (id: number, description: string) => {
        setAlarmId(id);
        if (description != null) {
            form.setFieldsValue({ description: description });
        }
        setVisible(true);
    };

    function save(values: any) {
        NyRequestResolver.requestPut(CONSTANTS_REQ.GATEWAY.ALARM_DESCRIPTION + '/' + alarmId, undefined, {
            id: alarmId,
            description: values.description,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.CREATED) {
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('app.default.save.ok.desc'),
                    duration: 3,
                });
                setAlarmListRefresh(alarmListRefresh + 1);
                hideModal();
            } else {
                notification.error({ message: geti18nText('app.default.save.nok'), description: '' });
            }
        });
    }

    const hideModal = () => {
        setVisible(false);
        form.resetFields();
    };

    return (
        <React.Fragment>
            <Title level={4}>{geti18nText('gateway.alarm.table.title')}</Title>
            <NyDataTable
                url={CONSTANTS_REQ.GATEWAY.ALARMS}
                hideButtons={true}
                scroll={{ y: 180, x: 800 }}
                fetchWhenChange={alarmListRefresh}
                readonly={true}
                columns={[
                    {
                        title: geti18nText('gateway.alarm.table.name'),
                        dataIndex: ['gateway', 'name'],
                        sorter: (a: string, b: string) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('gateway.alarm.table.mac'),
                        dataIndex: ['gateway', 'mac'],
                        sorter: (a: string, b: string) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('gateway.alarm.table.address'),
                        dataIndex: ['gateway', 'contact', 'address'],
                        sorter: (a: string, b: string) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('gateway.alarm.table.type'),
                        dataIndex: 'alarmStatus',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    <b style={{ color: 'red' }}>
                                        {geti18nText(
                                            'app.enum.ALARM_STATUS.' +
                                                GetEnumNameForValue('ALARM_STATUS', record.alarmStatus)
                                        )}
                                    </b>
                                </div>
                            );
                        },
                        ...getColumnSearchOption(alarmTypes()),
                    },
                    {
                        dataIndex: 'operation',
                        width: '10%',
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <React.Fragment>
                                    <Tooltip title={geti18nText('dashboard.widget.alarm.table.description.tooltip')}>
                                        {record.description ? (
                                            <FormOutlined
                                                style={{
                                                    fontSize: '18px',
                                                    marginRight: '10px',
                                                    color: '#40a9ff',
                                                }}
                                                onClick={() => editDescription(record.alarm_id, record.description)}
                                            />
                                        ) : (
                                            <FormOutlined
                                                style={{
                                                    fontSize: '18px',
                                                    marginRight: '10px',
                                                }}
                                                onClick={() => editDescription(record.alarm_id, record.description)}
                                            />
                                        )}
                                    </Tooltip>
                                </React.Fragment>
                            );
                        },
                    },
                ]}
            />

            <Modal
                visible={visible}
                title={geti18nText('dashboard.widget.alarm.edit.description.title')}
                onCancel={hideModal}
                okText={geti18nText('app.default.button.save')}
                width={600}
                onOk={() => {
                    form.validateFields().then((values) => {
                        save(values);
                    });
                }}
            >
                <Form form={form} layout="horizontal" labelCol={{ span: 4 }}>
                    <Form.Item
                        name="description"
                        label={geti18nText('dashboard.widget.alarm.edit.description.description')}
                    >
                        <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
                    </Form.Item>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

export default AlarmsGateway;
