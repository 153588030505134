import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Form, Select } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { GetEnumNameForValue, GetEnum } from '../../utils/Enums';
import { selectedItemSet } from '../../slices/selectedItemSlice';
import { geti18nText, NyDataEdit, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';

const AlarmEdit = (props: any) => {
    const dispatch = useDispatch();
    const { item } = useSelector((state: RootState) => state.selectedItem);

    const [editHeader, setEditHeader] = useState(geti18nText('user.alarm.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [alarmList, setAlarmList] = useState<any>(undefined);
    const [userAlarmList, setUserAlarmList] = useState<any>(undefined);
    const [userID, setUserID] = useState(undefined);
    const [isCreate, setIsCreate] = useState(false);

    const [form] = Form.useForm();
    const history = useHistory();

    useEffect(() => {
        loadUserData();
    }, []);

    function loadUserData() {
        setUserID(item);
        NyRequestResolver.requestGet(CONSTANTS_REQ.USER.USER_ALARM_BY_USER_ID + '/' + item).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                let user_alarms: any = [];
                user_alarms.push(0);

                response.data.forEach(function (value: any) {
                    user_alarms.push(value.alarmStatus);
                });

                const aTypes = GetEnum('ALARM_STATUS');
                let alarms = [];
                let disabled_alarms: any = [];
                for (var key in aTypes) {
                    if (aTypes.hasOwnProperty(key) && user_alarms.indexOf(aTypes[key]) == -1) {
                        let ret = { value: undefined, label: undefined };
                        ret.value = aTypes[key];
                        ret.label = geti18nText('app.enum.ALARM_STATUS.' + key);
                        alarms.push(ret);
                    } else if (aTypes.hasOwnProperty(key)) {
                        let ret = { value: undefined, label: undefined };
                        ret.value = aTypes[key];
                        ret.label = geti18nText('app.enum.ALARM_STATUS.' + key);
                        disabled_alarms.push(ret);
                    }
                }
                console.log(disabled_alarms);
                setAlarmList(alarms);
                setUserAlarmList(disabled_alarms);
            }
        });
    }

    function setValues(dataForm: any) {
        setEditHeader(dataForm.id);
        setDataForm(dataForm.id);

        delete dataForm.active;
        console.log(dataForm);
        form.setFieldsValue(dataForm);
    }

    function normalizeValues(values: any) {
        let normalized = { ...values };
        normalized.userId = userID;
        normalized.alarmStatus = form.getFieldValue('alarmStatus');

        return normalized;
    }

    const onModalClose = () => {
        form.resetFields();
        setEditHeader(geti18nText('user.alarm.edit.new'));
        loadUserData();
    };

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 7 }, wrapperCol: { span: 17 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.USER.ALARM_EDIT}
            setValues={setValues}
            width={600}
            form={form}
            goBack={() => history.goBack()}
            setIsCreate={setIsCreate}
            hideSubmitButton={!isCreate}
            {...props}
            normalize={normalizeValues}
            onModalClose={onModalClose}
            destroyOnClose={true}
            hideButtons={!NySession.hasAnyRole(['ROLE_AUTH_ADMIN'])}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    {!isCreate && (
                        <Form.Item label={geti18nText('user.alarm.edit.alarmStatus')} name="alarmStatus">
                            <Select disabled={true} options={userAlarmList}></Select>
                        </Form.Item>
                    )}
                    {isCreate && (
                        <Form.Item label={geti18nText('user.alarm.edit.alarmStatus')} name="alarmStatus">
                            <Select options={alarmList}></Select>
                        </Form.Item>
                    )}
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default AlarmEdit;
