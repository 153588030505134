import React, { useState } from 'react';
import { CONSTANTS_REQ, DeviceCategoryType } from '../../utils/Constants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession } from '@nybble/nyreact';
import { Tag } from 'antd';
import ParameterCatalogEdit from './edit';
import { parametarKeyName } from '../../utils/Utils';

const ParameterCatalogIndex = (props: any) => {
    const setDefaultFilterValue = () => {
        return [{ field: 'active', condition: 'equals_bool', value: 1 }];
    };

    const columns = [
        {
            dataIndex: 'key',
            width: '10%',
            render: (text: any, record: any) => {
                if (record.icon) {
                    let icon = 'data:image/jpeg;base64,' + record.icon;
                    return <img style={{ maxWidth: '50px', maxHeight: '50px' }} src={icon}></img>;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
            defaultFilteredValue: setDefaultFilterValue(),
        },

        {
            title: geti18nText('parameter.catalog.table.column.name'),
            dataIndex: parametarKeyName('name'),
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('parameter.catalog.table.column.minRange'),
            dataIndex: 'minRange',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('parameter.catalog.table.column.maxRange'),
            dataIndex: 'maxRange',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('parameter.catalog.table.column.resolution'),
            dataIndex: 'resolution',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('number'),
        },
        {
            title: geti18nText('parameter.catalog.table.column.unit'),
            dataIndex: 'unit',
            sorter: (a: any, b: any) => {},
            ...getColumnSearch('string'),
        },
        {
            title: geti18nText('category.table.column.active'),
            dataIndex: 'active',
            width: '10%',
            render: (text: any, record: { active: any }) => {
                if (record.active) {
                    return <CheckOutlined style={{ color: 'green' }} />;
                } else {
                    return <CloseOutlined style={{ color: 'red' }} />;
                }
            },
            ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
            defaultFilteredValue: setDefaultFilterValue(),
        },
    ];

    return (
        <>
            <NyDataTable
                headerTitle={geti18nText('parameter.catalog.table.header')}
                url={CONSTANTS_REQ.WEATHER_PARAMETER.LIST}
                modalComponent={ParameterCatalogEdit}
                showRecordModal={true}
                hideNewButton
                exportCSV={true}
                scroll={{ x: 500 }}
                setDefaultFilterValue={setDefaultFilterValue}
                columns={columns}
                setDefaultPageSize={20}
            />
        </>
    );
};

export default ParameterCatalogIndex;
