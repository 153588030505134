import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IDeviceAlarmThrasholdState {
    deviceId: string | undefined;
    endCustomerId: string | undefined;
    customerCode: string | undefined;
    visible: boolean;
}

const initialState: IDeviceAlarmThrasholdState = {
    deviceId: undefined,
    endCustomerId: undefined,
    customerCode: undefined,
    visible: false,
};

const deviceAlarmThrashold = createSlice({
    name: 'deviceAlarmThrashold',
    initialState,
    reducers: {
        deviceAlarmThrasholdInit(state) {
            state.deviceId = undefined;
            state.endCustomerId = undefined;
            state.customerCode = undefined;
            state.visible = false;
        },
        deviceAlarmThrasholdOpen(state, action: PayloadAction<IDeviceAlarmThrasholdState>) {
            const { visible = true, deviceId, endCustomerId, customerCode } = action.payload;
            state.deviceId = deviceId;
            state.endCustomerId = endCustomerId;
            state.customerCode = customerCode;
            state.visible = visible;
        },
    },
});

export const { deviceAlarmThrasholdInit, deviceAlarmThrasholdOpen } = deviceAlarmThrashold.actions;

export default deviceAlarmThrashold.reducer;
