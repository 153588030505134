import React, { useState, useEffect } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { IWidget } from './index';
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    PushpinOutlined,
    ZoomInOutlined,
    FullscreenOutlined,
    CommentOutlined,
} from '@ant-design/icons';
import { deviceLocationOpen } from '../../slices/deviceLocationSlice';
import { RootState } from '../../rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
    refresLightsList,
    setMapPanTo,
    filterDeviceDevices,
    filterReset,
    refreshWatermeterList,
} from '../../slices/dashboardSlice';
import { Tooltip, Modal, Popover } from 'antd';
import { GetEnumNameForValue, GetEnum } from '../../utils/Enums';
import { getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession, NyUtils } from '@nybble/nyreact';
import { deviceLightDetailsOpen } from '../../slices/deviceLightDetailsSlice';
import FormatNumber, { formatNumberValueString } from '../number-formatter';

const getScrolls = (dataGrid: { [index: string]: string | number | boolean | undefined}) => {
    let scr: { [index: string]: any } = {};
    if (typeof dataGrid.h == 'number') {
        scr.y = 40 * dataGrid.h - 170;
    }
    return scr;
};

const getModalScrolls = (dataGrid: { [index: string]: string | number | boolean | undefined }) => {
    let scr: { [index: string]: any } = { x: 1400 };

    return scr;
};

interface ColumnTitleProps {
    text: string;
}

const ColumnTitle: React.FC<ColumnTitleProps> = ({ text }) => {
    return <div style={{ whiteSpace: 'nowrap' }}>{text}</div>;
};

const WidgetLightsList: React.FC<IWidget> = ({ dataGrid }) => {
    const [visible, setVisible] = useState(false);

    const lightsListRefresh = useSelector((state: RootState) => state.dashboard.lightsListRefresh);
    const mapSelectedBounds = useSelector((state: RootState) => state.dashboard.mapSelectedBounds);
    const { lightsActive,lightsAlarm } = useSelector((state: RootState) => state.dashboard);

    const filterDevice = useSelector((state: RootState) => state.dashboard.filterDevice);
    const dispatch = useDispatch();

    useEffect(() => {
        console.log('LIST REFRESH ', lightsListRefresh);
    }, [lightsListRefresh]);

    useEffect(() => {
        dispatch(refresLightsList());
    }, [mapSelectedBounds]);


    const editLocation = (deviceId: string, data?: { [index: string]: any }) => {
        dispatch(deviceLocationOpen({ deviceId: deviceId, data: data, visible: true }));
    };

    const onRowSelect = (record: { [index: string]: any }) => {
        dispatch(deviceLightDetailsOpen({ record: record, visible: true }));
    };

    const filterErrorOptions = [
        { id: '0', text: geti18nText('dashboard.widget.watermeter.filer.error.ok') },
        { id: '1', text: geti18nText('dashboard.widget.watermeter.filer.error.nok') },
    ];

    const setDefaultFilterValue = () => {
        let filter=[];
        if (lightsActive) {
            filter.push({ field: 'device_status', condition: 'equals', value: 4 });
        }
        if(lightsAlarm) {
            filter.push({ field: 'nodeAlarm', condition: 'equals', value: 1 });
        }

        return filter;
    };

    const generateKey = () => {
        return 'WidgetLightsList-K1'; // new Date().toUTCString();
    };

    const showOnMap = (record: { [index: string]: any }) => {
        if (record.lat && record.lng) {
            dispatch(setMapPanTo({ lat: record.lat, lng: record.lng }));
        }
    };

    const actionTypes = () => {
        const aTypes = GetEnum('PAYLOAD_STATUS_ACTION');
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.PAYLOAD_STATUS_ACTION.' + key);
                types.push(ret);
            }
        }
        return types;
    };

    const showOnMapFilter = (item: any) => {
        if (filterDevice != item.id) {
            showOnMap(item);
            dispatch(filterDeviceDevices({ id: item.id }));
        } else {
            dispatch(filterReset());
        }
    };

    const createDate = (data: any[]) => {
        if (data.length >= 3 && data.length < 5) {
            return new Date(data[0], data[1] - 1, data[2]);
        } else if (data.length == 5) {
            return new Date(data[0], data[1] - 1, data[2], data[3], data[4]);
        } else if (data.length > 5) {
            return new Date(data[0], data[1] - 1, data[2], data[3], data[4], data[5]);
        } else {
            return '';
        }
    };

    const alarmList: any = (list: string, alarm_type: number) => {
        const alarms: any = list && list.split(',');
        var result: any = [];
        if (alarms != null && alarms.length > 0) {
            if (alarm_type === 1) {
                result = alarms.map((x: any) => GetEnumNameForValue('TERMINAL_NODE_ALARM', parseInt(x)));
            } else {
                result = alarms.map((x: any) => GetEnumNameForValue('LAMP_ALARM', parseInt(x)));
            }
        }
        return result;
    };

    const columns: any = () => {
        return [
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.light.table.name')} />,
                dataIndex: 'name',
                sorter: (a: string, b: string) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    if ('comment' in record) {
                        return (
                            <div>
                                {record.name}
                                <Tooltip title={record.comment}>
                                    <CommentOutlined
                                        style={{
                                            fontSize: '16px',
                                            marginLeft: '10px',
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        );
                    } else {
                        return <div> {record.name} </div>;
                    }
                },
                ...getColumnSearch('string'),
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.light.table.serial')} />,
                dataIndex: 'eui',
                sorter: (a: string, b: string) => {},
                ...getColumnSearch('string'),
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.light.table.electric.energy')} />,
                dataIndex: 'electricEnergy',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return <FormatNumber value={record.electricEnergy}></FormatNumber>;
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.light.table.current')} />,
                dataIndex: 'current',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.current ? NyUtils.formatNumber(record.current, 2) : '0,0'}
                            &nbsp;A
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.light.table.voltage')} />,
                dataIndex: 'voltage',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.voltage ? NyUtils.formatNumber(record.voltage, 2) : '0,0'}
                            &nbsp;V
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.light.table.power.factor')} />,
                dataIndex: 'powerFactor',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return <div>{record.powerFactor ? NyUtils.formatNumber(record.powerFactor, 2) : '0,0'}</div>;
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.light.table.leakage.voltage')} />,
                dataIndex: 'leakageVoltage',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.leakageVoltage ? NyUtils.formatNumber(record.leakageVoltage, 2) : '0,0'}
                            &nbsp;V
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.light.table.lighting.time')} />,
                dataIndex: 'lightingTime',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.lightingTime ? NyUtils.formatNumber(record.lightingTime, 2) : '0,0'}
                            &nbsp;h
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.light.table.dimming')} />,
                dataIndex: 'dimmingValue',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    if (record.dimmingValue || record.dimmingValue == -1) {
                        return (
                            <Tooltip title={geti18nText('dashboard.widget.light.dimming.off')}>
                                <ExclamationCircleOutlined style={{ color: 'red', fontSize: '18px' }} />
                            </Tooltip>
                        );
                    }
                    return (
                        <div>
                            {record.dimmingValue ? NyUtils.formatNumber(record.dimmingValue, 2) : '0'}
                            &nbsp;
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.light.table.action')} />,
                dataIndex: 'action',
                align: 'right',
                render: (text: string, record: { [index: string]: any }) => {
                    return (
                        <div>
                            <b>
                                {geti18nText(
                                    'app.enum.PAYLOAD_STATUS_ACTION.' +
                                        GetEnumNameForValue('PAYLOAD_STATUS_ACTION', record.action)
                                )}
                            </b>
                        </div>
                    );
                },
                ...getColumnSearchOption(actionTypes()),
            },

            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.light.table.node.alarm')} />,
                dataIndex: 'nodeAlarm',
                align: 'center',
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.nodeAlarm == 0) {
                        return <CheckCircleOutlined style={{ color: 'green', fontSize: '18px' }} />;
                    } else {
                        return (
                            <Popover
                                title={geti18nText('dashboard.widget.light.table.node.alarm')}
                                content={
                                    <div>
                                        {alarmList(record.alarmList, 1).map((element: any) => {
                                            return <div>{geti18nText('app.enum.TERMINAL_NODE_ALARM.' + element)}</div>;
                                        })}
                                    </div>
                                }
                            >
                                <ExclamationCircleOutlined style={{ color: 'red', fontSize: '18px' }} />
                            </Popover>
                        );
                    }
                },
                ...getColumnSearchOption(filterErrorOptions),
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.light.table.light.alarm')} />,
                dataIndex: 'lightAlarm',
                align: 'center',
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.lightAlarm == 0) {
                        return <CheckCircleOutlined style={{ color: 'green', fontSize: '18px' }} />;
                    } else {
                        return (
                            <Popover
                                title={geti18nText('dashboard.widget.light.table.light.alarm')}
                                content={
                                    <div>
                                        {alarmList(record.alarmList, 2).map((element: any) => {
                                            return <div>{geti18nText('app.enum.LAMP_ALARM.' + element)}</div>;
                                        })}
                                    </div>
                                }
                            >
                                <ExclamationCircleOutlined style={{ color: 'red', fontSize: '18px' }} />
                            </Popover>
                        );
                    }
                },
                ...getColumnSearchOption(filterErrorOptions),
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.lastactivity')} />,
                dataIndex: 'ts',
                sorter: (a: string, b: string) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.ts) {
                        var ts: any = createDate(record.ts);
                        if (ts !== '') {
                            return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                        } else {
                            return <div></div>;
                        }
                    }
                },
            },

            {
                dataIndex: 'operation',
                width: NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER']) ? 125 : 30,
                align: 'left',
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.id) {
                        return (
                            <div>
                                {NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER']) && (
                                    <React.Fragment>
                                        <Tooltip
                                            title={geti18nText('dashboard.widget.watermeter.table.location.tooltip')}
                                        >
                                            <PushpinOutlined
                                                style={{ fontSize: '18px', marginRight: '10px' }}
                                                onClick={() =>
                                                    editLocation(record.id, {
                                                        latitude: record.lat,
                                                        longitude: record.lng,
                                                    })
                                                }
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                )}
                                {record.lat != undefined && (
                                    <Tooltip title={geti18nText('dashboard.widget.watermeter.table.showonmap.tooltip')}>
                                        {filterDevice === record.id ? (
                                            <ZoomInOutlined
                                                style={{ fontSize: '18px', color: 'red' }}
                                                onClick={() => showOnMapFilter(record)}
                                            />
                                        ) : (
                                            <ZoomInOutlined
                                                style={{ fontSize: '18px' }}
                                                onClick={() => showOnMapFilter(record)}
                                            />
                                        )}
                                    </Tooltip>
                                )}
                            </div>
                        );
                    }
                },
            },
        ];
    };

    const hideColumnsInMainTable = ['current', 'voltage', 'powerFactor', 'lightingVoltage', 'lightingTime'];
    function getCsvColumns() {
        return [
            {
                title: geti18nText('dashboard.widget.light.table.name'),
                dataIndex: 'name',
            },
            {
                title: geti18nText('dashboard.widget.light.table.serial'),
                dataIndex: 'eui',
            },
            {
                title: geti18nText('dashboard.widget.light.table.electric.energy'),
                dataIndex: 'electricEnergy',
            },
            {
                title: geti18nText('dashboard.widget.light.table.current'),
                dataIndex: 'current',
            },
            {
                title: geti18nText('dashboard.widget.light.table.voltage'),
                dataIndex: 'voltage',
            },
            {
                title: geti18nText('dashboard.widget.light.table.power.factor'),
                dataIndex: 'powerFactor',
            },
            {
                title: geti18nText('dashboard.widget.light.table.leakage.voltage'),
                dataIndex: 'leakageVoltage',
            },
            {
                title: geti18nText('dashboard.widget.light.table.lighting.time'),
                dataIndex: 'lightingTime',
                align: 'right',
            },
            {
                title: geti18nText('dashboard.widget.light.table.dimming'),
                dataIndex: 'dimmingValue',
            },
            {
                title: geti18nText('dashboard.widget.light.table.action'),
                dataIndex: 'action',
            },

            {
                title: geti18nText('dashboard.widget.light.table.node.alarm'),
                dataIndex: 'nodeAlarm',
            },
            {
                title: geti18nText('dashboard.widget.light.table.light.alarm'),
                dataIndex: 'lightAlarm',
            },
            {
                title: geti18nText('dashboard.widget.watermeter.table.lastactivity'),
                dataIndex: 'ts',
            },
        ];
    }

    const csvCustomizationColumns: any = () => {
        return [
            {
                ts: (value: any) => {
                    if (value) {
                        var ts: any = createDate(value);
                        if (ts !== '') {
                            return ts.toLocaleString(NyUtils.getSelectedLocale());
                        }
                    }

                    return '';
                },
            },
            {
                current: (value: any) => {
                    if (value != undefined) {
                        return NyUtils.formatNumber(value, 2);
                    }
                    return value;
                },
            },
            {
                action: (value: any) => {
                    if (value != undefined) {
                        return geti18nText(
                            'app.enum.PAYLOAD_STATUS_ACTION.' + GetEnumNameForValue('PAYLOAD_STATUS_ACTION', value)
                        );
                    }
                    return '';
                },
            },
            {
                electricEnergy: (value: any) => {
                    return value ? formatNumberValueString(value, true) : '';
                },
            },
            {
                current: (value: any) => {
                    return value ? value + ' A' : '';
                },
            },
            {
                voltage: (value: any) => {
                    return value ? value + ' V' : '';
                },
            },
            {
                leakageVoltage: (value: any) => {
                    return value ? value + ' V' : '';
                },
            },
        ];
    };

    return (
        <React.Fragment>
            <FullscreenOutlined
                style={{ position: 'absolute', right: '5px', top: '5px' }}
                onClick={() => setVisible(true)}
            />
            <NyDataTable
                key={generateKey()}
                headerTitle={geti18nText('dashboard.widget.light.table.title')}
                url={CONSTANTS_REQ.DASHBOARD.LIGHTS_LIST}
                hideButtons={true}
                exportCSV={true}
                onRowSelect={onRowSelect}
                addedData={
                    mapSelectedBounds != null
                        ? {
                              latNorthEast: mapSelectedBounds.latNorthEast,
                              lngNorthEast: mapSelectedBounds.lngNorthEast,
                              latSouthWest: mapSelectedBounds.latSouthWest,
                              lngSouthWest: mapSelectedBounds.lngSouthWest,
                          }
                        : undefined
                }
                setDefaultFilterValue={setDefaultFilterValue}
                scroll={getScrolls(dataGrid)}
                fetchWhenChange={lightsListRefresh}
                columns={columns().filter((x: any) => !hideColumnsInMainTable.includes(x.dataIndex))}
                csvColumns={getCsvColumns()}
                colCSVCustomization={csvCustomizationColumns()}
            />
            <Modal
                title={geti18nText('dashboard.widget.watermeter.table.title')}
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={'100%'}
                footer={null}
            >
                {visible && (
                    <NyDataTable
                        key={generateKey()}
                        headerTitle={geti18nText('dashboard.widget.light.table.title')}
                        url={CONSTANTS_REQ.DASHBOARD.LIGHTS_LIST}
                        hideButtons={true}
                        exportCSV={true}
                        onRowSelect={onRowSelect}
                        setDefaultFilterValue={setDefaultFilterValue}
                        scroll={getModalScrolls(dataGrid)}
                        fetchWhenChange={lightsListRefresh}
                        columns={columns()}
                        csvColumns={getCsvColumns()}
                        colCSVCustomization={csvCustomizationColumns()}
                    />
                )}
            </Modal>
        </React.Fragment>
    );
};

export default WidgetLightsList;
