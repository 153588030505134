import { combineReducers } from '@reduxjs/toolkit';
import deviceDetailsReducer from './slices/deviceDetailsSlice';
import deviceContactReducer from './slices/deviceContactSlice';
import deviceLocationReducer from './slices/deviceLocationSlice';
import deviceAlarmThrasholdReducer from './slices/deviceAlarmThrasholdSlice';
import dashboardReducer from './slices/dashboardSlice';
import selectedCustomerReducer from './slices/selectedCustomerSlice';
import selectedItemReducer from './slices/selectedItemSlice';
import deviceManualConsumtionSliceReducer from './slices/deviceManualConsumtionSlice';
import alarmDescriptionReducer from './slices/alarmDescriptionSlice';
import gatewayDetailsReducer from './slices/gatewayDetailsSlice';
import selectedDeviceGroupReducer from './slices/selectedDeviceGroupSlice';
import importExportReducer from './slices/importExportSlice';
import deviceLightDetailsReducer from './slices/deviceLightDetailsSlice';
import deviceEnergyDetailsReducer from './slices/deviceEnergyDetailsSlice';
import deviceWeatherStateDetailsReducer from './slices/deviceWeatherStateDetailsReducer';
const rootReducer = combineReducers({
    deviceDetails: deviceDetailsReducer,
    deviceContact: deviceContactReducer,
    deviceLocation: deviceLocationReducer,
    deviceAlarmThrashold: deviceAlarmThrasholdReducer,
    dashboard: dashboardReducer,
    selectedCustomer: selectedCustomerReducer,
    selectedItem: selectedItemReducer,
    deviceManualConsumtionSlice: deviceManualConsumtionSliceReducer,
    alarmDescriptionSlice: alarmDescriptionReducer,
    gatewayDetails: gatewayDetailsReducer,
    selectedDeviceGroup: selectedDeviceGroupReducer,
    importExport: importExportReducer,
    deviceLightDetails: deviceLightDetailsReducer,
    deviceEnergyDetails: deviceEnergyDetailsReducer,
    deviceWeatherStateDetails: deviceWeatherStateDetailsReducer 
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
