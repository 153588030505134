import React from 'react';
import { DeviceCategoryType } from '../../utils/Constants';
import DeviceGroup from '../device-groups';

const LightsGroup = () => {
    return (
        <>
            <DeviceGroup type={DeviceCategoryType.LIGHTS}></DeviceGroup>
        </>
    );
};

export default LightsGroup;
