import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { geti18nText, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import { RootState } from '../../../rootReducer';
import { deviceLightDetailsInit } from '../../../slices/deviceLightDetailsSlice';
import PayloadLightHistoryTable from '../../device/lights/PayloadLightHistoryTable';
import AlarmsLightTable from '../../device/lights/AlarmsLightTable';
import DeviceLightStatisticDetail from '../../device/lights/DeviceLightDetail';

const { TabPane } = Tabs;

const DeviceLightDetail = () => {
    const dispatch = useDispatch();
    const { record, visible } = useSelector((state: RootState) => state.deviceLightDetails);
    const [modalTitle, setModalTitle] = useState(geti18nText('deviceDetails.modal.lights.title'));

    const hideModal = () => {
        dispatch(deviceLightDetailsInit());
    };

    return (
        <Modal
            zIndex={1009}
            title={modalTitle}
            destroyOnClose={true}
            visible={visible}
            onOk={hideModal}
            onCancel={hideModal}
            width="90%"
            footer={null}
        >
            <Tabs type="card">
                <TabPane tab={geti18nText('deviceDetails.tab.overview')} key="1">
                    <Row>
                        <Col span={24}>
                            <DeviceLightStatisticDetail />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('deviceDetails.tab.alarms')} key="2">
                    <Row>
                        <Col span={24}>
                            <AlarmsLightTable previewType="BY_DEVICE"></AlarmsLightTable>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab={geti18nText('deviceDetails.tab.payload')} key="3">
                    <Row>
                        <Col span={24}>
                            <PayloadLightHistoryTable></PayloadLightHistoryTable>
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
        </Modal>
    );
};

export default DeviceLightDetail;
