import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IDeviceDetailsState {
    record: { [index: string]: any };
    visible: boolean;
}

const initialState: IDeviceDetailsState = {
    record: {},
    visible: false,
};

const deviceDetails = createSlice({
    name: 'deviceDetails',
    initialState,
    reducers: {
        deviceDetailsInit(state) {
            state.record = {};
            state.visible = false;
        },
        deviceDetailsOpen(state, action: PayloadAction<IDeviceDetailsState>) {
            const { record, visible = true } = action.payload;
            state.record = record;
            state.visible = visible;
        },
    },
});

export const { deviceDetailsInit, deviceDetailsOpen } = deviceDetails.actions;

export default deviceDetails.reducer;
