import { geti18nText, NyUtils } from '@nybble/nyreact';

export const getUnreadAlarmFilterType: any = () => {
    let types = [
        { id: 1, text: geti18nText('dashboard.widget.alarm.unread.filter.unread') },
        { id: 0, text: geti18nText('dashboard.widget.alarm.unread.filter.read') },
    ];

    return types;
};

export function toCamelCase(str: any): any {
    if (str == undefined) return undefined;
    str = str.replace(/_([a-z])/g, (match: any, letter: any) => letter.toUpperCase());
    return str;
}

export function parametarKeyName(name: any): any {
    if (NyUtils.getSelectedLanguage() == 'en') return name + '_en';
    return name;
}

export function transleteByCount(key: string, count: number): any {
    if (count && count == 1) {
        return geti18nText(key + '.one');
    }
    return geti18nText(key + '.multi');
}
