import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IGatewayDetailsState {
    record: { [index: string]: any };
    visible: boolean;
}

const initialState: IGatewayDetailsState = {
    record: {},
    visible: false,
};

const gatewayDetails = createSlice({
    name: 'gatewayDetails',
    initialState,
    reducers: {
        gatewayDetailsInit(state) {
            state.record = {};
            state.visible = false;
        },
        gatewayDetailsOpen(state, action: PayloadAction<IGatewayDetailsState>) {
            const { record, visible = true } = action.payload;
            state.record = record;
            state.visible = visible;
        },
    },
});

export const { gatewayDetailsInit, gatewayDetailsOpen } = gatewayDetails.actions;

export default gatewayDetails.reducer;
