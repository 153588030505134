import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeviceCategoryType } from '../utils/Constants';

interface IAlarmDescriptionState {
    deviceId: string | undefined;
    data: { [index: string]: any } | undefined;
    visible: boolean;
    deviceAlarmListRefresh: number;
    deviceType?: any;
    alarmCount?: any,
}

const initialState: IAlarmDescriptionState = {
    deviceId: undefined,
    data: {},
    visible: false,
    deviceAlarmListRefresh: 0,
    deviceType: DeviceCategoryType.WATERMETER,
    alarmCount:{}
};

const alarmDescription = createSlice({
    name: 'alarmDescription',
    initialState,
    reducers: {
        alarmDescriptionInit(state) {
            state.deviceId = undefined;
            state.data = {};
            state.visible = false;
            state.deviceAlarmListRefresh = 0;
            state.deviceType = DeviceCategoryType.WATERMETER;
        },
        alarmDescriptionOpen(state, action: PayloadAction<IAlarmDescriptionState>) {
            const { data, visible = true, deviceId, deviceAlarmListRefresh = 0, deviceType } = action.payload;
            state.deviceId = deviceId;
            state.data = data;
            state.visible = visible;
            state.deviceAlarmListRefresh = deviceAlarmListRefresh;
            state.deviceType = deviceType;
        },
        refreshDeviceAlarmList(state) {
            state.deviceAlarmListRefresh++;
        },
        setAlarmCount(state, action) {
           
            state.alarmCount = action.payload;
        },
    },
});

export const { alarmDescriptionInit, alarmDescriptionOpen, refreshDeviceAlarmList,setAlarmCount } = alarmDescription.actions;

export default alarmDescription.reducer;
