import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Card, Statistic, Form, Button, Modal } from 'antd';
import { FullscreenOutlined, LineChartOutlined, ThunderboltOutlined, UnorderedListOutlined, WarningOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { geti18nText, NyRequestResolver, NySearchField, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';
import { WindDirectionDescription } from '../../../../utils/Constants';
import { Bar, BarChart, CartesianGrid, Label, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { formatNumberValueString } from '../../../number-formatter';
import ChartWeatherStateDataConsumption from '../ChartWeatherStateDataConsumption';

const { Title } = Typography;

interface IWindCard {
    loading?: boolean | null;
    data?: any;
    title: string | null;
    formatNumber?: boolean;
    deviceId?: number;
    period?: string;
    params?: string[];




}
const WeatherRainfallBarChart: React.FC<IWindCard> = ({ loading,
    data,
    title,
    formatNumber,
    deviceId,
    period,
    params = [],


    }) => {
   
    const [width, setWidth] = useState(150);
    const [height, setHeight] = useState(200);
    const lightSettings: any = {
        title: geti18nText('weatherStateDetails.graph.title.rainfall'),
        'y-title': geti18nText('weatherStateDetails.graph.y.value.rainfall'),
        color: '#FFD700',
    };
    function getSettings() {
        return lightSettings;
    }
    const [settings, setSettings] = useState<any>(getSettings());
    const [visibleDataVolumes, setVisibleDataVolumes] = useState(false);
    const [visible, setVisible] = useState(false);


    useEffect(()=>{
        console.log(data)
    },[])

    

    function tickFormaterYAxis(value: any) {
        if (formatNumber == true) {
            let formatValue = formatNumberValueString(value, true);
            return formatValue;
        }
        return value;
    }

    const renderTooltip = (value: any, name: any, props: any) => {
        if (formatNumber == true) {
            let formValue = formatNumberValueString(value, true);
            return [formValue, name];
        }
        return [value, name];
    };


    return (
        <div className="box" style={{ height: '95%' }}>
            <Title style={{ fontSize: '1.4em', width: '75%' }}>{settings?.title + ' - ' + title}</Title>
            {deviceId && (
                <Button
                    style={{ position: 'absolute', right: '50px', top: '20px' }}
                    shape="circle"
                    onClick={() => setVisibleDataVolumes(true)}
                    icon={<UnorderedListOutlined />}
                />
            )}
            <Button
                style={{ position: 'absolute', right: '20px', top: '20px' }}
                shape="circle"
                onClick={() => {setVisible(true)}}
                icon={<FullscreenOutlined />}
            />
            <ResponsiveContainer width="100%" height={250}>
                {loading ? (
                    <NySpinner />
                ) : (
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="converted" />
                        <YAxis tickFormatter={tickFormaterYAxis}>
                            
                                <Label
                                    value={settings['y-title']}
                                    position="insideLeft"
                                    angle={-90}
                                    style={{ textAnchor: 'middle' }}
                                />
                        </YAxis>
                        <Tooltip formatter={renderTooltip} />
                        <Legend />
                        <Bar dataKey="barValue" name={settings['y-title']} fill={settings['color']} />
                    </BarChart>
                )}
            </ResponsiveContainer>
            <Modal
                title={settings['title'] + ' - ' + title}
                visible={visible}
                zIndex={1010}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={'80%'}
                centered={true}
            >
                <ResponsiveContainer width="100%" height={600}>
                    {loading ? (
                        <NySpinner />
                    ) : (
                        <BarChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis dataKey="converted" />
                            <YAxis tickFormatter={tickFormaterYAxis}>
                                    <Label
                                        value={settings['y-title']}
                                        position="insideLeft"
                                        angle={-90}
                                        style={{ textAnchor: 'middle' }}
                                    />
                                
                            </YAxis>
                            <Tooltip formatter={renderTooltip} />
                            <Legend />
                            <Bar dataKey="barValue" name={settings['y-title']} fill={settings['color']} />

                        </BarChart>
                    )}
                </ResponsiveContainer>
            </Modal>
            {deviceId && (
                <Modal
                    visible={visibleDataVolumes}
                    zIndex={1010}
                    onCancel={() => setVisibleDataVolumes(false)}
                    width={'80%'}
                    centered={true}
                    footer={null}
                >
                    <ChartWeatherStateDataConsumption
                        title={title}
                        deviceId={deviceId}
                        period={{ period: period }}
                        params={params}
                    />
                </Modal>
            )}
        </div>        
    );
};

export default WeatherRainfallBarChart;
