import {
    ApiFilled,
    AreaChartOutlined,
    BellOutlined,
    DisconnectOutlined,
    PercentageOutlined,
    PlusCircleOutlined,
    RocketOutlined,
    SignalFilled,
    ThunderboltOutlined,
} from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySession, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Card, Switch, Tooltip } from 'antd';
import { AnyArray } from 'immer/dist/internal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from '../../rootReducer';
import {
    filterActiveDevices,
    filterInactiveDevices,
    filterLowBatteryDevices,
    filterReset,
    showGenerateTasks,
    filterActiveLights,
    filterActiveLightsReset,
    filterAlarmLights,
    filterAlarmLightsReset,
} from '../../slices/dashboardSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { getUserCategoryType } from '../../utils/Enums';
import FormatNumber, { formatNumberValue } from '../number-formatter';
import { IWidget } from './index';

const WidgetCards: React.FC<IWidget> = ({ showLightsOn, showWatermeterOn, dataGrid, showOnlyCategory }) => {
    const [stats, setStats] = useState<{ [index: string]: any }>({});
    const [loading, setLoading] = useState(true);
    const { filterActive, filterInactive, filterLowBattery, mapBounds, mapSelectedBounds } = useSelector(
        (state: RootState) => state.dashboard
    );
    const history = useHistory();
    const dispatch = useDispatch();
    /* useEffect(() => {
        fetchStat();
        const interval = setInterval(() => {
            fetchStat();
        }, 3 * 60 * 1000);

        return () => {
            clearInterval(interval);
            dispatch(filterReset());
        };
    }, []); */

    useEffect(() => {
        setLoading(true);
        fetchStat();
    }, [mapSelectedBounds]);

    const fetchStat = () => {
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.DASHBOARD.STATISTIC,
            mapSelectedBounds != null
                ? {
                      latNorthEast: mapSelectedBounds.latNorthEast,
                      lngNorthEast: mapSelectedBounds.lngNorthEast,
                      latSouthWest: mapSelectedBounds.latSouthWest,
                      lngSouthWest: mapSelectedBounds.lngSouthWest,
                  }
                : {}
        ).then((result) => {
            if (result && result.status == RESPONSE.OK) {
                if (Array.isArray(result.data)) {
                    setLoading(false);
                    setStats(result.data[0]);
                }
            }
        });
    };

    const gotoDetails = () => {
        history.push('/card-details');
    };

    function getWidth() {

        if (typeof dataGrid.w == 'number') {
            if (dataGrid.w * 133 > 300 && dataGrid.w * 133 < 960) return { width: (dataGrid.w * 133 - 20) / 2 };
            /* else if(dataGrid.w * 133 >  960) {
                return {width: (dataGrid.w * 133 - 40) / 6}; 
            }
            else return undefined; */
        }

        return undefined;
    }

    const filterActiveDevicesOnClick = (e: any) => {
        if (e === true) {
            dispatch(filterActiveDevices());
        } else {
            dispatch(filterReset());
        }
    };
    const filterInactiveDevicesOnClick = (e: any) => {
        if (e === true) {
            dispatch(filterInactiveDevices());
        } else {
            dispatch(filterReset());
        }
    };
    const filterDeviceWithAlarmStatusOnClick = (e: any) => {
        if (e === true) {
            dispatch(filterLowBatteryDevices());
        } else {
            dispatch(filterReset());
        }
    };

    return (
        <React.Fragment>
            {getUserCategoryType() &&
                getUserCategoryType().length > 0 &&
                getUserCategoryType().includes('WATERMETER') &&
                showWatermeterOn &&
                stats && (
                    <div className="widget-cards-card-container-watermeter">
                        <Card className="widget-cards-card-watermeter" style={getWidth()}>
                            <div>
                                <div className="widget-cards-number">
                                    {loading && <NySpinner />}
                                    {stats != null &&
                                        (stats.active || stats.active === 0 ? NyUtils.formatNumber(stats.active) : ' ')}
                                </div>
                                <div className="widget-cards-title">
                                    <span>{geti18nText('dashboard.widget.card.active.devices')}</span>
                                </div>
                            </div>
                            <div className="widget-cards-background-icon-watermeter">
                                <Switch size="small" onChange={(e: any) => filterActiveDevicesOnClick(e)} />
                            </div>
                            <div className="widget-cards-background-icon-box">
                                <ApiFilled className="widget-cards-background-icon-box-watermeter" />
                            </div>
                        </Card>

                        <Card className="widget-cards-card-watermeter" style={getWidth()}>
                            <div className="widget-cards-text-bottom">
                                <div className="widget-cards-number">
                                    {loading && <NySpinner />}
                                    {stats != null &&
                                        (stats.lowBattery || stats.lowBattery === 0
                                            ? NyUtils.formatNumber(stats.lowBattery)
                                            : ' ')}
                                </div>
                                <div className="widget-cards-title">
                                    <span>{geti18nText('dashboard.widget.card.low.battery')}</span>
                                </div>
                            </div>
                            <div className="widget-cards-background-icon-watermeter">
                                <Switch size="small" onChange={(e: any) => filterDeviceWithAlarmStatusOnClick(e)} />
                            </div>
                            <div className="widget-cards-background-icon-box">
                                <PercentageOutlined className="widget-cards-background-icon-box-watermeter" />
                            </div>
                        </Card>
                        <Card className="widget-cards-card-watermeter" style={getWidth()}>
                            <div className="widget-cards-text-bottom">
                                <div className="widget-cards-number">
                                    {loading && <NySpinner />}
                                    {stats != null &&
                                        (stats.inactive || stats.inactive === 0
                                            ? NyUtils.formatNumber(stats.inactive)
                                            : ' ')}
                                </div>
                                <div className="widget-cards-title">
                                    <span>{geti18nText('dashboard.widget.card.inactive.devices')}</span>
                                </div>
                            </div>
                            <div className="widget-cards-background-icon-watermeter">
                                <Switch size="small" onChange={(e: any) => filterInactiveDevicesOnClick(e)} />
                            </div>
                            <div className="widget-cards-background-icon-box">
                                <DisconnectOutlined
                                    style={filterInactive ? { color: 'black' } : {}}
                                    className="widget-cards-background-icon-box-watermeter"
                                />
                            </div>
                            {NySession.hasAnyRole(['ROLE_CUSTOMER']) && (
                                <div className="widget-cards-action">
                                    <Tooltip title={geti18nText('dashboard.widget.card.add.task.tooltips')}>
                                        <PlusCircleOutlined
                                            onClick={() => {
                                                dispatch(showGenerateTasks());
                                            }}
                                            style={{ color: 'black', fontSize: '32px', cursor: 'pointer' }}
                                        />
                                    </Tooltip>
                                </div>
                            )}
                        </Card>
                        <Card className="widget-cards-card-watermeter" style={getWidth()}>
                            <div className="widget-cards-text-bottom">
                                <div className="widget-cards-number">
                                    {loading && <NySpinner />}
                                    {stats != null &&
                                        (stats.waterConsumption || stats.waterConsumption === 0
                                            ? NyUtils.formatNumber(stats.waterConsumption, 1)
                                            : '0,0')}
                                    m³
                                </div>
                                <div className="widget-cards-title">
                                    <span>{geti18nText('dashboard.widget.card.last.days')}</span>
                                </div>
                            </div>
                            <div className="widget-cards-background-icon-watermeter">
                                <SignalFilled
                                    className="widget-cards-background-icon-box-details"
                                    onClick={gotoDetails}
                                />
                            </div>
                            <div className="widget-cards-background-icon-box">
                                <AreaChartOutlined className="widget-cards-background-icon-box-watermeter" />
                            </div>
                        </Card>
                    </div>
                )}
        </React.Fragment>
    );
};

export default WidgetCards;
