import React, { createRef, useEffect, useRef, useState } from 'react';
import { AlarmSettingsType, CONSTANTS_REQ } from '../../utils/Constants';
import WidgetAlarmSettings from './widgetSettings';
import { Button, Col, Modal, Row, Tag, Typography, message } from 'antd';
import { NyRequestResolver, NySession, NySettings, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import UserAlarmSelect from '../user/UserAlarmSelect';
const { Title } = Typography;
const AlarmSettingsIndex = ({ type, deviceId, deviceGroupId, customerId }: any) => {
    const myRefs = useRef([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [alarmSettingsList, setAlarmSettingsList] = useState<any>([]);
    const [showSettings, setShowSettings] = useState(true);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const alarmUsers = useRef<any>([]);
    const alarmUsersCount = useRef<any>(0);
    const removeUsers = useRef<any>([]);
    const settingsType = useRef<any>(-1);
    const customer = useRef<any>();
    const device = useRef<any>();
    const group = useRef<any>();
    const alarmId = useRef<any>(undefined);

    useEffect(() => {
        if (type == undefined || type == AlarmSettingsType.GLOBAL) {
            //     setShowSettings(true);
        }
        setLoading(true);
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.ALARM_SETTINGS.LIST_BY_HIERARCHY,
            {
                deviceId: deviceId,
                deviceGroupId: deviceGroupId,
                customerId: customerId,
            },
            true,
            false
        ).then((result: any) => {
            if (result.status === RESPONSE.OK && result.data) {
                const data = result.data.sort((a: any, b: any) => (a.ord > b.ord ? 1 : -1));
                setAlarmSettingsList(data);
                if (data != undefined && data.length > 0) {
                    alarmUsers.current = data[0].users;
                    alarmUsersCount.current = data[0].users?.length;
                    settingsType.current = data[0].type;
                    customer.current = data[0].customer;
                    group.current = data[0].deviceGroup;
                    device.current = data[0].device;
                    alarmId.current = data[0].id;
                }
                myRefs.current = result.data.map((element: any, i: any) => myRefs.current[i] ?? createRef());
            }
            setLoading(false);
        });
    }, []);

    function save(action = undefined) {
        let dataArray: any = [];
        let alarmList = [...alarmSettingsList];
        myRefs.current.forEach((ref: any) => {
            const data = ref.current.method();
            dataArray.push(data);
            let itemIndex = alarmList.findIndex((x) => x.key == data.key);
            if (itemIndex > -1) {
                alarmList[itemIndex].upperLimit = data.upperLimit;
                alarmList[itemIndex].lowerLimit = data.lowerLimit;
            }
        });
        // setAlarmSettingsList(alarmList);
        setLoading(true);
        let params: any = {
            alarmSettingsList: dataArray,
            type: type != undefined ? type : AlarmSettingsType.GLOBAL,
            device: deviceId != undefined ? { id: deviceId } : undefined,
            deviceGroup: deviceGroupId != undefined ? { id: deviceGroupId } : undefined,
            users: alarmUsers.current,
        };
        if (action == 'ADD') {
            params['users'] = alarmUsers.current;
            params['userAction'] = 'ADD';
        }

        if (action == 'REMOVE') {
            params['users'] = removeUsers.current;
            params['userAction'] = 'REMOVE';
        }
        NyRequestResolver.requestPost(CONSTANTS_REQ.ALARM_SETTINGS.SAVE_LIST_BY_TYPE, undefined, params).then(
            (result: any) => {
                if (result.status === RESPONSE.OK) {
                    message.destroy();
                    message.success(geti18nText('app.default.save.ok'));
                    const data = result.data.sort((a: any, b: any) => (a.ord > b.ord ? 1 : -1));
                    setAlarmSettingsList(data);
                    setRefresh(refresh + 1);
                    if (data != undefined && data.length > 0) {
                        alarmUsers.current = data[0].users;
                        alarmUsersCount.current = data[0].users?.length;
                        settingsType.current = data[0].type;
                        customer.current = data[0].customer;
                        group.current = data[0].deviceGroup;
                        device.current = data[0].device;
                        alarmId.current = data[0].id;
                    }
                } else {
                    message.destroy();
                    message.error(`${geti18nText('app.default.save.nok')}`);
                }
                setLoading(false);
            }
        );
    }

    function reset() {
        setLoading(true);
        NyRequestResolver.requestPost(CONSTANTS_REQ.ALARM_SETTINGS.RESET_SETTINGS, undefined, {
            type: type != undefined ? type : AlarmSettingsType.GLOBAL,
            device: deviceId != undefined ? { id: deviceId } : undefined,
            deviceGroup: deviceGroupId != undefined ? { id: deviceGroupId } : undefined,
        }).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                message.destroy();
                message.success(geti18nText('app.default.save.ok'));
                const data = result.data.sort((a: any, b: any) => (a.ord > b.ord ? 1 : -1));
                setAlarmSettingsList(data);
                setRefresh(refresh + 1);
                if (data != undefined && data.length > 0) {
                    alarmUsers.current = data[0].users;
                    alarmUsersCount.current = data[0].users?.length;
                    settingsType.current = data[0].type;
                    customer.current = data[0].customer;
                    group.current = data[0].deviceGroup;
                    device.current = data[0].device;
                    alarmId.current = data[0].id;
                }
                // myRefs.current = result.data.map((element: any, i: any) => myRefs.current[i] ?? createRef());Ny
            } else {
                message.destroy();
                message.error(`${geti18nText('app.default.save.nok')}`);
            }
            setLoading(false);
        });
    }

    const getTitle = () => {
        if (type == AlarmSettingsType.GLOBAL || type == AlarmSettingsType.CUSTOMER || type == undefined)
            return geti18nText('alarm.settings.edit.header.global');
        if (type == AlarmSettingsType.DEVICE) return geti18nText('alarm.settings.edit.header.device');
        if (type == AlarmSettingsType.DEVICE_GROUP) return geti18nText('alarm.settings.edit.header.deviceGroup');
    };

    const getTitleTag = (type: any) => {
        console.log('Type ', type);
        if (
            settingsType.current == AlarmSettingsType.GLOBAL ||
            settingsType.current == AlarmSettingsType.DEFAULT ||
            (NySession.hasAnyRole(['ROLE_CUSTOMER']) && settingsType.current == AlarmSettingsType.CUSTOMER)
        )
            return <Tag color="blue">{geti18nText('alarm.settings.edit.tab.global')}</Tag>;
        if (!NySession.hasAnyRole(['ROLE_CUSTOMER']) && settingsType.current == AlarmSettingsType.CUSTOMER)
            return (
                <Tag color="blue">
                    {geti18nText('alarm.settings.edit.tab.customer') +
                        (customer.current != undefined ? ': ' + customer.current.name : '')}
                </Tag>
            );
        if (settingsType.current == AlarmSettingsType.DEVICE_GROUP)
            return (
                <Tag color="orange">
                    {geti18nText('alarm.settings.edit.tab.deviceGroup') +
                        (group.current != undefined ? ': ' + group.current.name : '')}
                </Tag>
            );
        if (settingsType.current == AlarmSettingsType.DEVICE)
            return (
                <Tag color="green">
                    {geti18nText('alarm.settings.edit.tab.device') +
                        (device.current != undefined ? ': ' + device.current.name : '')}
                </Tag>
            );
    };

    function setUsers(values: any) {
        console.log(values);
        alarmUsers.current = values;
    }

    function setRemovedUsers(values: any) {
        console.log(values);
        removeUsers.current = values;
    }

    return (
        <>
            <Row>
                <div style={{ width: '100%', paddingBottom: '10px', paddingTop: '20px' }}>
                    <Title level={5} style={{ display: 'inline' }}>
                        {getTitle()}
                    </Title>
                    {settingsType.current != undefined && loading == false && (
                        <div style={{ paddingLeft: '15px', display: 'inline' }}>
                            {getTitleTag(settingsType.current)}
                        </div>
                    )}

                    {showSettings && (
                        <Button type="primary" onClick={() => save()} style={{ float: 'right', margin: '2px' }}>
                            {geti18nText('alarm.settings.edit.btn.save')}
                        </Button>
                    )}

                    {showSettings && (
                        <Button type="default" danger onClick={() => reset()} style={{ float: 'right', margin: '2px' }}>
                            {geti18nText('alarm.settings.edit.btn.reset')}
                        </Button>
                    )}

                    {showSettings && (
                        <Button
                            type="default"
                            onClick={() => {
                                setShowAddUserModal(true);
                            }}
                            style={{ float: 'right', margin: '2px' }}
                        >
                            {geti18nText('alarm.settings.edit.btn.users') +
                                (alarmUsersCount.current != undefined && alarmUsersCount.current > 0
                                    ? '(' + alarmUsersCount.current + ')'
                                    : '')}
                        </Button>
                    )}

                    {/* {!showSettings && (
                        <Button
                            type="primary"
                            onClick={() => {
                                setShowSettings(true);
                            }}
                            style={{ float: 'right', margin: '2px' }}
                        >
                            {geti18nText('alarm.settings.edit.btn.edit')}
                        </Button>
                    )} */}
                </div>
            </Row>
            <Modal
                visible={showAddUserModal}
                title={geti18nText('alarm.settings.edit.btn.users')}
                width={1000}
                onCancel={() => setShowAddUserModal(false)}
                onOk={() => {
                    setShowAddUserModal(false);
                }}
            >
                <UserAlarmSelect
                    setSelectedValues={setUsers}
                    seRemovedValues={setRemovedUsers}
                    //defaultSelectedRowKey={alarmUsers.current}
                    forAlarm={true}
                    alarmId={alarmId.current}
                    save={save}
                    refresh={refresh}
                ></UserAlarmSelect>
            </Modal>

            {loading == true ? (
                <NySpinner />
            ) : (
                <>
                    {showSettings &&
                        alarmSettingsList &&
                        alarmSettingsList.map((item: any, index: any) => {
                            return (
                                <WidgetAlarmSettings
                                    ref={myRefs.current[index]}
                                    item={item}
                                    style={{ padding: '15px' }}
                                ></WidgetAlarmSettings>
                            );
                        })}
                </>
            )}
        </>
    );
};

export default AlarmSettingsIndex;
