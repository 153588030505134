import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Tabs, Modal, Button, Select, notification } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { AlarmSettingsType, CONSTANTS_REQ, DeviceCategoryType } from '../../utils/Constants';
import DeviceTable from './DeviceTable';
import { useDispatch } from 'react-redux';
import { refreshDeviceList, selectedDeviceSet } from '../../slices/selectedDeviceGroupSlice';
import DeviceInGroupTable from './DeviceInGroupTable';
import GenerateTasks from '../device/GenerateTasks';
import { geti18nText, NyDataEdit, NyRequestResolver, NySearchField, NySession, RESPONSE } from '@nybble/nyreact';
import { GetEnum } from '../../utils/Enums';
import AlarmSettingsIndex from '../alarm-settings';

const { TabPane } = Tabs;
const { Option } = Select;
const DeviceGroupEdit = (props: any) => {
    const dispatch = useDispatch();

    const [editHeader, setEditHeader] = useState(geti18nText('deviceGroup.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [isCreate, setIsCreate] = useState(false);
    const [enableTabs, setEnableTabs] = useState(false);
    const [createTasksVisible, setCreateTasksVisible] = useState(false);
    const [groupId, setGroupId] = useState(0);
    const [type, setType] = useState(props.addedData?.type ? props.addedData?.type : DeviceCategoryType.WATERMETER);
    const [activeItem, setActiveItem] = useState(true);
    const [tabIndex, setTabIndex] = useState<any>(1);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    const [customerId, setCustomerId] = useState(null);
    useEffect(() => {
        dispatch(selectedDeviceSet({ device: dataForm, refreshDevice: 0 }));
    }, [dataForm]);

    function setValues(dataForm: any) {
        setEnableTabs(true);
        setEditHeader(dataForm.name);
        setDataForm(dataForm.id);
        setGroupId(dataForm.id);
        setActiveItem(dataForm.active);
        delete dataForm.active;
        if (dataForm.customer) {
            setCustomerId(dataForm.customer.id);
        }
        form.setFieldsValue(dataForm);
    }

    const deviceCategoryEnum = () => {
        const aTypes = GetEnum('DEVICE_CATEGORY');
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.DEVICE_CATEGORY.' + key);
                if (type == null || type == aTypes[key]) {
                    types.push(ret);
                }
            }
        }
        return types;
    };

    const onModalClose = () => {
        form.resetFields();
        setTabIndex(1);
        setDataForm(null);
        setEditHeader(geti18nText('deviceGroup.edit.new'));
    };

    function normalizeValues(values: any) {
        delete values.type;
        return {
            ...values,
            customer:
                values.customer != null && values.customer.constructor === Number
                    ? { id: parseInt(values.customer) }
                    : values.customer,
        };
    }
    function makeRequest(isCreate: boolean, values: any) {
        if (!isCreate) {
            return NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE_GROUP.EDIT, undefined, normalizeValues(values));
        } else {
            return NyRequestResolver.requestPut(
                CONSTANTS_REQ.DEVICE_GROUP.EDIT + '/' + values.id,
                undefined,
                normalizeValues(values)
            );
        }
    }
    function onSave(active: any) {
        form.validateFields()
            .then((values) => {
                if (active != undefined) {
                    values.active = active;
                }
                setLoading(true);
                makeRequest(values.id != null, values).then((result: any) => {
                    if (result && result.status === RESPONSE.CREATED) {
                        setLoading(false);
                        setValues(result.data);
                        setEnableTabs(true);
                        dispatch(refreshDeviceList());
                        notification.success({
                            message: geti18nText('app.default.save.ok'),
                            description: geti18nText('app.default.save.ok.desc'),
                            duration: 3,
                        });
                        // if(result.data?.active == true){
                        // onModalClose()
                        // }
                    } else {
                        setLoading(false);
                        notification.error({
                            message: geti18nText('app.default.save.nok'),
                            description: geti18nText('generate.tasks.not.created'),
                            duration: 0,
                        });
                    }
                });
            })
            .catch(() => {
                setLoading(false);
            });

        return false;
    }
    function customButtons() {
        if (activeItem == true) {
            return [
                {
                    text: geti18nText('app.default.button.deactivate'),
                    onClick: () => onSave(false),
                    addPopconfirm: true,
                    style: { backgroundColor: 'red', color: 'white', opacity: 0.7, marginRight: '1em' },
                },
            ];
        }
        return [
            {
                text: geti18nText('app.default.button.activate'),
                onClick: () => onSave(true),
                addPopconfirm: true,
                style: { backgroundColor: 'green', color: 'white', marginRight: '1em' },
            },
        ];
    }

    return (
        <React.Fragment>
            <NyDataEdit
                layout="horizontal"
                formProps={{ labelCol: { span: 5 }, wrapperCol: { span: 14 } }}
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                // setVisible={sez}
                onModalClose={onModalClose}
                url={CONSTANTS_REQ.DEVICE_GROUP.EDIT}
                setValues={setValues}
                width={1200}
                form={form}
                goBack={() => history.goBack()}
                paramsId={id}
                setIsCreate={setIsCreate}
                checkBeforeSave={onSave}
                {...props}
                hideActivationButtons={true}
                hideButtons={tabIndex > 1}
                customButtons={customButtons()}
                normalize={(values: any) => {
                    delete values.type;
                    return {
                        ...values,
                        customer:
                            values.customer != null && values.customer.constructor === Number
                                ? { id: parseInt(values.customer) }
                                : values.customer,
                    };
                }}
            >
                <Tabs
                    type="card"
                    onTabClick={(e) => {
                        setTabIndex(e);
                    }}
                    destroyInactiveTabPane={true}
                >
                    <TabPane tab={geti18nText('deviceGroup.tab.general')} key="1">
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item name="id" style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label={geti18nText('deviceGroup.edit.name')} name="name">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label={geti18nText('deviceType.edit.category')}
                                    name="deviceCategory"
                                    rules={[
                                        {
                                            required: true,
                                            message: geti18nText('app.default.required'),
                                        },
                                    ]}
                                >
                                    <Select>
                                        {deviceCategoryEnum().map((option: { [index: string]: any }) => (
                                            <Option key={option.id} value={option.id}>
                                                {option.text}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        {!isCreate && NySession.hasAnyRole(['ROLE_CUSTOMER']) && type != 'LIGHTS' && (
                            <Row>
                                <Col span={5}></Col>
                                <Col span={18}>
                                    <Button type="primary" onClick={() => setCreateTasksVisible(true)}>
                                        {geti18nText('deviceGroup.tab.button.generate.tasks')}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                        <Row gutter={24}>
                            <Col span={24}>
                                {NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) && (
                                    <Form.Item label={geti18nText('deviceGroup.edit.customer')} name="customer">
                                        <NySearchField
                                            url={CONSTANTS_REQ.CUSTOMER.SEARCH}
                                            map={{ id: 'id', label: 'name' }}
                                            searchBy="name"
                                            addedFilter={{
                                                field: 'category_type',
                                                condition: 'in',
                                                value: [type].join(','),
                                            }}
                                        />
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                {NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) && type == 'LIGHTS' && (
                                    <Form.Item label={geti18nText('deviceGroup.edit.api')} name="api">
                                        <Input />
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane
                        tab={geti18nText('deviceGroup.tab.devicesInGroup')}
                        key="2"
                        disabled={(isCreate || loading) && !enableTabs}
                        // forceRender={true}
                    >
                        <DeviceInGroupTable type={type} />
                    </TabPane>
                    <TabPane
                        tab={geti18nText('deviceGroup.tab.devicesOutGroup')}
                        key="3"
                        disabled={(isCreate || loading) && !enableTabs}
                        // forceRender={true}
                    >
                        <DeviceTable type={type} customerId={customerId} />
                    </TabPane>
                    {type == DeviceCategoryType.WEATHER && !isCreate && (
                        <TabPane
                            tab={geti18nText('alarm.settings.edit.header')}
                            key="4"
                            disabled={(isCreate || loading) && !enableTabs}
                        >
                            <AlarmSettingsIndex
                                type={AlarmSettingsType.DEVICE_GROUP}
                                deviceGroupId={dataForm}
                                customerId={customerId}
                            />
                        </TabPane>
                    )}
                </Tabs>
            </NyDataEdit>
            <Modal
                visible={createTasksVisible}
                title={geti18nText('generate.tasks.title')}
                footer={null}
                width={1000}
                onCancel={() => setCreateTasksVisible(false)}
                destroyOnClose={true}
            >
                <GenerateTasks groupId={groupId} />
            </Modal>
        </React.Fragment>
    );
};

export default DeviceGroupEdit;
