import React, { useState, useEffect } from 'react';
import { Card, Statistic } from 'antd';
import { LineChartOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { geti18nText, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';

const StatisticWidget = () => {
    const { record, visible } = useSelector((state: RootState) => state.deviceDetails);

    const [widgetData, setWidgetData] = useState<any>(null);

    useEffect(() => {
        if (visible) {
            fetchWidgetValue();
        }
    }, [visible]);

    const fetchWidgetValue = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CONSUMPTION_FOR_TODAY_AND_CURRENT_WEEK_FOR_DEVICE, {
            deviceId: record.id,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setWidgetData(response.data);
            }
        });
    };

    return (
        <div className="box" style={{ marginRight: '10px', height: '95%' }}>
            <Card style={{ height: '45%' }}>
                <Statistic
                    title={geti18nText('deviceDetails.widget1.title')}
                    value={widgetData != null ? NyUtils.formatNumber(widgetData.today, 2) : ' '}
                    precision={2}
                    valueStyle={{ color: '#1890ff', fontSize: '24px', fontWeight: 'bold' }}
                    prefix={<LineChartOutlined />}
                    suffix="m³"
                />
            </Card>
            <Card style={{ marginTop: '10px', height: '45%' }}>
                <Statistic
                    title={geti18nText('deviceDetails.widget2.title')}
                    value={widgetData != null ? NyUtils.formatNumber(widgetData['current-week'], 2) : ' '}
                    precision={2}
                    valueStyle={{ color: '#1890ff', fontSize: '24px', fontWeight: 'bold' }}
                    prefix={<LineChartOutlined />}
                    suffix="m³"
                />
            </Card>
        </div>
    );
};

export default StatisticWidget;
