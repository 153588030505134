import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IDeviceDetailsState {
    record: { [index: string]: any };
    visible: boolean;
}

const initialState: IDeviceDetailsState = {
    record: {},
    visible: false,
};

const deviceLightDetails = createSlice({
    name: 'deviceLightDetails',
    initialState,
    reducers: {
        deviceLightDetailsInit(state) {
            state.record = {};
            state.visible = false;
        },
        deviceLightDetailsOpen(state, action: PayloadAction<IDeviceDetailsState>) {
            const { record, visible } = action.payload;
            state.record = record;
            state.visible = visible;
        },
    },
});

export const { deviceLightDetailsInit, deviceLightDetailsOpen } = deviceLightDetails.actions;

export default deviceLightDetails.reducer;
