import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IImportExportSlice {
    refresh: number;
}

const initialState: IImportExportSlice = {
    refresh: 0,
};

const importExportSlice = createSlice({
    name: 'importExportSlice',
    initialState,
    reducers: {
        importExportInit(state) {
            state.refresh = 0;
        },
        importExportSet(state, action: PayloadAction<IImportExportSlice>) {
            const { refresh } = action.payload;
            state.refresh = refresh;
        },
        refreshImportExportList(state) {
            state.refresh++;
        },
    },
});

export const { importExportInit, importExportSet, refreshImportExportList } = importExportSlice.actions;

export default importExportSlice.reducer;