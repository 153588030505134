import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Card, Statistic } from 'antd';
import { geti18nText, NyUtils } from '@nybble/nyreact';
import { parametarKeyName, toCamelCase } from '../../../../utils/Utils';

const { Title } = Typography;
interface ITempCard {
    data?: any;
}
const TempCard: React.FC<ITempCard> = ({ data = undefined }) => {
    const styles: any = {
        row: {
            alignItems: 'center',

            width: '100%',
            backgroundImage: 'linear-gradient(to bottom right, #87C1FF, #93c7ff)',
        },

        statistic: {
            margin: '20px',
        },
    };
    const [lastActivity, setLastActivity] = useState<any>(undefined);
    useEffect(() => {
        if (data != undefined && data.ts != undefined) {
            const ts: Date = new Date(data.ts[0], data.ts[1] - 1, data.ts[2], data.ts[3], data.ts[4], data.ts[5]);
            setLastActivity(ts.toLocaleString(NyUtils.getSelectedLocale()));
        }
    }, [data]);

    return (
        <React.Fragment>
            <Card style={styles.row} /*onClick={() => setParName(null)}*/>
                <Row>
                    {lastActivity && (
                        <Col style={styles.statistic}>
                            <Statistic
                                title={geti18nText('dashboard.widget.alarm.table.lastactivity')}
                                value={lastActivity}
                                valueStyle={{
                                    //color: parName == 'total_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: 'white',
                                }}
                                style={{
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    color: 'black',
                                }}
                            />
                        </Col>
                    )}
                    {data?.parameters?.map((parameter: any, index: number) => (
                        <Col style={styles.statistic}>
                            <Statistic
                                title={parameter[parametarKeyName('name')]}
                                value={
                                    data[parameter.dbColumn] != undefined
                                        ? data[parameter.dbColumn]
                                        : data[toCamelCase(parameter.dbColumn)] != undefined
                                        ? data[toCamelCase(parameter.dbColumn)]
                                        : undefined
                                }
                                valueStyle={{
                                    //color: parName == 'total_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    color: 'white',
                                }}
                                style={{
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    color: 'black',
                                }}
                                suffix={parameter.unit}
                            />
                        </Col>
                    ))}
                </Row>
            </Card>
        </React.Fragment>
    );
};

export default TempCard;
