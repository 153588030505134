import React, { useEffect, useState } from 'react';
import { Popover, Typography } from 'antd';

import { getColumnSearchOption, geti18nText, NyDataTable, NyUtils } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { GetEnumNameForValue } from '../../../utils/Enums';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { deviceDetailsInit } from '../../../slices/deviceDetailsSlice';
import FormatNumber from '../../number-formatter';

const { Title } = Typography;

interface IChartDataConsumption {
    period?: any;
    title: string | null;
    deviceId: number | null;
    dateFrom?: any;
    dateTo?: any;
    phaseValue?: any;
}

const ChartEnergyDataConsumption: React.FC<IChartDataConsumption> = ({
    period,
    title,
    deviceId,
    dateFrom,
    dateTo,
    phaseValue = null,
}) => {
    const [refresh, setRefresh] = useState<any>(null);
    useEffect(() => {
        setRefresh(new Date());
    }, [dateFrom, phaseValue]);
    return (
        <div>
            <Title level={4}>{geti18nText('chart.data.energy.consumption.title') + ' - ' + title}</Title>
            <NyDataTable
                url={CONSTANTS_REQ.DEVICE.ENERGY_CONSUMPTION_DATA}
                hideButtons={true}
                addedData={{
                    ...period,
                    deviceId: deviceId,
                    timestampFrom: dateFrom,
                    timestampTo: dateTo,
                    phase: phaseValue != null && phaseValue != 'ALL' ? parseInt(phaseValue?.replace('L', '')) : null,
                }}
                scroll={{ x: 800 }}
                readonly={true}
                fetchWhenChange={refresh}
                setRowClassName={(record: any) => {
                    if (record.phase == 1) {
                        return 'table-row-phase1';
                    }
                    if (record.phase == 2) {
                        return 'table-row-phase2';
                    }
                    if (record.phase == 3) {
                        return 'table-row-phase3';
                    }
                    return '';
                }}
                columns={[
                    {
                        title: geti18nText('chart.data.energy.consumption.electricEnergy'),
                        dataIndex: 'electricEnergy',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return <FormatNumber value={record.electricEnergy}></FormatNumber>;
                        },
                    },

                    {
                        title: geti18nText('chart.data.energy.consumption.totalPower'),
                        dataIndex: 'totalPower',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.totalPower ? NyUtils.formatNumber(record.totalPower, 2) : '0,0'}
                                    &nbsp;W
                                </div>
                            );
                        },
                    },

                    {
                        title: geti18nText('chart.data.energy.consumption.voltage'),
                        dataIndex: 'voltage',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.voltage ? NyUtils.formatNumber(record.voltage, 2) : '0,0'}
                                    &nbsp;V
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('chart.data.energy.consumption.current'),
                        dataIndex: 'current',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.current ? NyUtils.formatNumber(record.current, 2) : '0,0'}
                                    &nbsp;A
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('chart.data.energy.consumption.activePower'),
                        dataIndex: 'activePower',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.activePower ? NyUtils.formatNumber(record.activePower, 2) : '0,0'}
                                    &nbsp;kW
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('chart.data.energy.consumption.reactivePower'),
                        dataIndex: 'reactivePower',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.reactivePower ? NyUtils.formatNumber(record.reactivePower, 2) : '0,0'}
                                    &nbsp;kvar
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('chart.data.energy.consumption.phase'),
                        dataIndex: 'phase',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return <div>{record.phase}</div>;
                        },
                    },

                    {
                        title: geti18nText('chart.data.consumption.table.ts'),
                        dataIndex: 'ts',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.ts) {
                                const ts: Date = new Date(record.ts);
                                return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                            }
                        },
                    },
                ]}
            />
        </div>
    );
};

export default ChartEnergyDataConsumption;
