import React from 'react';
import './card.scss'; // Učitavanje CSS-a za stilizaciju
import { AlertOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { setQueryId, setSelectedDeviceCategorie } from '../../../slices/dashboardSlice';
import { useDispatch } from 'react-redux';
import { ReactComponent as BellAlert } from '../../../assets/img/bell-alert-outline.svg';
import { ReactComponent as BellCheck } from '../../../assets/img/bell-check.svg';
import { transleteByCount } from '../../../utils/Utils';
const CategoryCard = ({ item }: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    return (
        <div
            className="card"
            onClick={() => {
                dispatch(setQueryId(item.id));
                dispatch(setSelectedDeviceCategorie(item));
                history.push('weatherDashboard');
            }}
        >
            <div className="card-title-item">
                <img className="card-title-icon" src={'data:image/jpeg;base64,' + item.icon} alt="icon" />
                <h3 className="card-title">{item.name}</h3>
            </div>
            <div>
                <div className="category-device-number">
                    {item.deviceCount}
                    {item.inactiveCount != undefined && item.inactiveCount > 0 && (
                        <span className="category-device-number-inactive">
                            {' / ' +
                                item.inactiveCount +
                                ' ' +
                                transleteByCount('dashboard.card.inactive', item.deviceCount)}
                        </span>
                    )}
                </div>
                <span className="category-device-number-label">
                    {transleteByCount('dashboard.card.sensor', item.deviceCount)}
                </span>
            </div>

            {item.alarmCount > 0 && (
                <div className="alarm-info">
                    <div className="alarm-icon-content">
                        <BellAlert height={'24px'} width={'24px'} fill="#FF3034" />
                    </div>
                    <span className="alarm-count">{item.alarmCount > 100 ? '99+' : item.alarmCount}</span>
                </div>
            )}
            {item.alarmCount == 0 && (
                <div className="alarm-info">
                    <div className="alarm-icon-content">
                        <BellCheck height={'24px'} width={'24px'} fill="#57B894" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default CategoryCard;
