import React from 'react';
import { Typography } from 'antd';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { GetEnumNameForValue } from '../../utils/Enums';
import { geti18nText, NyDataTable, NyUtils } from '@nybble/nyreact';

const { Title } = Typography;

interface IChartDataVolumes {
    period?: any;
    title: string | null;
    deviceId: number | null;
}

const ChartDataVolumes: React.FC<IChartDataVolumes> = ({ period, title, deviceId }) => {
    return (
        <div>
            <Title level={4}>{geti18nText('chart.data.volumes.title') + ' - ' + title}</Title>
            <NyDataTable
                url={CONSTANTS_REQ.DEVICE.VOLUMES}
                hideButtons={true}
                addedData={{ ...period, deviceId: deviceId }}
                scroll={{ x: 800 }}
                readonly={true}
                columns={[
                    {
                        title: geti18nText('chart.data.volumes.table.volume'),
                        dataIndex: 'volume',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.volume ? NyUtils.formatNumber(record.volume, 3) : '0,0'}
                                    &nbsp;m3
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('chart.data.volumes.table.ts'),
                        dataIndex: 'ts',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.ts) {
                                const ts: Date = new Date(record.ts);
                                return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                            }
                        },
                    },
                    {
                        title: geti18nText('chart.data.volumes.table.payloadType'),
                        dataIndex: 'payloadType',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {geti18nText(
                                        'app.enum.PAYLOAD_TYPE.' +
                                            GetEnumNameForValue('PAYLOAD_TYPE', record.payloadType)
                                    )}
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('chart.data.volumes.table.status'),
                        dataIndex: 'status',
                        align: 'right',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {geti18nText(
                                        'app.enum.ALARM_STATUS.' + GetEnumNameForValue('ALARM_STATUS', record.status)
                                    )}
                                </div>
                            );
                        },
                    },
                ]}
            />
        </div>
    );
};

export default ChartDataVolumes;
