import React from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import DashboardByCategoryType from './dashboardByCategoryType';

const EnergyDashboard = () => {
    

    return (
       <DashboardByCategoryType type={'ENERGYMETER'} />
    );
};

export default EnergyDashboard;
