import React, { useState, useEffect, Component, ComponentLifecycle } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import LayoutMain from './components/layout-main';
import { Spin } from 'antd';
import { URL_PREFIX, CONSTANTS_REQ } from './utils/Constants';
import './assets/scss/index.scss';
import logoBlack from './assets/img/logo_black.png';
import Monitor from './views/monitor';
import { LoadEnums } from './utils/Enums';
import { geti18nText, NyLogin, NyRequestResolver, NySession, NyUtils, RESPONSE } from '@nybble/nyreact';
import { useDispatch } from 'react-redux';
import { setUserWeatherWidgets } from './slices/dashboardSlice';

const App = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const settings = NyUtils.loadFromSession('IoTSettings');
    const loginLogo =
        settings != null && settings.loginLogo != null ? (
            <img
                src={CONSTANTS_REQ.DISK_FILE.DOWNLOAD + `?fileName=${settings.loginLogo}`}
                alt="Chameleon"
                style={{ width: '280px' }}
            />
        ) : (
            <img src={logoBlack} alt="Chameleon" style={{ width: '280px' }} />
        );

    const LoginComponent = () => (
        <NyLogin
            logo={loginLogo}
            loginErrorText={geti18nText('login.error')}
            validationUsernameText={geti18nText('login.validation.username')}
            validationPasswordText={geti18nText('login.validation.password')}
            usernamePlaceholderText={geti18nText('login.username.placeholder')}
            passwordePlaceholderText={geti18nText('login.password.placeholder')}
            loginButtonText={geti18nText('login.button')}
            redirectTo="/"
            url={URL_PREFIX + 'auth/login'}
        />
    );

    useEffect(() => {
        initApp();
    }, []);

    const initApp = async () => {
        setLoading(true);
        await NySession.init();
        await LoadEnums();
        setLoading(false);
    };

    if (loading) {
        return (
            <div className="spin-container">
                <Spin size="large" />
            </div>
        );
    } else {
        if (window.location.pathname === '/monitor') {
            return <Monitor />;
        } else
            return (
                <HashRouter>
                    <Switch>
                        <Route exact path="/monitor" component={Monitor} />
                        <Route exact path="/login" component={() => <LoginComponent />} />
                        <Route path="/" component={LayoutMain} />
                    </Switch>
                </HashRouter>
            );
    }
};

export default App;
