import React, { useEffect, useState } from 'react';
import { setSelectedWeatherDataType, seWeatherParameters } from '../../../slices/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import { NyRequestResolver, RESPONSE } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { Popover } from 'antd';
import { parametarKeyName } from '../../../utils/Utils';

const WeatherDataTypeFilter = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    const { selectedWeatherDataType, queryId } = useSelector((state: RootState) => state.dashboard);

    const [parameterLabels, setParameterLabels] = useState([]);

    useEffect(() => {
        loadFilterParameters();
    }, [queryId]);

    const loadFilterParameters = async () => {
        let data: any = {};
        if (queryId) {
            data.categoryId = queryId;
        }
        let result: any = await NyRequestResolver.requestGet(CONSTANTS_REQ.WEATHER_PARAMETER.LIST_ALL, data);
        if (result.status === RESPONSE.OK) {
            if (result.data) {
                result.data.forEach((item: any) => {
                    if (item.icon != undefined) {
                        let icon = 'data:image/jpeg;base64,' + item.icon;
                        item.icon = (
                            <img
                                className="filter-img selected"
                                style={{ maxWidth: '30px', maxHeight: '30px' }}
                                src={icon}
                            ></img>
                        );
                    }
                });
                setParameterLabels(result.data);
                dispatch(seWeatherParameters(result.data));
            }
        }
    };
    const selectKey = (item: any) => {
        let sel = item?.key;
        if (item?.key == selectedWeatherDataType?.key) {
            sel = undefined;
        }
        dispatch(setSelectedWeatherDataType(sel != undefined ? item : undefined));
    };

    return (
        <div className="weather-data-type-div">
            {parameterLabels.map((item: any) => {
                return (
                    <>
                        <div
                            className={
                                item.key == selectedWeatherDataType?.key
                                    ? 'filter-img filter-img-selected'
                                    : 'filter-img'
                            }
                            onClick={() => selectKey(item)}
                        >
                            <Popover content={item[parametarKeyName('name')]}>{item.icon}</Popover>
                        </div>
                    </>
                );
            })}
        </div>
    );
};

export default WeatherDataTypeFilter;
