import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'antd/dist/antd.compact.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { ConfigProvider } from 'antd';
import { URL_PREFIX, APPLICATION_KEY, CONSTANTS_REQ } from './utils/Constants';
import enEN from 'antd/es/locale/en_US';
import hrHR from 'antd/es/locale/hr_HR';
import store from './store';
import { Provider } from 'react-redux';
import { NyRequestResolver, NySession, NySettings, NyUtils, RESPONSE } from '@nybble/nyreact';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConfigProvider locale={NyUtils.getSelectedLocale() == 'hr-HR' ? hrHR : enEN}>
                <NySettings
                    applicationKey={APPLICATION_KEY}
                    urlPreffix={URL_PREFIX}
                    urlRefresh={URL_PREFIX + 'auth/refresh'}
                />
                <App />
            </ConfigProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

NyRequestResolver.requestGet(CONSTANTS_REQ.WHITELABEL.DOMAIN + `?domain=${window.location.hostname}`).then((result) => {
    if (result.status === RESPONSE.OK) {
        if (result.data != null) {
            const resultData: any = result.data;
            if (resultData != null && resultData[0] != null) {
                const config = JSON.parse(resultData[0].json_config);
                if (config != null) {
                    sessionStorage.setItem('IoTSettings', JSON.stringify(config));
                }
                if (config.title != null) {
                    document.title = config.title;
                }
            } else if (NySession.getUser() != null && NySession.getUser().id != null) {
                NyRequestResolver.requestGet(CONSTANTS_REQ.WHITELABEL.CUSTOMER).then((result) => {
                    if (result.status === RESPONSE.OK) {
                        if (result.data != null) {
                            const resultData: any = result.data;
                            if (resultData != null && resultData[0] != null) {
                                const config = JSON.parse(resultData[0].json_config);
                                if (config != null) {
                                    sessionStorage.setItem('IoTSettings', JSON.stringify(config));
                                }

                                if (config.title != null) {
                                    document.title = config.title;
                                }
                            }
                        }
                    }
                });
            }
        }
    }
});
