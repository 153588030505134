import React, { useState, useEffect } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { IWidget } from './index';
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    PushpinOutlined,
    ZoomInOutlined,
    FullscreenOutlined,
    CommentOutlined,
} from '@ant-design/icons';
import { deviceLocationOpen } from '../../slices/deviceLocationSlice';
import { RootState } from '../../rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
    refresLightsList,
    setMapPanTo,
    filterDeviceDevices,
    filterReset,
    refreshWatermeterList,
} from '../../slices/dashboardSlice';
import { Tooltip, Modal, Popover } from 'antd';
import { GetEnumNameForValue } from '../../utils/Enums';
import { getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession, NyUtils } from '@nybble/nyreact';
import FormatNumber, { formatNumberValueString } from '../number-formatter';
import { deviceEnergyDetailsOpen } from '../../slices/deviceEnergyDetailsSlice';

const getScrolls = (dataGrid: { [index: string]: string | number | boolean }) => {
    let scr: { [index: string]: any } = {};
    if (typeof dataGrid.h == 'number') {
        scr.y = 40 * dataGrid.h - 170;
    }
    return scr;
};

const getModalScrolls = (dataGrid: { [index: string]: string | number | boolean }) => {
    let scr: { [index: string]: any } = { x: 1400 };

    return scr;
};

interface ColumnTitleProps {
    text: string;
}

const ColumnTitle: React.FC<ColumnTitleProps> = ({ text }) => {
    return <div style={{ whiteSpace: 'nowrap' }}>{text}</div>;
};

const WidgetEnrgyMeterList: any = ({ dataGrid }:any) => {
    const [visible, setVisible] = useState(false);

    const lightsListRefresh = useSelector((state: RootState) => state.dashboard.lightsListRefresh);
    const mapSelectedBounds = useSelector((state: RootState) => state.dashboard.mapSelectedBounds);
    const filterDevice = useSelector((state: RootState) => state.dashboard.filterDevice);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(refresLightsList());
    }, [mapSelectedBounds]);

    const editLocation = (deviceId: string, data?: { [index: string]: any }) => {
        dispatch(deviceLocationOpen({ deviceId: deviceId, data: data, visible: true }));
    };

    const onRowSelect = (record: { [index: string]: any }) => {
        dispatch(deviceEnergyDetailsOpen({ record: record, visible: true }));
    };

    const setDefaultFilterValue = () => {
        return [];
    };

    const generateKey = () => {
        return 'WidgetEnrgyMeterList-K1'; // new Date().toUTCString();
    };

    const showOnMap = (record: { [index: string]: any }) => {
        if (record.lat && record.lng) {
            dispatch(setMapPanTo({ lat: record.lat, lng: record.lng }));
        }
    };

    const showOnMapFilter = (item: any) => {
        if (filterDevice != item.id) {
            showOnMap(item);
            dispatch(filterDeviceDevices({ id: item.id }));
        } else {
            dispatch(filterReset());
        }
    };

    const columns: any = () => {
        return [
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.energy.table.name')} />,
                dataIndex: ['device', 'name'],
                sorter: (a: string, b: string) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    return <div> {record.device?.name} </div>;
                },
                ...getColumnSearch('string'),
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.energy.table.serial')} />,
                dataIndex: ['device', 'eui'],
                sorter: (a: string, b: string) => {},
                ...getColumnSearch('string'),
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.energy.table.ua')} />,
                dataIndex: 'ua',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.ua ? NyUtils.formatNumber(record.ua, 2) : '0,0'}
                            &nbsp;V
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.energy.table.ia')} />,
                dataIndex: 'ia',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.ia ? NyUtils.formatNumber(record.ia, 2) : '0,0'}
                            &nbsp;A
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.energy.table.na')} />,
                dataIndex: 'na',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.na ? NyUtils.formatNumber(record.na, 2) : '0,0'}
                            &nbsp;W
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.energy.table.aa')} />,
                dataIndex: 'aa',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return <div>{record.aa ? formatNumberValueString(record.aa, true) : '0,0 Wh'}</div>;
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.energy.table.ub')} />,
                dataIndex: 'ub',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.ub ? NyUtils.formatNumber(record.ub, 2) : '0,0'}
                            &nbsp;V
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.energy.table.ib')} />,
                dataIndex: 'ib',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.ib ? NyUtils.formatNumber(record.ib, 2) : '0,0'}
                            &nbsp;A
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.energy.table.nb')} />,
                dataIndex: 'nb',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.nb ? NyUtils.formatNumber(record.nb, 2) : '0,0'}
                            &nbsp;W
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.energy.table.ab')} />,
                dataIndex: 'ab',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return <div>{record.aa ? formatNumberValueString(record.ab, true) : '0,0 Wh'}</div>;
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.energy.table.uc')} />,
                dataIndex: 'uc',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.uc ? NyUtils.formatNumber(record.uc, 2) : '0,0'}
                            &nbsp;V
                        </div>
                    );
                },
            },

            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.energy.table.ic')} />,
                dataIndex: 'ic',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.ic ? NyUtils.formatNumber(record.ic, 2) : '0,0'}
                            &nbsp;A
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.energy.table.nc')} />,
                dataIndex: 'nc',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.nc ? NyUtils.formatNumber(record.nc, 2) : '0,0'}
                            &nbsp;W
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.energy.table.ac')} />,
                dataIndex: 'ac',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return <div>{record.ac ? formatNumberValueString(record.ac, true) : '0,0 Wh'}</div>;
                },
            },

            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.lastactivity')} />,
                dataIndex: 'ts',
                sorter: (a: string, b: string) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.ts) {
                        var ts: any = new Date(record.ts);
                        if (ts !== '') {
                            return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                        } else {
                            return <div></div>;
                        }
                    }
                },
            },

            {
                dataIndex: 'operation',
                width: NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER']) ? 125 : 30,
                align: 'left',
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.id) {
                        return (
                            <div>
                                {NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER']) && (
                                    <React.Fragment>
                                        <Tooltip
                                            title={geti18nText('dashboard.widget.watermeter.table.location.tooltip')}
                                        >
                                            <PushpinOutlined
                                                style={{ fontSize: '18px', marginRight: '10px' }}
                                                onClick={() =>
                                                    editLocation(record.id, {
                                                        latitude: record.lat,
                                                        longitude: record.lng,
                                                    })
                                                }
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                )}
                                {record.lat != undefined && (
                                    <Tooltip title={geti18nText('dashboard.widget.watermeter.table.showonmap.tooltip')}>
                                        {filterDevice === record.id ? (
                                            <ZoomInOutlined
                                                style={{ fontSize: '18px', color: 'red' }}
                                                onClick={() => showOnMapFilter(record)}
                                            />
                                        ) : (
                                            <ZoomInOutlined
                                                style={{ fontSize: '18px' }}
                                                onClick={() => showOnMapFilter(record)}
                                            />
                                        )}
                                    </Tooltip>
                                )}
                            </div>
                        );
                    }
                },
            },
        ];
    };

    const hideColumnsInMainTable: any = ['ua', 'ub', 'uc', 'ia', 'ib', 'ic'];
    function getCsvColumns() {
        return [
            {
                title: geti18nText('dashboard.widget.light.table.name'),
                dataIndex: 'device.name',
            },
            {
                title: geti18nText('dashboard.widget.light.table.serial'),
                dataIndex: 'device.eui',
            },
            {
                title: geti18nText('dashboard.widget.energy.table.ia'),
                dataIndex: 'ia',
            },
            {
                title: geti18nText('dashboard.widget.energy.table.ib'),
                dataIndex: 'ia',
            },
            {
                title: geti18nText('dashboard.widget.energy.table.ic'),
                dataIndex: 'ia',
            },
            {
                title: geti18nText('dashboard.widget.energy.table.ua'),
                dataIndex: 'ua',
            },
            {
                title: geti18nText('dashboard.widget.energy.table.ub'),
                dataIndex: 'ub',
            },
            {
                title: geti18nText('dashboard.widget.energy.table.uc'),
                dataIndex: 'uc',
            },
            {
                title: geti18nText('dashboard.widget.energy.table.na'),
                dataIndex: 'na',
            },
            {
                title: geti18nText('dashboard.widget.energy.table.nb'),
                dataIndex: 'nb',
            },
            {
                title: geti18nText('dashboard.widget.energy.table.nc'),
                dataIndex: 'nc',
            },
            {
                title: geti18nText('dashboard.widget.energy.table.aa'),
                dataIndex: 'aa',
            },
            {
                title: geti18nText('dashboard.widget.energy.table.ab'),
                dataIndex: 'ab',
            },
            {
                title: geti18nText('dashboard.widget.energy.table.ac'),
                dataIndex: 'ac',
            },
            {
                title: geti18nText('dashboard.widget.watermeter.table.lastactivity'),
                dataIndex: 'ts',
            },
        ];
    }

    const csvCustomizationColumns: any = () => {
        return [
            {
                ts: (value: any) => {
                    if (value) {
                        var ts: any = new Date(value);
                        if (ts !== '') {
                            return ts.toLocaleString(NyUtils.getSelectedLocale());
                        }
                    }

                    return '';
                },
            },
            {
                current: (value: any) => {
                    if (value != undefined) {
                        return NyUtils.formatNumber(value, 2);
                    }
                    return value;
                },
            },
            {
                action: (value: any) => {
                    if (value != undefined) {
                        return geti18nText(
                            'app.enum.PAYLOAD_STATUS_ACTION.' + GetEnumNameForValue('PAYLOAD_STATUS_ACTION', value)
                        );
                    }
                    return '';
                },
            },
            {
                aa: (value: any) => {
                    return value ? formatNumberValueString(value, true) : '';
                },
            },
            {
                ab: (value: any) => {
                    return value ? formatNumberValueString(value, true) : '';
                },
            },
            {
                ac: (value: any) => {
                    return value ? formatNumberValueString(value, true) : '';
                },
            },
            {
                ia: (value: any) => {
                    return value ? value + ' A' : '';
                },
            },
            {
                ib: (value: any) => {
                    return value ? value + ' A' : '';
                },
            },
            {
                ic: (value: any) => {
                    return value ? value + ' A' : '';
                },
            },
            {
                ua: (value: any) => {
                    return value ? value + ' V' : '';
                },
            },
            {
                ub: (value: any) => {
                    return value ? value + ' V' : '';
                },
            },
            {
                uc: (value: any) => {
                    return value ? value + ' V' : '';
                },
            },
            {
                na: (value: any) => {
                    return value ? value + ' W' : '';
                },
            },
            {
                nb: (value: any) => {
                    return value ? value + ' W' : '';
                },
            },
            {
                nc: (value: any) => {
                    return value ? value + ' W' : '';
                },
            },
        ];
    };

    return (
        <React.Fragment>
            <FullscreenOutlined
                style={{ position: 'absolute', right: '5px', top: '5px' }}
                onClick={() => setVisible(true)}
            />
            <NyDataTable
                key={generateKey()}
                headerTitle={geti18nText("dashboard.widget.energy.table.title")}
                url={CONSTANTS_REQ.DASHBOARD.ENERGY_METER_LIST}
                hideButtons={true}
                exportCSV={true}
                onRowSelect={onRowSelect}
                addedData={
                    mapSelectedBounds != null
                        ? {
                              latNorthEast: mapSelectedBounds.latNorthEast,
                              lngNorthEast: mapSelectedBounds.lngNorthEast,
                              latSouthWest: mapSelectedBounds.latSouthWest,
                              lngSouthWest: mapSelectedBounds.lngSouthWest,
                          }
                        : undefined
                }
                setDefaultFilterValue={setDefaultFilterValue}
                scroll={getScrolls(dataGrid)}
                fetchWhenChange={lightsListRefresh}
                columns={columns().filter((x: any) => !hideColumnsInMainTable.includes(x.dataIndex))}
                csvColumns={getCsvColumns()}
                colCSVCustomization={csvCustomizationColumns()}
            />
            <Modal
                title={geti18nText('dashboard.widget.watermeter.table.title')}
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={'100%'}
                footer={null}
            >
                {visible && (
                    <NyDataTable
                        key={generateKey()}
                        headerTitle={geti18nText('dashboard.widget.light.table.title')}
                        url={CONSTANTS_REQ.DASHBOARD.ENERGY_METER_LIST}
                        hideButtons={true}
                        exportCSV={true}
                        onRowSelect={onRowSelect}
                        setDefaultFilterValue={setDefaultFilterValue}
                        scroll={getModalScrolls(dataGrid)}
                        fetchWhenChange={lightsListRefresh}
                        columns={columns()}
                        csvColumns={getCsvColumns()}
                        colCSVCustomization={csvCustomizationColumns()}
                    />
                )}
            </Modal>
        </React.Fragment>
    );
};

export default WidgetEnrgyMeterList;
