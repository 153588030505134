import React, { useState, useEffect } from 'react';
import { BarChart, CartesianGrid, XAxis, YAxis, Legend, Bar, Tooltip, ResponsiveContainer } from 'recharts';
import { Typography, Row, Col, Select, DatePicker, Modal } from 'antd';
import { CONSTANTS_REQ } from '../../utils/Constants';
import './index.scss';
import moment from 'moment';
import { FullscreenOutlined } from '@ant-design/icons';
import {
    getColumnSearch,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySpinner,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';

const { Title } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const CardDetails = () => {
    const [params, setParams] = useState<any>({period:'current-month'});
    const [selected, setSelected] = useState<any>('current-month');
    const [graphData, setGraphData] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [tableFetchChange, setTableFetchChange] = useState(-1);
    const [tableRefresh, setTableRefresh] = useState<any>(-1);
    const [rangePickerValue, setRangePickerValue] = useState<any>(null);


    useEffect(() => {
        if (params && params.period && params.period != 'span') {
            fetch();
        }
        else if(params && params.period && params.period == 'span' && params.timestampFrom != null && params.timestampTo != null) {
            fetch();
        }
    }, [params]);

    useEffect(()=>{
        if(tableRefresh >0) {
            setTableFetchChange(Math.random())
        }
    },[tableRefresh])

    function fetch() {
        setGraphData([]);
        if (
            params &&
            ((params.period !== 'initial' && params.period !== 'span') ||
                (params.period === 'span' && params.timestampFrom !== null && params.timestampTo !== null))
        ) {
            setLoading(true);
            NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_CONSUMPTION, {
                ...params,
                lang: NyUtils.getSelectedLanguage(),
            }).then((response: any) => {
                setLoading(false);
                if (response.status === RESPONSE.OK) {
                    setGraphData(response.data);
                }
            });
        }
    }

    function onTimeRangeChange(value: any) {
        setSelected(value);
        setRangePickerValue(null);
        setParams((prevState: any) => {
            return {
                period: value,
            };
        });
        if(value != 'span'){
            setTableRefresh(Math.random());
        }
    }

    function onRangeChange(dates: any) {
        const timestampFrom = dates[0] ? moment(dates[0]).valueOf() : null;
        const timestampTo = dates[1] ? moment(dates[1]).valueOf() : null;
        setParams((prevState: any) => {
            return { ...prevState, timestampFrom: timestampFrom, timestampTo: timestampTo };
        });
        setTableRefresh(Math.random());
        setRangePickerValue(dates);
    }

    function disabledDate(current: any) {
        const rangeStart = moment().subtract(1, 'years');
        const rangeEnd = moment().endOf('day');
        return !(rangeStart.isSameOrBefore(current) && rangeEnd.isAfter(current));
    }

   

    return (
        <React.Fragment>
            <div className="box">
                <Title level={4}>{geti18nText('cardDetails.graph1.title')}</Title>
                <Row gutter={24} style={{ marginBottom: '10px' }}>
                    <Col span={8}>
                        <Select defaultValue={selected} style={{ width: '100%' }} onChange={onTimeRangeChange}>
                            <Option value="last-24h">{geti18nText('report.time_range.last24h')}</Option>
                            <Option value="current-week">{geti18nText('report.time_range.current_week')}</Option>
                            <Option value="last-week">{geti18nText('report.time_range.last_week')}</Option>
                            <Option value="current-month">{geti18nText('report.time_range.current_month')}</Option>
                            <Option value="current-year">{geti18nText('report.time_range.current_year')}</Option>
                            <Option value="span">{geti18nText('report.time_range.span')}</Option>
                        </Select>
                    </Col>
                    <Col span={8} hidden={selected != 'span'}>
                        <RangePicker
                            disabledDate={disabledDate}
                            showTime
                            format="DD.MM.YYYY HH:mm:ss"
                            onChange={onRangeChange}
                            value={rangePickerValue}
                        />
                    </Col>
                </Row>
                <ResponsiveContainer width="100%" height={350}>
                    {loading ? (
                        <NySpinner />
                    ) : (
                        <BarChart data={graphData}>
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis dataKey="converted" />
                            <YAxis
                                label={{
                                    value: geti18nText('cardDetails.graph1.y.value'),
                                    angle: -90,
                                    position: 'insideLeft',
                                }}
                            />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="barValue" name={geti18nText('cardDetails.graph1.y.value')} fill="#1890ff" />
                        </BarChart>
                    )}
                </ResponsiveContainer>
            </div>
            <Row>
                <Col span={24}>
                    <div className="box">
                        <FullscreenOutlined
                            style={{ position: 'absolute', right: '15px', top: '15px' }}
                            onClick={() => setVisible(true)}
                        />
                        <DataTableComponent fetch={tableFetchChange} topConsumersParams={params} />
                    </div>
                </Col>
            </Row>

            <Modal
                title={geti18nText('cardDetails.table.title')}
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={'90%'}
                centered={true}
                footer={null}
            >
                <DataTableComponent fetch={tableFetchChange} topConsumersParams={params} />
            </Modal>
        </React.Fragment>
    );
};


const DataTableComponent = (props:any) => {
    const [params, setParams] = useState<any>({});
    useEffect(()=>{
        setParams(props.topConsumersParams)
    },[props.topConsumersParams])
    return (
        <React.Fragment>
            <Title level={4}>{geti18nText('cardDetails.table.title')}</Title>
            <NyDataTable
                url={CONSTANTS_REQ.DEVICE.TOP_CUSTOMERS}
                hideButtons={true}
                scroll={{ x: 800 }}
                exportCSV={true}
                fetchWhenChange={props.fetch}
                addedData={Object.keys(params).length>0?params:{period:'currenth-month'}}
                readonly={true}
                setDefaultSortOrder={{ order: 'volume', orderType: 'desc' }}
                columns={[
                    {
                        title: geti18nText('cardDetails.table.column.id'),
                        dataIndex: 'id',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('cardDetails.table.column.name'),
                        dataIndex: 'name',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('cardDetails.table.column.volume'),
                        dataIndex: 'volume',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                        defaultSortOrder: 'descend',
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.volume ? NyUtils.formatNumber(record.volume, 3) : '0,0'}
                                    &nbsp;m3
                                </div>
                            );
                        },
                    },
                ]}
            />
        </React.Fragment>
    );
};

export default CardDetails;
