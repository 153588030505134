import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Row, Col, Input, Form, Button, notification } from 'antd';
import { geti18nText, NyRequestResolver, NySession, NyUtils, RESPONSE } from '@nybble/nyreact';
import { useSelector } from 'react-redux';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { RootState } from '../../../rootReducer';
import SSE from './see';
import { ReloadOutlined } from '@ant-design/icons';

const LightParamSettings = (props: any) => {
    const { record } = useSelector((state: RootState) => state.deviceLightDetails);
    const eventSourceRef = useRef<any>(null);
    const [snapshotData, setSnapshotData] = useState<any>(null);
    const intervalRef = useRef<any>(null);
    const [progressBar, setProgressBar] = useState<any>(false);
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        register(true);
    }, []);

    function setValues(dataForm: any) {
        delete dataForm.active;
        if (dataForm.created) {
            dataForm.created = createDate(dataForm.created);
        }
        form.setFieldsValue(dataForm);
    }

    function register(init = false) {
        eventSourceRef.current = new SSE(
            CONSTANTS_REQ.DEVICE.LIGHT_STREAM + '/' + record.id + '?init=' + init + '&type=1',
            {
                headers: {
                    Authorization: 'Bearer ' + NySession.getUserToken(),
                },
            }
        );

        eventSourceRef.current.onmessage = (e: any) => {
            console.log('message ', e);
            const response = JSON.parse(e.data);
            const params = response.params;
            const error = response.error;

            if (params) {
                eventSourceRef.current?.close();
                setValues(params);

                if (progressBar == true) {
                    notification.success({
                        message: geti18nText('app.default.save.ok'),
                        description: geti18nText('deviceDetails.widget.light.command.success.msg'),
                        duration: 3,
                    });
                }
                setProgressBar(false);
                clearInterval(intervalRef.current);
            }
            if (error) {
                onError();
            }
        };

        eventSourceRef.current.onerror = (e: any) => {
            console.log('Error ', e);
            clearInterval(intervalRef.current);
            eventSourceRef.current?.close();
        };

        eventSourceRef.current.stream();
    }

    function onError() {
        setProgressBar(false);
        clearInterval(intervalRef.current);
        eventSourceRef.current?.close();
        notification.error({
            message: geti18nText('app.default.save.nok'),
            description: geti18nText('deviceDetails.widget.light.command.error.msg'),
        });
    }

    function queryparam() {
        eventSourceRef.current.close();
        setProgressBar(true);
        clearInterval(intervalRef.current);
        register(false);
        intervalRef.current = setInterval(onError, 60000);
        sendCommand('paramQuery', { deviceId: record.id });
    }

    function sendCommand(command: string, params: any) {
        NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE.EDIT + '/' + command, undefined, params).then((result) => {
            if (result && result.status !== RESPONSE.OK) {
                notification.error({
                    message: geti18nText('app.default.save.nok'),
                    description: '',
                });
            }
        });
    }

    const createDate = (data: any[]) => {
        if (data.length >= 3 && data.length < 5) {
            return new Date(data[0], data[1] - 1, data[2]).toLocaleString(NyUtils.getSelectedLocale());
        } else if (data.length == 5) {
            return new Date(data[0], data[1] - 1, data[2], data[3], data[4]).toLocaleString(
                NyUtils.getSelectedLocale()
            );
        } else if (data.length > 5) {
            return new Date(data[0], data[1] - 1, data[2], data[3], data[4], data[5]).toLocaleString(
                NyUtils.getSelectedLocale()
            );
        } else {
            return '';
        }
    };

    const onFinish = (values: any) => {
        const params = { ...values, deviceId: record.id };
        const command = 'submitParams';
        delete params.created;
        NyRequestResolver.requestPost(CONSTANTS_REQ.DEVICE.EDIT + '/' + command, undefined, params).then((result) => {
            if (result && result.status === RESPONSE.OK) {
                notification.success({
                    message: geti18nText('app.default.save.ok'),
                    description: geti18nText('deviceDetails.widget.light.command.success.msg'),
                    duration: 3,
                });
            } else {
                notification.error({
                    message: geti18nText('app.default.save.nok'),
                    description: '',
                });
            }
        });
    };

    return (
        <Fragment>
            <Row gutter={24}>
                <Col span={24}>
                    <Form form={form} onFinish={onFinish}>
                        <Row>
                            <Col span={8}>
                                <Form.Item name="id" style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item label={'heartbeatDte'} name="heartbeatDte">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col offset={3} span={8}>
                                <Form.Item label={geti18nText('deviceDetails.widget8.light.title')} name="created">
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <Button loading={progressBar} icon={<ReloadOutlined />} onClick={(e) => queryparam()} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item label={'biasU'} name="biasU">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col offset={3} span={8}>
                                <Form.Item label={'capacitorFDPFC'} name="capacitorFDPFC">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item label={'capacitorFP'} name="capacitorFP">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col offset={3} span={8}>
                                <Form.Item label={'capacitorFUPCF'} name="capacitorFUPCF">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item label={'drainVoltageD'} name="drainVoltageD">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col offset={3} span={8}>
                                <Form.Item label={'drainVoltageU'} name="drainVoltageU">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item label={'hyperthermia'} name="hyperthermia">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col offset={3} span={8}>
                                <Form.Item label={'lampFaultP'} name="lampFaultP">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item label={'lampFaultPFC'} name="lampFaultPFC">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col offset={3} span={8}>
                                <Form.Item label={'lightDownBrightness'} name="lightDownBrightness">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item label={'lightUpBrightness'} name="lightUpBrightness">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col offset={3} span={8}>
                                <Form.Item label={'overcurrent'} name="overcurrent">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item label={'overvoltage'} name="overvoltage">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col offset={3} span={8}>
                                <Form.Item label={'powerFaultI'} name="powerFaultI">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item label={'powerFaultP'} name="powerFaultP">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col offset={3} span={8}>
                                <Form.Item label={'turnOffLux'} name="turnOffLux">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item label={'turnOnLux'} name="turnOnLux">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col offset={3} span={8}>
                                <Form.Item label={'undervoltage'} name="undervoltage">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24} style={{ textAlign: 'right' }}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        {geti18nText('deviceDetails.widget.light.settings.btn')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Fragment>
    );
};

export default LightParamSettings;
