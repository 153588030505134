import React, { useState } from 'react';
import { Row, Col, Form, Input, Divider } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { NyDataEdit } from '@nybble/nyreact';

const PayloadHistoryEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState('Payload');
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setEditHeader(dataForm.id);
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    return (
        <NyDataEdit
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.DEVICE.PAYLOADHISTORY}
            setValues={setValues}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            hideActivationButtons={true}
            hideSubmitButton={true}
            {...props}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Divider orientation="left">Device volume</Divider>
                    <Form.Item label={'volume'} name={['deviceVolume', 'volume']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'payloadType'} name={['deviceVolume', 'payloadType']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'status'} name={['deviceVolume', 'status']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'ts'} name={['deviceVolume', 'ts']}>
                        <Input />
                    </Form.Item>
                    <Divider />
                    <Form.Item label={'payload'} name="payload">
                        <Input />
                    </Form.Item>
                    <Divider orientation="left">Payload info</Divider>
                    <Form.Item label={'antenna'} name={['payloadInfo', 'antenna']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'rssi'} name={['payloadInfo', 'rssi']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'payloadLength'} name={['payloadInfo', 'payloadLength']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'channel'} name={['payloadInfo', 'channel']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'dataRateSpreadFactor'} name={['payloadInfo', 'dataRateSpreadFactor']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'mac'} name={['payloadInfo', 'mac']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'codeRate'} name={['payloadInfo', 'codeRate']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'dataRateModulation'} name={['payloadInfo', 'dataRateModulation']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'frequency'} name={['payloadInfo', 'frequency']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'size'} name={['payloadInfo', 'size']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'port'} name={['payloadInfo', 'port']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'rfChain'} name={['payloadInfo', 'rfChain']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'dataRateBandwidth'} name={['payloadInfo', 'dataRateBandwidth']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'loraSnr'} name={['payloadInfo', 'loraSnr']}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={'crcStatus'} name={['payloadInfo', 'crcStatus']}>
                        <Input />
                    </Form.Item>
                    <Divider />
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default PayloadHistoryEdit;
