import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import { hideGenerateTasks } from '../../slices/dashboardSlice';
import GenerateTasks from '../device/GenerateTasks';
import { geti18nText } from '@nybble/nyreact';

const GenerateTasksForDevices: React.FC = () => {
    const dispatch = useDispatch();
    const { visibleGenerateTasks } = useSelector((state: RootState) => state.dashboard);
    const hideModal = () => {
        dispatch(hideGenerateTasks());
    };

    return (
        <React.Fragment>
            <Modal
                zIndex={1009}
                visible={visibleGenerateTasks}
                title={geti18nText('generate.tasks.title')}
                footer={null}
                width={1000}
                onCancel={hideModal}
                destroyOnClose={true}
            >
                <GenerateTasks groupId={0} />
            </Modal>
        </React.Fragment>
    );
};

export default GenerateTasksForDevices;
