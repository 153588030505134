import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, Form, Modal, Button, Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { geti18nText, NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { refreshImportExportList } from '../../slices/importExportSlice';
const { Dragger } = Upload;

const ImportModal = (props: any) => {

    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [uploading, setUploading] = useState<any>(false);
    const [customersFile, setCustomersFile] = useState<any>(null);
    const [devicesFile, setDevicesFile] = useState<any>(null);
    const [consumptionsFile, setConsumptionsFile] = useState<any>(null);
    
    const handleUpload = () => {
        const formData = new FormData();
        formData.append("customersFile", customersFile);
        formData.append("devicesFile", devicesFile);
        formData.append("consumptionsFile", consumptionsFile);
        
        setUploading(true);
        NyRequestResolver.requestPostFile(CONSTANTS_REQ.IMPORT_EXPORT.IMPORT_XML, undefined, formData, false).then(
            (result: any) => {
                if (result && result.status === RESPONSE.OK) {
                    setUploading(false);
                    setCustomersFile(null);
                    setDevicesFile(null);
                    setConsumptionsFile(null);
                    props.closeModal();
                    dispatch(refreshImportExportList());
                    message.destroy();
                    message.success(geti18nText('importExport.import.text.done'));
                } else {
                    setUploading(false);
                    message.destroy();
                    message.error(geti18nText('importExport.import.text.error'));
                }
            }
        );
      };

    const uploadCustomersFile = {
        name: 'file_customers',
        accept: '.xml',
        showUploadList: true,
        multiple: false,
        headers: {
            Authorization: 'Bearer ' + NySession.getUserToken(),
        },
        onRemove: () => {
            setCustomersFile(null);
          },
        beforeUpload: (file: any) => {
            setCustomersFile(file)
            return false;
        },
        customersFile,
    };

    const uploadDevicesFile = {
        name: 'file_devices',
        accept: '.xml',
        showUploadList: true,
        multiple: false,
        headers: {
            Authorization: 'Bearer ' + NySession.getUserToken(),
        },
        onRemove: () => {
            setDevicesFile(null);
          },
        beforeUpload: (file: any) => {
            setDevicesFile(file)
            return false;
        },
        devicesFile,
    };

    const uploadConsumptionsFile = {
        name: 'file_consumptions',
        accept: '.xml',
        showUploadList: true,
        multiple: false,
        headers: {
            Authorization: 'Bearer ' + NySession.getUserToken(),
        },
        onRemove: () => {
            setConsumptionsFile(null);
          },
        beforeUpload: (file: any) => {
            setConsumptionsFile(file)
            return false;
        },
        consumptionsFile,
    };


    return (
        <Modal
        title={geti18nText('importExport.table.column.import')}
        visible={props.visible}
        onCancel={props.closeModal}
        destroyOnClose={true}
        footer={null}
    >
        <Form 
            form={form} 
            labelCol={{ span: 7 }}
            layout="vertical"
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item label={geti18nText('importExport.import.xml.consumers')} name="appLogo">
                        <Dragger {...uploadCustomersFile} >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-hint">{geti18nText('importExport.import.text')}</p>
                        </Dragger>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={geti18nText('importExport.import.xml.devices')} name="loginLogo">
                        <Dragger {...uploadDevicesFile} >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-hint">{geti18nText('importExport.import.text')}</p>
                        </Dragger>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label={geti18nText('importExport.import.xml.consumptions')} name="loginLogo">
                        <Dragger {...uploadConsumptionsFile} >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-hint">{geti18nText('importExport.import.text')}</p>
                        </Dragger>
                    </Form.Item>
                </Col>
                <Col span={24} style={{ textAlign: 'right' }}>
                    <Form.Item>
                        <Button 
                            style={{ marginRight: '1em' }} 
                            type="primary" 
                            onClick={handleUpload}
                            disabled={customersFile === null && devicesFile === null && consumptionsFile === null}
                            loading={uploading}
                        >
                            {geti18nText('importExport.import.run')}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    </Modal>
    );
};

export default ImportModal;
