import React, { useEffect, useState } from 'react';
import { Typography, Button, Modal } from 'antd';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Legend, Tooltip, Bar, Label } from 'recharts';
import { FullscreenOutlined, UnorderedListOutlined } from '@ant-design/icons';
import ChartDataVolumes from './ChartDataVolumes';
import { geti18nText, NySpinner } from '@nybble/nyreact';
import { DeviceCategoryType, DeviceEnergySettingsByPhase } from '../../utils/Constants';
import ChartDataConsumption from '../device/lights/ChartDataConsumption';
import { formatNumberValueString } from '../number-formatter';
import ChartEnergyDataConsumption from '../dashboard/energy/ChartEnergyDataConsumption';

const { Title } = Typography;

interface IChart {
    loading: boolean | null;
    data: any;
    title: string | null;
    deviceId?: number;
    period?: string;
    category?: string;
    formatNumber?: boolean;
    showYLabel?: boolean;
    phases?: 'L1' | 'L2' | 'L3' | 'ALL';
    nameL1?: any;
    nameL2?: any;
    nameL3?: any;
}

const Chart: React.FC<IChart> = ({
    loading,
    data,
    title,
    deviceId,
    period,
    category = DeviceCategoryType.WATERMETER,
    formatNumber = false,
    showYLabel = true,
    phases = 'ALL',
    nameL1 = 'L1',
    nameL2 = 'L2',
    nameL3 = 'L3',
}) => {
    const watterMeterSettings: any = {
        title: geti18nText('deviceDetails.graph.title'),
        'y-title': geti18nText('deviceDetails.graph.y.value'),
        color: '#1890ff',
    };

    const lightSettings: any = {
        title: geti18nText('deviceDetails.graph.light.title'),
        'y-title': geti18nText('deviceDetails.graph.light.y.value'),
        color: '#FFD700',
    };

    const [settings, setSettings] = useState<any>(getSettings());

    const [visible, setVisible] = useState(false);
    const [visibleDataVolumes, setVisibleDataVolumes] = useState(false);

    function getSettings() {
        return category == DeviceCategoryType.LIGHTS
            ? lightSettings
            : category == DeviceCategoryType.ENERGYMETER
            ? DeviceEnergySettingsByPhase(nameL1, nameL2, nameL3)
            : watterMeterSettings;
    }

    useEffect(() => {
        setSettings(getSettings());
    }, [nameL1, nameL2, nameL3]);

    function tickFormaterYAxis(value: any) {
        if (formatNumber == true) {
            let formatValue = formatNumberValueString(value, true);
            return formatValue;
        }
        return value;
    }

    const renderTooltip = (value: any, name: any, props: any) => {
        if (formatNumber == true) {
            let formValue = formatNumberValueString(value, true);
            return [formValue, name];
        }
        return [value, name];
    };

    return (
        <div className="box" style={{ height: '95%' }}>
            <Title style={{ fontSize: '1.4em', width: '75%' }}>{settings['title'] + ' - ' + title}</Title>
            {deviceId && (
                <Button
                    style={{ position: 'absolute', right: '50px', top: '20px' }}
                    shape="circle"
                    onClick={() => setVisibleDataVolumes(true)}
                    icon={<UnorderedListOutlined />}
                />
            )}
            <Button
                style={{ position: 'absolute', right: '20px', top: '20px' }}
                shape="circle"
                onClick={() => setVisible(true)}
                icon={<FullscreenOutlined />}
            />
            <ResponsiveContainer width="100%" height={250}>
                {loading ? (
                    <NySpinner />
                ) : (
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="converted" />
                        <YAxis tickFormatter={tickFormaterYAxis}>
                            {showYLabel == true && (
                                <Label
                                    value={settings['y-title']}
                                    position="insideLeft"
                                    angle={-90}
                                    style={{ textAnchor: 'middle' }}
                                />
                            )}
                        </YAxis>
                        <Tooltip formatter={renderTooltip} />
                        <Legend />
                        {(category == DeviceCategoryType.WATERMETER || category == DeviceCategoryType.LIGHTS) && (
                            <Bar dataKey="barValue" name={settings['y-title']} fill={settings['color']} />
                        )}
                        {category == DeviceCategoryType.ENERGYMETER && (phases === 'L1' || phases === 'ALL') && (
                            <Bar dataKey="barValue" name={settings['y-title']} fill={settings['color']} />
                        )}
                        {category == DeviceCategoryType.ENERGYMETER && (phases === 'L2' || phases === 'ALL') && (
                            <Bar dataKey="barValue1" name={settings['y-title1']} fill={settings['color1']} />
                        )}
                        {category == DeviceCategoryType.ENERGYMETER && (phases === 'L3' || phases === 'ALL') && (
                            <Bar dataKey="barValue2" name={settings['y-title2']} fill={settings['color2']} />
                        )}
                    </BarChart>
                )}
            </ResponsiveContainer>

            <Modal
                title={settings['title'] + ' - ' + title}
                visible={visible}
                zIndex={1010}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={'80%'}
                centered={true}
            >
                <ResponsiveContainer width="100%" height={600}>
                    {loading ? (
                        <NySpinner />
                    ) : (
                        <BarChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" vertical={false} />
                            <XAxis dataKey="converted" />
                            <YAxis tickFormatter={tickFormaterYAxis}>
                                {showYLabel == true && (
                                    <Label
                                        value={settings['y-title']}
                                        position="insideLeft"
                                        angle={-90}
                                        style={{ textAnchor: 'middle' }}
                                    />
                                )}
                            </YAxis>
                            <Tooltip formatter={renderTooltip} />
                            <Legend />
                            {(category == DeviceCategoryType.WATERMETER || category == DeviceCategoryType.LIGHTS) && (
                                <Bar dataKey="barValue" name={settings['y-title']} fill={settings['color']} />
                            )}
                            {category == DeviceCategoryType.ENERGYMETER && (phases === 'L1' || phases === 'ALL') && (
                                <Bar dataKey="barValue" name={settings['y-title']} fill={settings['color']} />
                            )}
                            {category == DeviceCategoryType.ENERGYMETER && (phases === 'L2' || phases === 'ALL') && (
                                <Bar dataKey="barValue1" name={settings['y-title1']} fill={settings['color1']} />
                            )}
                            {category == DeviceCategoryType.ENERGYMETER && (phases === 'L3' || phases === 'ALL') && (
                                <Bar dataKey="barValue2" name={settings['y-title2']} fill={settings['color2']} />
                            )}
                        </BarChart>
                    )}
                </ResponsiveContainer>
            </Modal>
            {deviceId && (
                <Modal
                    visible={visibleDataVolumes}
                    zIndex={1010}
                    onCancel={() => setVisibleDataVolumes(false)}
                    width={'80%'}
                    centered={true}
                    footer={null}
                >
                    {category == DeviceCategoryType.LIGHTS ? (
                        <ChartDataConsumption title={title} deviceId={deviceId} period={{ period: period }} />
                    ) : category == DeviceCategoryType.ENERGYMETER ? (
                        <ChartEnergyDataConsumption
                            title={title}
                            deviceId={deviceId}
                            period={{ period: period }}
                            phaseValue={phases}
                        ></ChartEnergyDataConsumption>
                    ) : (
                        <ChartDataVolumes title={title} deviceId={deviceId} period={{ period: period }} />
                    )}
                </Modal>
            )}
        </div>
    );
};

export default Chart;
