import React, { useState } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { Row, Col, Form, Input, Select, InputNumber } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { geti18nText, NyDataEdit, NySearchField } from '@nybble/nyreact';
import { refreshMenu } from '../../slices/dashboardSlice';
import { useDispatch } from 'react-redux';
import { parametarKeyName } from '../../utils/Utils';
import NyImageUpload from '../../utils/NyImageUpload';
const CategoryEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('category.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();
    const [uploadKey, setUploadKey] = useState<any>(Date.now());
    const [file, setFile] = useState<any>(null);

    function setValues(dataForm: any) {
        setDataForm(dataForm.id);
        if (dataForm.parent) {
            dataForm.parent = { key: dataForm.parent.id, label: dataForm.parent.name };
        }
        if (dataForm['parameters']) {
            dataForm['parameters'] = dataForm['parameters'].map((u: any) => {
                return { key: u.id, label: u[parametarKeyName('name')] };
            });
        }
        if (dataForm.icon != null) {
            dataForm.icon = 'data:image/jpeg;base64,' + dataForm.icon;
            setFile(dataForm.icon);
        }
        form.setFieldsValue(dataForm);
        setEditHeader(geti18nText('category.edit.category') + ': ' + dataForm.name);
    }

    const onModalClose = () => {
        form.resetFields();
        setDataForm(null);
        setEditHeader(geti18nText('category.edit.new'));
        setTimeout(() => {
            dispatch(refreshMenu());
        }, 100);
        setFile(undefined);
    };

    return (
        <NyDataEdit
            layout="horizontal"
            formProps={{ labelCol: { span: 5 }, wrapperCol: { span: 14 } }}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={CONSTANTS_REQ.CATEGORY.EDIT}
            setValues={setValues}
            width={1200}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            {...props}
            normalize={(values: any) => {
                if (values.parent != undefined) {
                    values.parent = {
                        id: values.parent.constructor === Number ? values.parent : values.parent.id,
                    };
                }

                if (values.parameters != undefined) {
                    if (Array.isArray(values.parameters)) {
                        values.parameters = values.parameters.map((parameter: any) => {
                            if (parameter.id !== undefined) return parameter.id;
                            return { id: Number(parameter) };
                        });
                    } else {
                        values.parameters = [{ id: Number(values.parameters) }];
                    }
                }
                if (file) {
                    values.file = file;
                }
                delete values.icon;
                console.log('values ', values);
                return values;
            }}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item name="id" style={{ display: 'none' }}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={geti18nText('category.edit.name')} name="name">
                        <Input />
                    </Form.Item>
                    <Form.Item label={geti18nText('category.edit.parent')} name="parent">
                        <NySearchField
                            addedFilter={
                                dataForm != undefined
                                    ? {
                                          field: 'id',
                                          condition: 'not_equals',
                                          value: dataForm,
                                      }
                                    : undefined
                            }
                            url={CONSTANTS_REQ.CATEGORY.SEARCH_PARENT_CATEGORIES}
                            map={{ id: 'id', label: 'name' }}
                            searchBy="name"
                        />
                    </Form.Item>
                    <Form.Item label={geti18nText('category.edit.parameters')} name="parameters">
                        <NySearchField
                            mode="multiple"
                            url={CONSTANTS_REQ.WEATHER_PARAMETER.SEARCH}
                            map={{ id: 'id', label: parametarKeyName('name') }}
                            searchBy={parametarKeyName('name')}
                            onChange={onchange}
                        />
                    </Form.Item>
                    <Form.Item label={geti18nText('category.edit.ord')} name="ord">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item label={geti18nText('category.edit.icon')} name="icon">
                        <NyImageUpload
                            key={uploadKey}
                            files={file}
                            setFiles={setFile}
                            multiple={false}
                            value={file}
                            accept={'image/*'}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default CategoryEdit;
