import {
    ApiFilled,
    AreaChartOutlined,
    BellOutlined,
    RocketOutlined,
    SignalFilled,
    ThunderboltOutlined,
} from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NySpinner, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Card, Row, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IWidget } from './../index';
import { getUserCategoryType } from '../../../utils/Enums';
import { RootState } from '../../../rootReducer';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import {
    filterActiveLights,
    filterActiveLightsReset,
    filterAlarmLights,
    filterAlarmLightsReset,
} from '../../../slices/dashboardSlice';
import { formatNumberValueString } from '../../number-formatter';

const WidgetCardsEnergy: React.FC<IWidget> = ({ showEnergyOn, dataGrid }) => {
    const [statsEnergy, setStatsEnergy] = useState<{ [index: string]: any }>({});
    const [loading, setLoading] = useState(true);
    const { filterActive, filterInactive, filterLowBattery, mapBounds, mapSelectedBounds } = useSelector(
        (state: RootState) => state.dashboard
    );
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true);
        if (getUserCategoryType().includes('ENERGYMETER')) {
            fetchStatEnergymeters();
        }
    }, [mapSelectedBounds]);

    const fetchStatEnergymeters = () => {
        NyRequestResolver.requestGet(
            CONSTANTS_REQ.DASHBOARD.STATOSTIC_ENERGY,
            mapSelectedBounds != null
                ? {
                      latNorthEast: mapSelectedBounds.latNorthEast,
                      lngNorthEast: mapSelectedBounds.lngNorthEast,
                      latSouthWest: mapSelectedBounds.latSouthWest,
                      lngSouthWest: mapSelectedBounds.lngSouthWest,
                  }
                : {}
        ).then((result) => {
            if (result && result.status == RESPONSE.OK) {
                if (Array.isArray(result.data)) {
                    setLoading(false);
                    setStatsEnergy(result.data[0]);
                }
            } else {
                setLoading(false);
            }
        });
    };

    const gotoLightDetails = () => {
        history.push('/light-details');
    };

    const filterActiveLightOnClick = (e: any) => {
        if (e === true) {
            dispatch(filterActiveLights());
        } else {
            dispatch(filterActiveLightsReset());
        }
    };
    const filterLightWithAlarmOnClick = (e: any) => {
        if (e === true) {
            dispatch(filterAlarmLights());
        } else {
            dispatch(filterAlarmLightsReset());
        }
    };

    function getHeight() {
        console.log(dataGrid);
        if (typeof dataGrid.h == 'number') {
            return dataGrid.h * 10;
        }
    }
    function getWidth() {
        console.log(dataGrid);

        // if (typeof dataGrid.w == 'number') {
        //     if (dataGrid.w * 133 > 300 && dataGrid.w * 133 < 960) return { width: (dataGrid.w * 133 - 20) / 3 };
        //     /* else if(dataGrid.w * 133 >  960) {
        //         return {width: (dataGrid.w * 133 - 40) / 6};
        //     }
        //     else return undefined; */
        // }

        return undefined;
    }

    return (
        <React.Fragment>
            {getUserCategoryType() &&
                getUserCategoryType().length > 0 &&
                getUserCategoryType().includes('ENERGYMETER') &&
                showEnergyOn &&
                statsEnergy && (
                    <div className="widget-cards-card-container-energy">
                        <Card className="widget-cards-card-energy" style={getWidth()}>
                            <div className="widget-cards-text-bottom">
                                <div className="widget-cards-number-energy">
                                    {loading && <NySpinner />}
                                    {statsEnergy.totalDevice || statsEnergy.totalDevice === 0
                                        ? NyUtils.formatNumber(statsEnergy.totalDevice)
                                        : '0'}
                                </div>
                                <div className="widget-cards-title-energy">
                                    <span>{geti18nText('dashboard.widget.card.active.energymeters')}</span>
                                </div>
                            </div>
                            <div className="widget-cards-background-icon-lights">
                                {/* <Switch size="small" onChange={(e: any) => filterActiveLightOnClick(e)} /> */}
                            </div>
                            <div className="widget-cards-background-icon-box">
                                <ApiFilled className="widget-cards-background-icon-box-lights" />
                            </div>
                        </Card>
                        <Card className="widget-cards-card-energy" style={getWidth()}>
                            <div className="widget-cards-text-bottom">
                                <div className="widget-cards-number-energy">
                                    {loading && <NySpinner />}
                                    {statsEnergy.totalEnergy || statsEnergy.totalEnergy === 0
                                        ? formatNumberValueString(statsEnergy.totalEnergy, true)
                                        : '0 Wh'}
                                </div>
                                <div className="widget-cards-title-energy">
                                    <span>
                                        {geti18nText(
                                            'dashboard.widget.card.active.energy.current.consumption.last.24hour'
                                        )}
                                    </span>
                                </div>
                            </div>

                            <div className="widget-cards-background-icon-box">
                                <AreaChartOutlined className="widget-cards-background-icon-box-lights" />
                            </div>
                        </Card>
                    </div>
                )}
        </React.Fragment>
    );
};

export default WidgetCardsEnergy;
