import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Popconfirm, Row, Tag, Tooltip } from 'antd';
import { FormOutlined } from '@ant-design/icons';

import {
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { alarmDescriptionOpen, refreshDeviceAlarmList } from '../../../slices/alarmDescriptionSlice';
import { CONSTANTS_REQ, DeviceCategoryType } from '../../../utils/Constants';
import { RootState } from '../../../rootReducer';
import { GetEnumNameForValue } from '../../../utils/Enums';
import { formatNumberValueString } from '../../number-formatter';

import { getUnreadAlarmFilterType } from '../../../utils/Utils';
import { refreshMenu } from '../../../slices/dashboardSlice';

const AlarmsLightTable = ({ previewType = 'DASHBOARD' }: any) => {
    const { record } = useSelector((state: RootState) => state.deviceLightDetails);
    const { deviceAlarmListRefresh, alarmCount } = useSelector((state: RootState) => state.alarmDescriptionSlice);
    const [showAlarmBtn, setShowAlarmBtn] = useState<boolean>(false);
    const dispatch = useDispatch();

    const setDefaultFilterValue = () => {
        if (record?.id != undefined && previewType == 'BY_DEVICE') {
            return [
                // { field: 'unread', condition: 'equals_bool', value: 1 },
                { field: 'deviceId', condition: 'equals', value: record.id },
            ];
        }
        return [{ field: 'deviceId', condition: 'equals', value: record.id }];
    };

    const editDescription = (deviceId: string, data?: { [index: string]: any }) => {
        dispatch(
            alarmDescriptionOpen({
                deviceId: deviceId,
                data: data,
                visible: true,
                deviceAlarmListRefresh: 0,
                deviceType: DeviceCategoryType.LIGHTS,
            })
        );
    };

    const alarmEnumName: any = (status: number, type: number) => {
        if (type == 1) {
            return geti18nText('app.enum.TERMINAL_NODE_ALARM.' + GetEnumNameForValue('TERMINAL_NODE_ALARM', status));
        } else {
            return geti18nText('app.enum.LAMP_ALARM.' + GetEnumNameForValue('LAMP_ALARM', status));
        }
    };

    const csvCustomizationColumns: any = () => {
        return [
            {
                'deviceLightConsumption.ts': (value: any) => {
                    if (value) {
                        const ts: Date = new Date(value);
                        return ts.toLocaleString(NyUtils.getSelectedLocale());
                    }
                    return '';
                },
            },
            {
                'deviceLightConsumption.electric_energy': (value: any) => {
                    return value ? formatNumberValueString(value, true) : '';
                },
            },
            {
                alarmType: (value: any) => {
                    if (value) {
                        return geti18nText(
                            'app.enum.LIGHTS_ALARM_TYPE.' + GetEnumNameForValue('LIGHTS_ALARM_TYPE', value)
                        );
                    }
                    return '';
                },
            },
            {
                alarm: (value: any) => {
                    if (value) {
                        return alarmEnumName(value.alarmStatus, value.alarmType);
                    }
                    return '';
                },
            },
        ];
    };

    const onUnreadClick = (record: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.ALARM.LIGHT_READ + '/' + record.id, undefined, {
            id: record.id,
            category: record.deviceCategory,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                dispatch(refreshDeviceAlarmList());
            }
        });
    };

    const onUnreadAllClick = () => {
        let item: any = {};
        if (record?.id != undefined && previewType == 'BY_DEVICE') {
            item = { deviceId: record.id };
        }
        item['category'] = DeviceCategoryType.LIGHTS;
        NyRequestResolver.requestPost(CONSTANTS_REQ.ALARM.LIGHT_READ_ALL, undefined, item).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                dispatch(refreshDeviceAlarmList());
                dispatch(refreshMenu());
            }
        });
    };

    function getCsvColumns() {
        return [
            {
                title: geti18nText('dashboard.widget.alarm.table.name'),
                dataIndex: 'device.name',
            },
            {
                title: geti18nText('dashboard.widget.alarm.table.serial'),
                dataIndex: 'device.eui',
            },
            {
                title: geti18nText('dashboard.widget.alarm.table.waterusage'),
                dataIndex: 'deviceLightConsumption.electric_energy',
            },
            {
                title: geti18nText('dashboard.widget.alarm.table.type'),
                dataIndex: 'alarm',
            },
            {
                title: geti18nText('dashboard.widget.alarm.table.source'),
                dataIndex: 'alarmType',
            },
            {
                title: geti18nText('dashboard.widget.alarm.table.lastactivity'),
                dataIndex: 'deviceLightConsumption.ts',
            },
        ];
    }
    const onLoadData = (data: { [index: string]: any } | string, params: { [index: string]: any } | undefined) => {
        setShowAlarmBtn(data != undefined && data.length > 0 ? true : false);
        if (data != undefined && data.length > 0) {
            const items: any = data;
            const firstItem = items[0];
            if (firstItem.unread == false) {
                setShowAlarmBtn(false);
            }
        }
    };

    return (
        <>
            {showAlarmBtn == true && (
                <Row gutter={24} style={{ float: 'right' }}>
                    <Popconfirm
                        title={geti18nText('dashboard.widget.alarm.table.unread_all.btn.msg')}
                        onConfirm={() => {
                            onUnreadAllClick();
                        }}
                    >
                        <Button className="margin-right" danger style={{ right: '25px', top: '5px' }}>
                            {geti18nText('dashboard.widget.alarm.table.unread_all.btn')}
                        </Button>
                    </Popconfirm>
                </Row>
            )}

            <NyDataTable
                headerTitle={geti18nText('dashboard.widget.alarm.table.title')}
                url={CONSTANTS_REQ.DASHBOARD.LIGHTS_ALARMS}
                hideButtons={true}
                exportCSV={true}
                scroll={{ x: 800 }}
                setDefaultFilterValue={setDefaultFilterValue}
                fetchWhenChange={deviceAlarmListRefresh}
                readonly={true}
                colCSVCustomization={csvCustomizationColumns()}
                csvColumns={getCsvColumns()}
                onDataLoaded={onLoadData}
                setDefaultSortOrder={{ order: 'unread', orderType: 'desc' }}
                columns={[
                    {
                        title: geti18nText('dashboard.widget.alarm.table.name'),
                        dataIndex: ['device', 'name'],
                        sorter: (a: string, b: string) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('dashboard.widget.alarm.table.serial'),
                        dataIndex: ['device', 'eui'],
                        sorter: (a: string, b: string) => {},
                        ...getColumnSearch('string'),
                    },

                    {
                        title: geti18nText('dashboard.widget.alarm.table.waterusage'),
                        dataIndex: ['deviceLightConsumption', 'electric_energy'],
                        align: 'right',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceLightConsumption?.electric_energy
                                        ? NyUtils.formatNumber(record.deviceLightConsumption?.electric_energy, 2)
                                        : '0,0'}
                                    &nbsp;Wh
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('dashboard.widget.alarm.table.type'),
                        dataIndex: 'alarm',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    <b style={{ color: 'red' }}>
                                        {alarmEnumName(record.alarmStatus, record.alarmType)}
                                    </b>
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('dashboard.widget.alarm.table.source'),
                        dataIndex: 'alarmType',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {geti18nText(
                                        'app.enum.LIGHTS_ALARM_TYPE.' +
                                            GetEnumNameForValue('LIGHTS_ALARM_TYPE', record.alarmType)
                                    )}
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('dashboard.widget.alarm.table.lastactivity'),
                        dataIndex: ['deviceLightConsumption', 'ts'],
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.deviceLightConsumption?.ts) {
                                const ts: Date = new Date(record.deviceLightConsumption.ts);
                                return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                            }
                        },
                    },
                    {
                        dataIndex: 'operation',
                        width: '10%',
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <React.Fragment>
                                    <Tooltip title={geti18nText('dashboard.widget.alarm.table.description.tooltip')}>
                                        {record.description ? (
                                            <FormOutlined
                                                style={{
                                                    fontSize: '18px',
                                                    marginRight: '10px',
                                                    color: '#40a9ff',
                                                }}
                                                onClick={() =>
                                                    editDescription(record.alarm_id, {
                                                        description: record.description,
                                                    })
                                                }
                                            />
                                        ) : (
                                            <FormOutlined
                                                style={{
                                                    fontSize: '18px',
                                                    marginRight: '10px',
                                                }}
                                                onClick={() =>
                                                    editDescription(record.alarm_id, {
                                                        description: record.description,
                                                    })
                                                }
                                            />
                                        )}
                                    </Tooltip>
                                </React.Fragment>
                            );
                        },
                    },
                    {
                        dataIndex: 'unread',
                        sorter: (a: string, b: string) => {},
                        width: '5%',
                        ...getColumnSearchOption(
                            getUnreadAlarmFilterType(),
                            'equals_bool',
                            setDefaultFilterValue()[0]['value']
                        ),
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.unread == true) {
                                return (
                                    <Popconfirm
                                        title={geti18nText('dashboard.widget.alarm.table.unread.btn.msg')}
                                        onConfirm={() => {
                                            onUnreadClick(record);
                                        }}
                                    >
                                        <Tag color="red">{geti18nText('dashboard.widget.alarm.table.unread.btn')}</Tag>
                                    </Popconfirm>
                                );
                            }
                        },
                    },
                ]}
            />
        </>
    );
};

export default AlarmsLightTable;
