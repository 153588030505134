import React, { useEffect } from 'react';
import { geti18nText } from '@nybble/nyreact';
import { Divider, Row, Col } from 'antd';
import ImportExportList from './ImportExportList';
import { useDispatch } from 'react-redux';
import { importExportSet } from '../../slices/importExportSlice';

const ImportExport = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(importExportSet({ refresh: 0 }));
    }, []);

    return (
        <>
            <Divider orientation="left" style={{ fontSize: '18px' }} plain>
                {geti18nText('importExport.title')}
            </Divider>
            <Row gutter={24}>
                <Col>
                    <ImportExportList />
                </Col>
            </Row>
        </>
    );
};

export default ImportExport;
