import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Card, Statistic, Form } from 'antd';
import { LineChartOutlined, ThunderboltOutlined, WarningOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { geti18nText, NyRequestResolver, NySearchField, NyUtils, RESPONSE } from '@nybble/nyreact';
import { RootState } from '../../../rootReducer';
import { CONSTANTS_REQ, DeviceCategoryType, DeviceEnergySettings } from '../../../utils/Constants';
import Chart from '../../device-details/Chart';
import { formatNumberValueString } from '../../number-formatter';
import CharEnergySpan from './CharEnergySpan';
import ZoomEnergyChart from './ZoomEnergyChart';

const { Title } = Typography;

const DeviceEnergyStatisticDetail = () => {
    const { record } = useSelector((state: RootState) => state.deviceEnergyDetails);

    const [last24hData, setLast24hData] = useState<any>(null);
    const [currentWeekData, setCurrentWeekData] = useState<any>(null);
    const [lastWeekData, setLastWeekData] = useState<any>(null);
    const [currentMonthData, setCurrentMonthData] = useState<any>(null);
    const [currentYearData, setCurrentYearData] = useState<any>(null);
    const [snapshotData, setSnapshotData] = useState<any>(null);
    const [widgetData, setWidgetData] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [parName, setParName] = useState<any>();
    const [phaseNameL1, setPhaseNameL1] = useState<any>('L1');
    const [phaseNameL2, setPhaseNameL2] = useState<any>('L2');
    const [phaseNameL3, setPhaseNameL3] = useState<any>('L3');
    const phases = [
        { id: 'L1', text: geti18nText('energyDetails.widget.select.phase', [phaseNameL1]) },
        { id: 'L2', text: geti18nText('energyDetails.widget.select.phase', [phaseNameL2]) },
        { id: 'L3', text: geti18nText('energyDetails.widget.select.phase', [phaseNameL3]) },
        { id: 'ALL', text: geti18nText('energyDetails.widget.select.phase.all') },
    ];

    const [phaseValue, setPhaseValue] = useState<any>('ALL');
    const [phaseSearchValue, setPhaseSearchValue] = useState<any>(phases[3]);

    useEffect(() => {
        if (parName == null) {
            fetchLast24hValue(record.id);
            fetchCurrentWeekData(record.id);
            fetchLastWeekData(record.id);
            fetchCurrentMonthData(record.id);
            fetchCurrentYearData(record.id);
        }

        fetchCurrentValue(record.id);
        fetchWidgetValue(record.id);
        if (record.device?.phaseDescription != null) {
            const phaseDescription = JSON.parse(record.device?.phaseDescription);
            if (phaseDescription.L1 != null) setPhaseNameL1(phaseDescription.L1);
            if (phaseDescription.L2 != null) setPhaseNameL2(phaseDescription.L2);
            if (phaseDescription.L3 != null) setPhaseNameL3(phaseDescription.L3);
        }
    }, []);

    const fetchLast24hValue = (value: any) => {
        setLast24hData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_ENERGY_CONSUMPTION, {
            period: 'last-24h',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: value,
            parName: parName,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setLast24hData(response.data);
            }
        });
    };

    const fetchCurrentWeekData = (value: any) => {
        setCurrentWeekData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_ENERGY_CONSUMPTION, {
            period: 'current-week',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: value,
            parName: parName,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setCurrentWeekData(response.data);
            }
        });
    };

    const fetchLastWeekData = (value: any) => {
        setLastWeekData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_ENERGY_CONSUMPTION, {
            period: 'last-week',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: value,
            parName: parName,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setLastWeekData(response.data);
            }
        });
    };

    const fetchCurrentMonthData = (value: any) => {
        setCurrentMonthData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_ENERGY_CONSUMPTION, {
            period: 'current-month',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: value,
            parName: parName,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setCurrentMonthData(response.data);
            }
        });
    };

    const fetchCurrentYearData = (value: any) => {
        setCurrentYearData(null);
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_ENERGY_CONSUMPTION, {
            period: 'current-year',
            lang: NyUtils.getSelectedLanguage(),
            deviceId: value,
            parName: parName,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setCurrentYearData(response.data.filter((x: any) => x.barValue != null));
            }
        });
    };

    const fetchCurrentValue = (value: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CURRENT_ENERGY_CONSUMPTION_FOR, { deviceId: value }).then(
            (response: any) => {
                if (response.status === RESPONSE.OK) {
                    setSnapshotData(response.data);
                }
            }
        );
    };

    const fetchWidgetValue = (value: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CONSUMPTION_FOR_TODAY_AND_CURRENT_WEEK_FOR_DEVICE, {
            deviceId: value,
            type: DeviceCategoryType.ENERGYMETER,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                setWidgetData(response.data);
            }
            setLoading(false);
        });
    };

    function phaseOnChange(val: any) {
        setPhaseSearchValue(val);
        setPhaseValue(val?.id != undefined ? val.id : 'ALL');
    }

    return (
        <React.Fragment>
            <Row>
                <Col span={12}>
                    <Form.Item label={geti18nText('energyDetails.widget.select.title')}>
                        <NySearchField
                            value={phaseSearchValue}
                            onChange={phaseOnChange}
                            searchBy={'name'}
                            style={{ width: '90%' }}
                            options={phases}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <div className="box" style={{ marginRight: '10px', height: '95%' }}>
                        <Card style={{ height: '90%', cursor: 'pointer' }} onClick={() => setParName(null)}>
                            <Statistic
                                title={geti18nText('energyDetails.widget.title.energy', [phaseNameL1])}
                                value={
                                    widgetData != null && widgetData['today-aa'] != null
                                        ? formatNumberValueString(widgetData['today-aa'], true)
                                        : '0,00'
                                }
                                precision={2}
                                valueStyle={{
                                    color: parName == null ? DeviceEnergySettings.defaultColor : '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<LineChartOutlined />}
                            />
                            <Statistic
                                title={geti18nText('energyDetails.widget.title.energy', [phaseNameL2])}
                                value={
                                    widgetData != null && widgetData['today-ab'] != null
                                        ? formatNumberValueString(widgetData['today-ab'], true)
                                        : '0,00'
                                }
                                precision={2}
                                valueStyle={{
                                    color: parName == null ? DeviceEnergySettings.defaultColor : '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<LineChartOutlined />}
                            />
                            <Statistic
                                title={geti18nText('energyDetails.widget.title.energy', [phaseNameL3])}
                                value={
                                    widgetData != null && widgetData['today-ac'] != null
                                        ? formatNumberValueString(widgetData['today-ac'], true)
                                        : '0,00'
                                }
                                precision={2}
                                valueStyle={{
                                    color: parName == null ? DeviceEnergySettings.defaultColor : '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<LineChartOutlined />}
                            />
                        </Card>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <div className="box" style={{ marginRight: '10px', height: '95%' }}>
                        <Card onClick={() => setParName('current')} style={{ cursor: 'pointer' }}>
                            <Statistic
                                title={geti18nText('energyDetails.widget.title.current', [phaseNameL1])}
                                value={
                                    snapshotData != null && snapshotData['current-1'] != undefined
                                        ? NyUtils.formatNumber(snapshotData['current-1'], 2)
                                        : '0,00'
                                }
                                precision={2}
                                valueStyle={{
                                    color: parName == 'current' ? DeviceEnergySettings.defaultColor : '#808080',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<ThunderboltOutlined />}
                                suffix="A"
                            />
                            <Statistic
                                title={geti18nText('energyDetails.widget.title.current', [phaseNameL2])}
                                value={
                                    snapshotData != null && snapshotData['current-2'] != undefined
                                        ? NyUtils.formatNumber(snapshotData['current-2'], 2)
                                        : '0,00'
                                }
                                precision={2}
                                valueStyle={{
                                    color: parName == 'current' ? DeviceEnergySettings.defaultColor : '#808080',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<ThunderboltOutlined />}
                                suffix="A"
                            />
                            <Statistic
                                title={geti18nText('energyDetails.widget.title.current', [phaseNameL3])}
                                value={
                                    snapshotData != null && snapshotData['current-3'] != undefined
                                        ? NyUtils.formatNumber(snapshotData['current-3'], 2)
                                        : '0,00'
                                }
                                precision={2}
                                valueStyle={{
                                    color: parName == 'current' ? DeviceEnergySettings.defaultColor : '#808080',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<ThunderboltOutlined />}
                                suffix="A"
                            />
                        </Card>
                        <Card onClick={() => setParName('voltage')} style={{ cursor: 'pointer' }}>
                            <Statistic
                                title={geti18nText('energyDetails.widget.title.voltage', [phaseNameL1])}
                                value={
                                    snapshotData != null && snapshotData['voltage-1']
                                        ? NyUtils.formatNumber(snapshotData['voltage-1'], 2)
                                        : '0,00'
                                }
                                precision={2}
                                valueStyle={{
                                    color:
                                        parName == 'voltage-1' && snapshotData['voltage-1'] == undefined
                                            ? DeviceEnergySettings.defaultColor
                                            : '#808080',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<WarningOutlined />}
                                suffix="V"
                            />
                            <Statistic
                                title={geti18nText('energyDetails.widget.title.voltage', [phaseNameL2])}
                                value={
                                    snapshotData != null && snapshotData['voltage-2'] != undefined
                                        ? NyUtils.formatNumber(snapshotData['voltage-2'], 2)
                                        : '0,00'
                                }
                                precision={2}
                                valueStyle={{
                                    color: parName == 'voltage' ? DeviceEnergySettings.defaultColor : '#808080',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<WarningOutlined />}
                                suffix="V"
                            />
                            <Statistic
                                title={geti18nText('energyDetails.widget.title.voltage', [phaseNameL3])}
                                value={
                                    snapshotData != null && snapshotData['voltage-3'] != undefined
                                        ? NyUtils.formatNumber(snapshotData['voltage-3'], 2)
                                        : '0,00'
                                }
                                precision={2}
                                valueStyle={{
                                    color: parName == 'voltage' ? DeviceEnergySettings.defaultColor : '#808080',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<WarningOutlined />}
                                suffix="V"
                            />
                        </Card>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12} xl={3}>
                    <div className="box" style={{ marginRight: '10px', height: '95%' }}>
                        <Card onClick={() => setParName('total_power')} style={{ cursor: 'pointer' }}>
                            <Statistic
                                title={geti18nText('energyDetails.widget.title.power', [phaseNameL1])}
                                value={
                                    snapshotData != null && snapshotData['power-1'] != undefined
                                        ? NyUtils.formatNumber(snapshotData['power-1'], 2)
                                        : '0,00'
                                }
                                precision={2}
                                valueStyle={{
                                    color: parName == 'total_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<LineChartOutlined />}
                                suffix="W"
                            />
                            <Statistic
                                title={geti18nText('energyDetails.widget.title.power', [phaseNameL2])}
                                value={
                                    snapshotData != null && snapshotData['power-2']
                                        ? NyUtils.formatNumber(snapshotData['power-2'], 2)
                                        : '0,00'
                                }
                                precision={2}
                                valueStyle={{
                                    color: parName == 'total_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<LineChartOutlined />}
                                suffix="W"
                            />
                            <Statistic
                                title={geti18nText('energyDetails.widget.title.power', [phaseNameL3])}
                                value={
                                    snapshotData != null && snapshotData['power-3']
                                        ? NyUtils.formatNumber(snapshotData['power-3'], 2)
                                        : '0,00'
                                }
                                precision={2}
                                valueStyle={{
                                    color: parName == 'total_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<LineChartOutlined />}
                                suffix="W"
                            />
                        </Card>
                        <Card onClick={() => setParName('active_power')} style={{ cursor: 'pointer' }}>
                            <Statistic
                                title={geti18nText('energyDetails.widget.title.activePower', [phaseNameL1])}
                                value={
                                    snapshotData != null && snapshotData['active-power-1'] != undefined
                                        ? NyUtils.formatNumber(snapshotData['active-power-1'], 2)
                                        : '0,00'
                                }
                                precision={2}
                                valueStyle={{
                                    color: parName == 'active_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<LineChartOutlined />}
                                suffix="W"
                            />
                            <Statistic
                                title={geti18nText('energyDetails.widget.title.activePower', [phaseNameL2])}
                                value={
                                    snapshotData != null && snapshotData['active-power-2']
                                        ? NyUtils.formatNumber(snapshotData['active-power-2'], 2)
                                        : '0,00'
                                }
                                precision={2}
                                valueStyle={{
                                    color: parName == 'active_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<LineChartOutlined />}
                                suffix="W"
                            />
                            <Statistic
                                title={geti18nText('energyDetails.widget.title.activePower', [phaseNameL3])}
                                value={
                                    snapshotData != null && snapshotData['active-power-3']
                                        ? NyUtils.formatNumber(snapshotData['active-power-3'], 2)
                                        : '0,00'
                                }
                                precision={2}
                                valueStyle={{
                                    color: parName == 'active_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                                prefix={<LineChartOutlined />}
                                suffix="W"
                            />
                        </Card>
                    </div>
                </Col>

                {parName == null && (
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                        <Chart
                            loading={loading}
                            data={last24hData}
                            title={geti18nText('report.time_range.last24h')}
                            deviceId={record.id}
                            category={DeviceCategoryType.ENERGYMETER}
                            period="last-24h"
                            showYLabel={false}
                            formatNumber={true}
                            phases={phaseValue}
                            nameL1={phaseNameL1}
                            nameL2={phaseNameL2}
                            nameL3={phaseNameL3}
                        />
                    </Col>
                )}
                {parName == null && (
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                        <Chart
                            loading={loading}
                            data={currentWeekData}
                            title={geti18nText('report.time_range.current_week')}
                            deviceId={record.id}
                            category={DeviceCategoryType.ENERGYMETER}
                            period="current-week"
                            showYLabel={false}
                            formatNumber={true}
                            phases={phaseValue}
                            nameL1={phaseNameL1}
                            nameL2={phaseNameL2}
                            nameL3={phaseNameL3}
                        />
                    </Col>
                )}
            </Row>
            {parName == null ? (
                <Row>
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                        <Chart
                            loading={loading}
                            data={lastWeekData}
                            title={geti18nText('report.time_range.last_week')}
                            deviceId={record.id}
                            category={DeviceCategoryType.ENERGYMETER}
                            period="last-week"
                            showYLabel={false}
                            formatNumber={true}
                            phases={phaseValue}
                            nameL1={phaseNameL1}
                            nameL2={phaseNameL2}
                            nameL3={phaseNameL3}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                        <Chart
                            loading={loading}
                            data={currentMonthData}
                            title={geti18nText('report.time_range.current_month')}
                            deviceId={record.id}
                            category={DeviceCategoryType.ENERGYMETER}
                            period="current-month"
                            showYLabel={false}
                            formatNumber={true}
                            phases={phaseValue}
                            nameL1={phaseNameL1}
                            nameL2={phaseNameL2}
                            nameL3={phaseNameL3}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                        <Chart
                            loading={loading}
                            data={currentYearData}
                            title={geti18nText('report.time_range.current_year')}
                            deviceId={record.id}
                            category={DeviceCategoryType.ENERGYMETER}
                            period="current-year"
                            showYLabel={false}
                            formatNumber={true}
                            phases={phaseValue}
                            nameL1={phaseNameL1}
                            nameL2={phaseNameL2}
                            nameL3={phaseNameL3}
                        />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                        <CharEnergySpan
                            phases={phaseValue}
                            nameL1={phaseNameL1}
                            nameL2={phaseNameL2}
                            nameL3={phaseNameL3}
                        />
                    </Col>
                </Row>
            ) : (
                <ZoomEnergyChart
                    loading={loading}
                    deviceId={record.id}
                    parm={parName}
                    phases={phaseValue}
                    nameL1={phaseNameL1}
                    nameL2={phaseNameL2}
                    nameL3={phaseNameL3}
                ></ZoomEnergyChart>
            )}
        </React.Fragment>
    );
};

export default DeviceEnergyStatisticDetail;
