import React from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import DeleteFromGroup from './DeleteFromGroup';
import { ENUMS, getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable } from '@nybble/nyreact';
import DeviceEdit from '../device/edit';

const DeviceInGroupTable = (props: any) => {
    const { device, refreshDevice } = useSelector((state: RootState) => state.selectedDeviceGroup);

    const setDefaultFilterValue = () => {
        return [
            { field: 'active', condition: 'equals_bool', value: 1 },
            { field: 'device_group_id', condition: 'equals', value: device },
        ];
    };

    return (
        <NyDataTable
            headerTitle={geti18nText('device.table.header')}
            url={CONSTANTS_REQ.DEVICE.LIST}
            setDefaultFilterValue={setDefaultFilterValue}
            showRowSelection={true}
            exportCSV={true}
            scroll={{ x: 800 }}
            rowSelectionModal={DeleteFromGroup}
            fetchWhenChange={refreshDevice}
            showRecordModal={true}
            modalComponent={DeviceEdit}
            hideNewButton={true}
            addedData={props && props.type ? { type: props.type } : undefined}
            columns={[
                {
                    title: geti18nText('device.table.column.id'),
                    dataIndex: 'id',
                    width: '10%',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('number'),
                },
                {
                    title: geti18nText('device.table.column.name'),
                    dataIndex: 'name',
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('device.table.column.deviceType'),
                    dataIndex: ['deviceType', 'name'],
                    sorter: (a: any, b: any) => {},
                    ...getColumnSearch('string'),
                },
                {
                    title: geti18nText('device.table.column.active'),
                    dataIndex: 'active',
                    width: '10%',
                    render: (text: any, record: { active: any }) => {
                        if (record.active) {
                            return <CheckOutlined style={{ color: 'green' }} />;
                        } else {
                            return <CloseOutlined style={{ color: 'red' }} />;
                        }
                    },
                    ...getColumnSearchOption(ENUMS.ACTIVE(), 'equals_bool', setDefaultFilterValue()[0]['value']),
                    defaultFilteredValue: setDefaultFilterValue(),
                },
            ]}
        />
    );
};

export default DeviceInGroupTable;
