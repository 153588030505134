import { ArrowDownOutlined, ArrowUpOutlined, ExperimentOutlined, FireOutlined } from '@ant-design/icons';
import { NyRequestResolver, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Checkbox, Popover, Progress, Row } from 'antd';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import {
    deviceWeatherStateDetailsChartOpen,
    deviceWeatherStateDetailsOpen,
} from '../../../slices/deviceWeatherStateDetailsReducer';
import moment from 'moment';
import { calculatePercent, calculateProgressGradient, formLabelCard } from './cardsLabel';
import { setUserWeatherWidgets } from '../../../slices/dashboardSlice';
import { parametarKeyName } from '../../../utils/Utils';

const SelectedWeatherlabel: React.FC = (props: any) => {
    const [visibleSettings, setVisibleSettings] = useState(false);
    const [checkedCards, setCheckedCards] = useState<any>([]);
    const [selectedDevice, setSelectedDevice] = useState<any>(undefined);
    const {
        selectedIdToShowDetail,
        queryId,
        weatherParameters,
        userWeatherWidgets,
        selectedDefaultDeviceToShowDetail,
    } = useSelector((state: RootState) => state.dashboard);
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    // const [userWidgets, setUserWidgets] = useState<any>();
    const [filterOptions, setFilterOptions] = useState<any>([]);
    const dispatch = useDispatch();
    const gridRef = useRef<any>();

    useEffect(() => {
        const options = weatherParameters?.map((item: any) => ({
            label: item[parametarKeyName('name')],
            key: item.key,
            value: item.key,
        }));
        setFilterOptions(options);
    }, [weatherParameters]);

    useEffect(() => {
        fetchWeatherStatePins();
        let widgetData = { ...userWeatherWidgets };
        if (selectedIdToShowDetail != undefined) {
            if (widgetData != undefined) {
                widgetData.selectedDevice = selectedIdToShowDetail;
                //  setUserWidgets(widgetData);
                NyRequestResolver.requestPost(
                    CONSTANTS_REQ.USER.WEATHER_WIDGETS,
                    undefined,
                    { widgets: JSON.stringify(widgetData) },
                    false
                );
                dispatch(setUserWeatherWidgets(widgetData));
            }
        }
    }, [selectedIdToShowDetail, queryId]);

    async function fetchWeatherStatePins() {
        setLoading(true);
        let tmpSelectedId = selectedIdToShowDetail;
        setSelectedDevice(tmpSelectedId);
        let data: any = {
            categoryId: queryId,
            deviceParameters: true,
        };
        if (tmpSelectedId?.id != undefined) {
            data.deviceId = tmpSelectedId.id;
        }
        let result = await NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.DEVICE_CONSUMTION_WITH_LIMIT, data);
        if (result.status === RESPONSE.OK && result.data) {
            let resultData: any = result.data;
            resultData = resultData.map((data: any) => {
                data.chartValue = data.value;
                delete data.value;
                return data;
            });
            if (resultData.length == 0) setSelectedDevice(null);
            //  setCheckedCards([]);

            // const checkedCards =
            //     userWeatherWidgets?.checkedCard instanceof Array
            //         ? userWeatherWidgets.checkedCard.find((i: any) => i.category === queryId)?.items
            //         : null;

            setCheckedCards(resultData.filter((f: any) => f.key !== 'INACTIVE').map((rData: any) => rData.key));

            setData(resultData);
        }

        setLoading(false);
    }

    const onChange = (values: any) => {
        setCheckedCards(values);
    };

    const onVisibleChange = (visible: any) => {
        setVisibleSettings(visible);
        if (!visible) {
            let widgetData: any = { ...userWeatherWidgets };
            if (queryId == undefined) return;

            let item = {
                items: checkedCards,
                category: queryId,
            };
            if (widgetData.checkedCard != undefined) {
                widgetData.checkedCard = widgetData.checkedCard ? [...widgetData.checkedCard] : [];
                let existingIndex = widgetData.checkedCard.findIndex((i: any) => i.category === item.category);
                if (existingIndex !== -1) {
                    widgetData.checkedCard[existingIndex] = item;
                } else {
                    widgetData.checkedCard.push(item);
                }
            } else {
                widgetData.checkedCard = [item];
            }

            dispatch(setUserWeatherWidgets(widgetData));
            NyRequestResolver.requestPost(
                CONSTANTS_REQ.USER.WEATHER_WIDGETS,
                undefined,
                { widgets: JSON.stringify(widgetData) },
                false
            );
        }
    };

    return loading ? (
        <NySpinner />
    ) : (
        <div style={{ padding: 10 }}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 15 }}>
                {selectedDevice && (
                    <div style={{ fontSize: 16, width: '50%', fontWeight: 'bold' }}>
                        {selectedDevice.name +
                            ' - ' +
                            selectedDevice.serialNumber +
                            ' - ' +
                            moment(selectedDevice.ts).format('DD.MM.yyyy HH:mm')}
                    </div>
                )}

                <div style={{ justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                    <Popover
                        visible={visibleSettings}
                        onVisibleChange={onVisibleChange}
                        placement="bottom"
                        overlayStyle={{ width: '20%' }}
                        content={
                            <div>
                                <Row gutter={[12, 12]}>
                                    <Checkbox.Group
                                        defaultValue={checkedCards}
                                        onChange={onChange}
                                        options={filterOptions}
                                    />
                                </Row>
                                {/* <Button type="link" onClick={() => {}}>
                                    {geti18nText('dashboard.widget.map.clearfilter')}
                                </Button> */}
                            </div>
                        }
                        trigger="click"
                    >
                        <Button type="primary" shape="round" icon={<ExperimentOutlined />} />
                    </Popover>
                </div>
            </div>
            <div ref={gridRef} style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {data &&
                    data.map((card: any) => {
                        return (
                            <div
                                style={{
                                    flex: '0 1 300px',
                                    flexDirection: 'row',
                                    display: 'flex',
                                    padding: '10px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    console.log('Otvaram ', {
                                        chartRecord: selectedDevice,
                                        chartVisible: true,
                                        chartParmKey: card.parameter.dbColumn,
                                    });
                                    dispatch(
                                        deviceWeatherStateDetailsChartOpen({
                                            chartRecord: selectedDevice,
                                            chartVisible: true,
                                            chartParmKey: card.parameter.dbColumn,
                                            chartParmeter: card.parameter,
                                            category: queryId,
                                        })
                                    );
                                }}
                            >
                                <div style={{ position: 'relative', width: '100px', height: '150px' }}>
                                    {card.parameter?.icon && (
                                        <img
                                            style={{
                                                position: 'absolute',
                                                height: 60,
                                                width: 60,
                                                top: '20px',
                                                left: '30px',
                                            }}
                                            src={'data:image/jpeg;base64,' + card.parameter?.icon}
                                        ></img>
                                    )}
                                    <div style={{ position: 'absolute' }}>
                                        <Progress
                                            type="dashboard"
                                            format={(percent) => ``}
                                            strokeColor={calculateProgressGradient(card)}
                                            gapDegree={120}
                                            strokeLinecap={'square'}
                                            percent={calculatePercent(card)}
                                        />
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '250px' }}>
                                            {card.showAlarm === true && card.lowerLimit && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <span>
                                                        {geti18nText('weatherStateDetails.graph.alarm')}
                                                        {':  '}
                                                    </span>
                                                    <ArrowDownOutlined
                                                        style={{
                                                            fontSize: '16px',
                                                            color: '#108ee9',
                                                            marginLeft: '3px',
                                                        }}
                                                    />
                                                    <span>{card.lowerLimit + ' ' + card.unit}</span>
                                                </div>
                                            )}

                                            {card.showAlarm === true && card.upperLimit && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        marginLeft: card.lowerLimit ? '30px' : '0px',
                                                    }}
                                                >
                                                    <span>
                                                        {geti18nText('weatherStateDetails.graph.alarm')}
                                                        {':  '}
                                                    </span>
                                                    <ArrowUpOutlined
                                                        style={{
                                                            fontSize: '16px',
                                                            color: '#a3264b',
                                                            marginLeft: '3px',
                                                        }}
                                                    />
                                                    <span>{card.upperLimit + ' ' + card.unit}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div></div>
                                </div>
                                <div style={{ marginLeft: 20 }}>
                                    <div
                                        style={{
                                            fontSize: 16,
                                            color: formLabelCard(card),
                                            fontWeight: 'bold',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {card.label}
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div
                                            style={{
                                                fontSize: 28,
                                                marginLeft: 10,
                                                color: formLabelCard(card),
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {card.chartValue ? card.chartValue : 0}
                                        </div>
                                        <div style={{ fontSize: 18, fontWeight: 'bold', color: formLabelCard(card) }}>
                                            {card.unit}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default SelectedWeatherlabel;
