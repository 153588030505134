import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal, Popconfirm, Row, Tag, Tooltip } from 'antd';
import { AlertOutlined, FormOutlined, FullscreenOutlined, ZoomInOutlined } from '@ant-design/icons';

import {
    ENUMS,
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NySession,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { alarmDescriptionOpen, refreshDeviceAlarmList } from '../../../slices/alarmDescriptionSlice';
import { AlarmSettingsType, CONSTANTS_REQ, DeviceCategoryType } from '../../../utils/Constants';
import { RootState } from '../../../rootReducer';
import { GetEnumNameForValue } from '../../../utils/Enums';
import { formatNumberValueString } from '../../number-formatter';
import {
    filterDeviceDevices,
    filterReset,
    refreshAlarmList,
    refreshMenu,
    setMapPanTo,
    startRefresAlarmList,
    stopRefresAlarmList,
} from '../../../slices/dashboardSlice';
import { deviceWeatherStateDetailsOpen } from '../../../slices/deviceWeatherStateDetailsReducer';
import AlarmSettingsIndex from '../../alarm-settings';
import { parametarKeyName } from '../../../utils/Utils';

const getScrolls = (dataGrid: { [index: string]: string | number | boolean }) => {
    let scr: { [index: string]: any } = { x: 800 };
    if (dataGrid != undefined) {
        if (typeof dataGrid.h == 'number') {
            scr.y = 40 * dataGrid.h - 190;
        }
    }

    return scr;
};
const AlarmsWeatherStateTable = ({ dataGrid, previewType = 'DASHBOARD' }: any) => {
    const { record } = useSelector((state: RootState) => state.deviceWeatherStateDetails);
    const dispatch = useDispatch();
    const [showAlarmBtn, setShowAlarmBtn] = useState(false);
    const [visible, setVisible] = useState(false);
    const [visibleSettingsModal, setVisibleSettingsModal] = useState(false);
    const [deviceId, setDeviceId] = useState(null);
    const {
        filterDevice,
        alarmListRefresh,
        mapSelectedBounds,
        queryId,
        selectedDeviceGroupId,
        selectedWeatherDataType,
        selectedIdToShowDetail,
    } = useSelector((state: RootState) => state.dashboard);
    const timerRef = useRef<any>(null);

    useEffect(() => {
        if (previewType == 'DASHBOARD') {
            if (alarmListRefresh < 1) {
                if (timerRef.current != null) {
                    clearTimeout(timerRef.current);
                }
                timerRef.current = setTimeout(() => {
                    dispatch(startRefresAlarmList());
                }, 3 * 60 * 1000);
            }
            return function cleanup() {
                dispatch(stopRefresAlarmList());
            };
        }
    }, []);

    useEffect(() => {
        if (previewType == 'DASHBOARD') {
            if (timerRef.current != null) {
                clearTimeout(timerRef.current);
            }
            timerRef.current = setTimeout(() => {
                if (alarmListRefresh > 0) {
                    dispatch(refreshAlarmList());
                }
            }, 60 * 1000);
        }
    }, [alarmListRefresh]);

    const onRowSelect = (record: { [index: string]: any }) => {
        let modifiedRecord = JSON.parse(JSON.stringify(record));
        modifiedRecord.id = record.device_id;
        dispatch(deviceWeatherStateDetailsOpen({ record: modifiedRecord, visible: true }));
    };

    const showOnMap = (record: { [index: string]: any }) => {
        if (record.device.lat && record.device.lng) {
            dispatch(setMapPanTo({ lat: record.device.lat, lng: record.device.lng }));
        }
    };

    const showOnMapFilter = (item: any) => {
        if (filterDevice != item.device_id) {
            showOnMap(item);
            dispatch(filterDeviceDevices({ id: item.device_id }));
        } else {
            dispatch(filterReset());
        }
    };

    useEffect(() => {
        dispatch(refreshAlarmList());
        console.log('ALARM REFRESH');
    }, [mapSelectedBounds, queryId, selectedDeviceGroupId, selectedWeatherDataType, selectedIdToShowDetail]);

    const setDefaultFilterValue = () => {
        if (record?.id != undefined && previewType == 'BY_DEVICE') {
            return [
                //  { field: 'unread', condition: 'equals_bool', value: 1 },
                { field: 'deviceId', condition: 'equals', value: record.id },
            ];
        }
        if (previewType == 'BY_USER') {
            return [{ field: 'userAlarmAlert.unread', condition: 'equals_bool', value: 1 }];
        }
        return [{ field: 'unread', condition: 'equals_bool', value: 1 }];
    };

    const editDescription = (deviceId: string, data?: { [index: string]: any }) => {
        dispatch(
            alarmDescriptionOpen({
                deviceId: deviceId,
                data: data,
                visible: true,
                deviceAlarmListRefresh: 0,
                deviceType: DeviceCategoryType.WEATHER,
            })
        );
    };

    const alarmEnumName: any = (status: number) => {
        return geti18nText('app.enum.ALARM_STATUS.' + GetEnumNameForValue('ALARM_STATUS', status));
    };

    const csvCustomizationColumns: any = () => {
        return [
            {
                'deviceConsumption.ts': (value: any) => {
                    if (value) {
                        const ts: Date = new Date(value);
                        return ts.toLocaleString(NyUtils.getSelectedLocale());
                    }
                    return '';
                },
            },
            {
                key: (value: any) => {
                    if (value) {
                        return geti18nText('alarm.settings.edit.' + value.toLocaleLowerCase());
                    }
                    return '';
                },
            },
            {
                alarm: (value: any) => {
                    if (value) {
                        return alarmEnumName(value.alarmStatus);
                    }
                    return '';
                },
            },
        ];
    };

    function getCsvColumns() {
        return [
            {
                title: geti18nText('dashboard.widget.alarm.table.name'),
                dataIndex: 'device.name',
            },
            {
                title: geti18nText('dashboard.widget.alarm.table.serial'),
                dataIndex: 'device.eui',
            },
            {
                title: geti18nText('dashboard.widget.alarm.table.type'),
                dataIndex: 'alarm',
            },

            {
                title: geti18nText('dashboard.widget.alarm.table.lastactivity'),
                dataIndex: 'deviceConsumption.ts',
            },
            {
                title: geti18nText('dashboard.widget.alarm.table.key'),
                dataIndex: 'key',
            },
            {
                title: geti18nText('dashboard.widget.alarm.table.value'),
                dataIndex: 'value',
            },
        ];
    }

    const onUnreadClick = (record: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.ALARM.READ + '/' + record.id, undefined, {
            id: record.id,
            category: record.deviceCategory,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                dispatch(refreshAlarmList());
            }
        });
    };

    const onUnreadAllClick = () => {
        let item: any = {};
        if (record?.id != undefined && previewType == 'BY_DEVICE') {
            item = { deviceId: record.id };
        }
        item['category'] = DeviceCategoryType.WEATHER;
        if (queryId != undefined && queryId > 0) {
            item != undefined ? (item['categoryId'] = queryId) : (item = { categoryId: queryId });
        }

        NyRequestResolver.requestPost(CONSTANTS_REQ.ALARM.READ_ALL, undefined, item).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                dispatch(refreshAlarmList());
                dispatch(refreshMenu());
            }
        });
    };

    const onUnreadByUserClick = (id: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.ALARM.WEATHER_USER_READ + '/' + id, undefined, {
            id: id,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                dispatch(refreshAlarmList());
                dispatch(refreshMenu());
            }
        });
    };

    const onUnreadAllByUserClick = () => {
        NyRequestResolver.requestPost(CONSTANTS_REQ.ALARM.WEATHER_USER_READ_ALL, undefined, {
            userId: NySession.getUser().id,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                dispatch(refreshAlarmList());
                dispatch(refreshMenu());
            }
        });
    };

    const unreadFilterType: any = () => {
        let types = [
            { id: 1, text: geti18nText('dashboard.widget.alarm.unread.filter.unread') },
            { id: 0, text: geti18nText('dashboard.widget.alarm.unread.filter.read') },
        ];

        return types;
    };

    const onLoadData = (data: { [index: string]: any } | string, params: { [index: string]: any } | undefined) => {
        setShowAlarmBtn(data != undefined && data.length > 0 ? true : false);
        if (data != undefined && data.length > 0) {
            const items: any = data;
            const firstItem = items[0];
            if (firstItem.unread == false) {
                setShowAlarmBtn(false);
            }
        }
    };

    const addData = () => {
        let item: any =
            mapSelectedBounds != null && previewType == 'DASHBOARD'
                ? {
                      latNorthEast: mapSelectedBounds.latNorthEast,
                      lngNorthEast: mapSelectedBounds.lngNorthEast,
                      latSouthWest: mapSelectedBounds.latSouthWest,
                      lngSouthWest: mapSelectedBounds.lngSouthWest,
                  }
                : undefined;
        if (queryId != undefined && queryId > 0) {
            item != undefined ? (item['categoryId'] = queryId) : (item = { categoryId: queryId });
        }

        if (selectedDeviceGroupId != undefined) {
            item != undefined
                ? (item['deviceGroup'] = selectedDeviceGroupId)
                : (item = { deviceGroup: selectedDeviceGroupId });
        }
        if (selectedWeatherDataType != undefined) {
            item != undefined
                ? (item['key'] = selectedWeatherDataType.key)
                : (item = { key: selectedWeatherDataType.key });
        }
        if (previewType == 'BY_USER') {
            if (item != undefined) {
                item['userId'] = NySession.getUser().id;
            } else {
                item = { userId: NySession.getUser().id };
            }
        }
        return item;
    };

    const columns = () => {
        let columns = [
            {
                title: geti18nText('dashboard.widget.alarm.table.name'),
                dataIndex: ['device', 'name'],
                sorter: (a: string, b: string) => {},
                ...getColumnSearch('string'),
            },
            {
                title: geti18nText('dashboard.widget.alarm.table.serial'),
                dataIndex: ['device', 'eui'],
                sorter: (a: string, b: string) => {},
                ...getColumnSearch('string'),
            },

            {
                title: geti18nText('dashboard.widget.alarm.table.type'),
                dataIndex: 'alarmStatus',
                sorter: (a: string, b: string) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    return (
                        <div>
                            <b style={{ color: 'red' }}>{alarmEnumName(record.alarmStatus)}</b>
                        </div>
                    );
                },
            },
            {
                title: geti18nText('dashboard.widget.alarm.table.key'),
                dataIndex: 'key',
                sorter: (a: string, b: string) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.key != undefined) {
                        if (record.key == 'INACTIVE' && record.value) {
                            return (
                                <div>
                                    {geti18nText('alarm.settings.edit.inactive.ts') +
                                        ': ' +
                                        new Date(record.value).toLocaleString(NyUtils.getSelectedLocale())}
                                </div>
                            );
                        }
                        return <div>{record[parametarKeyName('name')] + ': ' + record.value}</div>;
                    }
                    return null;
                },
            },

            {
                title: geti18nText('dashboard.widget.alarm.table.created'),
                dataIndex: ['created'],
                sorter: (a: string, b: string) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.created) {
                        const ts: Date = new Date(record.created);
                        return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                    }
                },
            },
            {
                dataIndex: 'operation',
                width: '10%',
                render: (text: string, record: { [index: string]: any }) => {
                    return (
                        <React.Fragment>
                            <Tooltip title={geti18nText('dashboard.widget.alarm.table.description.tooltip')}>
                                {record.description ? (
                                    <FormOutlined
                                        style={{
                                            fontSize: '18px',
                                            marginRight: '10px',
                                            color: '#40a9ff',
                                        }}
                                        onClick={() =>
                                            editDescription(record.alarm_id, {
                                                description: record.description,
                                            })
                                        }
                                    />
                                ) : (
                                    <FormOutlined
                                        style={{
                                            fontSize: '18px',
                                            marginRight: '10px',
                                        }}
                                        onClick={() =>
                                            editDescription(record.alarm_id, {
                                                description: record.description,
                                            })
                                        }
                                    />
                                )}
                            </Tooltip>
                            {record.device_id &&
                                record.device.lat &&
                                record.device.lng &&
                                previewType == 'DASHBOARD' && (
                                    <Tooltip title={geti18nText('dashboard.widget.watermeter.table.showonmap.tooltip')}>
                                        {filterDevice === record.device_id ? (
                                            <ZoomInOutlined
                                                style={{ fontSize: '18px', color: 'red', marginRight: '10px' }}
                                                onClick={() => showOnMapFilter(record)}
                                            />
                                        ) : (
                                            <ZoomInOutlined
                                                style={{ fontSize: '18px', marginRight: '10px' }}
                                                onClick={() => showOnMapFilter(record)}
                                            />
                                        )}
                                    </Tooltip>
                                )}
                            {previewType == 'DASHBOARD' && (
                                <Tooltip title={geti18nText('dashboard.widget.watermeter.table.settings.tooltip')}>
                                    <AlertOutlined
                                        style={{ fontSize: '18px', marginRight: '10px' }}
                                        onClick={() => {
                                            setDeviceId(record.device?.id);
                                            setVisibleSettingsModal(true);
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </React.Fragment>
                    );
                },
            },
        ];

        let item: any = {
            dataIndex: 'unread',
            sorter: (a: string, b: string) => {},
            width: '5%',
            ...getColumnSearchOption(unreadFilterType(), 'equals_bool', setDefaultFilterValue()[0]['value']),
            render: (text: string, record: { [index: string]: any }) => {
                if (record.unread == true && previewType != 'BY_USER') {
                    return (
                        <Popconfirm
                            title={geti18nText('dashboard.widget.alarm.table.unread.btn.msg')}
                            onConfirm={() => {
                                onUnreadClick(record);
                                dispatch(refreshMenu());
                            }}
                        >
                            <Tag color="red">{geti18nText('dashboard.widget.alarm.table.unread.btn')}</Tag>
                        </Popconfirm>
                    );
                } else if (previewType == 'BY_USER' && record.userAlarmAlert?.unread == true) {
                    return (
                        <Popconfirm
                            title={geti18nText('dashboard.widget.alarm.table.unread.btn.msg')}
                            onConfirm={() => {
                                onUnreadByUserClick(record.userAlarmAlert?.id);
                                dispatch(refreshMenu());
                            }}
                        >
                            <Tag color="red">{geti18nText('dashboard.widget.alarm.table.unread.btn')}</Tag>
                        </Popconfirm>
                    );
                }
            },
        };
        if (previewType == 'BY_USER') {
            item.dataIndex = ['userAlarmAlert', 'unread'];
        }
        columns.push(item);
        return columns;
    };
    return (
        <>
            {previewType == 'DASHBOARD' && (
                <FullscreenOutlined
                    style={{ position: 'absolute', right: '5px', top: '5px', zIndex: 2 }}
                    onClick={() => setVisible(true)}
                />
            )}

            <Row gutter={24} style={{ float: 'right' }}>
                <Popconfirm
                    title={geti18nText('dashboard.widget.alarm.table.unread_all.btn.msg')}
                    onConfirm={() => {
                        previewType == 'BY_USER' ? onUnreadAllByUserClick() : onUnreadAllClick();
                    }}
                >
                    <Button className="margin-right" danger style={{ right: '25px', top: '5px' }}>
                        {geti18nText('dashboard.widget.alarm.table.unread_all.btn')}
                    </Button>
                </Popconfirm>
            </Row>

            <NyDataTable
                headerTitle={geti18nText('dashboard.widget.alarm.table.title')}
                url={CONSTANTS_REQ.DASHBOARD.WEATHER_STATE_ALARMS}
                hideButtons={true}
                exportCSV={true}
                onRowSelect={onRowSelect}
                scroll={getScrolls(dataGrid)}
                setDefaultFilterValue={setDefaultFilterValue}
                fetchWhenChange={alarmListRefresh}
                readonly={true}
                colCSVCustomization={csvCustomizationColumns()}
                csvColumns={getCsvColumns()}
                onDataLoaded={onLoadData}
                addedData={addData()}
                columns={columns()}
                setDefaultSortOrder={{ order: 'unread', orderType: 'desc' }}
            />
            <Modal
                title={geti18nText('dashboard.widget.alarm.table.title')}
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={'90%'}
                centered={true}
                footer={null}
                zIndex={1}
            >
                <NyDataTable
                    headerTitle={geti18nText('dashboard.widget.alarm.table.title')}
                    url={CONSTANTS_REQ.DASHBOARD.WEATHER_STATE_ALARMS}
                    hideButtons={true}
                    exportCSV={true}
                    setDefaultPageSize={100}
                    fetchWhenChange={alarmListRefresh}
                    scroll={getScrolls(dataGrid)}
                    setDefaultFilterValue={setDefaultFilterValue}
                    onRowSelect={onRowSelect}
                    columns={columns()}
                    csvColumns={getCsvColumns()}
                    colCSVCustomization={csvCustomizationColumns()}
                    addedData={addData()}
                    setDefaultSortOrder={{ order: 'unread', orderType: 'desc' }}
                />
            </Modal>
            <Modal
                // title={geti18nText('dashboard.widget.alarm.table.title')}
                visible={visibleSettingsModal}
                onOk={() => setVisibleSettingsModal(false)}
                onCancel={() => setVisibleSettingsModal(false)}
                width={'90%'}
                centered={true}
                footer={null}
                zIndex={2000}
            >
                {deviceId && <AlarmSettingsIndex type={AlarmSettingsType.DEVICE} deviceId={deviceId} />}
            </Modal>
        </>
    );
};

export default AlarmsWeatherStateTable;
