import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Input, Select, Divider, Button, Space, InputNumber, Descriptions } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { GetEnum, GetEnumNameForValue } from '../../utils/Enums';
import ConsumersEdit from '../consumers/edit';
import { geti18nText, NyDataEdit, NyRequestResolver, NyUtils, NySearchField, RESPONSE, NyFileUpload, NySession } from '@nybble/nyreact';

const { Option } = Select;

const TasksEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState(geti18nText('task.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [taskTypeEnum, setTaskTypeEnum] = useState<Number | undefined>(undefined);
    const [isCreate, setIsCreate] = useState(false);
    const [addEndCustomerVisible, setAddEndCustomerVisible] = useState(false);
    const [endCustomer, setEndCustomer] = useState(undefined);
    const [oldDevice, setOldDevice] = useState<Number | undefined>(undefined);
    const [selectedDevice, setSelectedDevice] = useState<Number | undefined>(undefined);
    const [endCustomerName, setEndCustomerName] = useState('');
    const [oldDeviceName, setOldDeviceName] = useState('');
    const [created, setCreated] = useState('');
    const [appName, setsetAppName] = useState(undefined);
    const [selectedDeviceName, setSelectedDeviceName] = useState('');
    const [address, setAddress] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('');
    const [coordinatesAddress, setCoordinatesAddress] = useState([]);
    const [coordinateSelect, setCoordinateSelect] = useState(undefined);
    const [customerId, setCustomerId] = useState(0);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    useEffect(() => {
        getCustomerId();
    }, [isCreate]);

    const getCustomerId = async function () {
        await NyRequestResolver.requestGet(CONSTANTS_REQ.CUSTOMER.CUSTOMER_ID ).then((response: any) => {
            if (response.status === RESPONSE.OK) {
               setCustomerId(response.data);
            }
        });
    };

    function setValues(dataForm: any) {
        setEditHeader(
            dataForm.name +
                ' - ' +
                geti18nText('app.enum.TASK_TYPE.' + GetEnumNameForValue('TASK_TYPE', dataForm.taskType))
        );
        setDataForm(dataForm.id);
        setEndCustomer(dataForm.endCustomer.id);
        setOldDevice(dataForm.oldDevice.id);
        setSelectedDevice(dataForm.device.id);
        setEndCustomerName(dataForm.endCustomer.fullName);
        setOldDeviceName(dataForm.oldDevice.name);
        setSelectedDeviceName(dataForm.device.name);
        setTaskTypeEnum(dataForm.taskType);
        if (dataForm.application.name) {
            setsetAppName(dataForm.application.name);
        }
        const ts: Date = new Date(dataForm.created);
        setCreated(ts.toLocaleString(NyUtils.getSelectedLocale()));

        dataForm.lat = dataForm.device.lat;
        dataForm.lng = dataForm.device.lng;
        delete dataForm.device.lat;
        delete dataForm.device.lng;
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const taskStatus = () => {
        const aTypes = GetEnum('TASK_STATUS');
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.TASK_STATUS.' + key);
                types.push(ret);
            }
        }
        return types;
    };

    const taskType = () => {
        const aTypes = GetEnum('TASK_TYPE');
        let types = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { id: undefined, text: undefined };
                ret.id = aTypes[key];
                ret.text = geti18nText('app.enum.TASK_TYPE.' + key);
                types.push(ret);
            }
        }
        return types;
    };

    const onTaskTypeChange = (value: any) => {
        if (value != null) {
            setTaskTypeEnum(value);
        }
    };

    const onAddNewEndCustomer = (id: number) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.END_CUSTOMER.EDIT + '/' + id).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                //form.setFieldsValue({ endCustomer: result.data });
                form.setFieldsValue({
                    endCustomer: {
                        id: result.data.id,
                        oib: result.data.firstName + ' ' + result.data.lastName + ' (' + result.data.oib + ')',
                    },
                });
            }
        });
    };

    const onEndCustomerChange = (value: any) => {
        if (value != null) {
            setEndCustomer(value.id);
        }
    };

    const onGetAdressFromEndCustomer = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.END_CUSTOMER.EDIT + '/' + endCustomer).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                form.setFieldsValue({
                    contact: result.data.contact,
                    lat: result.data.lat,
                    lng: result.data.lng,
                });
                setAddress(result.data.contact.address);
                setPostalCode(result.data.contact.postalCode);
                setCity(result.data.contact.city);
                setState(result.data.contact.state);
                setCountry(result.data.contact.country.name);
                setEndCustomer(undefined);
            }
        });
    };

    const onOldDeviceChange = (value: any) => {
        if (value != null) {
            getAdressFromDevice(value.id);
        }
    };

    const onDeviceChange = (value: any) => {
        if (value != null && GetEnumNameForValue('TASK_TYPE', taskTypeEnum) === 'DEVICE_READING_TASK') {
            getAdressFromDevice(value.id);
        }
    };

    const onGetAdressFromOldDevice = () => {
        if (oldDevice != undefined) {
            getAdressFromDevice(oldDevice);
        }
    };

    const getAdressFromDevice = (deviceId: Number) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.EDIT + '/' + deviceId).then((result: any) => {
            if (result.status === RESPONSE.OK) {
                form.setFieldsValue({
                    contact: result.data.contact,
                });
                if (result.data.contact != null) {
                    if (result.data.contact.address != null) setAddress(result.data.contact.address);
                    if (result.data.contact.postalCode != null) setPostalCode(result.data.contact.postalCode);
                    if (result.data.contact.city != null) setCity(result.data.contact.city);
                    if (result.data.contact.state != null) setState(result.data.contact.state);
                    if (result.data.contact.name != null) setCountry(result.data.contact.country.name);
                }
                if (result.data.lat && result.data.lng) {
                    form.setFieldsValue({
                        lat: result.data.lat,
                        lng: result.data.lng,
                    });
                }

                if (result.data.endCustomer && result.data.endCustomer.id) {
                    form.setFieldsValue({
                        endCustomer: { id: result.data.endCustomer.id, oib: result.data.endCustomer.firstName },
                    });
                }
            }
        });
    };

    const onModalClose = () => {
        setTaskTypeEnum(undefined);
        setCoordinateSelect(undefined);
        setCoordinatesAddress([]);
        form.resetFields();
        setEditHeader(geti18nText('task.edit.new'));
        setsetAppName(undefined);
        setCreated('');
    };

    const getCoordinatesFromAddress = () => {
        NyRequestResolver.requestGet(
            `https://nominatim.openstreetmap.org/search?street=${address}&city=${city}&country=${country}&state=${state}&postalCode=${postalCode}&format=json`
        ).then((data: any) => {
            setCoordinatesAddress(data.data);
        });
    };

    const onAddressChange = (value: any) => {
        if (value != null) {
            setAddress(value);
        }
    };

    const onPostalCodeChange = (value: any) => {
        if (value != null) {
            setPostalCode(value);
        }
    };

    const onCityChange = (value: any) => {
        if (value != null) {
            setCity(value);
        }
    };

    const onStateChange = (value: any) => {
        if (value != null) {
            setState(value);
        }
    };

    const onCountryChnage = (value: any) => {
        if (value != null) {
            setCountry(value);
        }
    };

    const onCoordinatesSelect = (value: any) => {
        if (value != null) {
            setCoordinateSelect(value);
        }
    };

    const onLoadCoordinates = () => {
        const coords: any = coordinatesAddress.filter((coordinate: any) => coordinate.place_id === coordinateSelect);
        if (coords != null && coords[0] != null) {
            form.setFieldsValue({
                lng: coords[0].lon,
                lat: coords[0].lat,
            });
        }
    };

    const normalizeValues = (values: any) => {
        let normalized: any = {};
        if (!isCreate) {
            normalized.id = values.id;
            normalized.status = values.status;
            normalized.device = { id: selectedDevice };
        } else {
            normalized.taskType = values.taskType;
            normalized.device = { id: values.device.constructor === Number ? values.device : values.device.id };
            if (values.oldDevice) {
                normalized.oldDevice = {
                    id: values.oldDevice.constructor === Number ? values.oldDevice : values.oldDevice.id,
                };
            }
            normalized.endCustomer = {
                id: values.endCustomer.constructor === Number ? values.endCustomer : values.endCustomer.id,
            };
        }
        normalized.contact = values.contact;
        normalized.active = values.active;
        normalized.contact.country = {
            id:
                values.contact.country === Number
                    ? values.contact.country
                    : values.contact.country.key
                    ? values.contact.country.key
                    : values.contact.country.id,
        };

        if (values.lat) {
            normalized.device.lat = values.lat;
        }
        if (values.lng) {
            normalized.device.lng = values.lng;
        }

        if (values.file) {
            normalized.file = values.file;
        }
        console.log(normalized);
        return normalized;
    };

    return (
        <React.Fragment>
            <NyDataEdit
                layout="horizontal"
                editHeader={editHeader}
                loading={loading}
                setLoading={setLoading}
                url={CONSTANTS_REQ.TASK.EDIT}
                setValues={setValues}
                width={1200}
                form={form}
                onModalClose={onModalClose}
                goBack={() => history.goBack()}
                setIsCreate={setIsCreate}
                paramsId={id}
                {...props}
                formProps={{ labelCol: { span: 4 }, wrapperCol: { span: 14 } }}
                normalize={normalizeValues}
            >
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item name="id" style={{ display: 'none' }}>
                            <Input />
                        </Form.Item>
                        {isCreate && (
                            <Form.Item label={geti18nText('task.edit.taskType')} name="taskType">
                                <Select onChange={onTaskTypeChange} disabled={!isCreate}>
                                    {taskType().map((option: { [index: string]: any }) => (
                                        <Option key={option.id} value={option.id}>
                                            {option.text}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                        {(props.value || taskTypeEnum != null) && (
                            <React.Fragment>
                                {!isCreate && (
                                    <React.Fragment>
                                        <Descriptions column={1}>
                                            <Descriptions.Item label={geti18nText('task.edit.created')}>
                                                <b>{created}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={geti18nText('task.edit.endCustomer')}>
                                                <b>{endCustomerName}</b>
                                            </Descriptions.Item>
                                            {GetEnumNameForValue('TASK_TYPE', taskTypeEnum) ===
                                                'DEVICE_REPLACEMENT_TASK' && (
                                                <Descriptions.Item label={geti18nText('task.edit.oldDevice')}>
                                                    <b>{oldDeviceName}</b>
                                                </Descriptions.Item>
                                            )}
                                            <Descriptions.Item label={geti18nText('task.edit.device')}>
                                                <b>{selectedDeviceName}</b>
                                            </Descriptions.Item>
                                            <Descriptions.Item label={geti18nText('task.edit.application')}>
                                                <b>{appName}</b>
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <Form.Item label={geti18nText('task.edit.status')} name="status">
                                            <Select>
                                                {taskStatus().map((option: { [index: string]: any }) => (
                                                    <Option key={option.id} value={option.id}>
                                                        {option.text}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </React.Fragment>
                                )}
                                {isCreate && (
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Form.Item
                                                label={geti18nText('task.edit.endCustomer')}
                                                style={{ marginBottom: 0 }}
                                            >
                                                <Form.Item  
                                                    name="endCustomer"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: geti18nText('app.default.required'),
                                                        },
                                                    ]}
                                                    style={{ display: 'inline-block', width: 'calc(60%)' }}
                                                >
                                                    <NySearchField
                                                        disabled={!isCreate}
                                                        onChange={onEndCustomerChange}
                                                        url={CONSTANTS_REQ.END_CUSTOMER.SEARCH}
                                                        map={{ id: 'id', label: 'oib' }}
                                                        searchBy="oib"
                                                    />
                                                </Form.Item>
                                                <Form.Item style={{ display: 'inline-block', float: "right" }}>
                                                    {isCreate && (  
                                                        <Button
                                                            onClick={() => {
                                                                setAddEndCustomerVisible(true);
                                                            }}
                                                        >
                                                            {geti18nText('task.edit.button.addEndCustomer')}
                                                        </Button>
                                                        
                                                    )}
                                                </Form.Item>
                                                 
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}

                                {GetEnumNameForValue('TASK_TYPE', taskTypeEnum) === 'DEVICE_REPLACEMENT_TASK' &&
                                    isCreate && (
                                        <Row gutter={24}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label={geti18nText('task.edit.oldDevice')}
                                                    name="oldDevice"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: geti18nText('app.default.required'),
                                                        },
                                                    ]}
                                                >
                                                    <NySearchField
                                                        disabled={!isCreate}
                                                        onChange={onOldDeviceChange}
                                                        url={CONSTANTS_REQ.DEVICE.SEARCH_FOR_TASKS}
                                                        addedFilter={{
                                                            field: 'status',
                                                            condition: 'equals',
                                                            value: GetEnum('DEVICE_STATUS')['IN_USE'],
                                                        }}
                                                        map={{ id: 'id', label: 'name' }}
                                                        searchBy="name"
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    )}
                                {isCreate && (
                                    <Row gutter={24}>
                                        <Col span={24}>
                                            <Form.Item
                                                label={geti18nText('task.edit.device')}
                                                name="device"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: geti18nText('app.default.required'),
                                                    },
                                                ]}
                                            >
                                                <NySearchField
                                                    disabled={!isCreate}
                                                    onChange={onDeviceChange}
                                                    url={CONSTANTS_REQ.DEVICE.SEARCH_FOR_TASKS}
                                                    addedFilter={{
                                                        field: 'status',
                                                        condition: 'equals',
                                                        value:
                                                            GetEnumNameForValue('TASK_TYPE', taskTypeEnum) ===
                                                            'DEVICE_READING_TASK'
                                                                ? GetEnum('DEVICE_STATUS')['IN_USE']
                                                                : GetEnum('DEVICE_STATUS')['IN_STOCK'],
                                                    }}
                                                    map={{ id: 'id', label: 'name' }}
                                                    searchBy="name"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                                 <Row gutter={24}>
                                    <Col span={24}>
                                        <Form.Item 
                                            label={geti18nText('task.edit.file')}
                                            name="file"
                                        >
                                            <NyFileUpload CONSTANTS_REQ = { CONSTANTS_REQ } customerId = { customerId } />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider>{geti18nText('task.edit.contact')}</Divider>
                                {isCreate && (
                                    <Space style={{ marginBottom: '10px' }}>
                                        <Button
                                            disabled={endCustomer == undefined || !isCreate}
                                            onClick={onGetAdressFromEndCustomer}
                                        >
                                            {geti18nText('task.edit.button.getFromEndCustomer')}
                                        </Button>
                                    </Space>
                                )}
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('task.edit.address')}
                                            name={['contact', 'address']}
                                            labelCol={{ span: 4 }}
                                            wrapperCol={{ span: 19 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <Input onBlur={(e: any) => onAddressChange(e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('task.edit.address2')}
                                            name={['contact', 'address2']}
                                            labelCol={{ span: 4 }}
                                            wrapperCol={{ span: 19 }}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('task.edit.postalCode')}
                                            name={['contact', 'postalCode']}
                                            labelCol={{ span: 4 }}
                                            wrapperCol={{ span: 19 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <Input onBlur={(e: any) => onPostalCodeChange(e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('task.edit.city')}
                                            name={['contact', 'city']}
                                            labelCol={{ span: 4 }}
                                            wrapperCol={{ span: 19 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <Input onBlur={(e: any) => onCityChange(e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('task.edit.state')}
                                            name={['contact', 'state']}
                                            labelCol={{ span: 4 }}
                                            wrapperCol={{ span: 19 }}
                                        >
                                            <Input onBlur={(e: any) => onStateChange(e.target.value)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('task.edit.country')}
                                            name={['contact', 'country']}
                                            labelCol={{ span: 4 }}
                                            wrapperCol={{ span: 19 }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: geti18nText('app.default.required'),
                                                },
                                            ]}
                                        >
                                            <NySearchField
                                                onChange={onCountryChnage}
                                                url={CONSTANTS_REQ.COUNTRY.SEARCH}
                                                map={{ id: 'id', label: 'name' }}
                                                searchBy="name"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('task.edit.email')}
                                            name={['contact', 'email']}
                                            labelCol={{ span: 4 }}
                                            wrapperCol={{ span: 19 }}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('task.edit.phone')}
                                            name={['contact', 'phone']}
                                            labelCol={{ span: 4 }}
                                            wrapperCol={{ span: 19 }}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {isCreate && (
                                    <Space style={{ marginBottom: '10px', marginTop: '20px' }}>
                                        <Button disabled={!isCreate} onClick={getCoordinatesFromAddress}>
                                            {geti18nText('task.edit.button.getCoordinatesFromAddress')}
                                        </Button>
                                        {coordinatesAddress.length > 0 && (
                                            <Select
                                                style={{ width: '800px' }}
                                                onSelect={onCoordinatesSelect}
                                                placeholder={geti18nText('task.edit.selectAddress')}
                                            >
                                                {coordinatesAddress.map((option: { [index: string]: any }) => (
                                                    <Option key={option.place_id} value={option.place_id}>
                                                        {option.display_name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                        {coordinateSelect != null && coordinatesAddress.length > 0 && (
                                            <Button onClick={onLoadCoordinates} type="primary" ghost>
                                                {geti18nText('task.edit.button.load')}
                                            </Button>
                                        )}
                                    </Space>
                                )}
                                <Form.Item name={['device', 'id']} style={{ display: 'none' }}>
                                    <Input />
                                </Form.Item>
                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('task.edit.lat')}
                                            name="lat"
                                            labelCol={{ span: 10 }}
                                            wrapperCol={{ span: 10 }}
                                        >
                                            <InputNumber decimalSeparator="." min={-90} max={90} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label={geti18nText('task.edit.lng')}
                                            name="lng"
                                            labelCol={{ span: 10 }}
                                            wrapperCol={{ span: 10 }}
                                        >
                                            <InputNumber decimalSeparator="." min={-180} max={180} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider />
                            </React.Fragment>
                        )}
                    </Col>
                </Row>
            </NyDataEdit>
            <ConsumersEdit
                isModal={true}
                onlyPage="true"
                visible={addEndCustomerVisible}
                setVisible={setAddEndCustomerVisible}
                onSaveAndGetID={onAddNewEndCustomer}
            />
        </React.Fragment>
    );
};

export default TasksEdit;
