import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISelectedItemState {
    item: any;
    refreshItem: number;
}

const initialState: ISelectedItemState = {
    item: undefined,
    refreshItem: 0,
};

const selectedItem = createSlice({
    name: 'selectedItem',
    initialState,
    reducers: {
        selectedItemInit(state) {
            state.item = {};
            state.refreshItem = 0;
        },
        selectedItemSet(state, action: PayloadAction<ISelectedItemState>) {
            const { item, refreshItem } = action.payload;
            state.item = item;
            state.refreshItem = refreshItem;
        },
        refreshItemList(state) {
            state.refreshItem++;
        },
    },
});

export const { selectedItemInit, selectedItemSet, refreshItemList } = selectedItem.actions;

export default selectedItem.reducer;
