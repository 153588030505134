import React, { useState, useEffect } from 'react';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { IWidget } from './index';
import { PushpinOutlined, ZoomInOutlined, FullscreenOutlined } from '@ant-design/icons';
import { deviceLocationOpen } from '../../slices/deviceLocationSlice';
import { RootState } from '../../rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { setMapPanTo, filterDeviceDevices, filterReset } from '../../slices/dashboardSlice';
import { Tooltip, Modal, Popover } from 'antd';
import { GetEnumNameForValue } from '../../utils/Enums';
import { getColumnSearch, getColumnSearchOption, geti18nText, NyDataTable, NySession, NyUtils } from '@nybble/nyreact';
import FormatNumber, { formatNumberValueString } from '../number-formatter';
import { deviceEnergyDetailsOpen } from '../../slices/deviceEnergyDetailsSlice';
import { deviceWeatherStateDetailsOpen } from '../../slices/deviceWeatherStateDetailsReducer';

const getScrolls = (dataGrid: { [index: string]: string | number | boolean | undefined }) => {
    let scr: { [index: string]: any } = {};
    if (typeof dataGrid.h == 'number') {
        scr.y = 40 * dataGrid.h - 170;
    }
    return scr;
};

const getModalScrolls = (dataGrid: { [index: string]: string | number | boolean | undefined }) => {
    let scr: { [index: string]: any } = { x: 1400 };

    return scr;
};

interface ColumnTitleProps {
    text: string;
}

const ColumnTitle: React.FC<ColumnTitleProps> = ({ text }) => {
    return <div style={{ whiteSpace: 'nowrap' }}>{text}</div>;
};

const WidgetWeatherMeterList: any = ({ dataGrid }:any) => {
    const [visible, setVisible] = useState(false);

    const [refresh, setRefresh] = useState<any>(0);
    const mapSelectedBounds = useSelector((state: RootState) => state.dashboard.mapSelectedBounds);
    const filterDevice = useSelector((state: RootState) => state.dashboard.filterDevice);
    const weatherStateActive = useSelector((state: RootState) => state.dashboard.weatherStateActive);
    const weatherStateInactive = useSelector((state: RootState) => state.dashboard.weatherStateInactive);
    const dispatch = useDispatch();

    useEffect(() => {
        setRefresh(new Date());
    }, [mapSelectedBounds, filterDevice, weatherStateActive, weatherStateInactive]);

    const editLocation = (deviceId: string, data?: { [index: string]: any }) => {
        dispatch(deviceLocationOpen({ deviceId: deviceId, data: data, visible: true }));
    };

    const onRowSelect = (record: { [index: string]: any }) => {
        dispatch(deviceWeatherStateDetailsOpen({ record: record, visible: true }));
    };

    const setDefaultFilterValue = () => {
        if (weatherStateActive) {
            return [{ field: 'alarmStatus', condition: 'not_equals', value: -48 }];
        }
        if (weatherStateInactive) {
            return [{ field: 'alarmStatus', condition: 'equals', value: -48 }];
        }

        return [];
    };

    const generateKey = () => {
        return 'WidgetWeatherMeterList-K1';
    };

    const showOnMap = (record: { [index: string]: any }) => {
        if (record.lat && record.lng) {
            dispatch(setMapPanTo({ lat: record.lat, lng: record.lng }));
        }
    };

    const showOnMapFilter = (item: any) => {
        if (filterDevice != item.id) {
            showOnMap(item);
            dispatch(filterDeviceDevices({ id: item.id }));
        } else {
            dispatch(filterReset());
        }
    };

    const columns: any = () => {
        return [
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.name')} />,
                dataIndex: ['device', 'name'],
                sorter: (a: string, b: string) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    return <div> {record.device?.name} </div>;
                },
                ...getColumnSearch('string'),
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.serial')} />,
                dataIndex: ['device', 'eui'],
                sorter: (a: string, b: string) => {},
                ...getColumnSearch('string'),
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.minWindDirection')} />,
                dataIndex: 'minWindDirection',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.minWindDirection ? NyUtils.formatNumber(record.minWindDirection, 0) : '0'}
                            &nbsp;°
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.avgWindDirection')} />,
                dataIndex: 'avgWindDirection',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.avgWindDirection ? NyUtils.formatNumber(record.avgWindDirection, 0) : '0'}
                            &nbsp;°
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.maxWindDirection')} />,
                dataIndex: 'maxWindDirection',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.maxWindDirection ? NyUtils.formatNumber(record.maxWindDirection, 0) : '0'}
                            &nbsp;°
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.minWindSpeed')} />,
                dataIndex: 'minWindSpeed',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.minWindSpeed ? NyUtils.formatNumber(record.maxWindDirection, 2) : '0,0'}
                            &nbsp;m/s
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.avgWindSpeed')} />,
                dataIndex: 'avgWindSpeed',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.avgWindSpeed ? NyUtils.formatNumber(record.avgWindSpeed, 2) : '0,0'}
                            &nbsp;m/s
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.maxWindSpeed')} />,
                dataIndex: 'maxWindSpeed',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.maxWindSpeed ? NyUtils.formatNumber(record.maxWindSpeed, 2) : '0,0'}
                            &nbsp;m/s
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.temp')} />,
                dataIndex: 'temp',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.temp ? NyUtils.formatNumber(record.temp, 2) : '0,0'}
                            &nbsp;°
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.humidity')} />,
                dataIndex: 'humidity',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.humidity ? NyUtils.formatNumber(record.humidity, 2) : '0,0'}
                            &nbsp;%
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.airPressure')} />,
                dataIndex: 'airPressure',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.airPressure ? NyUtils.formatNumber(record.airPressure, 2) : '0,0'}
                            &nbsp;HPa
                        </div>
                    );
                },
            },

            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.rainfall')} />,
                dataIndex: 'rainfall',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.rainfall ? NyUtils.formatNumber(record.rainfall, 2) : '0,0'}
                            &nbsp;mm/s
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.UVIntensity')} />,
                dataIndex: 'UVIntensity',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.UVIntensity ? NyUtils.formatNumber(record.UVIntensity, 0) : '0'}
                            &nbsp;W
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.minNoise')} />,
                dataIndex: 'minNoise',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.minNoise ? NyUtils.formatNumber(record.minNoise, 0) : '0'}
                            &nbsp;dB
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.avgNoise')} />,
                dataIndex: 'avgNoise',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.avgNoise ? NyUtils.formatNumber(record.avgNoise, 0) : '0'}
                            &nbsp;dB
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.maxNoise')} />,
                dataIndex: 'maxNoise',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.maxNoise ? NyUtils.formatNumber(record.maxNoise, 0) : '0'}
                            &nbsp;dB
                        </div>
                    );
                },
            },
            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.weather.table.lux')} />,
                dataIndex: 'lux',
                align: 'right',
                sorter: (a: number, b: number) => {},
                render: (text: string, record: { [index: string]: number }) => {
                    return (
                        <div>
                            {record.lux ? NyUtils.formatNumber(record.maxNluxoise, 0) : '0'}
                            &nbsp;L
                        </div>
                    );
                },
            },

            {
                title: <ColumnTitle text={geti18nText('dashboard.widget.watermeter.table.lastactivity')} />,
                dataIndex: 'ts',
                sorter: (a: string, b: string) => {},
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.ts) {
                        var ts: any = new Date(record.ts);
                        if (ts !== '') {
                            return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                        } else {
                            return <div></div>;
                        }
                    }
                },
            },

            {
                dataIndex: 'operation',
                width: NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER']) ? 125 : 30,
                align: 'left',
                render: (text: string, record: { [index: string]: any }) => {
                    if (record.id) {
                        return (
                            <div>
                                {NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER']) && (
                                    <React.Fragment>
                                        <Tooltip
                                            title={geti18nText('dashboard.widget.watermeter.table.location.tooltip')}
                                        >
                                            <PushpinOutlined
                                                style={{ fontSize: '18px', marginRight: '10px' }}
                                                onClick={() =>
                                                    editLocation(record.id, {
                                                        latitude: record.lat,
                                                        longitude: record.lng,
                                                    })
                                                }
                                            />
                                        </Tooltip>
                                    </React.Fragment>
                                )}
                                {record.lat != undefined && (
                                    <Tooltip title={geti18nText('dashboard.widget.watermeter.table.showonmap.tooltip')}>
                                        {filterDevice === record.id ? (
                                            <ZoomInOutlined
                                                style={{ fontSize: '18px', color: 'red' }}
                                                onClick={() => showOnMapFilter(record)}
                                            />
                                        ) : (
                                            <ZoomInOutlined
                                                style={{ fontSize: '18px' }}
                                                onClick={() => showOnMapFilter(record)}
                                            />
                                        )}
                                    </Tooltip>
                                )}
                            </div>
                        );
                    }
                },
            },
        ];
    };

    const hideColumnsInMainTable: any = [
        'minWindDirection',
        'maxWindDirection',
        'minWindSpeed',
        'maxWindSpeed',
        'minNoise',
        'maxNoise',
    ];
    function getCsvColumns() {
        return [
            {
                title: geti18nText('dashboard.widget.light.table.name'),
                dataIndex: 'device.name',
            },
            {
                title: geti18nText('dashboard.widget.light.table.serial'),
                dataIndex: 'device.eui',
            },
        ];
    }

    const csvCustomizationColumns: any = () => {
        return [
            {
                ts: (value: any) => {
                    if (value) {
                        var ts: any = new Date(value);
                        if (ts !== '') {
                            return ts.toLocaleString(NyUtils.getSelectedLocale());
                        }
                    }

                    return '';
                },
            },
            {
                current: (value: any) => {
                    if (value != undefined) {
                        return NyUtils.formatNumber(value, 2);
                    }
                    return value;
                },
            },
            {
                action: (value: any) => {
                    if (value != undefined) {
                        return geti18nText(
                            'app.enum.PAYLOAD_STATUS_ACTION.' + GetEnumNameForValue('PAYLOAD_STATUS_ACTION', value)
                        );
                    }
                    return '';
                },
            },
        ];
    };

    return (
        <React.Fragment>
            <FullscreenOutlined
                style={{ position: 'absolute', right: '5px', top: '5px' }}
                onClick={() => setVisible(true)}
            />
            <NyDataTable
                key={generateKey()}
                headerTitle={geti18nText('dashboard.widget.weather.table.title')}
                url={CONSTANTS_REQ.DASHBOARD.WEATHER_METER_LIST}
                hideButtons={true}
                exportCSV={true}
                onRowSelect={onRowSelect}
                addedData={
                    mapSelectedBounds != null
                        ? {
                              latNorthEast: mapSelectedBounds.latNorthEast,
                              lngNorthEast: mapSelectedBounds.lngNorthEast,
                              latSouthWest: mapSelectedBounds.latSouthWest,
                              lngSouthWest: mapSelectedBounds.lngSouthWest,
                          }
                        : undefined
                }
                setDefaultFilterValue={setDefaultFilterValue}
                scroll={getScrolls(dataGrid)}
                fetchWhenChange={refresh}
                columns={columns().filter((x: any) => !hideColumnsInMainTable.includes(x.dataIndex))}
                csvColumns={getCsvColumns()}
                colCSVCustomization={csvCustomizationColumns()}
            />
            <Modal
                title={geti18nText('dashboard.widget.weather.table.title')}
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                width={'100%'}
                footer={null}
            >
                {visible && (
                    <NyDataTable
                        key={generateKey()}
                        headerTitle={geti18nText('dashboard.widget.weather.table.title')}
                        url={CONSTANTS_REQ.DASHBOARD.WEATHER_METER_LIST}
                        hideButtons={true}
                        exportCSV={true}
                        onRowSelect={onRowSelect}
                        setDefaultFilterValue={setDefaultFilterValue}
                        scroll={getModalScrolls(dataGrid)}
                        fetchWhenChange={refresh}
                        columns={columns()}
                        csvColumns={getCsvColumns()}
                        colCSVCustomization={csvCustomizationColumns()}
                    />
                )}
            </Modal>
        </React.Fragment>
    );
};

export default WidgetWeatherMeterList;
